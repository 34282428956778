import React, { ChangeEvent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { format } from 'date-fns'

import Button from 'components/basics/Button/Button'
import DatePicker from 'components/basics/DatePicker/DatePicker'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Select from 'components/basics/Input/Select/Select'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { DATE_FORMAT_Y_M_D_DASH } from 'utils/constants'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { createQueryParamString } from 'utils/create-query-param-string'
import styles from './SearchBar.module.css'
import allContent from '../../../../content/content'

const content = allContent.hotel.searchPage.searchBar

type HotelsSearchParams = {
    destination?: string
    checkInDate: string
    checkOutDate: string
    numberOfRooms: string
    numberOfAdults: string
}

type FormFieldTypes = {
    destination: string
    checkInDate: Date
    checkOutDate: Date
    numberOfRooms: string
    numberOfAdults: string
}

const SearchBar: React.FC = () => {
    const { control, getValues, handleSubmit, setValue } = useForm<FormFieldTypes>()
    const [maxCheckInDate, setMaxCheckInDate] = useState<Date | null>() // could use getValues('departureDate') as default if the form is ever being pre-populated from recent values
    const [minCheckOutDate, setMinCheckOutDate] = useState<Date | null>(getValues('checkOutDate')) // could use getValues('returnDate') as default if the form is ever being pre-populated from recent values
    const navigate = useNavigate()

    function handleCheckInDateClick(date: Date): void {
        setMinCheckOutDate(date)
        setValue('checkInDate', date)
    }
    function handleCheckOutDateDateClick(date: Date): void {
        setMaxCheckInDate(date)
        setValue('checkOutDate', date)
    }
    function handleDestinationOnChange(value: string): void {
        setValue('destination', value)
    }
    const numberOfRoomsOnChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            setValue('numberOfRooms', e.target.value)
        },
        [setValue]
    )
    const numberOfAdultsOnChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            setValue('numberOfAdults', e.target.value)
        },
        [setValue]
    )

    const numberOfAdultsOptions = [
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '3', value: '3' },
        { text: '4', value: '4' },
        { text: '5', value: '5' },
        { text: '6', value: '6' },
    ]

    return (
        <form
            onSubmit={handleSubmit((fieldValues) => {
                const queryKeyValues: HotelsSearchParams = {
                    ...(fieldValues.destination && { destination: fieldValues.destination }),

                    checkInDate: format(new Date(fieldValues.checkInDate), DATE_FORMAT_Y_M_D_DASH),

                    checkOutDate: format(
                        new Date(fieldValues.checkOutDate),
                        DATE_FORMAT_Y_M_D_DASH
                    ),

                    numberOfAdults: fieldValues.numberOfAdults || '1',
                    numberOfRooms: fieldValues.numberOfRooms || '1',
                }
                const queryParamsString = createQueryParamString(queryKeyValues)
                navigate(`${ROUTES.HOTEL_RESULTS}/${queryParamsString}`)
            })}
        >
            <div className={styles['search-bar']}>
                <Controller
                    control={control}
                    name='destination'
                    render={(): React.ReactElement => (
                        <LabelledInput
                            className={styles.destination}
                            htmlFor='destination'
                            label={content.destination}
                            labelColor='white'
                        >
                            <TextInput
                                onChangeCallback={handleDestinationOnChange}
                                placeholder={content.destinationPlaceholder}
                                autoComplete='off'
                            />
                        </LabelledInput>
                    )}
                />
                <Controller
                    name='checkInDate'
                    control={control}
                    rules={{ required: true }}
                    render={(): React.ReactElement => (
                        <DatePicker
                            autoComplete='off'
                            name='checkInDate'
                            maxDate={maxCheckInDate}
                            onChange={handleCheckInDateClick}
                            placeholderText={content.checkInDatePlaceholder}
                            selected={getValues('checkInDate')}
                            required={true}
                            customInput={
                                <LabelledInput
                                    labelColor='white'
                                    label={content.checkInDate}
                                    htmlFor='checkInDate'
                                >
                                    <TextInput
                                        iconName='Calendar'
                                        onKeyDown={(e): void => {
                                            e.preventDefault() // this stops typing into input - manually typing the date doesn't set the value so shouldn't let people try.
                                        }}
                                    />
                                </LabelledInput>
                            }
                        />
                    )}
                />
                <Controller
                    name='checkOutDate'
                    control={control}
                    rules={{ required: true }}
                    render={(): React.ReactElement => (
                        <DatePicker
                            autoComplete='off'
                            name='checkOutDate'
                            minDate={minCheckOutDate}
                            onChange={handleCheckOutDateDateClick}
                            placeholderText={content.checkOutDatePlaceholder}
                            selected={getValues('checkOutDate')}
                            required={true}
                            customInput={
                                <LabelledInput
                                    labelColor='white'
                                    label={content.checkOutDate}
                                    htmlFor='checkOutDate'
                                >
                                    <TextInput
                                        iconName='Calendar'
                                        onKeyDown={(e): void => {
                                            e.preventDefault() // this stops typing into input - manually typing the date doesn't set the value so shouldn't let people try.
                                        }}
                                    />
                                </LabelledInput>
                            }
                        />
                    )}
                />
                <Controller
                    name='numberOfRooms'
                    control={control}
                    render={({ field: { value } }): React.ReactElement => (
                        <LabelledInput
                            labelColor='white'
                            htmlFor='numberOfRooms'
                            label={content.numberOfRooms}
                        >
                            <Select
                                value={value}
                                options={numberOfAdultsOptions}
                                onChange={(e): void => numberOfRoomsOnChange(e)}
                            />
                        </LabelledInput>
                    )}
                />
                <Controller
                    name='numberOfAdults'
                    control={control}
                    render={({ field: { value } }): React.ReactElement => (
                        <LabelledInput
                            labelColor='white'
                            htmlFor='numberOfAdults'
                            label={content.numberOfAdult}
                        >
                            <Select
                                value={value}
                                options={numberOfAdultsOptions}
                                onChange={(e): void => {
                                    numberOfAdultsOnChange(e)
                                }}
                            />
                        </LabelledInput>
                    )}
                />
                <Button
                    onDarkBackground={true}
                    iconName='Search'
                    type='submit'
                    text={content.searchButton}
                />
            </div>
        </form>
    )
}
export default SearchBar
