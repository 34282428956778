import { datadogLogs } from '@datadog/browser-logs'
import { extractUserDataFields } from 'utils/user-data-helpers/extract-user-data-fields'

export class UserAnalytics {
    // userId is a built-in GA property so we have a separate method for it
    static sendUserId(userId: string): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const isUserIdInDataLayer = window?.dataLayer?.some(
            (data: Record<string, any>) => !!data.user_id
        )
        if (userId && !isUserIdInDataLayer) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window?.dataLayer?.push({
                user_id: userId,
            })
        }
    }

    // a way to pass any data to Google Analytics that is not built-in
    /**  Preferably pass property name in a snack case */
    static sendCustomProperty(propertyName: string, property: string): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const isPropertyInDataLayer = window?.dataLayer?.some(
            (data: Record<string, any>) => !!data.propertyName
        )
        const propertyObject: Record<string, string> = {}
        if (propertyName && property && !isPropertyInDataLayer) {
            propertyObject[propertyName] = property
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window?.dataLayer?.push(propertyObject)
        }
    }

    static trackEvent(event: string): void {
        const { userId } = extractUserDataFields(
            datadogLogs.getGlobalContext() as GlobalContextUserData
        )
        const eventToPush: { event: string; user_id?: string } = { event: event }
        if (userId) {
            eventToPush.user_id = userId
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window?.dataLayer?.push(eventToPush)
    }
}
