import { capitalizeEachWord, insertDecimal2CharsFromEnd } from '../utils/string-helpers'
import { getFormattedDate } from '../utils/date-helpers'
import {
    APICruise,
    APICruisesResponse,
    APIItineraryItem,
    APILeadInPricing,
    ItineraryItemType,
    MetaDataItem,
} from './cruises-api-types'

const PORT_LIST_CHAR_LIMIT = 200
export const DO_NOT_FILTER_OUT = 'DoNotFilterOut'

export const ITINERARY_ITEM_TYPE_MAP: Record<ItineraryItemType, ItineraryItemType> = {
    AT_SEA: 'AT_SEA',
    PORT: 'PORT',
    CROSS_IDL_WESTBOUND: 'CROSS_IDL_WESTBOUND',
    CROSS_IDL_EASTBOUND: 'CROSS_IDL_EASTBOUND',
    SAIL_PAST: 'SAIL_PAST',
    EMBARK: 'EMBARK',
    DISEMBARK: 'DISEMBARK',
}

/** Class representing Cabin Pricing for each type of cabin within Pricing of a Cruise. */
export class CabinTypePricing {
    /**
     * @param {int} fare - is the total cabin fare for all passengers. Default is 2 people (so half the fare for price per person) it gets more complicated if request provided 3, 4 or 5 passengers. Search Results come back for 2 passengers
     * @param {int} fareStringRounded - is the total cabin fare for all passengers as a integer.
     * @param {string} cabinType - is the type of cabin the fare is for: Inside / Outside / Balcony / Suite.
     * @param {bool} available - is a boolean to indicate if the cabin type is available. = False if fare is 0, caused by a number of scenarios - sold out being one of them.
     */
    readonly fare: number
    readonly cabinType: 'Inside' | 'Outside' | 'Balcony' | 'Suite'
    readonly available: boolean
    readonly fareStringRounded: string
    constructor(priceData: Record<string, any>) {
        this.fare = priceData.fare
        this.fareStringRounded = '' + Math.round(+priceData.fare / 100)
        this.cabinType = priceData.cabinType.charAt(0) + priceData.cabinType.slice(1).toLowerCase()
        this.available = priceData.available
    }
}

/** Class representing Pricing of a cruise */
export class Pricing {
    /**
     * @param {int} taxesFeesAndPortExpenses - is the total tax fee for all passengers on booking. (Note: the value is a fixed fee per person for this cruise, its not related to fare amount).
     * @param {string}  currencyCode - is the ISO 4217 currency code the fare value is quoted in.
     * @param {array} cabinTypePricing - is an array of price details per cabin type.
     * @param {string} fromPrice - is string of the cheapest price, or 'DoNotFilterOut' if no prices provided.
     */
    readonly taxesFeesAndPortExpenses: number | string | null
    readonly currencyCode: 'GBP' | 'USD' | null
    readonly cabinTypePricing: CabinTypePricing[]
    readonly fromPrice: string | number
    constructor(pricing: APILeadInPricing[]) {
        this.taxesFeesAndPortExpenses = pricing[0]
            ? insertDecimal2CharsFromEnd(pricing[0].taxesFeesAndPortExpenses)
            : null // use index 0 because all priceItems have same tax, fees, and port expenses
        this.currencyCode = pricing[0] ? pricing[0].currency : null
        this.cabinTypePricing = this.getPricing(pricing)
        this.fromPrice = this.getFromPrice(pricing)
    }

    /** finds cheapest price option of the cruise, or if no prices then pass magic string to skip price range filter */
    getFromPrice(cabinTypePricing: APILeadInPricing[]): string | number {
        let availablePrice = false
        let fromPrice = 987654321 // start from price must be bigger than most expensive cruise possible (?10 million USD/GBP pence/cents per cabin)
        cabinTypePricing.forEach((cabinTypingPrice) => {
            if (cabinTypingPrice.available && cabinTypingPrice.fare < fromPrice) {
                // if an available price is less than fromPrice, set fromPrice
                fromPrice = cabinTypingPrice.fare
            }
            // set availablePrice to true (and leave as true) since we have at least one price
            if (!availablePrice) {
                availablePrice = cabinTypingPrice.available
            }
        })
        // return either the found smallest fare or the special string to bypass being filtered on price 'DoNotFilterOut'
        return availablePrice ? fromPrice : DO_NOT_FILTER_OUT
    }

    /** drops unavailable prices - passes array of cabin-type-pricing objects and returns an array only with those with available = true */
    getPricing(cabinTypePricing: APILeadInPricing[]): CabinTypePricing[] {
        const availableCabinTypePrices: CabinTypePricing[] = []
        cabinTypePricing.forEach((pricing) => {
            if (pricing.available) availableCabinTypePrices.push(new CabinTypePricing(pricing))
        })
        return availableCabinTypePrices
    }
}

/** Class representing an ItineraryDay of the Itinerary array of a Cruise - used to create port list on itinerary. */
export class ItineraryDay {
    /**
     *  @param {string} portCode - is the code for main location on that itinerary day, can be port/excursion references or undefined.
     *  @param {string} portName - is the port name or the excursion day location 'StoneHenge', or other notable events locations like 'crossing equator'). Sea Days are set to empty string.
     */
    readonly portName?: string
    readonly portCode: string
    constructor(itineraryItem: APIItineraryItem) {
        this.portCode = itineraryItem.portCode
        this.portName = this.getPortName({
            name: capitalizeEachWord(itineraryItem?.portName ?? ''),
            itemType: itineraryItem.itineraryItemType,
        })
    }
    getPortName({ name, itemType }: { name?: string; itemType: ItineraryItemType }): string {
        // NOTE: The location is made up of 'Port name, City, Country' (comma seperated), so we split by comma and take 1st in array.
        const locationIsAPort =
            itemType === ITINERARY_ITEM_TYPE_MAP.PORT ||
            itemType === ITINERARY_ITEM_TYPE_MAP.EMBARK ||
            itemType === ITINERARY_ITEM_TYPE_MAP.DISEMBARK
        if (name && locationIsAPort) {
            return name.split(',')[0]
        } else return ''
    }
}

export type ItineraryItem = {
    day: number
    portName: string | null
    portCode: string
    arrivalTime?: string
    departureTime: string
    geolocation: Record<string, any>
    itemType: string
    itemDate: string
}

/**  Class representing the Itinerary field of a cruise*/
export class Itinerary {
    /**
     * @param {string} portListContentFull - is a string of port names constructed using all location field from each ItineraryDays.
     * @param {string} portListContentLimited - is either empty string or as many ports before total length exceed fixed char limit when full list exceeds limit.
     */
    readonly portListContentFull: string[]
    readonly portListContentLimited: string[]
    readonly portCodesWithNamesAndDaysAndTimes: ItineraryItem[]
    constructor(itinerary: APIItineraryItem[]) {
        this.portListContentFull = this.getFullPortListContent(itinerary)
        this.portListContentLimited =
            this.portListContentFull.join(' ').length > PORT_LIST_CHAR_LIMIT
                ? this.getTruncatedPortListContent(itinerary)
                : []
        this.getTruncatedPortListContent(itinerary)
        this.portCodesWithNamesAndDaysAndTimes =
            this.getPortCodesWithNamesAndDaysAndTimes(itinerary)
    }

    getPortCodesWithNamesAndDaysAndTimes(itinerary: APIItineraryItem[]): ItineraryItem[] {
        return itinerary.map((item) => {
            const firstPartOfPortName = item.portName ? item.portName.split(',')[0] : null
            const itineraryItem = {
                day: item.dayNumber,
                portName: item.portName,
                justPortName: firstPartOfPortName ? capitalizeEachWord(firstPartOfPortName) : null,
                portCode: item.portCode,
                geolocation: item.geolocation,
                arrivalTime: item.arrivalTime,
                departureTime: item.departureTime,
                itemType: item.itineraryItemType,
                itemDate: item.itemDate,
            }
            return itineraryItem
        })
    }

    /**
     * Get all the ports using 'location' field from each itinerary day array on a cruise itinerary and constructs as single string list.
     * @param { array }  itinerary - an array of itinerary days for a cruise, each contain location and locationCode
     * @return {string} the cruise's itinerary's ports as a string list.
     */
    getFullPortListContent(itinerary: APIItineraryItem[]): string[] {
        const itineraryDayList = itinerary.map((itineraryDay) => new ItineraryDay(itineraryDay))
        const fullPortsList: string[] = []

        itineraryDayList.forEach((itineraryDay: ItineraryDay) => {
            if (itineraryDay.portName) {
                fullPortsList.push(itineraryDay.portName)
            }
        })

        return fullPortsList
    }

    /**
     * Creates the ports list again but stops adding ports when char length is exceeded.
     * @param { array }  itinerary - an array of itinerary days for a cruise, each contain location and locationCode
     * @return {string} the cruise's itinerary's ports as a string list.
     */
    getTruncatedPortListContent(itinerary: APIItineraryItem[]): string[] {
        const itineraryList = itinerary.map((itineraryDay) => new ItineraryDay(itineraryDay))
        const trimmedPortsList: string[] = []

        itineraryList.some((itineraryDay: ItineraryDay) => {
            if (itineraryDay.portName) {
                if (
                    [...trimmedPortsList, itineraryDay.portName].join(' ').length >
                    PORT_LIST_CHAR_LIMIT
                ) {
                    return true // stop iterating through PortsList when char limit has been exceeded
                }
                trimmedPortsList.push(itineraryDay.portName)
                return false
            } else return false
        })

        return trimmedPortsList
    }
}

export class Cruise {
    readonly itemIndex?: number
    readonly duration: number
    readonly embarkDate: string
    readonly generalDestination?: string
    readonly hasAlternativeSailings: boolean
    readonly cruiseId: string
    readonly productName: string
    readonly shipName: string
    readonly shipImage: string
    readonly supplierCode: string
    readonly supplierLogo: string
    readonly supplierName: string
    readonly itinerary: Itinerary
    readonly pricing: Pricing
    constructor(cruiseData: APICruise) {
        this.duration = cruiseData.duration
        this.embarkDate = getFormattedDate(cruiseData.embarkDate)
        this.generalDestination = cruiseData.generalDestination
            ? capitalizeEachWord(cruiseData.generalDestination)
            : undefined
        this.hasAlternativeSailings = cruiseData.hasAlternativeSailings
        this.cruiseId = cruiseData.id
        this.productName = capitalizeEachWord(cruiseData.product.name)
        this.shipImage = cruiseData.shipImage
        this.shipName = cruiseData.ship.name
        this.supplierCode = cruiseData.ship.line.code
        this.supplierName = cruiseData.ship.line.name
        this.supplierLogo = cruiseData.supplierLogo
        this.itinerary = new Itinerary(cruiseData.itineraryItems)
        this.pricing = new Pricing(cruiseData.leadInPrices)
        this.itemIndex = undefined /** gets added below in CruiseSearchResult constructor */
    }
}

export type CruiseSearchMetaData = {
    numberOfResults: number
    allDeparturePorts: MetaDataItem[]
    allArrivalPorts: MetaDataItem[]
    allPorts: MetaDataItem[]
    allShipNames: MetaDataItem[]
    allCruiseLines: MetaDataItem[]
    allDurations: MetaDataItem[]
    allCabinTypes: MetaDataItem[]
    minPrice: number
    maxPrice: number
}

export class CruiseSearchResult {
    readonly cruiseSearchMetaData: CruiseSearchMetaData
    readonly allCruiseProductNames: string[]
    readonly cruises: Cruise[]
    constructor(cruiseSearchResult: APICruisesResponse) {
        this.allCruiseProductNames = []
        this.cruises = cruiseSearchResult?.searchResults
            .map((cruise, index) => {
                const modelledCruise = new Cruise(cruise)
                this.allCruiseProductNames.push(cruise.product.name)
                return { ...modelledCruise, itemIndex: index } // index is needed due to duplicate cruise ids causing render issues
            })
            .sort((a, b) => {
                return new Date(a.embarkDate).getTime() - new Date(b.embarkDate).getTime()
            })
        this.cruiseSearchMetaData = {
            numberOfResults: cruiseSearchResult.resultsMetaData.numberOfResults,
            allDeparturePorts: cruiseSearchResult.resultsMetaData.embarkPorts,
            allArrivalPorts: cruiseSearchResult.resultsMetaData.disembarkPorts,
            allPorts: cruiseSearchResult.resultsMetaData.allPorts,
            allShipNames: cruiseSearchResult.resultsMetaData.shipNames,
            allCruiseLines: cruiseSearchResult.resultsMetaData.cruiseLines,
            allDurations: cruiseSearchResult.resultsMetaData.durations,
            allCabinTypes: cruiseSearchResult.resultsMetaData.cabinTypes,
            minPrice: cruiseSearchResult.resultsMetaData.minPrice,
            maxPrice: cruiseSearchResult.resultsMetaData.maxPrice,
        }
    }
}

export type APICruisesMetaData = {
    numberOfResults: number
    cruiseLines: MetaDataItem[]
    countries: MetaDataItem[]
    shipNames: MetaDataItem[]
    cabinTypes: MetaDataItem[]
    durations: MetaDataItem[]
    embarkPorts: MetaDataItem[]
    disembarkPorts: MetaDataItem[]
    allPorts: MetaDataItem[]
    unPorts: MetaDataItem[]
    minPrice: number
    maxPrice: number
}

export type CruisesMetaData = {
    allDeparturePorts: MetaDataItem[]
    allArrivalPorts: MetaDataItem[]
    allCountries: MetaDataItem[]
    allPorts: MetaDataItem[]
    allShips: MetaDataItem[]
    allSuppliers: MetaDataItem[]
    allUnPorts: MetaDataItem[]
    numberOfResults: number
}
export const getCruisesMetaData = (cruisesMetaData: APICruisesMetaData): CruisesMetaData => {
    return {
        numberOfResults: cruisesMetaData.numberOfResults,
        allCountries: cruisesMetaData.countries,
        allSuppliers: cruisesMetaData.cruiseLines,
        allShips: cruisesMetaData.shipNames,
        allDeparturePorts: cruisesMetaData.embarkPorts,
        allArrivalPorts: cruisesMetaData.disembarkPorts,
        allPorts: cruisesMetaData.allPorts,
        allUnPorts: cruisesMetaData.unPorts,
    }
}
