import React, { SetStateAction, useEffect, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'
import { RenderInputComponentProps } from 'react-autosuggest'

import AddGroupButton from '../AdminLanding/GettingStartedSection/AddGroupButton/AddGroupButton'
import Autosuggest from 'components/blocks/Autosuggest/Autosuggest'
import Button from 'components/basics/Button/Button'
import GroupsList from '../GroupManagement/GroupsList/GroupsList'
import Heading from 'components/basics/Heading/Heading'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import Text from 'components/basics/Text/Text'
import TablePaginationButtons from 'components/blocks/TablePaginationButtons/TablePaginationButtons'

import { AGENT_CONNECT_LIST_GROUPS, LIST_SALES_CHANNELS } from 'graphql-queries/admin/admin-queries'
import {
    Group,
    GroupsContentFunction,
    SalesChannel,
} from 'api-data-models/admin/GroupsContentModel'
import { SalesChannelsContent } from 'api-data-models/admin/SalesChannelsContentModel'
import { stringArrayFilterAndSortByMatcher } from 'utils/stringArrayFilterAndSortByMatcher'
import { checkAndPerformUserSessionRefreshIfNeeded } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'

import styles from './GroupManagement.module.scss'
import allContent from 'content/content'

const content = allContent.admin.groupManagementPage

export const GROUPS_PER_PAGE = 10
const NUMBER_OF_SUGGESTIONS = 3

type GroupManagementProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
}

export function handlePageClick(
    selectedPage: number,
    setCurrentPage: React.Dispatch<SetStateAction<number>>
): void {
    setCurrentPage(selectedPage)
}

export function groupsToDisplay(groupsListData: Group[], page: number): Group[] {
    const startIndex = (page - 1) * GROUPS_PER_PAGE
    const endIndex = startIndex + GROUPS_PER_PAGE

    return groupsListData.slice(startIndex, endIndex)
}
export function getSalesChannelsList(
    apiClient: ApolloClient<NormalizedCacheObject>,
    setSalesChannelsData: React.Dispatch<SetStateAction<SalesChannel[]>>,
    setSalesChannelsApiErrorMessage: React.Dispatch<SetStateAction<string | null>>,
    setFetchingSalesChannels: React.Dispatch<SetStateAction<boolean>>
): void {
    const userContext = datadogLogs.getGlobalContext()
    setFetchingSalesChannels(true)
    apiClient
        .query({ query: LIST_SALES_CHANNELS })
        .then((response) => {
            if (response?.data?.salesChannels) {
                setFetchingSalesChannels(false)
                const salesChannelsAPIResponseData = response.data.salesChannels
                setSalesChannelsData(SalesChannelsContent(salesChannelsAPIResponseData))
                datadogLogs.logger.info(
                    `Query LIST_SALES_CHANNELS successfully got salesChannels list: ${JSON.stringify(
                        salesChannelsAPIResponseData
                    )}`,
                    { userContext }
                )
            }
        })
        .catch((error) => {
            setSalesChannelsApiErrorMessage(content.errors.fetchingSalesChannels)
            setFetchingSalesChannels(false)
            datadogLogs.logger.error(`Query LIST_SALES_CHANNELS - error:`, { userContext }, error)
        })
}

export function handleClearFiltersClick(
    setFilteredGroupsListData: React.Dispatch<SetStateAction<[] | Group[]>>,
    setGroupNameFilterValue: React.Dispatch<SetStateAction<string>>,
    setGroupNameSuggestions: React.Dispatch<SetStateAction<string[]>>
): void {
    setFilteredGroupsListData([])
    setGroupNameFilterValue('')
    setGroupNameSuggestions([])
}

export function handleFetchGroupList({
    apiClient,
    setGroupsListData,
    setGroupsApiErrorMessage,
    setFetchingGroups,
}: {
    apiClient: ApolloClient<NormalizedCacheObject>
    setGroupsListData: React.Dispatch<SetStateAction<[] | Group[]>>
    setGroupsApiErrorMessage: React.Dispatch<SetStateAction<string | null>>
    setFetchingGroups: React.Dispatch<SetStateAction<boolean>>
}): void {
    const userContext = datadogLogs.getGlobalContext()
    setFetchingGroups(true)
    apiClient
        .query({ query: AGENT_CONNECT_LIST_GROUPS })
        .then((response) => {
            setFetchingGroups(false)
            if (response?.data?.userGroups) {
                const groups = GroupsContentFunction(response.data.userGroups).sort(
                    (a, b) => a.groupTitle.localeCompare(b.groupTitle) //to list groups alphabetically
                )
                setGroupsListData(groups)
                datadogLogs.logger.info(
                    `Query AGENT_CONNECT_LIST_GROUPS successfully got group list: ${JSON.stringify(
                        groups
                    )}`,
                    { userContext }
                )
            }
        })
        .catch((error) => {
            setFetchingGroups(false)
            datadogLogs.logger.error(
                `Query AGENT_CONNECT_LIST_GROUPS - error:`,
                { userContext },
                error
            )
            setGroupsApiErrorMessage(content.errors.fetchingGroups)
        })
}

const GroupManagement: React.FC<GroupManagementProps> = ({ apiClient }) => {
    const navigate = useNavigate()
    const [groupsListData, setGroupsListData] = useState<Group[] | []>([])
    const [shouldRefreshGroupsListData, setShouldRefreshGroupsListData] = useState<boolean>(true)
    const [filteredGroupsListData, setFilteredGroupsListData] = useState<Group[] | []>([])
    const [fetchingGroups, setFetchingGroups] = useState<boolean>(false)
    const [salesChannelsApiErrorMessage, setSalesChannelsApiErrorMessage] = useState<string | null>(
        null
    )
    const [addGroupApiError, setAddGroupApiError] = useState(null)
    const [groupsApiErrorMessage, setGroupsApiErrorMessage] = useState<string | null>(null)
    const [addGroupApiResponseStatus, setAddGroupApiResponseStatus] = useState<boolean>(false)
    const [salesChannelsData, setSalesChannelsData] = useState<SalesChannel[]>([])
    const [fetchingSalesChannels, setFetchingSalesChannels] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [groupNameFilterValue, setGroupNameFilterValue] = useState<string>('')
    const [groupNameSuggestions, setGroupNameSuggestions] = useState<string[]>([])

    useEffect(() => {
        const handleUserSession = checkAndPerformUserSessionRefreshIfNeeded(navigate)
        handleUserSession.then(() => {
            getSalesChannelsList(
                apiClient,
                setSalesChannelsData,
                setSalesChannelsApiErrorMessage,
                setFetchingSalesChannels
            )
        })
    }, [apiClient, navigate])

    useEffect(() => {
        if (shouldRefreshGroupsListData) {
            handleFetchGroupList({
                apiClient,
                setGroupsListData,
                setGroupsApiErrorMessage,
                setFetchingGroups,
            })
        }
        setShouldRefreshGroupsListData(false)
    }, [apiClient, shouldRefreshGroupsListData])

    useEffect(() => {
        setFilteredGroupsListData(
            groupsListData.filter((group) =>
                group.groupTitle.toLowerCase().includes(groupNameFilterValue.toLowerCase())
            )
        )
    }, [groupNameFilterValue, groupsListData])

    function getGroupSuggestionsList(searchString: string): void {
        const filteredGroupsBySearchString = groupsListData
            .map((group) => group.groupTitle)
            .filter((title) => title.toLowerCase().includes(searchString.toLowerCase()))
        const weightedOrderedGroupNames = stringArrayFilterAndSortByMatcher(
            filteredGroupsBySearchString,
            searchString
        )
        setGroupNameSuggestions(weightedOrderedGroupNames.slice(0, NUMBER_OF_SUGGESTIONS))
    }

    return (
        <div className={styles.container}>
            <Heading heading='1'>{content.title}</Heading>
            {addGroupApiError && (
                <InfoBanner
                    bannerType='error'
                    text={addGroupApiError}
                    id='group-add-error-banner'
                />
            )}
            {addGroupApiResponseStatus && (
                <InfoBanner
                    bannerType='success'
                    text={content.groupAddSuccessMessage}
                    id='group-add-success-banner'
                />
            )}
            <div className={styles['groups-add']}>
                <Text>{content.description}</Text>
                <AddGroupButton
                    apiClient={apiClient}
                    setApiError={setAddGroupApiError}
                    setApiResponseStatus={setAddGroupApiResponseStatus}
                />
            </div>
            <div className={styles['groups-filter']}>
                <Text>{content.filter.title}</Text>
                <div className={styles['groups-filter-content']}>
                    <Autosuggest
                        openSuggestionsOnFocus={groupNameFilterValue?.length > 0}
                        inputValue={groupNameFilterValue}
                        setInputValue={(value): void => {
                            let input = value
                            if (typeof value === 'object') input = Object.values(value).join('')
                            setGroupNameFilterValue(input)
                        }}
                        onBlur={(value): void => {
                            if (!value)
                                handleClearFiltersClick(
                                    setFilteredGroupsListData,
                                    setGroupNameFilterValue,
                                    setGroupNameSuggestions
                                )
                            if (value) setGroupNameFilterValue(value)
                        }}
                        id='group-autosuggest'
                        getSuggestionValue={(groupTitle): string => {
                            return groupTitle
                        }}
                        onSuggestionSelected={(value: string): string => {
                            setGroupNameFilterValue(value)
                            const filteredGroup = groupsListData.filter((group) =>
                                group.groupTitle.toLowerCase().includes(value.toLowerCase())
                            )
                            if (filteredGroup) setFilteredGroupsListData(filteredGroup)
                            return value
                        }}
                        name='autosuggest-group-filter'
                        renderSuggestion={(value: string): React.ReactElement => {
                            return <span>{value}</span>
                        }}
                        onSuggestionsClearRequested={(): void => setGroupNameSuggestions([])}
                        suggestionsData={groupNameSuggestions}
                        suggestionsFetchRequest={getGroupSuggestionsList}
                        renderInputComponent={({
                            ...rest
                        }: RenderInputComponentProps): React.ReactElement => (
                            <LabelledInput
                                htmlFor='group-filter'
                                label={content.filter.title}
                                labelHidden
                                {...rest}
                            >
                                <TextInput />
                            </LabelledInput>
                        )}
                    />
                    <div>
                        <Button
                            text={content.filter.button}
                            type='button'
                            flavour='secondary'
                            onClick={(): void =>
                                handleClearFiltersClick(
                                    setFilteredGroupsListData,
                                    setGroupNameFilterValue,
                                    setGroupNameSuggestions
                                )
                            }
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className={styles['groups-header']}>
                    <Heading heading='2' size='3' onDarkBackground={true}>
                        {content.list.heading}
                    </Heading>
                    <Button
                        type='button'
                        flavour='text'
                        iconName='ArrowsRotate'
                        text={content.list.refresh}
                        onDarkBackground={true}
                        onClick={(): void =>
                            handleFetchGroupList({
                                apiClient,
                                setGroupsListData,
                                setFetchingGroups,
                                setGroupsApiErrorMessage,
                            })
                        }
                    />
                </div>
                <div className={styles['groups-content']}>
                    {fetchingGroups && <LargeSpinner text={content.fetchingGroups} />}
                    {groupsApiErrorMessage && (
                        <InfoBanner
                            bannerType='error'
                            text={groupsApiErrorMessage}
                            id='api-error-banner'
                        />
                    )}
                    {salesChannelsApiErrorMessage && (
                        <InfoBanner
                            bannerType='error'
                            text={salesChannelsApiErrorMessage}
                            id='api-error-banner'
                        />
                    )}
                    {!fetchingGroups && (
                        <GroupsList
                            fetchingSalesChannels={fetchingSalesChannels}
                            groupsListData={groupsToDisplay(
                                filteredGroupsListData.length > 0
                                    ? filteredGroupsListData
                                    : groupsListData,
                                currentPage
                            )}
                            setShouldRefreshGroupsListData={setShouldRefreshGroupsListData}
                            apiClient={apiClient}
                            salesChannels={salesChannelsData}
                        />
                    )}
                    {!!groupsListData?.length && (
                        <TablePaginationButtons
                            handlePageChange={(selected: number): void =>
                                handlePageClick(selected, setCurrentPage)
                            }
                            page={currentPage}
                            totalNumOfPages={
                                filteredGroupsListData.length > 0
                                    ? Math.ceil(filteredGroupsListData.length / GROUPS_PER_PAGE)
                                    : Math.ceil(groupsListData.length / GROUPS_PER_PAGE)
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default GroupManagement
