import React from 'react'
import { useNavigate } from 'react-router-dom'

import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import allContent from 'content/content'
import { ROUTES } from '../../../sections/app/AppRoutes'

const content = allContent.app.timeoutPage

const TimeoutLayout: React.FC<{ cleanUpAppOnLogout(): void }> = ({ cleanUpAppOnLogout }) => {
    const navigate = useNavigate()
    cleanUpAppOnLogout()

    return (
        <div className='general-container'>
            <InfoBanner
                id='timeout-messageerror'
                text={content.message}
                bannerType='info'
                onButtonClick={(): void => navigate(ROUTES.LOGIN)}
                buttonText={content.goToLogin}
                logType='info'
                source={'timeout-page'}
            />
        </div>
    )
}
export default TimeoutLayout
