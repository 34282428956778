import React from 'react'

import Heading from 'components/basics/Heading/Heading'
import Link from 'components/basics/Link/Link'
import Text from 'components/basics/Text/Text'
import { ROUTES } from 'components/sections/app/AppRoutes'

import styles from './GettingStartedSection.module.css'
import allContent from 'content/content'

const contentPaidAC =
    allContent.admin.adminLanding.agentConnectCruise.gettingStartedSection.paidAgentConnect
const contentFree = allContent.admin.adminLanding.agentConnectCruise.gettingStartedSection.free

type GettingStartedSectionProps = {
    agentConnectCruiseOnPaid: boolean
}

const GettingStartedSection: React.FC<GettingStartedSectionProps> = ({
    agentConnectCruiseOnPaid,
}): React.ReactElement => {
    const freeAgentConnectGetStarted = (
        <>
            <Heading heading='2'>{contentFree.title}</Heading>
            <div>
                <p>
                    <Text>{contentFree.description1}</Text>
                </p>
                <p>
                    <Text>{contentFree.description2}</Text>
                </p>
            </div>
        </>
    )

    const paidAgentConnectGetStarted = (
        <>
            <Heading heading='3'>{contentPaidAC.title}</Heading>
            <div>
                <p>
                    <Text>{contentPaidAC.description1a}</Text>
                    <Link to={ROUTES.USER_MANAGEMENT}>{contentPaidAC.description1aLinkText}</Link>
                    <Text>{contentPaidAC.description1b}</Text>
                    <Link to={ROUTES.GROUP_MANAGEMENT}>{contentPaidAC.description1bLinkText}</Link>
                    <Text>{contentPaidAC.description1c}</Text>
                    <Link to={ROUTES.SALES_CHANNEL_MANAGEMENT}>
                        {contentPaidAC.description1cLinkText}
                    </Link>
                    <Text>{contentPaidAC.description1d}</Text>
                </p>
            </div>
        </>
    )
    return (
        <div className={styles.container}>
            <div className={styles['coloured-top']} />
            <div className={styles.content}>
                {agentConnectCruiseOnPaid && paidAgentConnectGetStarted}
                {!agentConnectCruiseOnPaid && freeAgentConnectGetStarted}
            </div>
        </div>
    )
}
export default GettingStartedSection
