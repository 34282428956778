import { gql } from '@apollo/client'

export const GET_DUFFEL_LINKS_SESSION_URL = gql`
    query GetDuffelLinksSessionUrl {
        flightSessionUrl {
            url
        }
    }
`

export const GET_DUFFEL_OFFER_DETAILS = gql`
    query GetDuffleFlightOfferDetails(
        $offerId: String!
        $providerId: String!
        $providerItemId: String!
    ) {
        flightOption(id: $offerId, providerId: $providerId, providerItemId: $providerItemId) {
            airline {
                code
                name
            }
            allowedIdentityDocumentTypes
            currencyCode
            fareType
            flightLegs {
                direction
                duration
                legNumber
                segments {
                    arrivalAirport
                    arrivalAirportCode
                    arrivalDatetime
                    arrivalTerminal
                    checkedBaggage
                    baggageSummary
                    departureAirport
                    departureAirportCode
                    departureDatetime
                    departureTerminal
                    duration
                    fareBrandName
                    flightNumber
                    passengers {
                        cabinClass
                        cabinClassMarketingName
                        fareBasis
                        passengerId
                        seatClass
                    }
                    segmentId
                    segmentNumber
                    stops
                }
            }
            flightType
            grossCost
            margin
            id
            nettCost
            passengerIdentityDocumentsRequired
            passengers {
                age
                id
                type
            }
            priceValidity {
                guaranteeExpiry
                instantPayment
                paymentRequiredBy
            }
            providerSpecificData
            providerItemId
            providerId
            costBreakdown {
                currencyCode
                description
                grossCost
                margin
                nettCost
                quantity
                type
            }
            conditions {
                allowed
                currencyCode
                description
                penaltyAmount
                type
            }
        }
    }
`
