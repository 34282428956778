import React from 'react'
import { Navigate } from 'react-router-dom'

import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import { PRODUCT_TIERS } from 'utils/constants'
import { ROUTES } from 'components/sections/app/AppRoutes'
import allContent from 'content/content'

const content = allContent.app.protectedRoute
type ProtectedRouteWrapperProps = {
    isFetchingProductTiers: boolean
    productTiers: Record<ProductNameType, ProductTierType>
    children: React.ReactNode
    productName: ProductNameType
    tenantFinanceId?: string
    isDemoUrl?: boolean
}
/** ProtectRoutesWrapper - holds user while product tiers is unknown,
 * and passes child if on 'PAID' tier for product or redirects to ROOT of app if not.
 * It is not for handling AUTHORISED login status of user, just PAID tiers of products */
const ProtectedRouteWrapper: React.FC<ProtectedRouteWrapperProps> = ({
    isFetchingProductTiers,
    productTiers,
    children,
    productName,
    tenantFinanceId,
    isDemoUrl,
}): JSX.Element => {
    if (isFetchingProductTiers) {
        /** This shows likely too quickly for user to see anything - no spinner as flashes).
         *  It is a holding component for when user has refreshed the page
         *  and trying to access 'PAID' tier page but app is still checking local storage
         *  and updating state with product tier info */
        return (
            <div className='general-container'>
                <LargeSpinner text={content.fetchingUserData} />
            </div>
        )
    } else if (
        !isFetchingProductTiers &&
        productTiers[productName] === PRODUCT_TIERS.PAID &&
        (isDemoUrl || tenantFinanceId)
    ) {
        return <>{children}</>
    }
    return <Navigate to={ROUTES.ROOT} />
}

export default ProtectedRouteWrapper
