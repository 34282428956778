import { ROUTES } from 'components/sections/app/AppRoutes'
import React, { useEffect } from 'react'
import jwtEncode from 'jwt-encode'
import { v4 } from 'uuid'
import { datadogLogs } from '@datadog/browser-logs'
import { useNavigate } from 'react-router-dom'

const FeaturebaseEmbedPage: React.FC = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const userContext = datadogLogs.getGlobalContext()
        if (userContext?.emailAddress && userContext?.userName) {
            // Adding the script before the component is mounted
            const script = document.createElement('script')
            script.src = 'https://do.featurebase.app/js/sdk.js'
            script.id = 'featurebase-sdk'
            document.head.appendChild(script)

            // Once the component is mounted, initialize the Featurebase SDK
            const win: any = window

            if (typeof win.Featurebase !== 'function') {
                win.Featurebase = function (...args: any): void {
                    win.Featurebase.q = win.Featurebase.q || []
                    win.Featurebase.q.push(args)
                }
            }
            // For our current plan it seems like no meter what secret we are using
            // If Featurebase plan will be changed we need to update it
            // (https://help.featurebase.app/articles/5257986-creating-and-signing-a-jwt-for-single-sign-on)
            const secret = 'JWT_SECRET'
            const data = {
                email: userContext.emailAddress,
                name: userContext.userName,
                jti: v4(),
            }
            const jwt = jwtEncode(data, secret)

            win.Featurebase('embed', {
                /* Required */
                organization: 'TravelTek', // Replace with your organization name

                /* Optional */
                basePath: ROUTES.FEATURE_PORTAL, // Sync urls between your website & embed
                theme: 'light', // options: light [default], dark
                initialPage: 'Board', // options: Board [default], Changelog, Roadmap
                hideMenu: true, // Hides the top navigation bar. Necessary because we not allow user to sign out manually, so they will always with the same account (same username and email) they have in our app.
                hideLogo: true, // Hides the logo in the top navigation bar & leaves the Sign In button visible.
                filters: null, // Default filters to apply to the board view
                jwtToken: jwt, // Automatically sign in a user with a JWT token
                metadata: null, // Attach session-specific metadata to feedback
                locale: 'en', // Change the language
            })
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.type === 'childList') {
                        const iframeElement = document.getElementById('featurebase-embed-iframe')
                        if (iframeElement) {
                            iframeElement.style.height = '100%'
                            observer.disconnect() // Stop observing once the iframe is found and styled
                        }
                    }
                })
            })

            // Start observing the container where the iframe will be added
            const targetNode = document.querySelector('[data-featurebase-embed]')
            if (targetNode) {
                observer.observe(targetNode, { childList: true })
            }

            return () => {
                document.head.removeChild(script)
                observer.disconnect() // Clean up the observer on unmount
            }
        } else {
            navigate(ROUTES.LOGIN)
        }
    }, [navigate])

    return <div data-featurebase-embed style={{ width: '100%' }} />
}

export default FeaturebaseEmbedPage
