import React, { useRef, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Button from 'components/basics/Button/Button'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import Modal from 'components/blocks/Modal/Modal'
import Text from 'components/basics/Text/Text'
import { REQUEST_PRODUCT_UPGRADE } from 'graphql-queries/admin/admin-queries'

import styles from './Upgrade.module.scss'

type UpgradeProps = {
    disabled?: boolean
    apiClient: ApolloClient<NormalizedCacheObject>
    productName: ProductNameType
    content: Record<string, any>
    upgradeIsProcessing: boolean
}
const Upgrade: React.FC<UpgradeProps> = ({
    disabled = false,
    apiClient,
    productName,
    content,
    upgradeIsProcessing,
}) => {
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [clickedButtonId, setClickedButtonId] = useState('')
    const [isUpgradeConfirmOpen, setIsUpgradeConfirmOpen] = useState(false)
    const [apiError, setApiError] = useState(null)
    const [responseStatusMessage, setResponseStatusMessage] = useState(null)
    const upgradeButtonRef = useRef<HTMLButtonElement | null>(null) // Create a ref for the Upgrade button

    const handleCancelClick = (): void => {
        setIsUpgradeConfirmOpen(false)
        if (upgradeButtonRef.current) {
            upgradeButtonRef.current.focus()
        }
    }

    const handleConfirmClick = async (): Promise<void> => {
        setIsUpgradeConfirmOpen(false)
        setSubmitting(true)
        await apiClient
            .mutate({ mutation: REQUEST_PRODUCT_UPGRADE, variables: { productName } })
            .then((response) => {
                if (response) {
                    setResponseStatusMessage(response.data.upgradeTenant.status)
                }
                setSubmitting(false)
            })
            .catch((error) => {
                setApiError(error.graphQLErrors[0].message)
                setSubmitting(false)
            })
    }

    const handleOnClickUpgradeButton = ({ buttonId }: { buttonId: string }): void => {
        setClickedButtonId(buttonId)
        setIsUpgradeConfirmOpen(true)
    }

    const openConfirmModalUpgradeButtonId = `open-confirm-modal-upgrade-${productName}`

    return upgradeIsProcessing ? (
        <InfoBanner
            id={`${productName}-upgrade-in-progress-banner`}
            text={content.upgradeInProgressMessage}
            bannerType='info'
            isCloseable={false}
        />
    ) : (
        <>
            {apiError && (
                <InfoBanner
                    id={`${productName}-upgrade-error-banner`}
                    bannerType='error'
                    text={apiError}
                />
            )}
            {responseStatusMessage === 'SUCCESS' && !submitting && (
                <InfoBanner
                    id={`${productName}-upgrade-success-banner`}
                    text={content.upgradeInProgressMessage}
                    bannerType='info'
                    isCloseable={false}
                />
            )}
            {responseStatusMessage !== 'SUCCESS' && (
                <div className={styles['container']}>
                    <div className={styles['text']}>
                        <Text color='white' weight='bold' size='L'>
                            {content.upgradeQuestion}
                        </Text>
                        <Text color='white'>{content.upgradeInfo}</Text>
                    </div>
                    {responseStatusMessage !== 'SUCCESS' && (
                        <Button
                            disabled={disabled}
                            id={openConfirmModalUpgradeButtonId}
                            className={styles['button']}
                            flavour='secondary'
                            text={
                                submitting ? content.upgradeButtonSubmitting : content.upgradeButton
                            }
                            onDarkBackground={true}
                            onClick={(): void =>
                                handleOnClickUpgradeButton({
                                    buttonId: openConfirmModalUpgradeButtonId,
                                })
                            }
                            ref={upgradeButtonRef}
                            type='button'
                            showSpinner={submitting}
                        />
                    )}
                    <Modal
                        headerText={content.upgradeModal.heading}
                        isOpen={isUpgradeConfirmOpen}
                        returnFocusId={clickedButtonId}
                        setClosed={(): void => setIsUpgradeConfirmOpen(false)}
                    >
                        <div className={styles['modal-content']}>
                            <div className={styles['text']}>
                                <Text>{content.upgradeModal.text1}</Text>
                                <Text>{content.upgradeModal.text2}</Text>
                                <Text>{content.upgradeModal.text3}</Text>
                            </div>
                            <div className={styles['modal-buttons']}>
                                <Button
                                    type='button'
                                    flavour='tertiary'
                                    text={content.upgradeModal.cancelButton}
                                    onClick={handleCancelClick}
                                />
                                <Button
                                    type='button'
                                    text={content.upgradeModal.confirmButton}
                                    onClick={handleConfirmClick}
                                />
                            </div>
                        </div>
                    </Modal>
                </div>
            )}
        </>
    )
}

export default Upgrade
