import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import Navigation from 'components/sections/admin/NavigationPannel/NavigationPanel'
import EditSalesChannel from 'components/sections/admin/EditSalesChannel/EditSalesChannel'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'

import { ROUTES } from 'components/sections/app/AppRoutes'

import styles from './EditSalesChannelLayout.module.css'

type EditSalesChannelLayoutProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productTiers: ProductTiersDataType
}

const EditSalesChannelLayout = ({
    apiClient,
    productTiers,
}: EditSalesChannelLayoutProps): JSX.Element => {
    const navigate = useNavigate()
    const { salesChannelId } = useParams()

    const salesChannelManagementURL = ROUTES.SALES_CHANNEL_MANAGEMENT

    return (
        <div className='general-container'>
            <div className={styles.container}>
                <Navigation productTiers={productTiers} />
                <div>
                    {!salesChannelId && (
                        <InfoBanner
                            id='SalesChannelInfo'
                            bannerType='error'
                            text='Sales channel not found!'
                            onButtonClick={(): void => navigate(salesChannelManagementURL)}
                            buttonText='Return to Sales Channel Management'
                            isCloseable={false}
                        />
                    )}
                </div>
                {salesChannelId && (
                    <EditSalesChannel apiClient={apiClient} salesChannelId={salesChannelId} />
                )}
            </div>
        </div>
    )
}

export default EditSalesChannelLayout
