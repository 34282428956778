import React from 'react'

import DescriptionListDisplay from 'components/blocks/DescriptionListDisplay/DescriptionListDisplay'
import Spacing from 'components/basics/Spacing/Spacing'
import Text from 'components/basics/Text/Text'
import Card from 'components/blocks/Card/Card'
import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import { capitalizeEachWord } from 'utils/string-helpers'
import { ContactDetails, Traveller } from 'api-data-models/order/OrderContentModel'
import styles from './OrderBookedPassengerDetails.module.css'

import allContent from 'content/content'
import { getFormattedDate } from 'utils/date-helpers'

const content = allContent.order.orderPage.bookedPassengerDetails

type Props = {
    assignedTravellerNumbers: number[]
    contactDetails: ContactDetails
    leadTravellerNumber: number
    travellers: Record<any, Traveller>
}

const OrderBookedPassengerDetails = ({
    travellers,
    assignedTravellerNumbers,
    contactDetails,
    leadTravellerNumber,
}: Props): JSX.Element => {
    const nonLeadTravellerNumbers = assignedTravellerNumbers.filter(
        (travellerNumber) => travellerNumber !== leadTravellerNumber
    )
    const leadTraveller = travellers[leadTravellerNumber]
    const nonLeadTravellers = nonLeadTravellerNumbers.map((travellerNumber) => {
        return travellers[travellerNumber]
    })

    const contactInfoKeyValues: any[] = [
        [
            content.contactInformation.address,
            contactDetails.address.line1 + ' ' + contactDetails.address.line2,
        ],
        [content.contactInformation.city, contactDetails.address.cityRegion],
        [content.contactInformation.stateProvince, contactDetails.address.stateProvince ?? ''],
        [content.contactInformation.zipPostalCode, contactDetails.address.zipPostalCode],
        [content.contactInformation.email, contactDetails.email],
        [content.contactInformation.phoneNumber, contactDetails.phoneNumber.number],
    ].filter((keyValue) => (keyValue as [string, string])?.[1])

    const descriptionsMaxKeyLength = 15

    const nonLeadTravellersInfo = nonLeadTravellers.map((nonLeadTraveller) => {
        const travellerInfoKeyValue: any[] = [
            [
                content.passengerInformation.dateOfBirth,
                nonLeadTraveller.dateOfBirth
                    ? getFormattedDate(nonLeadTraveller.dateOfBirth, 'short')
                    : '',
            ],
            [content.passengerInformation.nationality, nonLeadTraveller.nationality ?? ''],
            [content.passengerInformation.passportNumber, nonLeadTraveller.passport?.number ?? ''],
            [
                content.passengerInformation.passportStartDate,
                nonLeadTraveller.passport?.startDate ?? '',
            ],
            [
                content.passengerInformation.passportExpiryDate,
                nonLeadTraveller.passport?.endDate ?? '',
            ],
        ].filter((keyValue) => (keyValue as [string, string])?.[1])

        return (
            <>
                <div key={nonLeadTraveller.travellerNumber}>
                    <Spacing>
                        <Text weight='bold'>
                            {`${content.passengerInformation.nonLeadPassengerHeading} ${nonLeadTraveller.travellerNumber}`}
                        </Text>
                    </Spacing>
                    <Text>
                        {capitalizeEachWord(
                            `${nonLeadTraveller.title} ${nonLeadTraveller.firstName} ${nonLeadTraveller.lastName}`
                        )}
                    </Text>
                    <DescriptionListDisplay
                        itemClassName={styles['list-item']}
                        overrideMaxKeyLength={descriptionsMaxKeyLength}
                        keyValueContent={travellerInfoKeyValue}
                    />
                    <ColoredLine />
                </div>
            </>
        )
    })

    const leadPassengerInfo: any[] = [
        [
            content.passengerInformation.dateOfBirth,
            leadTraveller.dateOfBirth ? getFormattedDate(leadTraveller.dateOfBirth, 'short') : '',
        ],
        [content.passengerInformation.nationality, leadTraveller.nationality ?? ''],
        [content.passengerInformation.passportNumber, leadTraveller.passport?.number ?? ''],
        [content.passengerInformation.passportStartDate, leadTraveller.passport?.startDate ?? ''],
        [content.passengerInformation.passportExpiryDate, leadTraveller.passport?.endDate ?? ''],
    ].filter((keyValue) => (keyValue as [string, string])?.[1])

    return (
        <Card header={content.passengerInformationHeading}>
            <div className={styles['passenger-information__container']}>
                <div>
                    <Spacing>
                        <Text weight='bold'>
                            {content.passengerInformation.leadPassengerHeading}
                        </Text>
                    </Spacing>
                    <Text>
                        {capitalizeEachWord(
                            `${leadTraveller.title} ${leadTraveller.firstName} ${leadTraveller.lastName}`
                        )}
                    </Text>
                    <DescriptionListDisplay
                        itemClassName={styles['list-item']}
                        overrideMaxKeyLength={descriptionsMaxKeyLength}
                        keyValueContent={leadPassengerInfo}
                    />
                    <ColoredLine />
                </div>
                {nonLeadTravellersInfo.length > 0 && <div>{nonLeadTravellersInfo}</div>}
                <div>
                    <Spacing>
                        <Text weight='bold'>{content.contactInformation.heading}</Text>
                    </Spacing>
                    <Text>
                        {capitalizeEachWord(
                            `${contactDetails.title} ${contactDetails.firstName} ${contactDetails.lastName}`
                        )}
                    </Text>
                    <DescriptionListDisplay
                        itemClassName={styles['list-item']}
                        overrideMaxKeyLength={descriptionsMaxKeyLength}
                        keyValueContent={contactInfoKeyValues}
                    />
                </div>
            </div>
        </Card>
    )
}

export default OrderBookedPassengerDetails
