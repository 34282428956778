import React, { SetStateAction, useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import FieldError from 'components/basics/FieldError/FieldError'
import Heading from 'components/basics/Heading/Heading'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Link from 'components/basics/Link/Link'
import PasswordInput from 'components/basics/Input/PasswordInput/PasswordInput'
import Spacing from 'components/basics/Spacing/Spacing'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { setDataToLocalStorage } from 'utils/use-local-storage'
import { getCognitoErrorMessage } from 'utils/cognito-helpers/get-cognito-error-message'
import { extractCognitoFields } from 'utils/cognito-helpers/extract-cogntio-fields'
import {
    LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY,
    COGNITO_SESSION_EXPIRY_MINS,
    COGNITO_ACTIONS,
    REGEX,
} from 'utils/constants'
import { FeatureToggleContext } from 'App'

import styles from './LoginForm.module.css'
import allContent from 'content/content'

const content = allContent.cognito.loginForm

type LoginFormDataType = {
    userEmail: string
    password: string
}

type LoginFormProps = {
    setIsAuthorised: React.Dispatch<SetStateAction<boolean | null>>
    setUserCognitoData: React.Dispatch<SetStateAction<Record<string, any> | undefined>>
    setShowExpiredBanner: React.Dispatch<SetStateAction<boolean>>
    setTenantId: React.Dispatch<SetStateAction<string>>
}

const LoginForm: React.FC<LoginFormProps> = ({
    setIsAuthorised,
    setUserCognitoData,
    setShowExpiredBanner,
    setTenantId,
}) => {
    const navigate = useNavigate()
    const featureToggle = useContext(FeatureToggleContext)
    const turnOnSignUpLink = featureToggle.TURN_ON_SIGN_UP_LINK

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [fetchedUserWithPasswordChallengeToggle, setFetchedUserWithPasswordChallengeToggle] =
        useState<boolean>(false)
    const [loginApiError, setLoginApiError] = useState<string>('')
    useEffect(() => {
        //Render occurred. Navigate to new password if form submission returned valid user and challenge param = NEW_PASSWORD:',
        if (fetchedUserWithPasswordChallengeToggle) {
            navigate(ROUTES.NEW_PASSWORD)
        }
    }, [fetchedUserWithPasswordChallengeToggle, navigate])

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormDataType>({
        reValidateMode: 'onChange', // only comes into effect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            userEmail: '',
            password: '',
        },
    })

    async function handleSubmitClick(formFields: LoginFormDataType): Promise<void> {
        setIsSubmitting(true)
        setLoginApiError('')
        await Auth.signIn(formFields.userEmail, formFields.password.trim()) // Use Trim to remove leading and trailing whitespace - common error from user copying and pasting code from email on first login
            .then((user) => {
                /** Clear banner if user had timeout previous trying to log back in */
                setShowExpiredBanner(false)
                /** Handle successful login  - with new_password challenge (first time logging on from password rest) */
                if (user.challengeName === COGNITO_ACTIONS.new_password) {
                    setDataToLocalStorage({
                        data: { user: user },
                        key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY,
                        expiryMins: COGNITO_SESSION_EXPIRY_MINS,
                    })
                    datadogLogs.logger.info(`source: Login, userEmail: ${formFields.userEmail}`, {
                        userContext: { userEmail: formFields.userEmail },
                    })
                    setFetchedUserWithPasswordChallengeToggle(true)
                } else {
                    /** Handle successful login! */
                    setUserCognitoData(user)
                    datadogLogs.logger.info(`source: Login, userEmail: ${formFields.userEmail}`, {
                        userContext: {
                            userEmail: formFields.userEmail,
                        },
                    })
                    setIsAuthorised(true)
                    setTenantId(extractCognitoFields(user).tenantId)
                    setDataToLocalStorage({
                        data: { user: user },
                        key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY,
                        expiryMins: COGNITO_SESSION_EXPIRY_MINS,
                    })
                }
            })
            .catch((error) => {
                setIsSubmitting(false)
                setLoginApiError(getCognitoErrorMessage(error?.name || 'unknown'))
                datadogLogs.logger.error(
                    `source: Login, error using email: ${formFields.userEmail}`,
                    { userEmail: formFields.userEmail },
                    error
                )
            })
    }

    return (
        <>
            {turnOnSignUpLink && (
                <>
                    <InfoBanner bannerType='info' id='login-reset-pass' isCloseable={false}>
                        <p>
                            <Text weight='bold'>
                                {content.passwordResetInfo1a}
                                <Link bold={true} to={ROUTES.FORGOT_PASSWORD}>
                                    Here
                                </Link>
                            </Text>
                        </p>
                        <p>{content.passwordResetInfo1b}</p>
                        <p>{content.passwordResetInfo1c}</p>
                    </InfoBanner>
                    <Spacing size='double' />
                </>
            )}
            <div className={styles.container}>
                <form
                    className={styles['login-form']}
                    onSubmit={handleSubmit(async (formFields: LoginFormDataType) => {
                        await handleSubmitClick(formFields)
                    })}
                >
                    <Heading heading='1'>{content.title}</Heading>
                    <fieldset disabled={isSubmitting} className={styles['field-set']}>
                        <legend className='visually-hidden'>{`${content.userEmailInput} + ${content.passwordInput}`}</legend>
                        <Controller
                            control={control}
                            name='userEmail'
                            rules={{
                                required: true,
                                minLength: 1,
                                maxLength: 300,
                                pattern: REGEX.EMAIL,
                            }}
                            render={({
                                field: { onChange, onBlur, value },
                            }): React.ReactElement => (
                                <div className={styles['inputs-wrapper']}>
                                    <LabelledInput
                                        required={true}
                                        htmlFor='userEmail'
                                        label={content.userEmailInput}
                                        aria-describedby='userEmail-error-message'
                                    >
                                        <TextInput
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                        />
                                    </LabelledInput>
                                    <FieldError
                                        inputId='userEmail'
                                        showError={!!errors.userEmail}
                                        errorMessage={content.errors.userEmailInput}
                                    />
                                </div>
                            )}
                        />
                        <Controller
                            control={control}
                            name='password'
                            rules={{
                                required: true,
                                minLength: 6,
                                maxLength: 30,
                                pattern: REGEX.PASSWORD,
                            }}
                            render={({
                                field: { onChange, onBlur, value },
                            }): React.ReactElement => (
                                <div className={styles['inputs-wrapper']}>
                                    <LabelledInput
                                        required={true}
                                        htmlFor='password'
                                        label={content.passwordInput}
                                        aria-describedby='password-error-message'
                                        hint={content.passwordInputToolTip}
                                    >
                                        <PasswordInput
                                            className={styles['password-input']}
                                            autoComplete='off'
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                        />
                                    </LabelledInput>
                                    <FieldError
                                        inputId='password'
                                        showError={!!errors.password}
                                        errorMessage={content.errors.passwordInput}
                                    />
                                    {!errors.password && <Spacing size='double' />}
                                </div>
                            )}
                        />
                    </fieldset>
                    <Link bold={true} to={ROUTES.FORGOT_PASSWORD}>
                        {content.forgotPasswordLinkText}
                    </Link>
                    <Button
                        className={styles['submit-button']}
                        type='submit'
                        flavour='primary'
                        text={isSubmitting ? 'Submitting' : content.submitButton}
                        showSpinner={isSubmitting}
                    />
                    {!!loginApiError && (
                        <>
                            <InfoBanner
                                id='reset-password-api-error'
                                bannerType='error'
                                text={loginApiError}
                                isFocusable={true}
                            />
                            {turnOnSignUpLink && (
                                <InfoBanner
                                    bannerType='info'
                                    id='login-error-reminder'
                                    isCloseable={true}
                                    text='Did you remember to reset your password since our recent
                                        update? (20th Aug)'
                                />
                            )}
                        </>
                    )}
                    {turnOnSignUpLink && (
                        <p className={styles['signup-text']}>
                            {content.signUpText}
                            <Link bold={true} to={ROUTES.SIGNUP}>
                                {content.signUpLinkText}
                            </Link>
                        </p>
                    )}
                </form>
                <div className={styles['demo-link-section']}>
                    <Text color='white' weight='bold'>
                        {content.demoVersionQuestion}
                    </Text>
                    <Link bold={true} href={content.demoLink} noUnderLine>
                        <Button flavour='secondary' type='button' text={content.tryNowButtonText} />
                    </Link>
                </div>
            </div>
        </>
    )
}

export default LoginForm
