import React, { useEffect, useState } from 'react'

import AllOrdersLayout from 'components/layouts/order/AllOrdersLayout/AllOrdersLayout'

import { useRest, UseRestOptions } from 'components/hooks/useRest'
import { HTTP_METHODS, VITALLY_EVENTS } from 'utils/constants'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'

import { AllOrdersContentModel, Order, ApiOrder } from 'api-data-models/order/AllOrdersContentModel'
import { orderServiceUrls } from 'utils/order-service-urls'

const OrdersPage: React.FC = (): JSX.Element => {
    const [orders, setOrders] = useState<Order[] | undefined>(undefined)

    const fetchOptions: UseRestOptions = {
        url: orderServiceUrls.getOrders(),
        source: 'OrdersPage - GET_ORDERS',
        method: HTTP_METHODS.GET,
    }

    const { result, loading, error, refetch } = useRest(fetchOptions)

    function refreshOrders(): void {
        setOrders(undefined) // clear results out of local state when modify search triggers another query
        refetch()
    }

    useEffect(() => {
        CustomerSuccess.track({
            eventName: VITALLY_EVENTS.GET_ALL_ORDERS,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // only on mount only

    useEffect(() => {
        if (result && !loading) {
            setOrders(AllOrdersContentModel(result as ApiOrder[]))
        }
    }, [loading, result])

    // If result object exists, error is false, and cruises is undefined --> then the model is processing
    const processingResultData = !!result && !orders && !error

    return (
        <div className='general-container'>
            <AllOrdersLayout
                loading={loading || processingResultData}
                error={error}
                orders={orders ?? []}
                refreshOrders={refreshOrders}
            />
        </div>
    )
}

export default OrdersPage
