import React, { useState, useEffect, FC } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Text from 'components/basics/Text/Text'
import Button from 'components/basics/Button/Button'
import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import { GET_USA_STATE_BY_IATA } from 'graphql-queries/cruise/cruise-queries'
import InlineSpinner from 'components/basics/Spinners/InlineSpinner'
import { ORDER_VIEWS } from 'components/layouts/order/OrderLayout/OrderLayout'
import Card from 'components/blocks/Card/Card'
import { useApolloQuery } from 'components/hooks/useApolloQuery'

import {
    Traveller,
    ContactDetails,
    PassengerCriteria,
} from 'api-data-models/order/OrderContentModel'

import allContent from 'content/content'
import styles from './PassengerSummarySection.module.scss'
import { getFormattedDate } from 'utils/date-helpers'
import { capitalizeEachWord } from 'utils/string-helpers'
import Icon from 'components/basics/Icon/Icon'
import DescriptionListDisplay from 'components/blocks/DescriptionListDisplay/DescriptionListDisplay'
import Spacing from 'components/basics/Spacing/Spacing'

const content = allContent.order.orderPage.passengerSummarySection
const contentBooked = allContent.order.orderPage.bookedPassengerDetails

type PassengerSummarySectionProps = {
    travellers: Record<string, Traveller>
    view: string
    passengerBeingEdited: string
    contactDetails: ContactDetails
    passengersCriteria: PassengerCriteria[]
    l3ApiClient: ApolloClient<NormalizedCacheObject>
    handleGoToTravelerPage: (travellerNumber: string) => void
    handleGoToContactPage: () => void
}

const PassengerSummarySection = ({
    travellers,
    view,
    passengerBeingEdited,
    contactDetails,
    passengersCriteria,
    l3ApiClient,
    handleGoToTravelerPage,
    handleGoToContactPage,
}: PassengerSummarySectionProps): JSX.Element | null => {
    const [residencyState, setResidencyState] = useState<string>('')

    const passengers = [...passengersCriteria].sort((a, b) =>
        a.travellerNumber > b.travellerNumber ? 1 : -1
    )

    const leadPassenger = passengers?.find(({ travellerNumber }) => travellerNumber === 1)
    const nonLeadPassengers = passengers.filter(({ travellerNumber }) => travellerNumber !== 1)

    const { result, loading, error } = useApolloQuery({
        client: l3ApiClient,
        variables: { iata: passengersCriteria[0]?.residency },
        query: GET_USA_STATE_BY_IATA,
        skip: !passengersCriteria[0]?.residency,
        source: 'PassengerSummarySection - GET_USA_STATE_BY_IATA',
    })

    useEffect(() => {
        if (result?.data?.getUsaStateByIata?.name) {
            setResidencyState(result.data.getUsaStateByIata.name)
        }
        if (passengersCriteria[0]?.residency && error) {
            setResidencyState(passengersCriteria[0]?.residency)
        }
    }, [loading, result, passengersCriteria, error])

    let leadTravellerField
    if (leadPassenger) {
        leadTravellerField = (
            <PassengerShortViewSection
                onEdit={(): void => handleGoToTravelerPage('1')}
                editDisabled={passengerBeingEdited === '1'}
                passengerData={travellers[leadPassenger.travellerNumber]}
                pastPassengerReference={leadPassenger?.pastPassengerReference}
                heading={contentBooked.passengerInformation.leadPassengerHeading}
            />
        )
    }

    let nonLeadTravellerFields
    if (nonLeadPassengers.length > 0) {
        nonLeadTravellerFields = nonLeadPassengers.map(
            ({ travellerNumber, pastPassengerReference }) => {
                return (
                    <PassengerShortViewSection
                        key={travellerNumber}
                        onEdit={(): void => handleGoToTravelerPage(travellerNumber.toString())}
                        editDisabled={passengerBeingEdited === travellerNumber.toString()}
                        passengerData={travellers[travellerNumber]}
                        pastPassengerReference={pastPassengerReference}
                        heading={`${contentBooked.passengerInformation.nonLeadPassengerHeading} ${travellerNumber}`}
                    />
                )
            }
        )
    }

    return (
        <Card header={content.passengerSummary}>
            <div className={styles['passenger-summary-section__container']}>
                {leadTravellerField}
                {nonLeadTravellerFields}
                <ContactShortViewSection
                    onEdit={handleGoToContactPage}
                    editDisabled={view === ORDER_VIEWS.CONTACT}
                    contactDetails={contactDetails}
                />
                <PreferencesShortViewSection
                    passengersCriteria={passengersCriteria}
                    residencyState={residencyState}
                    error={error}
                    loading={loading}
                />
            </div>
        </Card>
    )
}

export default PassengerSummarySection

interface PreferencesShortViewSectionProps {
    passengersCriteria: PassengerCriteria[]
    residencyState?: string
    loading: boolean
    error: CustomApiError[] | null
}

const PreferencesShortViewSection: FC<PreferencesShortViewSectionProps> = ({
    passengersCriteria,
    residencyState,
    error,
    loading,
}) => {
    return (
        <div>
            <Spacing>
                <Text weight='bold'>{content.preferencesTitle}</Text>
            </Spacing>
            <div className={styles['passenger-summary-section__preferences']}>
                {passengersCriteria[0]?.military && (
                    <div className={styles['passenger-summary-section__item-preference']}>
                        <Icon iconName='Check' iconSize='S' />
                        <Text weight='bold'>{content.militaryRates}</Text>
                    </div>
                )}
                {!!passengersCriteria[0]?.residency && (
                    <div className={styles['passenger-summary-section__item-residential']}>
                        <div className={styles['passenger-summary-section__item-preference']}>
                            <Icon iconName='Check' iconSize='S' />
                            <Text weight='bold'>{content.residentialRates}</Text>
                        </div>
                        {residencyState && !loading && (
                            <Text>{`${content.residencyLocation} ${
                                error ? passengersCriteria[0]?.residency : residencyState
                            }`}</Text>
                        )}
                        {loading && <InlineSpinner />}
                    </div>
                )}
                {!passengersCriteria[0]?.military && !passengersCriteria[0]?.residency && (
                    <Text>{content.noPreferences}</Text>
                )}
                {(passengersCriteria[0]?.military || passengersCriteria[0]?.residency) && (
                    <Text size='XS'>{content.preferencesSmallText}</Text>
                )}
            </div>
        </div>
    )
}

interface PassengerShortViewSectionProps {
    onEdit: () => void
    editDisabled: boolean
    passengerData: Traveller
    pastPassengerReference: string | null
    heading: string
}

const PassengerShortViewSection: FC<PassengerShortViewSectionProps> = ({
    onEdit,
    editDisabled,
    passengerData,
    pastPassengerReference,
    heading,
}) => {
    const hasData = !!passengerData?.firstName && !!passengerData?.lastName
    const passengerInfo: any[] = hasData
        ? [
              [
                  contentBooked.passengerInformation.dateOfBirth,
                  passengerData.dateOfBirth
                      ? getFormattedDate(passengerData.dateOfBirth, 'short')
                      : '',
              ],
              [contentBooked.passengerInformation.nationality, passengerData.nationality ?? ''],
              [
                  contentBooked.passengerInformation.passportNumber,
                  passengerData.passport?.number ?? '',
              ],
              [
                  contentBooked.passengerInformation.passportStartDate,
                  passengerData.passport?.startDate ?? '',
              ],
              [
                  contentBooked.passengerInformation.passportExpiryDate,
                  passengerData.passport?.endDate ?? '',
              ],
          ].filter((keyValue) => (keyValue as [string, string])?.[1])
        : []
    return (
        <div>
            <div className={styles['passenger-summary-section__item-label']}>
                <Spacing>
                    <Text weight='bold'>{heading}</Text>
                </Spacing>
                {hasData && (
                    <Button
                        flavour='text'
                        text={content.edit}
                        iconName={'Pencil'}
                        onClick={onEdit}
                        type='button'
                        disabled={editDisabled}
                    />
                )}
            </div>
            <Text>
                {hasData &&
                    capitalizeEachWord(
                        `${passengerData.title} ${passengerData.firstName} ${passengerData.lastName}`
                    )}
            </Text>
            <div>
                <DescriptionListDisplay
                    itemClassName={styles['passenger-summary-section__item-list']}
                    overrideMaxKeyLength={15}
                    keyValueContent={passengerInfo}
                />
                {pastPassengerReference && (
                    <LabelledInput
                        htmlFor='pastPassenger'
                        label={content.pastPassenger}
                        disabled={true}
                    >
                        <TextInput value={pastPassengerReference} disabled />
                    </LabelledInput>
                )}
                <ColoredLine />
            </div>
        </div>
    )
}

interface ContactShortViewSectionProps {
    onEdit: () => void
    editDisabled: boolean
    contactDetails: ContactDetails
}

const ContactShortViewSection: FC<ContactShortViewSectionProps> = ({
    onEdit,
    editDisabled,
    contactDetails,
}) => {
    const hasData = !!contactDetails?.firstName && !!contactDetails?.lastName
    const contactInfoKeyValues: any[] = hasData
        ? [
              [
                  contentBooked.contactInformation.address,
                  `${contactDetails.address.line1} ${contactDetails.address.line2}`.trim(),
              ],
              [contentBooked.contactInformation.city, contactDetails.address.cityRegion],
              [
                  contentBooked.contactInformation.stateProvince,
                  contactDetails.address.stateProvince ?? '',
              ],
              [
                  contentBooked.contactInformation.zipPostalCode,
                  contactDetails.address.zipPostalCode,
              ],
              [contentBooked.contactInformation.email, contactDetails.email],
              [contentBooked.contactInformation.phoneNumber, contactDetails.phoneNumber.number],
          ].filter((keyValue) => (keyValue as [string, string])?.[1])
        : []
    return (
        <div>
            <div className={styles['passenger-summary-section__item-label']}>
                <Spacing>
                    <Text weight='bold'>{contentBooked.contactInformation.heading}</Text>
                </Spacing>
                {hasData && (
                    <Button
                        flavour='text'
                        text={content.edit}
                        iconName={'Pencil'}
                        onClick={onEdit}
                        type='button'
                        disabled={editDisabled}
                    />
                )}
            </div>
            <Text>
                {capitalizeEachWord(
                    `${contactDetails.title} ${contactDetails.firstName} ${contactDetails.lastName}`
                )}
            </Text>
            <div>
                <DescriptionListDisplay
                    itemClassName={styles['passenger-summary-section__item-list']}
                    overrideMaxKeyLength={15}
                    keyValueContent={contactInfoKeyValues}
                />
                <ColoredLine />
            </div>
        </div>
    )
}
