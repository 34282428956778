import { refreshUserSession } from './refresh-user-session'
import { shouldUserSessionBeRefreshed } from './should-user-session-be-refreshed'
import { handleRefreshError } from './handleRefreshError'

import { ROUTES } from '../../components/sections/app/AppRoutes'
import { getDataFromLocalStorage } from '../use-local-storage'
import { COGNITO_SESSION_EXPIRY_MINS, LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY } from '../constants'

export const HALF_SESSION_EXPIRY_MS = (COGNITO_SESSION_EXPIRY_MINS / 2) * 60 * 1000

export type AmplifyUserData = {
    user: {
        signInUserSession: {
            refreshToken: {
                token: string
            }
        }
    }
    expiry: number
}
export type HostedUserData = {
    refresh_token: string
    expiry: number
}

export async function checkAndPerformUserSessionRefreshIfNeeded(
    navigate: (url: string) => void
): Promise<void> {
    const userData: AmplifyUserData | HostedUserData | undefined = getDataFromLocalStorage({
        key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY,
        withExpiry: true,
    }) as AmplifyUserData | HostedUserData | undefined
    if (!userData) {
        navigate(ROUTES.TIMEOUT)
        return
    }
    shouldUserSessionBeRefreshed(userData)
        .then((shouldRefresh) => {
            if (shouldRefresh && userData) {
                refreshUserSession(userData as AmplifyUserData).catch((error) => {
                    handleRefreshError(navigate, error)
                })
            }
        })
        .catch((error) => {
            handleRefreshError(navigate, error)
        })
}
