import React from 'react'
import classnames from 'classnames'

import Heading from 'components/basics/Heading/Heading'
import Text from 'components/basics/Text/Text'

import styles from './CruiseConnectDescription.module.scss'
import allContent from 'content/content'

const content = allContent.admin.adminLanding.cruiseConnect
const CruiseConnectDescription = (): JSX.Element => {
    const verticalBoxClasses = [styles['item-box'], styles['item-box--vertical']]
    const verticalBoxClassnames = classnames(...verticalBoxClasses)
    const verticalBoxNoGapClassnames = classnames(...verticalBoxClasses, styles['item-box--no-gap'])

    return (
        <div className={verticalBoxClassnames}>
            <div className={styles['item-box']}>
                <div className={verticalBoxNoGapClassnames}>
                    <Heading heading='3'>{content.title}</Heading>
                    <Text weight='bold'>{content.subTitle}</Text>
                </div>
            </div>
            <div>
                <p>
                    <Text>{content.productDescription}</Text>
                </p>
                <p>
                    <Text>{content.productDescription2a}</Text>{' '}
                    <Text weight='bold'>{content.productDescription2bBold}</Text>
                </p>
            </div>
        </div>
    )
}

export default CruiseConnectDescription
