import React, { useMemo, useState, useEffect, Dispatch } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import Card from 'components/blocks/Card/Card'
import Checkbox from 'components/basics/Input/Checkbox/Checkbox'
import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import DateTextInput from 'components/blocks/DateTextInput/DateTextInput'
import FieldError from 'components/basics/FieldError/FieldError'
import Heading from 'components/basics/Heading/Heading'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import PhoneInput from 'components/blocks/PhoneInput/PhoneInput'
import Select from 'components/basics/Input/Select/Select'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import { ORDER_ACTIONS, OrderDispatchProps } from '../../../reducers/orderReducer'
import { countryData } from 'data/country-codes'
import { PassengerCriteria, Traveller } from 'api-data-models/order/OrderContentModel'
import { calculateHourDifference, checkDateIsValid, formatDateOnSubmit } from 'utils/date-helpers'
import { handleFocusOnId } from 'utils/handle-focus-on-id'
import { checkAndPerformUserSessionRefreshIfNeeded } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'
import { HTTP_METHODS, REGEX, VITALLY_EVENTS } from 'utils/constants'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'

import * as self from './TravellerAddForm'

import styles from './TravellerAddForm.module.css'
import allContent from 'content/content'
import callApi from 'services/callApi/callApi.service'
import { getRestErrorMessage } from 'utils/api-errors-helpers/get-rest-error-message'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import { orderServiceUrls } from 'utils/order-service-urls'

const content = allContent.order.orderPage.travellerAddForm
const emergencyPhoneContent = content.emergencyContact.phoneNumber

export const PHONE_TYPE = 'home' // TODO: API requires this value, not on form for users. should maybe just be set by api, this is now magic frontend value setting...

export type APIPassengerFormData = {
    traveller_number: number
    past_passenger_references: Array<{
        past_passenger_reference: string
        supplier_code: string
    }>
    title: string
    first_name: string
    middle_name: string | null
    last_name: string
    gender: string
    date_of_birth: string
    nationality: string | null
    passport: {
        number: string
        start_date: string
        end_date: string
    } | null
    emergency_contact: {
        first_name: string
        last_name: string
        phone_number: string
        email: string
    } | null
    accessibility_needs: boolean
    military: boolean
    military_number: string | null
    residency: string | null
}

export type FormDataSubmitValues = {
    birthDate: string
    passportStartDate: string
    passportEndDate: string
    title: string
    firstName: string
    middleName?: string
    noMiddleName: boolean
    lastName: string
    dayOfBirth: string
    monthOfBirth: string
    yearOfBirth: string
    nationality: string
    accessibilityNeeds: boolean
    passportNumber: string
    passportStartDay: string
    passportStartMonth: string
    passportStartYear: string
    passportEndDay: string
    passportEndMonth: string
    passportEndYear: string
    emergencyContactFirstName?: string
    emergencyContactLastName?: string
    emergencyContactCountryCallingCode?: string
    emergencyContactPhoneNumber?: string
    emergencyContactEmail?: string
}

type ValidateAddTravellerFormProps = {
    travellerData: FormDataSubmitValues
    setBirthDateError: (value: boolean) => void
    setPassportStartDateError: (value: boolean) => void
    setPassportEndDateError: (value: boolean) => void
    setEmergencyContactDetailsError: (value: boolean) => void
    todaysDate: Date
}

export const validateAddTravellerForm = ({
    travellerData,
    setBirthDateError,
    setPassportStartDateError,
    setPassportEndDateError,
    setEmergencyContactDetailsError,
    todaysDate,
}: ValidateAddTravellerFormProps): boolean => {
    const {
        dayOfBirth,
        monthOfBirth,
        yearOfBirth,
        passportNumber,
        passportStartDay,
        passportStartMonth,
        passportStartYear,
        passportEndDay,
        passportEndMonth,
        passportEndYear,
        emergencyContactFirstName,
        emergencyContactLastName,
        emergencyContactCountryCallingCode,
        emergencyContactPhoneNumber,
        emergencyContactEmail,
    } = travellerData
    let validationPassed = true
    const isBirthDateValid = checkDateIsValid({
        day: parseInt(dayOfBirth, 10),
        month: parseInt(monthOfBirth, 10),
        year: parseInt(yearOfBirth, 10),
    })
    const birthDate = new Date(`${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`)
    // Set Error if 1) date doesn't make sense (31 days in feb etc.), 2) if birthdate is later than today
    if (!isBirthDateValid || birthDate > todaysDate) {
        setBirthDateError(true)
        handleFocusOnId('birthDate-error-message')
        validationPassed = false
    }

    const emergencyContactDetails = [
        emergencyContactFirstName,
        emergencyContactLastName,
        emergencyContactCountryCallingCode,
        emergencyContactPhoneNumber,
        emergencyContactEmail,
    ]
    //checks if each field is filled
    const emergencyContactDetailsFilled = emergencyContactDetails.every((field) => {
        return field
    })
    //checks if each field is not filled
    const emergencyContactDetailsEmpty = emergencyContactDetails.every((field) => {
        return !field
    })

    if (!emergencyContactDetailsFilled && !emergencyContactDetailsEmpty) {
        setEmergencyContactDetailsError(true)
        handleFocusOnId('emergencyContactDetails-error-message')
        validationPassed = false
    }

    const passportDetailsNeedValidation =
        passportStartDay ||
        passportStartMonth ||
        passportStartYear ||
        passportEndDay ||
        passportEndMonth ||
        passportEndYear ||
        passportNumber
    if (passportDetailsNeedValidation) {
        if (passportNumber === '') validationPassed = false
        const isPassportStartDateValid = checkDateIsValid({
            day: parseInt(passportStartDay, 10),
            month: parseInt(passportStartMonth, 10),
            year: parseInt(passportStartYear, 10),
        })
        const passportStartDate = new Date(
            formatDateOnSubmit({
                day: passportStartDay,
                month: passportStartMonth,
                year: passportStartYear,
            })
        )
        // Set Error if 1) date doesn't make sense (31 days in feb etc), 2) if passport starts after today, 3) if passport starts before than birthdate
        if (
            !isPassportStartDateValid ||
            passportStartDate > todaysDate ||
            passportStartDate < birthDate
        ) {
            // TODO: check that passport needs to be valid from day of booking...
            setPassportStartDateError(true)
            handleFocusOnId('passportStartDate-error-message')
            validationPassed = false
        }

        const isPassportEndDateValid = checkDateIsValid({
            day: parseInt(passportEndDay, 10),
            month: parseInt(passportEndMonth, 10),
            year: parseInt(passportEndYear, 10),
        })
        const passportEndDate = new Date(
            formatDateOnSubmit({
                day: passportEndDay,
                month: passportEndMonth,
                year: passportEndYear,
            })
        )

        // Set Error if 1) date doesn't make sense (31 days in feb etc), 2) if end date is earlier than start date, 3) if end date is earlier than today?
        if (
            !isPassportEndDateValid ||
            passportEndDate <= passportStartDate ||
            passportEndDate <= todaysDate
        ) {
            setPassportEndDateError(true)
            handleFocusOnId('passportEndDate-error-message')
            validationPassed = false
        }
    }
    return validationPassed
}

type AssignPassengerProps = {
    isLeadPassenger: boolean
    orderId: string
    assignedTravellers: number[]
    orderItemId: string
    travellerNumber: number
    dispatchOrderState(props: OrderDispatchProps): void
    navigate(): void
}

export const assignPassenger = async ({
    isLeadPassenger,
    orderId,
    orderItemId,
    assignedTravellers,
    travellerNumber,
    dispatchOrderState,
    navigate,
}: AssignPassengerProps): Promise<{
    error: Error | undefined
}> => {
    let error: Error | undefined = undefined
    const handleUserSession = checkAndPerformUserSessionRefreshIfNeeded(navigate)
    const userContext = datadogLogs.getGlobalContext()
    const assignedTravelers = [...assignedTravellers, travellerNumber]
    await handleUserSession.then(() =>
        callApi({
            url: orderServiceUrls.assignOrderTraveller(orderId, orderItemId),
            variables: assignedTravelers,
            method: HTTP_METHODS.POST,
        })
            .then((response) => response.json())
            .then(async (response: any) => {
                datadogLogs.logger.info(
                    `source: OrderItemTravellerAssign, variables: ${JSON.stringify({
                        orderId: orderId,
                        orderItemId: orderItemId,
                        travellerNumbers: assignedTravelers,
                    })}`,
                    { userContext }
                )
                if (response) {
                    dispatchOrderState({
                        type: ORDER_ACTIONS.ASSIGN_TRAVELLERS,
                        payload: { travellerNumbers: assignedTravelers },
                    })
                    if (isLeadPassenger) {
                        await callApi({
                            url: orderServiceUrls.assignOrderLeadTraveller(
                                orderId,
                                orderItemId,
                                String(travellerNumber)
                            ),
                            method: HTTP_METHODS.POST,
                        })
                            .then((response: any) => {
                                datadogLogs.logger.info(
                                    `source: OrderItemLeadTravellerAssign, GraphQL-variables: ${JSON.stringify(
                                        {
                                            orderId: orderId,
                                            orderItemId: orderItemId,
                                            leadTravellerNumber: travellerNumber,
                                        }
                                    )}`,
                                    { userContext }
                                )
                                if (response) {
                                    dispatchOrderState({
                                        type: ORDER_ACTIONS.ASSIGN_LEAD_TRAVELLER,
                                        payload: { travellerNumber: travellerNumber },
                                    })
                                }
                            })
                            .catch((e: any) => {
                                datadogLogs.logger.error(
                                    `source: OrderItemTravellerAssign, GraphQL-variables: ${JSON.stringify(
                                        {
                                            orderId: orderId,
                                            orderItemId: orderItemId,
                                            leadTravellerNumber: travellerNumber,
                                        }
                                    )}`,
                                    { userContext },
                                    error
                                )
                                error = e
                            })
                    }
                }
            })
            .catch((e: any) => {
                datadogLogs.logger.error(
                    `source: OrderItemTravellerAssign, variables: ${JSON.stringify({
                        orderId: orderId,
                        orderItemId: orderItemId,
                        travellerNumbers: assignedTravelers,
                    })}`,
                    { userContext },
                    error
                )
                error = e
            })
    )

    return { error }
}

const getMiddleNameForSubmit = (travellerData: FormDataSubmitValues): any => {
    if (travellerData.middleName && !travellerData.noMiddleName)
        return { middle_name: travellerData.middleName }
    else return {}
}

const getEmergencyContactForSubmit = ({
    emergencyContactFirstName,
    emergencyContactLastName,
    emergencyContactPhoneNumber,
    emergencyContactCountryCallingCode,
    emergencyContactEmail,
}: FormDataSubmitValues): any => {
    if (
        emergencyContactFirstName &&
        emergencyContactLastName &&
        emergencyContactPhoneNumber &&
        emergencyContactCountryCallingCode &&
        emergencyContactEmail
    )
        return {
            emergency_contact: {
                first_name: emergencyContactFirstName,
                last_name: emergencyContactLastName,
                phone_number: `+${
                    emergencyContactCountryCallingCode + emergencyContactPhoneNumber
                }`,
                email: emergencyContactEmail,
            },
        }
    else return {}
}

const getPassportDataForSubmit = ({
    passportNumber,
    passportStartDay,
    passportStartMonth,
    passportStartYear,
    passportEndDay,
    passportEndMonth,
    passportEndYear,
    nationality,
}: FormDataSubmitValues): any => {
    if (passportNumber && nationality)
        return {
            nationality: nationality,
            passport: {
                number: passportNumber,
                start_date: formatDateOnSubmit({
                    day: passportStartDay,
                    month: passportStartMonth,
                    year: passportStartYear,
                }),
                end_date: formatDateOnSubmit({
                    day: passportEndDay,
                    month: passportEndMonth,
                    year: passportEndYear,
                }),
            },
        }
    else return {}
}

interface FormSubmitProps {
    navigate(): void
    backToOrder(): void
    travellerData: FormDataSubmitValues
    isEditing: boolean
    isLeadPassenger: boolean
    assignedTravellers: number[]
    orderId: string
    orderItemId: string
    setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
    travellerNumber: number
    dispatchOrderState(props: OrderDispatchProps): void
    setBirthDateError: Dispatch<boolean>
    setPassportStartDateError: Dispatch<boolean>
    setPassportEndDateError: Dispatch<boolean>
    setEmergencyContactDetailsError: Dispatch<boolean>
    setApiError: Dispatch<Record<string, any> | null>
    clearAllErrors(): void
    pastPassengerReferences?: {
        pastPassengerReference: string
        supplierCode: string
    }[]
    setCompletedStep: (step?: number) => void
}

export const onSubmit = async ({
    travellerData,
    isEditing,
    assignedTravellers,
    isLeadPassenger,
    orderItemId,
    orderId,
    setIsSubmitting,
    travellerNumber,
    dispatchOrderState,
    setBirthDateError,
    setPassportStartDateError,
    setPassportEndDateError,
    setEmergencyContactDetailsError,
    setApiError,
    clearAllErrors,
    pastPassengerReferences,
    navigate,
    setCompletedStep,
}: FormSubmitProps): Promise<void> => {
    clearAllErrors()
    setIsSubmitting(true)
    const isValid = self.validateAddTravellerForm({
        travellerData,
        todaysDate: new Date(),
        setBirthDateError,
        setPassportStartDateError,
        setPassportEndDateError,
        setEmergencyContactDetailsError,
    })
    if (!isValid) {
        setIsSubmitting(false)
        return
    }
    const userContext = datadogLogs.getGlobalContext()
    const requestUrl = isEditing
        ? orderServiceUrls.updateOrderTraveller(orderId, String(travellerNumber))
        : orderServiceUrls.addOrderTraveller(orderId)

    const eventName = isEditing
        ? VITALLY_EVENTS.UPDATE_TRAVELLER_DETAILS
        : VITALLY_EVENTS.ADD_TRAVELLER_DETAILS

    const dispatchType = isEditing
        ? ORDER_ACTIONS.UPDATE_TRAVELLER_DETAILS
        : ORDER_ACTIONS.ADD_TRAVELLER_DETAILS

    const source = isEditing ? 'OrderTravellerUpdate' : 'OrderTravellerAdd'

    const handleUserSession = checkAndPerformUserSessionRefreshIfNeeded(navigate)

    const requestBody = {
        traveller_number: travellerNumber,
        title: travellerData.title,
        first_name: travellerData.firstName,
        last_name: travellerData.lastName,
        ...getMiddleNameForSubmit(travellerData),
        ...getEmergencyContactForSubmit(travellerData),
        ...getPassportDataForSubmit(travellerData),
        date_of_birth: formatDateOnSubmit({
            day: travellerData.dayOfBirth,
            month: travellerData.monthOfBirth,
            year: travellerData.yearOfBirth,
        }),
        accessibility_needs: travellerData.accessibilityNeeds,
        ...(pastPassengerReferences?.length && {
            past_passenger_references: pastPassengerReferences,
        }),
    }

    let submitApiError: any = null
    let assignApiError: any = null

    await handleUserSession.then(() => {
        callApi({
            url: requestUrl,
            variables: requestBody,
            method: HTTP_METHODS.POST,
        })
            .then((response) => response.json())
            .then((data) => {
                datadogLogs.logger.info(`source: ${source}`, { userContext })
                // Search travellerData in the response to be sure we have one source of truth
                const travellerData = data?.travellers?.find(
                    ({ traveller_number }: any) => traveller_number === travellerNumber
                )
                if (travellerData) {
                    if (isEditing) setCompletedStep()
                    else setCompletedStep(travellerNumber)
                    dispatchOrderState({
                        type: dispatchType,
                        payload: travellerData,
                    })
                    return true
                } else return false
            })
            .then(async (isSuccessful) => {
                if (isSuccessful && !isEditing) {
                    const { error } = await assignPassenger({
                        isLeadPassenger,
                        orderItemId,
                        orderId,
                        assignedTravellers,
                        travellerNumber,
                        dispatchOrderState,
                        navigate,
                    })
                    if (error) assignApiError = error
                }
            })
            .catch((error) => {
                submitApiError = error
            })
            .finally(() => {
                setIsSubmitting(false)
                CustomerSuccess.track({
                    eventName: eventName,
                    properties: { leadPassenger: isLeadPassenger },
                })
                if (submitApiError || assignApiError) {
                    const error = submitApiError ?? assignApiError
                    setApiError(
                        getRestErrorMessage({
                            errors: error.cause,
                            source: requestUrl,
                            variables: requestBody,
                        })
                    )
                    return
                }
            })
    })
}

type TravellerAddFormProps = {
    supplierCode?: string
    assignedTravellers: number[]
    passengerBeingEdited: string
    orderId: string
    orderItemId: string
    backToOrder(): void
    dispatchOrderState(props: OrderDispatchProps): void
    setCompletedStep(step?: number): void
    travellers: Record<string, Traveller>
    passengersCriteria: PassengerCriteria[]
}

const defaultValues = {
    birthDate: '',
    passportStartDate: '',
    passportEndDate: '',
    title: '',
    firstName: '',
    middleName: '',
    noMiddleName: false,
    lastName: '',
    dayOfBirth: '',
    monthOfBirth: '',
    yearOfBirth: '',
    nationality: '',
    passportNumber: '',
    passportStartDay: '',
    passportStartMonth: '',
    passportStartYear: '',
    passportEndDay: '',
    passportEndMonth: '',
    passportEndYear: '',
    emergencyContactFirstName: '',
    emergencyContactLastName: '',
    emergencyContactPhoneNumber: '',
    emergencyContactCountryCallingCode: '',
    emergencyContactEmail: '',
    accessibilityNeeds: false,
}

const TravellerAddForm = ({
    supplierCode,
    assignedTravellers,
    orderId,
    orderItemId,
    passengerBeingEdited,
    backToOrder,
    dispatchOrderState,
    setCompletedStep,
    travellers,
    passengersCriteria,
}: TravellerAddFormProps): JSX.Element => {
    const [isEditing, setIsEditing] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [disableMiddleNameInput, setDisableMiddleNameInput] = useState(false)
    const [birthDateError, setBirthDateError] = useState(false)
    const [passportStartDateError, setPassportStartDateError] = useState(false)
    const [passportEndDateError, setPassportEndDateError] = useState(false)
    const [emergencyContactDetailsError, setEmergencyContactDetailsError] = useState(false)

    const [apiError, setApiError] = useState<CustomApiError[] | null>(null)

    const isLeadPassenger = passengerBeingEdited === '1'
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        reset,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange', // only comes into affect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            ...defaultValues,
            pastPassengerReference:
                passengersCriteria[+passengerBeingEdited - 1]?.pastPassengerReference,
        },
    })

    const validateDateOfBirth = (): void => {
        const MIN_AGE_TEMP = 18
        if (isLeadPassenger) {
            const data = getValues()
            const day = data.dayOfBirth
            const month = data.monthOfBirth
            const year = data.yearOfBirth
            if (day.length > 0 && month.length > 0 && year.length === 4) {
                const dateToCheck = new Date(`${month}-${day}-${year}`)
                const diff = Math.round(calculateHourDifference(dateToCheck) / 8760)
                if (diff > -MIN_AGE_TEMP) {
                    const message = `${content.dateOfBirth.minAgeValidationError} ${MIN_AGE_TEMP}`
                    setBirthDateError(true)
                    setError('birthDate', { message: message, type: 'validate' })
                } else {
                    setBirthDateError(false)
                    clearErrors('birthDate')
                }
            }
        }
    }

    function clearAllErrors(): void {
        setBirthDateError(false)
        setPassportStartDateError(false)
        setPassportEndDateError(false)
        setEmergencyContactDetailsError(false)
        setApiError(null)
        clearErrors()
    }

    const pastPassengerReference = passengersCriteria.find(
        (el) => String(el.travellerNumber) === String(passengerBeingEdited)
    )?.pastPassengerReference

    const pastPassengerReferences = pastPassengerReference
        ? [
              {
                  pastPassengerReference,
                  supplierCode /** ONLY EXISTS ON CRUISES - flight doesn't have this field on the product */,
              },
          ]
        : undefined

    useEffect(() => {
        if (assignedTravellers.includes(parseInt(passengerBeingEdited))) {
            const currentTraveller = travellers[parseInt(passengerBeingEdited)]
            const passportEndDate = currentTraveller?.passport?.endDate ?? ''
            const passportStartDate = currentTraveller?.passport?.startDate ?? ''
            const dateOfBirth = currentTraveller?.dateOfBirth ?? ''
            const [yearOfBirth, monthOfBirth, dayOfBirth] = dateOfBirth.split('-')
            const [passportStartYear, passportStartMonth, passportStartDay] =
                passportStartDate.split('-')
            const [passportEndYear, passportEndMonth, passportEndDay] = passportEndDate.split('-')
            const noMiddleName = currentTraveller?.middleName // TODO: get api to take this... till then needs to be inferred with this crap
                ? typeof currentTraveller.middleName === 'string' &&
                  currentTraveller.middleName.length > 0 &&
                  currentTraveller.firstName === ''
                : true

            setDisableMiddleNameInput(noMiddleName)
            const newInitialValues = {
                title: currentTraveller.title,
                firstName: currentTraveller.firstName,
                middleName: currentTraveller.middleName ?? '',
                noMiddleName: noMiddleName,
                lastName: currentTraveller.lastName,
                dayOfBirth: dayOfBirth,
                monthOfBirth: monthOfBirth,
                yearOfBirth: yearOfBirth,
                nationality: currentTraveller.nationality,
                accessibilityNeeds: currentTraveller.accessibilityNeeds,
                emergencyContactFirstName: currentTraveller?.emergencyContact?.firstName,
                emergencyContactLastName: currentTraveller?.emergencyContact?.lastName,
                emergencyContactPhoneNumber: currentTraveller?.emergencyContact?.phoneNumber,
                emergencyContactCountryCallingCode: '', // TODO: what to do here... its spliced in with number and seems an pain to split as country code can be different lengths
                emergencyContactEmail: currentTraveller?.emergencyContact?.email,
                passportNumber: currentTraveller?.passport?.number,
                passportStartDay: passportStartDay,
                passportStartMonth: passportStartMonth,
                passportStartYear: passportStartYear,
                passportEndDay: passportEndDay,
                passportEndMonth: passportEndMonth,
                passportEndYear: passportEndYear,
            }
            // Need to reset form defaults - since switching between passengers the data updates but useForm hook only sets defaults once, not on re-renders.
            reset({ ...newInitialValues })
            setIsEditing(true)
        } else {
            reset({ ...defaultValues })
            setIsEditing(false)
        }
    }, [passengersCriteria, passengerBeingEdited, travellers, reset, isEditing, assignedTravellers])

    const nationalityOptions = useMemo(() => {
        return countryData.map((country) => {
            return {
                value: country.iso2Code,
                text: country.flag + ' ' + country.name,
            }
        })
    }, [])

    const titleOptions = useMemo(() => {
        return [
            { value: 'MR', text: 'Mr' },
            { value: 'MS', text: 'Ms' },
        ]
    }, [])

    const countryCallingCodeOptions = useMemo(() => {
        return countryData
            .filter((country) => country.iso2Code !== 'UM') // Exclude the object with iso2Code 'UM' because the value won't be unique and is not needed here
            .map((country) => ({
                value: country.phoneCode,
                text: country.flag + ' ' + country.name + ' +' + country.phoneCode,
            }))
    }, [])

    const passengerTitle = isLeadPassenger
        ? content.leadPassenger
        : `${content.otherPassenger} ${passengerBeingEdited}`
    const passengerTitlePrefix = isEditing ? content.edit : content.add
    const submitButton = content.savePassengerButton

    function handleSelectTitle(e: React.ChangeEvent<HTMLSelectElement>): void {
        setValue('title', e.target.value)
    }
    function handleSelectNationality(e: React.ChangeEvent<HTMLSelectElement>): void {
        setValue('nationality', e.target.value)
    }
    function handleOnChangeContactCntryCllngCd(e: React.ChangeEvent<HTMLSelectElement>): void {
        setValue('emergencyContactCountryCallingCode', e.target.value)
    }
    function handleCheckNoMiddleName(): void {
        setDisableMiddleNameInput(!getValues('noMiddleName'))
        setValue('noMiddleName', !getValues('noMiddleName'))
    }
    const navigate = useNavigate()
    return (
        <>
            <Card header={passengerTitlePrefix + ' ' + passengerTitle}>
                <div className={styles.container}>
                    {apiError && <ErrorList errorsList={apiError} source='TravellerAddForm' />}
                    <form
                        id='travellerAddForm'
                        className={styles.form}
                        onSubmit={handleSubmit((travellerData: FormDataSubmitValues) => {
                            self.onSubmit({
                                travellerData,
                                setIsSubmitting,
                                travellerNumber: parseInt(passengerBeingEdited),
                                assignedTravellers,
                                orderItemId,
                                orderId,
                                isEditing,
                                isLeadPassenger,
                                dispatchOrderState,
                                setBirthDateError,
                                setPassportStartDateError,
                                setPassportEndDateError,
                                setEmergencyContactDetailsError,
                                setApiError,
                                clearAllErrors,
                                pastPassengerReferences,
                                navigate,
                                setCompletedStep,
                            } as FormSubmitProps)
                        })}
                    >
                        <fieldset
                            disabled={isSubmitting}
                            className={styles['inputs-group--vertical']}
                        >
                            <Heading heading='3'>{content.personalInformation}</Heading>
                            {pastPassengerReference && (
                                <LabelledInput
                                    htmlFor='pastPassengerReference'
                                    label={content.pastPassenger}
                                    disabled={true}
                                >
                                    <TextInput
                                        className={styles['short-input']}
                                        value={pastPassengerReference}
                                        disabled
                                    />
                                </LabelledInput>
                            )}
                            <Controller
                                control={control}
                                name='title'
                                rules={{ required: true }}
                                render={({ field: { value } }): React.ReactElement => (
                                    <LabelledInput
                                        htmlFor='title'
                                        label={content.title}
                                        required={true}
                                    >
                                        <Select
                                            placeholder={content.titlePlaceHolder}
                                            value={value}
                                            className={styles['short-input']}
                                            options={titleOptions}
                                            onChange={handleSelectTitle}
                                            isErrored={!!errors.title}
                                        />
                                    </LabelledInput>
                                )}
                            />
                            <Controller
                                control={control}
                                name='firstName'
                                rules={{
                                    required: true,
                                    minLength: 1,
                                    maxLength: 200,
                                    pattern: REGEX.NAME,
                                }}
                                render={({ field: { value, ref } }): React.ReactElement => (
                                    <div>
                                        <LabelledInput
                                            htmlFor='firstName'
                                            label={content.passengerFirstName}
                                            required={true}
                                            ref={ref}
                                            isErrored={!!errors.firstName}
                                        >
                                            <TextInput
                                                value={value}
                                                className={styles['short-input']}
                                                regExp={/^[',.\- a-zA-Z]*$/} // limit what can be typed to characters the final validation patten uses.
                                                onChangeCallback={(value): void => {
                                                    setValue('firstName', value)
                                                }}
                                            />
                                        </LabelledInput>
                                        <FieldError
                                            errorMessage={content.errors.name}
                                            showError={!!errors.firstName}
                                            inputId='firstName'
                                        />
                                    </div>
                                )}
                            />
                            <div>
                                <div className={styles['middle-name--inputs-group']}>
                                    <Controller
                                        control={control}
                                        name='middleName'
                                        rules={{
                                            required: !disableMiddleNameInput,
                                            minLength: 1,
                                            maxLength: 200,
                                            pattern: REGEX.NAME,
                                        }}
                                        render={({ field: { value, ref } }): React.ReactElement => (
                                            <div>
                                                <LabelledInput
                                                    htmlFor='middleName'
                                                    label={content.middleName}
                                                    disabled={
                                                        disableMiddleNameInput || isSubmitting
                                                    }
                                                    required={!disableMiddleNameInput}
                                                    isErrored={!!errors.middleName}
                                                    ref={ref}
                                                >
                                                    <TextInput
                                                        value={value}
                                                        className={styles['short-input']}
                                                        regExp={/^[',.\- a-zA-Z]*$/} // limit what can be typed to characters the final validation patten uses.
                                                        onChangeCallback={(value): void => {
                                                            setValue('middleName', value)
                                                        }}
                                                    />
                                                </LabelledInput>
                                            </div>
                                        )}
                                    />
                                    <div className={styles['middle-name--checkbox']}>
                                        <Controller
                                            control={control}
                                            name='noMiddleName'
                                            render={({ field: { value } }): React.ReactElement => (
                                                <Checkbox
                                                    text={content.noMiddleName}
                                                    value={content.noMiddleName}
                                                    checked={value}
                                                    onChange={handleCheckNoMiddleName}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <FieldError
                                    errorMessage={content.errors.name}
                                    showError={!!errors.middleName}
                                    inputId='middleName'
                                />
                            </div>
                            <Controller
                                control={control}
                                name='lastName'
                                rules={{
                                    required: true,
                                    minLength: 1,
                                    maxLength: 200,
                                    pattern: REGEX.NAME,
                                }}
                                render={({ field: { value, ref } }): React.ReactElement => (
                                    <div>
                                        <LabelledInput
                                            htmlFor='lastName'
                                            label={content.passengerLastName}
                                            required={true}
                                            ref={ref}
                                            isErrored={!!errors.lastName}
                                        >
                                            <TextInput
                                                value={value}
                                                className={styles['short-input']}
                                                regExp={/^[',.\- a-zA-Z]*$/} // limit what can be typed to characters the final validation patten uses.
                                                onChangeCallback={(value): void => {
                                                    setValue('lastName', value)
                                                }}
                                            />
                                        </LabelledInput>
                                        <FieldError
                                            errorMessage={content.errors.name}
                                            showError={!!errors.lastName}
                                            inputId='lastName'
                                        />
                                    </div>
                                )}
                            />
                            <DateTextInput
                                monthFieldName={'monthOfBirth'}
                                dayFieldName={'dayOfBirth'}
                                yearFieldName={'yearOfBirth'}
                                monthFieldLabel={content.dateOfBirth.month}
                                dayFieldLabel={content.dateOfBirth.day}
                                yearFieldLabel={content.dateOfBirth.year}
                                label={content.dateOfBirth.groupLabel}
                                errorMessage={
                                    errors?.birthDate?.message ?? content.errors.birthDate
                                }
                                disabled={isSubmitting}
                                control={control}
                                errors={errors}
                                generalError={birthDateError}
                                setValue={setValue}
                                validationCheck={validateDateOfBirth}
                                required
                            />
                        </fieldset>

                        <ColoredLine padding='non' />

                        <fieldset
                            disabled={isSubmitting}
                            className={styles['inputs-group--vertical']}
                        >
                            <Heading heading='3'>{content.passportInfo.groupLabel}</Heading>
                            <div className={styles['inputs-group--horizontal']}>
                                <Controller
                                    control={control}
                                    name='nationality'
                                    render={({ field: { value } }): React.ReactElement => (
                                        <LabelledInput
                                            htmlFor='nationality'
                                            label={content.passportInfo.nationality}
                                            isErrored={!!errors.nationality}
                                            className={styles['input-half']}
                                        >
                                            <Select
                                                placeholder={
                                                    content.passportInfo.nationalityPlaceholder
                                                }
                                                value={value}
                                                options={nationalityOptions}
                                                onChange={handleSelectNationality}
                                            />
                                        </LabelledInput>
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name='passportNumber'
                                    rules={{
                                        minLength: 8,
                                        maxLength: 9,
                                        pattern: REGEX.PASSPORT,
                                    }}
                                    render={({ field: { value, ref } }): React.ReactElement => (
                                        <div>
                                            <LabelledInput
                                                htmlFor='passportNumber'
                                                label={content.passportInfo.passportNumber}
                                                ref={ref}
                                                isErrored={!!errors.passportNumber}
                                                className={styles['input-half']}
                                            >
                                                <TextInput
                                                    regExp={REGEX.PASSPORT}
                                                    value={value}
                                                    onChangeCallback={(value): void => {
                                                        setValue('passportNumber', value)
                                                    }}
                                                />
                                            </LabelledInput>
                                            <FieldError
                                                errorMessage={content.errors.passportNumber}
                                                showError={!!errors.passportNumber}
                                                inputId='passportNumber'
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className={styles['inputs-group--horizontal']}>
                                <DateTextInput
                                    monthFieldName={'passportStartMonth'}
                                    dayFieldName={'passportStartDay'}
                                    yearFieldName={'passportStartYear'}
                                    monthFieldLabel={content.passportInfo.startDate.month}
                                    dayFieldLabel={content.passportInfo.startDate.day}
                                    yearFieldLabel={content.passportInfo.startDate.year}
                                    label={content.passportInfo.startDate.groupLabel}
                                    errorMessage={content.errors.passportStartDate}
                                    generalError={passportStartDateError}
                                    disabled={isSubmitting}
                                    control={control}
                                    errors={errors}
                                    setValue={setValue}
                                />
                                <DateTextInput
                                    monthFieldName={'passportEndMonth'}
                                    dayFieldName={'passportEndDay'}
                                    yearFieldName={'passportEndYear'}
                                    monthFieldLabel={content.passportInfo.expiryDate.month}
                                    dayFieldLabel={content.passportInfo.expiryDate.day}
                                    yearFieldLabel={content.passportInfo.expiryDate.year}
                                    label={content.passportInfo.expiryDate.groupLabel}
                                    errorMessage={content.errors.passportEndDate}
                                    generalError={passportEndDateError}
                                    disabled={isSubmitting}
                                    control={control}
                                    errors={errors}
                                    setValue={setValue}
                                />
                            </div>
                        </fieldset>

                        <ColoredLine padding='non' />

                        <fieldset
                            disabled={isSubmitting}
                            className={styles['inputs-group--vertical']}
                        >
                            <Heading heading='3'>{content.emergencyContact.groupLabel}</Heading>
                            <FieldError
                                errorMessage={content.emergencyContact.fillAllFieldsMessage}
                                inputId='allFields'
                                showError={emergencyContactDetailsError}
                            />
                            <div className={styles['inputs-group--horizontal']}>
                                <Controller
                                    control={control}
                                    name='emergencyContactFirstName'
                                    rules={{
                                        minLength: 1,
                                        maxLength: 200,
                                        pattern: REGEX.NAME,
                                    }}
                                    render={({ field: { value, ref } }): React.ReactElement => (
                                        <div>
                                            <LabelledInput
                                                htmlFor='emergencyContactFirstName'
                                                label={content.emergencyContact.firstName}
                                                ref={ref}
                                                isErrored={!!errors.emergencyContactFirstName}
                                                className={styles['input-half']}
                                                required={emergencyContactDetailsError}
                                            >
                                                <TextInput
                                                    value={value}
                                                    onChangeCallback={(value): void => {
                                                        setValue('emergencyContactFirstName', value)
                                                    }}
                                                />
                                            </LabelledInput>
                                            <FieldError
                                                errorMessage={content.errors.name}
                                                showError={!!errors.emergencyContactFirstName}
                                                inputId='emergencyContactFirstName'
                                            />
                                        </div>
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name='emergencyContactLastName'
                                    rules={{
                                        minLength: 1,
                                        maxLength: 200,
                                        pattern: REGEX.NAME,
                                    }}
                                    render={({ field: { value, ref } }): React.ReactElement => (
                                        <div>
                                            <LabelledInput
                                                htmlFor='emergencyContactLastName'
                                                label={content.emergencyContact.lastName}
                                                ref={ref}
                                                isErrored={!!errors.emergencyContactLastName}
                                                className={styles['input-half']}
                                                required={emergencyContactDetailsError}
                                            >
                                                <TextInput
                                                    value={value}
                                                    onChangeCallback={(value): void => {
                                                        setValue('emergencyContactLastName', value)
                                                    }}
                                                />
                                            </LabelledInput>
                                            <FieldError
                                                errorMessage={content.errors.name}
                                                showError={!!errors.emergencyContactLastName}
                                                inputId='emergencyContactLastName'
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className={styles['inputs-group--horizontal']}>
                                <PhoneInput
                                    countryCodeFieldName={'emergencyContactCountryCallingCode'}
                                    inputFieldName={'emergencyContactPhoneNumber'}
                                    generalError={
                                        !!errors.emergencyContactPhoneNumber ||
                                        !!errors.emergencyContactCountryCallingCode
                                    }
                                    disabled={isSubmitting}
                                    groupLabel={emergencyPhoneContent.groupLabel}
                                    countryCodeLabel={emergencyPhoneContent.countryCallingCode}
                                    inputLabel={emergencyPhoneContent.phoneNumberNoCountryCode}
                                    control={control}
                                    countryCodeOptions={countryCallingCodeOptions}
                                    onCountryCodeChange={handleOnChangeContactCntryCllngCd}
                                    onChange={setValue}
                                    required={emergencyContactDetailsError}
                                />
                                <Controller
                                    control={control}
                                    name='emergencyContactEmail'
                                    rules={{
                                        pattern: REGEX.EMAIL,
                                    }}
                                    render={({ field: { value, ref } }): React.ReactElement => (
                                        <div>
                                            <LabelledInput
                                                htmlFor='emergencyContactEmail'
                                                label={content.emergencyContact.email}
                                                ref={ref}
                                                isErrored={!!errors.emergencyContactEmail}
                                                className={styles['input-half']}
                                                required={emergencyContactDetailsError}
                                            >
                                                <TextInput
                                                    value={value}
                                                    inputMode='email' // for mobile keyboard selection
                                                    onChangeCallback={(value): void => {
                                                        setValue('emergencyContactEmail', value)
                                                    }}
                                                />
                                            </LabelledInput>
                                            <FieldError
                                                errorMessage={content.errors.email}
                                                showError={!!errors.emergencyContactEmail}
                                                inputId='emergencyContactEmail'
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </fieldset>
                    </form>
                </div>
            </Card>
            <div className={styles['buttons-wrapper']}>
                <Button
                    text={content.backButton}
                    flavour='tertiary'
                    onClick={backToOrder}
                    iconName='ChevronLeft'
                    type='button'
                />
                {isEditing && (
                    <Button
                        text={content.continueWithoutSavingButton}
                        flavour='tertiary'
                        onClick={(): void => setCompletedStep()}
                        type='button'
                    />
                )}
                <Button
                    form='travellerAddForm'
                    className={styles['submit-button']}
                    id='submit-button'
                    text={submitButton}
                    type='submit'
                    showSpinner={isSubmitting}
                />
            </div>
        </>
    )
}
export default TravellerAddForm
