import { datadogLogs } from '@datadog/browser-logs'

export type TrackUserData = {
    userId?: string
    eventName: string
    properties?: Record<string, any>
}

export type CustomerSuccessUserData = {
    userName: string
    userEmail: string
    userId: string
    productTiers: ProductTiersDataType
    companyName: string
}

/** NOTE: Due to network and console noise Vitally is guarded against running when build mode of app is not production or token is not present.
 * NODE_ENV is only 'test'/'development'/'production'
 * These are not the deployed environments, they are set during: npm run test, npm run start and npm run build
 *
 * To develop Vitally locally you will need to add the TEST token to env.development and comment out the guards below in shouldLoad method.
 * */

export class CustomerSuccess {
    private static vitally(): any {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return window?.Vitally
    }
    private static shouldLoad(): boolean {
        return !!process.env.REACT_APP_VITALLY_TOKEN && process.env.NODE_ENV === 'production'
    }

    static init(): void {
        if (this.shouldLoad()) {
            this.vitally()?.init(process.env.REACT_APP_VITALLY_TOKEN)
        }
    }

    static logAccount({ tenantInfo }: { tenantInfo: TenantData }): void {
        if (this.shouldLoad()) {
            this.vitally()?.account({
                accountId: tenantInfo.financeSystemId,
                traits: {
                    companyName: tenantInfo.companyName,
                    companyType: tenantInfo.companyType,
                    companyLocation: tenantInfo.companyLocation,
                    tenantId: tenantInfo.id,
                },
            })
        }
    }

    static logUser(userData: CustomerSuccessUserData): void {
        if (this.shouldLoad()) {
            this.vitally()?.user({
                userId: userData.userId,
                traits: {
                    name: userData.userName,
                    email: userData.userEmail,
                    productTiers: userData.productTiers,
                    companyName: userData.companyName,
                },
            })
        }
    }
    static survey(): void {
        if (this.shouldLoad()) {
            this.vitally()?.nps('survey', {
                productName: 'Agent Connect',
            })
        }
    }
    static track(trackUserData: TrackUserData): void {
        if (this.shouldLoad()) {
            const userContext = datadogLogs.getGlobalContext()
            const userIdVar = trackUserData.userId ?? userContext?.userId
            this.vitally()?.track({
                event: trackUserData.eventName,
                properties: trackUserData.properties ?? {},
                userId: userIdVar,
            })
        }
    }
}

export default CustomerSuccess
