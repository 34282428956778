import { CURRENCY_CODES } from '../utils/constants'
import { APICabinTypes, APIPriceItem } from './sailing-api-types'
import { capitalizeEachWord, insertDecimal2CharsFromEnd } from '../utils/string-helpers'
import allContent from '../content/content'
import { getFormattedDate } from '../utils/date-helpers'
import { formatSixOrFourDigitTimeStringToTwoDigitAmPmString } from '../utils/format-six-or-four-digit-time-string-to-two-digit-am-pm-string'

const content = allContent.cruise.sailingPage

export type ItineraryDay = {
    portName: string
    dayNumber: string
    itemDate: string
    arrivalTime?: string
    departureTime?: string
}

export type Rate = {
    index: number
    available: boolean
    rateCode: string
    totalFarePrice: string | number
    totalGrossPrice: string | number
    totalNonCommissionablePrice: string | number
    totalTaxesFeesPortExpensesPrice: string | number
}

export type RateCode = {
    code: string
    description: string
    label?: string
    refundPolicy?: RefundPolicyType
    military?: boolean
    residency?: boolean
}

export type CabinTypes = 'Inside' | 'Outside' | 'Balcony' | 'Suite'

export type CabinTypeGroup = {
    type: CabinTypes
    rateCodes: string[]
    cabinGrades: CabinGrade[]
}

export type CabinGrade = {
    available: boolean
    cabinGradeCode: string
    description: string
    name: string
    rates: Rate[]
}

function getRateCodesWithPrices(priceItems: Record<string, any>[]): Set<string> {
    const rateCodesWithPrices: Set<string> = new Set()
    // Iterate over all priceItems and work out which rates have no prices
    priceItems.forEach((priceItem) => {
        if (priceItem.cabinGrade.available) rateCodesWithPrices.add(priceItem.rateCode)
    })

    return rateCodesWithPrices
}
function getFormattedItineraryItems(itineraryItems: ItineraryDay[]): ItineraryDay[] {
    return itineraryItems.map((item): ItineraryDay => {
        return {
            portName: capitalizeEachWord(item.portName),
            dayNumber: item.dayNumber,
            itemDate: getFormattedDate(item.itemDate),
            ...(item.arrivalTime && {
                arrivalTime: formatSixOrFourDigitTimeStringToTwoDigitAmPmString(item.arrivalTime),
            }),
            ...(item.departureTime && {
                departureTime: formatSixOrFourDigitTimeStringToTwoDigitAmPmString(
                    item.departureTime
                ),
            }),
        }
    })
}

export class SailingContent {
    /** description of a cruise */
    readonly cruiseDescription: string
    /** name of a cruise */
    readonly cruiseName: string
    /** currency type */
    readonly currency: string
    /** date of disembarkation - format dd/MM/yyyy */
    readonly disembarkDate: string
    /** date of embarkation  */
    readonly embarkDate: string
    /** the duration of a sailing represented by the string 'X Nights' */
    readonly duration: string
    /** the name of the cruise ship */
    readonly shipName: string
    /** the name of the cruise line */
    readonly supplierName: string
    /** the port of embarkation */
    readonly embarkPort: string
    /** the port of disembarkation */
    readonly disembarkPort: string
    /** the number of days at sea counted from number of instances of 'At Sea' found in the itinerary */
    readonly seaDays: string
    /** the itinerary list **/
    readonly itinerary: ItineraryDay[]
    /** The strings 'Yes' or 'No' depending on whether or not the embarkPort and disembarkPort are the same */
    readonly roundTrip: string
    /** all rate codes and their descriptions found in the data passed into the constructor */
    readonly allRateCodes: RateCode[]
    /** all cabin types (from INSIDE, OUTSIDE, BALCONY, SUITE) found in the data passed into the constructor */
    readonly allAvailableCabinTypes: CabinTypeGroup[]
    /** all priceItems organised by cabin grade */
    readonly cabinsByType: CabinTypeGroup[]
    /** id for the sailing */
    readonly cruiseId: string
    readonly supplierCode: string
    constructor(sailingData: Record<string, any>) {
        this.cruiseDescription = sailingData.product?.description
        this.cruiseName = sailingData.product?.name
        this.cruiseId = sailingData.id
        this.currency = CURRENCY_CODES[sailingData.priceItems[0]?.currency] ?? ''
        this.disembarkDate = getFormattedDate(sailingData.disembarkDate)
        this.embarkDate = getFormattedDate(sailingData.embarkDate)
        this.duration = `${sailingData.duration} ${content.nights}`
        this.embarkPort = sailingData.embarkPort
        this.disembarkPort = sailingData.disembarkPort
        this.shipName = sailingData.ship.name
        this.supplierName = sailingData.ship.line.name
        this.supplierCode = sailingData.ship.line.code
        this.seaDays = this.getSeaDays(sailingData.itineraryItems)
        this.itinerary = getFormattedItineraryItems(sailingData.itineraryItems)
        this.roundTrip =
            sailingData.embarkPort === sailingData.disembarkPort ? content.yes : content.no
        this.allRateCodes = sailingData.rateCodes.map((rate: any) => {
            return {
                ...rate,
                military: rate.military,
                residency: rate.residency,
                code: rate.code,
                refundPolicy: rate.refundPolicy,
                label: rate.code,
                description: rate.description,
            }
        })
        this.allAvailableCabinTypes = this.getAvailableCabinTypes(sailingData.priceItems)
        this.cabinsByType = this.getCabinGradeRatesGroupByCabinType(sailingData.priceItems)
    }

    /** calculates the number of days at sea by counting the number of instances of portName: 'At Sea' in the itinerary */
    getSeaDays(itineraryItems: Record<string, string>[]): string {
        return itineraryItems
            .filter((item) => item.portName.toUpperCase() === 'AT SEA')
            .length.toString()
    }

    /** returns all distinct cabin grade types as an array */
    getAvailableCabinTypes(priceItems: Record<string, any>[]): CabinTypeGroup[] {
        return [...new Set(priceItems.map((item) => item.cabinGrade.cabinType))]
    }

    /** using priceItems passed it this returns a unique array of all the rate codes as objects with (name, description)
     * @param { array } priceItems - array of all price items
     * @returns { array } rate codes array, each containing the code and description of a single rate code.
     *  */

    /** getCabinGrades takes all the prices items of a single Cabin SubType - A SINGLE PRICE GRID TABLE!!
     *  returns an array where each item is a single cabin grade, each containing an array of rates for that cabin grade
     * @param { array } singleCabinTypePriceItems - array of all price items relating to just a single cabin sub type, that need grouping into each unique cabin grade
     * @returns { array } - of CabinGrade objects, all the data for a single cabin grade. i.e a single row on the pricing table.
     *  */
    structureCabinGradesForTable(singleCabinTypePriceItems: Record<string, any>[]): CabinGrade[] {
        // 1) Create unique list of cabin grade codes from the priceItems passed in - collection of just one cabin type.
        const allCabinGradeCodes = [
            ...new Set(singleCabinTypePriceItems.map((priceItem) => priceItem.cabinGrade.code)),
        ]

        // 2) Iterate over each Grade Code and create a CabinGrade object - each containing the data/rates for a single cabin grade code - one row of the sailing details table
        return allCabinGradeCodes.map((gradeCode: string) => {
            // 3) Collect just priceItems for current iteration's Cabin Grade Code.
            const priceItemsForCurrentCode = singleCabinTypePriceItems.filter(
                (item) => item.cabinGrade.code === gradeCode
            )

            // 4) Use Set(JSON.stringify) -> Array.from(JSON.parse) to remove duplicates
            const priceItems = Array.from(
                new Set(priceItemsForCurrentCode.map((item) => JSON.stringify(item)))
            ).map((rate) => JSON.parse(rate))

            // 5) Shape for ease of consumption
            let atLeastOneRateAvailable = false
            const rates = priceItems.map((priceItem) => {
                // keep setting atLeastOneRateAvailable = priceItem availability until it turns true
                if (!atLeastOneRateAvailable)
                    atLeastOneRateAvailable = priceItem.cabinGrade.available
                // NOTE: this does not mean all the rates for every grade will exist, so available = false is only half the story, it might not exist either.
                return {
                    index: priceItem.index,
                    available: priceItem.cabinGrade.available,
                    rateCode: priceItem.rateCode,
                    totalFarePrice: insertDecimal2CharsFromEnd(priceItem.totalFarePrice),
                    totalGrossPrice: insertDecimal2CharsFromEnd(priceItem.totalGrossPrice),
                    totalNonCommissionablePrice: insertDecimal2CharsFromEnd(
                        priceItem.totalNcfPrice
                    ),
                    totalTaxesFeesPortExpensesPrice: insertDecimal2CharsFromEnd(
                        priceItem.totalTfpePrice
                    ),
                }
            })
            return {
                available: atLeastOneRateAvailable, // This allows us to hide the entire grade row on the table, if wanted.
                cabinGradeCode: gradeCode,
                name: priceItems[0].cabinGrade.name, // all price items of same grade have same name value
                description: priceItems[0].cabinGrade.description, // all price items of same grade have same name value
                rates: rates,
            }
        })
    }

    /** returns an array of cabin type groups - each containing an array of cabinGrades who share a common type of cabin */
    getCabinGradeRatesGroupByCabinType(priceItems: any[]): CabinTypeGroup[] {
        const ArrayOfCabinTypes: APICabinTypes[] = ['INSIDE', 'OUTSIDE', 'BALCONY', 'SUITE'] // assume only these?
        const cabinTypesGroups: Record<APICabinTypes, { type: CabinTypes; cabinGrades: any[] }> = {
            INSIDE: { type: 'Inside', cabinGrades: [] },
            OUTSIDE: { type: 'Outside', cabinGrades: [] },
            BALCONY: { type: 'Balcony', cabinGrades: [] },
            SUITE: { type: 'Suite', cabinGrades: [] },
        }

        // Sort and assign an index for table view
        const sortedPriceItems = [...priceItems]
            .sort((a, b) => Number(a?.totalFarePrice) - Number(b?.totalFarePrice))
            .map((el, index) => ({ ...el, index }))

        // Iterate over all priceItems and push them into correct Cabin SubType array
        sortedPriceItems.forEach((priceItem: APIPriceItem): void => {
            cabinTypesGroups[priceItem.cabinGrade.cabinType].cabinGrades.push(priceItem)
        })
        // Only keep prices items per cabin type if at least one rate has price
        ArrayOfCabinTypes.forEach((cabinType) => {
            // Find all rates with code on cabinType
            const ratesWithPrices = getRateCodesWithPrices(cabinTypesGroups[cabinType].cabinGrades)
            // Filter out price items whose rate has no prices
            cabinTypesGroups[cabinType].cabinGrades = cabinTypesGroups[
                cabinType
            ].cabinGrades.filter((priceItem) => ratesWithPrices.has(priceItem.rateCode))
        })

        // Only return CabinGradeTypes with actual grades, and structure the cabinGrades data nicely
        const cabinTypesWithPricing: CabinTypeGroup[] = []
        ArrayOfCabinTypes.forEach((cabinGrade): void => {
            if (cabinTypesGroups[cabinGrade].cabinGrades.length > 0) {
                const gradeGroup = cabinTypesGroups[cabinGrade]

                // Find all rate codes for this cabin type
                const allRateCodesForThisCabinType = [
                    ...new Set(gradeGroup.cabinGrades.map((priceItem) => priceItem.rateCode)),
                ]
                cabinTypesWithPricing.push({
                    type: gradeGroup.type,
                    rateCodes: allRateCodesForThisCabinType,
                    cabinGrades: this.structureCabinGradesForTable(gradeGroup.cabinGrades),
                })
            }
        })
        return cabinTypesWithPricing
    }
}
