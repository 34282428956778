import { CURRENCY_CODES } from '../utils/constants'

export type Hotel = {
    currencySymbol: keyof typeof CURRENCY_CODES
    id: string
    name: string
    address: string
    averagePricePerNight: string
    rating: number
    numberOfNights: number
    priceBreakdown:
        | {
              priceDetail: {
                  currency: string
                  net: number
                  gross: number
              }
          }[]
        | []
}

function getHotel(hotelData: Record<string, any>): Hotel {
    return {
        name: hotelData?.name ?? '',
        address: hotelData?.address ?? '',
        averagePricePerNight: hotelData?.averagePricePerNight ?? '',
        id: hotelData?.id ?? '',
        rating: hotelData.rating,
        numberOfNights: hotelData.numberOfNights,
        priceBreakdown: hotelData.priceBreakdown,
        currencySymbol: CURRENCY_CODES[hotelData.priceBreakdown[0].priceDetail.currency] ?? '',
    }
}

export class HotelSearchResult {
    readonly hotels: Hotel[]
    readonly currencySymbol: keyof typeof CURRENCY_CODES

    constructor(hotelOptions: Record<string, any>[]) {
        this.hotels = hotelOptions.map((hotel) => getHotel(hotel)) || []
        this.currencySymbol =
            CURRENCY_CODES[hotelOptions[0].priceBreakdown[0].priceDetail.currency] ?? ''
    }
}
