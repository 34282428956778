import React, { SetStateAction, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import LoginForm from 'components/sections/cognito/LoginForm/LoginForm'
import Spacing from 'components/basics/Spacing/Spacing'
import { ROUTES } from 'components/sections/app/AppRoutes'
import domainAuthInfo from 'data/domainAuthInfo'

import allContent from 'content/content'

const cognitoContent = allContent.cognito

type LoginLayoutProps = {
    setIsAuthorised: React.Dispatch<SetStateAction<boolean | null>>
    setUserCognitoData: React.Dispatch<SetStateAction<Record<string, any> | undefined>>
    isHostedUxDomain: boolean | null
    isAuthorised: boolean | null
    showExpiredBanner: boolean
    setShowExpiredBanner: React.Dispatch<SetStateAction<boolean>>
    setTenantId: React.Dispatch<SetStateAction<string>>
}

const LoginLayout: React.FC<LoginLayoutProps> = ({
    isHostedUxDomain,
    isAuthorised,
    setIsAuthorised,
    setUserCognitoData,
    showExpiredBanner,
    setShowExpiredBanner,
    setTenantId,
}) => {
    const navigate = useNavigate()
    const navigateTo = useRef<string | null>(null)

    /** navigate() should be called in useEffect so first render is completed before the component is attempted to be unmounted - https://www.dhiwise.com/post/why-you-should-call-navigate-in-areact-useeffect */
    useEffect(() => {
        if (navigateTo.current) {
            navigate(navigateTo.current)
            navigateTo.current = null
        }
    }, [navigate])

    if (!(window.location.origin in domainAuthInfo)) {
        /** Needed because the root of the app always send user to login page even if app tries to send user to 404 page. All other paths can send to 404 page fine. */
        navigateTo.current = ROUTES.FOUR04
    }

    /** If isHostedUxDomain true, or null as hasn't been set yet, show spinner */
    if (isHostedUxDomain !== false) {
        if (isAuthorised === null || isAuthorised) {
            /** Show spinner when first sending to cognito hosted ux, and when user comes back while we use the url param code to verify them */
            return (
                <div className='general-container'>
                    <LargeSpinner text={cognitoContent.checkingAuth} />
                </div>
            )
        } else {
            /** If the user comes back from cognito but somehow isn't authorised (false = not auth, null is yet to be logged in) then show just 'unavailable' text.
             * ...but the app should bounce them back to the cognito hosted login for that URL, so might never be seen? */
            return <div className='general-container'>{cognitoContent.unavailable}</div>
        }
    }

    return (
        <div className='general-container'>
            {showExpiredBanner && (
                <>
                    <InfoBanner
                        bannerType='info'
                        text={cognitoContent.expiredBanner}
                        id='expiry-banner'
                        logType='info'
                        source='login-page'
                    />
                    <Spacing />
                </>
            )}
            <LoginForm
                setTenantId={setTenantId}
                setIsAuthorised={setIsAuthorised}
                setUserCognitoData={setUserCognitoData}
                setShowExpiredBanner={setShowExpiredBanner}
            />
        </div>
    )
}
export default LoginLayout
