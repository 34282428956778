import React from 'react'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { Navigate } from 'react-router-dom'

import { ROUTES } from 'components/sections/app/AppRoutes'
import SaaSSignUpForm from 'components/sections/app/SaaSSignupForm/SaaSSignUpForm'

type SaaSSignUpLayoutProps = {
    isAuthorised: boolean | null
}
const managerUrl = process.env.REACT_APP_CONNECT_MANAGER_SERVICE_URL + '/manager'

const MANAGER_API_CLIENT = new ApolloClient({
    /** HttpLink is used by default, but manually setting allows us to specify a fetch API */
    link: new HttpLink({ uri: managerUrl, fetch }),
    /** caches user queries in-memory to reduce redundant fetches */
    cache: new InMemoryCache(),
})

const SaaSSignUpLayout: React.FC<SaaSSignUpLayoutProps> = ({ isAuthorised }) => (
    <div className='general-container'>
        {isAuthorised ? (
            <Navigate to={ROUTES.LOGIN} />
        ) : (
            <SaaSSignUpForm isAuthorised={isAuthorised} signUpClient={MANAGER_API_CLIENT} />
        )}
    </div>
)

export default SaaSSignUpLayout
