import React, { SetStateAction, useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import AdminLayout from 'components/layouts/admin/AdminLayout/AdminLayout'
import GroupManagementLayout from 'components/layouts/admin/GroupManagementLayout/GroupManagementLayout'
import UserManagementLayout from 'components/layouts/admin/UserManagementLayout/UserManagementLayout'
import SalesChannelManagementLayout from 'components/layouts/admin/SalesChannelManagementLayout/SalesChannelManagementLayout'
import EditSalesChannelLayout from 'components/layouts/admin/EditSalesChannelLayout/EditSalesChannelLayout'
import ReportingLayout from 'components/layouts/admin/ReportingLayout/ReportingLayout'
import CompanyConfigurationLayout from 'components/layouts/admin/CompanyConfigurationLayout/CompanyConfigurationLayout'
import SaaSSignUpLayout from 'components/layouts/app/SaaSSignUpLayout/SaaSSignUpLayout'
import LoginLayout from 'components/layouts/cognito/LoginLayout/LoginLayout'
import NewPasswordLayout from 'components/layouts/cognito/NewPasswordLayout/NewPasswordLayout'
import ForgotPasswordLayout from 'components/layouts/cognito/ForgotPasswordLayout/ForgotPasswordLayout'
import ResetPasswordLayout from 'components/layouts/cognito/ResetPasswordLayout/ResetPasswordLayout'
import LandingPage from 'components/pages/app/LandingPage'
import CruiseSearchPage from 'components/pages/cruise/SearchPage'
import CruiseResultsPage from 'components/pages/cruise/ResultsPage'
import CruiseSailingPage from 'components/pages/cruise/SailingPage'
import CruiseCabinPage from 'components/pages/cruise/CabinPage'
import FlightSearchPage from 'components/pages/flight/SearchPage'
import FlightDuffelSearchPage from 'components/pages/flight/DuffelSearchPage'
import DuffelGetFlightPage from 'components/pages/flight/DuffelGetFlightPage'
import HotelSearchPage from 'components/pages/hotel/SearchPage'
import HotelResultsPage from 'components/pages/hotel/ResultsPage'
import RoomDetailsPage from 'components/pages/hotel/RoomDetailsPage'
import OrderPage from 'components/pages/order/OrderPage'
import OrderConfirmationPage from 'components/pages/order/OrderConfirmationPage'
import OrderPreviewPage from 'components/pages/order/OrderPreviewPage/OrderPreviewPage'
import OrderImportPage from 'components/pages/order/OrderImportPage/OrderImportPage'
import AllOrdersPage from 'components/pages/order/OrdersPage/OrdersPage'
import Four04Layout from 'components/layouts/app/Four04Layout/Four04Layout'
import FeatureBaseEmbedPage from 'components/pages/app/FeaturebaseEmbedPage'
import TimeoutLayout from 'components/layouts/app/TimeoutLayout/TimeoutLayout'
import ProtectedRouteWrapper from './ProtectedRouteWrapper/ProtectedRouteWrapper'
import CrashErrorLayout from 'components/layouts/app/CrashErrorLayout/CrashErrorLayout'
import { DEMO_PRODUCT_TIERS, PRODUCT_NAMES, USER_ROLES } from 'utils/constants'
import packageJson from '../../../../package.json'
import { FeatureToggleContext } from 'App'
import { CruisesMetaData } from '../../../api-data-models/CruisesContentModel'

export const ROUTES = {
    ROOT: '/',
    ADMIN_DASHBOARD: '/admin',
    USER_MANAGEMENT: '/admin/user-management',
    GROUP_MANAGEMENT: '/admin/group-management',
    SALES_CHANNEL_MANAGEMENT: '/admin/sales-channel-management',
    EDIT_SALES_CHANNEL_MANAGEMENT: '/admin/edit-sales-channel',
    COMPANY_CONFIGURATION: '/admin/company-configuration',
    MI_REPORTING: '/admin/mi-reporting',
    CRUISE_SEARCH: '/cruise/search',
    CRUISE_RESULTS: '/cruise/results',
    CRUISE_SAILING: '/cruise/sailing',
    CRUISE_CABIN: '/cruise/cabin',
    FLIGHT_SEARCH: '/flight/search',
    HOTEL_SEARCH: '/hotel/search',
    HOTEL_RESULTS: '/hotel/results',
    HOTEL_ROOM_DETAILS: '/hotel/room-details',
    FLIGHT_DUFFEL_SEARCH: '/flight/duffel-search',
    FLIGHT_DUFFEL_GET_OFFER: '/flight/duffel-get-offer',
    ORDER: '/order',
    ORDER_CONFIRMATION: '/order-confirmation',
    ORDER_IMPORT_PREVIEW: '/order-import-preview',
    ORDER_IMPORT: '/order-import',
    ALL_ORDERS: '/orders',
    LOGIN: '/login',
    SIGNUP: '/sign-up',
    FEATURE_PORTAL: '/featureportal',
    NEW_PASSWORD: '/new-password',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    FOUR04: '/404-page-not-found',
    TIMEOUT: '/session-timed-out',
    VERSION: '/version',
}

type AppRoutesProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    cleanUpAppOnLogout(): void
    errorFetchingUserData: boolean
    hasUserCognitoData: boolean
    isAuthorised: boolean | null
    isFetchingConnectUserData: boolean
    isHostedUxDomain: boolean | null
    managerApiClient: ApolloClient<NormalizedCacheObject>
    productTiers: Record<ProductNameType, ProductTierType>
    setIsAuthorised: React.Dispatch<SetStateAction<boolean | null>>
    setShowExpiredBanner: React.Dispatch<SetStateAction<boolean>>
    setTenantId: React.Dispatch<SetStateAction<string>>
    setUserCognitoData: React.Dispatch<SetStateAction<Record<string, any> | undefined>>
    showExpiredBanner: boolean
    tenantFinanceId?: string
    companyName?: string
    userRoles: UserRole[]
    isDemoUrl: boolean
    cruisesMetaData: CruisesMetaData
}

const AppRoutes: React.FC<AppRoutesProps> = ({
    apiClient,
    cleanUpAppOnLogout,
    errorFetchingUserData,
    hasUserCognitoData,
    isAuthorised,
    isFetchingConnectUserData,
    isHostedUxDomain,
    managerApiClient,
    productTiers,
    setIsAuthorised,
    setShowExpiredBanner,
    setTenantId,
    setUserCognitoData,
    showExpiredBanner,
    tenantFinanceId,
    companyName,
    userRoles,
    isDemoUrl,
    cruisesMetaData,
}) => {
    const isAdminUser = userRoles.includes(USER_ROLES.ADMIN)
    const featureToggles = useContext(FeatureToggleContext)
    const turnOnCompanyConfiguration = featureToggles.TURN_ON_COMPANY_CONFIG

    return (
        <Routes>
            <Route
                path={ROUTES.LOGIN}
                element={
                    !isAuthorised || !hasUserCognitoData ? (
                        <LoginLayout
                            isHostedUxDomain={isHostedUxDomain}
                            isAuthorised={isAuthorised}
                            setIsAuthorised={setIsAuthorised}
                            setUserCognitoData={setUserCognitoData}
                            setTenantId={setTenantId}
                            showExpiredBanner={showExpiredBanner}
                            setShowExpiredBanner={setShowExpiredBanner}
                        />
                    ) : (
                        <Navigate to={ROUTES.ROOT} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.FEATURE_PORTAL}
                element={<FeatureBaseEmbedPage />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.SIGNUP}
                element={<SaaSSignUpLayout isAuthorised={isAuthorised} />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.NEW_PASSWORD}
                element={<NewPasswordLayout setIsAuthorised={setIsAuthorised} />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.FORGOT_PASSWORD}
                element={<ForgotPasswordLayout />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.RESET_PASSWORD}
                element={<ResetPasswordLayout setIsAuthorised={setIsAuthorised} />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.TIMEOUT}
                element={<TimeoutLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.ROOT}
                element={
                    isAuthorised && hasUserCognitoData ? (
                        /** Only allow Authorised users with JWT's on to Landing page - is used for spinner while fetching product tiers and sending user when no paid products */
                        <LandingPage
                            errorFetchingUserData={errorFetchingUserData}
                            isFetchingConnectUserData={isFetchingConnectUserData}
                            productTiers={productTiers}
                            isAdminUser={isAdminUser}
                            isDemoUrl={isDemoUrl}
                            tenantFinanceId={tenantFinanceId}
                            companyName={companyName}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.ADMIN_DASHBOARD}
                element={
                    isAuthorised &&
                    hasUserCognitoData &&
                    featureToggles.TURN_ON_ADMIN_PAGES &&
                    isAdminUser ? (
                        <AdminLayout
                            apiClient={managerApiClient}
                            productTiers={productTiers}
                            tenantFinanceId={tenantFinanceId}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.USER_MANAGEMENT}
                element={
                    isAuthorised &&
                    hasUserCognitoData &&
                    featureToggles.TURN_ON_ADMIN_PAGES &&
                    isAdminUser ? (
                        <UserManagementLayout
                            apiClient={managerApiClient}
                            productTiers={productTiers}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.GROUP_MANAGEMENT}
                element={
                    isAuthorised &&
                    hasUserCognitoData &&
                    featureToggles.TURN_ON_ADMIN_PAGES &&
                    isAdminUser ? (
                        <GroupManagementLayout
                            apiClient={managerApiClient}
                            productTiers={productTiers}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.SALES_CHANNEL_MANAGEMENT}
                element={
                    isAuthorised &&
                    hasUserCognitoData &&
                    featureToggles.TURN_ON_ADMIN_PAGES &&
                    isAdminUser ? (
                        <SalesChannelManagementLayout
                            apiClient={managerApiClient}
                            productTiers={productTiers}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.EDIT_SALES_CHANNEL_MANAGEMENT} // Route without params exists because the page will handle this scenario and tell the user the issue
                element={
                    isAuthorised &&
                    hasUserCognitoData &&
                    featureToggles.TURN_ON_ADMIN_PAGES &&
                    isAdminUser ? (
                        <EditSalesChannelLayout
                            apiClient={managerApiClient}
                            productTiers={productTiers}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.EDIT_SALES_CHANNEL_MANAGEMENT}/:salesChannelId`}
                element={
                    isAuthorised &&
                    hasUserCognitoData &&
                    featureToggles.TURN_ON_ADMIN_PAGES &&
                    isAdminUser ? (
                        <EditSalesChannelLayout
                            apiClient={managerApiClient}
                            productTiers={productTiers}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.COMPANY_CONFIGURATION}`}
                element={
                    isAuthorised &&
                    hasUserCognitoData &&
                    featureToggles.TURN_ON_ADMIN_PAGES &&
                    isAdminUser &&
                    turnOnCompanyConfiguration ? (
                        <CompanyConfigurationLayout
                            apiClient={managerApiClient}
                            productTiers={productTiers}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.MI_REPORTING}`}
                element={
                    isAuthorised &&
                    hasUserCognitoData &&
                    featureToggles.TURN_ON_ADMIN_PAGES &&
                    isAdminUser ? (
                        <ReportingLayout productTiers={productTiers} />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.CRUISE_SEARCH}
                element={
                    <ProtectedRouteWrapper
                        isDemoUrl={isDemoUrl}
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTCRUISE}
                    >
                        <CruiseSearchPage cruisesMetaData={cruisesMetaData} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.CRUISE_RESULTS} // Route without params exists because the page will handle this scenario and tell the user the issue
                element={
                    <ProtectedRouteWrapper
                        isDemoUrl={isDemoUrl}
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTCRUISE}
                    >
                        <CruiseResultsPage cruisesMetaData={cruisesMetaData} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.CRUISE_RESULTS}/:params`}
                element={
                    <ProtectedRouteWrapper
                        isDemoUrl={isDemoUrl}
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTCRUISE}
                    >
                        <CruiseResultsPage cruisesMetaData={cruisesMetaData} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.CRUISE_SAILING} // Route without params exists because the page will handle this scenario and tell the user the issue
                element={
                    <ProtectedRouteWrapper
                        isDemoUrl={isDemoUrl}
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTCRUISE}
                    >
                        <CruiseSailingPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.CRUISE_SAILING}/:params`}
                element={
                    <ProtectedRouteWrapper
                        isDemoUrl={isDemoUrl}
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTCRUISE}
                    >
                        <CruiseSailingPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.CRUISE_CABIN} // Route without params exists because the page will handle this scenario and tell the user the issue
                element={
                    <ProtectedRouteWrapper
                        isDemoUrl={isDemoUrl}
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTCRUISE}
                    >
                        <CruiseCabinPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.CRUISE_CABIN}/:params`}
                element={
                    <ProtectedRouteWrapper
                        isDemoUrl={isDemoUrl}
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTCRUISE}
                    >
                        <CruiseCabinPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.FLIGHT_SEARCH}
                element={
                    <ProtectedRouteWrapper
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTFLIGHT}
                    >
                        <FlightSearchPage />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.FLIGHT_DUFFEL_SEARCH}
                element={
                    <ProtectedRouteWrapper
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTFLIGHT}
                    >
                        <FlightDuffelSearchPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.FLIGHT_DUFFEL_GET_OFFER}
                element={
                    <ProtectedRouteWrapper
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTFLIGHT}
                    >
                        <DuffelGetFlightPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.FLIGHT_DUFFEL_GET_OFFER}/:params`}
                element={
                    <ProtectedRouteWrapper
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTFLIGHT}
                    >
                        <DuffelGetFlightPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.HOTEL_SEARCH}
                element={
                    <ProtectedRouteWrapper
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTHOTEL}
                    >
                        <HotelSearchPage />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.HOTEL_RESULTS} // Route without params exists because the page will handle this scenario and tell the user the issue
                element={
                    <ProtectedRouteWrapper
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTHOTEL}
                    >
                        <HotelResultsPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.HOTEL_RESULTS}/:params`}
                element={
                    <ProtectedRouteWrapper
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTHOTEL}
                    >
                        <HotelResultsPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.HOTEL_ROOM_DETAILS} // Route without params exists because the page will handle this scenario and tell the user the issue
                element={
                    <ProtectedRouteWrapper
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTHOTEL}
                    >
                        <RoomDetailsPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.HOTEL_ROOM_DETAILS}/:params`}
                element={
                    <ProtectedRouteWrapper
                        tenantFinanceId={tenantFinanceId}
                        productTiers={productTiers}
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTHOTEL}
                    >
                        <RoomDetailsPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            {/** Route without params exists because the page will handle this scenario and tell the user the issue */}
            <Route
                path={ROUTES.ORDER}
                element={
                    <ProtectedRouteWrapper
                        isDemoUrl={isDemoUrl}
                        tenantFinanceId={tenantFinanceId}
                        productTiers={DEMO_PRODUCT_TIERS} // USE DEMO HERE BECAUSE ORDER PAGE DOESN'T REQUIRE PAID PRODUCT TO WORK
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTCRUISE} // This isn't the only valid product, but it works as we only need one product paid to allow order page to work
                    >
                        <OrderPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.ORDER}/:params`}
                element={
                    <ProtectedRouteWrapper
                        isDemoUrl={isDemoUrl}
                        tenantFinanceId={tenantFinanceId}
                        productTiers={DEMO_PRODUCT_TIERS} // USE DEMO HERE BECAUSE ORDER PAGE DOESN'T REQUIRE PAID PRODUCT TO WORK
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTCRUISE} // This isn't the only valid product, but it works as we only need one product paid to allow order page to work
                    >
                        <OrderPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />

            <Route
                path={`${ROUTES.ORDER_IMPORT_PREVIEW}/:params`}
                element={
                    <ProtectedRouteWrapper
                        tenantFinanceId={tenantFinanceId}
                        productTiers={DEMO_PRODUCT_TIERS} // USE DEMO HERE BECAUSE ORDER PAGE DOESN'T REQUIRE PAID PRODUCT TO WORK
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTCRUISE} // This isn't the only valid product, but it works as we only need one product paid to allow order page to work
                    >
                        <OrderPreviewPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.ORDER_IMPORT}/:params`}
                element={<OrderImportPage apiClient={apiClient} />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.ALL_ORDERS}
                element={
                    <ProtectedRouteWrapper
                        tenantFinanceId={tenantFinanceId}
                        productTiers={DEMO_PRODUCT_TIERS} // USE DEMO HERE BECAUSE ORDER PAGE DOESN'T REQUIRE PAID PRODUCT TO WORK
                        isFetchingProductTiers={isFetchingConnectUserData}
                        productName={PRODUCT_NAMES.AGENTCONNECTCRUISE} // This isn't the only valid product, but it works as we only need one product paid to allow order page to work
                    >
                        <AllOrdersPage />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            {/** Route without params exists because the page will handle this scenario and tell the user the issue */}
            <Route
                path={ROUTES.ORDER_CONFIRMATION}
                element={<OrderConfirmationPage />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.ORDER_CONFIRMATION}/:params`}
                element={<OrderConfirmationPage />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.VERSION}
                element={
                    <div className='general-container'>
                        <p>{`version:${packageJson.version}`}</p>
                    </div>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            {/** FOUR04 is for invalid subdomains - domains where the app doesn't know how to log the user on. E.g. http://bad-domain.agentconnect.traveltek.net */}
            <Route
                path={ROUTES.FOUR04}
                element={<Four04Layout />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            {/** Wild card to catch all routes that don't exist and divert to root */}
            <Route path={'*'} element={<Navigate to={ROUTES.LOGIN} replace />} />
        </Routes>
    )
}

export default AppRoutes
