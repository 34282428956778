import { Auth } from '@aws-amplify/auth'

import { AmplifyUserData } from './check-and-perform-user-session-refresh-if-needed'
import { updateCognitoAuthData } from './update-cognito-auth-data'

export async function refreshUserSession(userData: AmplifyUserData): Promise<void> {
    try {
        const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
        const currentSession = await Auth.currentSession()
        currentAuthenticatedUser.refreshSession(
            currentSession.getRefreshToken(),
            (error: Error, session: any) => {
                const { refreshToken } = session
                if (refreshToken.token) {
                    updateCognitoAuthData({ cognitoSession: session, userData })
                }
                if (error) {
                    throw error
                }
            }
        )
    } catch (error: unknown) {
        // return an error if anything went wrong during session refresh, this will get caught later
        return Promise.reject(new Error('Refresh user session has failed'))
    }
}
