export const extractCognitoFields = (
    userCognitoData: Record<string, any> | undefined
): {
    userName: string
    email: string
    userId: string
    tenantId: string
    firstName: string
    lastName: string
} => {
    // When user is logging on via hosted UX (demo user pool) we do not get a tenantId in the payload
    let tenantId = ''
    let email = ''
    let userId = ''
    let userName = ''
    let firstName = ''
    let lastName = ''

    if (userCognitoData?.user?.attributes?.['custom:tenantid']) {
        /** Data structure when getting Auth.current user (every time once confirmed user logged on) */
        tenantId = userCognitoData.user?.attributes?.['custom:tenantid']
        email = userCognitoData.user?.attributes?.email
        userName =
            userCognitoData.user?.attributes?.given_name +
            ' ' +
            userCognitoData.user?.attributes?.family_name
        userId = userCognitoData.user?.username
        firstName = userCognitoData.user?.attributes?.given_name
        lastName = userCognitoData.user?.attributes?.family_name
    } else if (userCognitoData?.signInUserSession?.idToken?.payload?.['custom:tenantid']) {
        /** Data structure as response for logging in (every time just after logging on) */
        tenantId = userCognitoData.signInUserSession?.idToken?.payload?.['custom:tenantid']
        email = userCognitoData.signInUserSession?.idToken?.payload?.email
        userName =
            userCognitoData.signInUserSession?.idToken?.payload?.given_name +
            ' ' +
            userCognitoData.signInUserSession?.idToken?.payload?.family_name
        userId = userCognitoData.signInUserSession?.username // TODO: CHECK THIS
        firstName = userCognitoData.signInUserSession?.idToken?.payload?.given_name
        lastName = userCognitoData.signInUserSession?.idToken?.payload?.family_name
    } else if (userCognitoData?.challengeParam) {
        /** Data structure when signing in from PASSWORD_CHALLENGE */
        tenantId = userCognitoData.challengeParam.userAttributes?.['custom:tenantid']
        email = userCognitoData.challengeParam.userAttributes?.email
        userName =
            userCognitoData.challengeParam.userAttributes?.given_name +
            ' ' +
            userCognitoData.challengeParam.userAttributes?.family_name
        userId = userCognitoData.username
        firstName = userCognitoData.challengeParam.userAttributes?.given_name
        lastName = userCognitoData.challengeParam.userAttributes?.family_name
    }

    return { userId, email, userName, tenantId, firstName, lastName }
}
