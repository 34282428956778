import React, { useEffect, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useParams } from 'react-router-dom'

import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import ResultsLayout from 'components/layouts/hotel/ResultsLayout/ResultsLayout'
import { useApolloQuery } from 'components/hooks/useApolloQuery'
import { GET_HOTELS } from 'graphql-queries/hotel/hotel-queries'
import { parseQueryParams } from 'utils/parse-query-params'
import { CURRENCY_CODES } from 'utils/constants'
import { HotelSearchResult, Hotel } from 'api-data-models/HotelResultsContentModel'

import allContent from 'content/content'

const content = allContent.hotel.resultsPage

type HOTEL_SEARCH_QUERY_VARIABLES =
    | 'destination'
    | 'numberOfAdults'
    | 'numberOfRooms'
    | 'checkInDate'
    | 'checkOutDate'

export const composeHotelSearchQueryVariables = (
    urlQueryParams: string
): Record<HOTEL_SEARCH_QUERY_VARIABLES, string> => {
    const parsedHotelQueryParams = parseQueryParams(urlQueryParams)
    const { destination, numberOfAdults, numberOfRooms, checkInDate, checkOutDate } =
        parsedHotelQueryParams
    return {
        ...(destination && { destination }),
        ...(numberOfAdults && { numberOfAdults: Number(numberOfAdults) }),
        ...(numberOfRooms && { numberOfRooms: Number(numberOfRooms) }),
        ...(checkInDate && { checkInDate }),
        ...(checkOutDate && { checkOutDate }),
    }
}

const HotelResultsPage: React.FC<{
    apiClient: ApolloClient<NormalizedCacheObject>
}> = ({ apiClient }): JSX.Element => {
    const [hotels, setHotels] = useState<Hotel[] | undefined>(undefined)
    const [currencySymbol, setCurrencySymbol] = useState<null | keyof typeof CURRENCY_CODES>(null)
    const { params } = useParams()
    const hotelQueryParams = composeHotelSearchQueryVariables(params ?? '') || {}

    const { result, loading, error } = useApolloQuery({
        client: apiClient,
        variables: hotelQueryParams,
        query: GET_HOTELS,
        source: 'ResultsPage - GET_HOTELS',
    })

    useEffect(() => {
        if (result?.data?.hotelSearch?.hotelOptions) {
            const hotelSearchResult = new HotelSearchResult(result?.data?.hotelSearch?.hotelOptions)
            setHotels(hotelSearchResult.hotels)
            setCurrencySymbol(hotelSearchResult.currencySymbol)
        }
    }, [loading, result])

    const processingResultData = !!result && !hotels && !error

    return (
        <div className='general-container'>
            {(loading || processingResultData) && <LargeSpinner text={content.fetchingResults} />}
            {error && <p>{content.error}</p>}
            {hotels && hotels.length > 0 && (
                <ResultsLayout hotels={hotels} currencySymbol={currencySymbol} />
            )}
        </div>
    )
}
export default HotelResultsPage
