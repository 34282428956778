import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import Card from 'components/blocks/Card/Card'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import Heading from 'components/basics/Heading/Heading'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import Menu from 'components/blocks/Menu/Menu'
import Spacing from 'components/basics/Spacing/Spacing'
import TablePagination from 'components/blocks/TablePagination/TablePagination'
import Text from 'components/basics/Text/Text'
import SendQuoteModal, { QuoteTemplateDynamicFields } from '../SendQuoteModal/SendQuoteModal'
import { Order } from 'api-data-models/order/AllOrdersContentModel'
import { isTestEmailDomain } from 'utils/testing-handlers'
import { extractUserDataFields } from 'utils/user-data-helpers/extract-user-data-fields'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { FeatureToggleContext } from 'App'

import styles from './AllOrdersLayout.module.scss'
import allContent from 'content/content'

const content = allContent.order.allOrdersPage
const tableContent = content.tableContent

type PaginationValues = 5 | 10 | 15 | 20 | 30 | 50 | 100
const INITIAL_ITEMS_PER_PAGE: PaginationValues = 10
const tablePaginationOptions: PaginationValues[] = [5, 10, 15, 20, 30, 50, 100]

const AllOrdersLayout: React.FC<{
    loading: boolean
    error: CustomApiError[] | null
    orders: Order[]
    refreshOrders?(): void
}> = ({ loading, error, orders, refreshOrders }): JSX.Element => {
    const navigate = useNavigate()
    const featureToggles = useContext(FeatureToggleContext)
    const { emailAddress } = extractUserDataFields(
        datadogLogs.getGlobalContext() as GlobalContextUserData
    )

    // For testing cases on production when feature toggle is false, the content will be visible only to traveltek users
    const importOrderEnabled =
        featureToggles.TURN_ON_ORDERS_IMPORT || isTestEmailDomain(emailAddress)

    const [isSendQuoteModalOpen, setIsSendQuoteModalOpen] = useState(false)
    const [quoteTemplateData, setQuoteTemplateData] = useState<QuoteTemplateDynamicFields | null>(
        null
    )
    const [unfulfilledOrdersCurrentPage, setUnfulfilledOrdersCurrentPage] = useState(1)
    const [bookedOrdersCurrentPage, setBookedOrdersCurrentPage] = useState(1)

    const [unfulfilledOrdersPerPage, setUnfulfilledOrdersPerPage] =
        useState<number>(INITIAL_ITEMS_PER_PAGE)
    const [bookedOrdersPerPage, setBookedOrdersPerPage] = useState<number>(INITIAL_ITEMS_PER_PAGE)

    const handleUnfulfilledPageClick = (selected: number): void => {
        setUnfulfilledOrdersCurrentPage(selected)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleBookedPageClick = (selected: number): void => {
        setBookedOrdersCurrentPage(selected)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const getOrdersTable = ({
        orders,
        page,
        itemsPerPage,
        hasSupplierRef,
    }: {
        orders: Order[]
        page: number
        itemsPerPage: number
        hasSupplierRef: boolean
    }): JSX.Element | null => {
        const startIndex = (page - 1) * itemsPerPage
        const endIndex = startIndex + itemsPerPage

        const currentPageOfOrders = orders.slice(startIndex, endIndex)

        return currentPageOfOrders.length ? (
            <table className={styles['table']}>
                <thead>
                    <tr>
                        <th>
                            <Text weight='bold'>{tableContent.reference}</Text>
                        </th>
                        <th>
                            {hasSupplierRef ? (
                                <Text weight='bold'>{tableContent.dateBooked}</Text>
                            ) : (
                                <Text weight='bold'>{tableContent.dateCreated}</Text>
                            )}
                        </th>
                        <th>
                            <Text weight='bold'>{tableContent.supplier}</Text>
                        </th>
                        <th>
                            <Text weight='bold'>{tableContent.departureDate}</Text>
                        </th>
                        <th>
                            <Text weight='bold'>{tableContent.passenger}</Text>
                        </th>
                        <th>
                            <Text weight='bold'>{tableContent.total}</Text>
                        </th>
                        {hasSupplierRef && importOrderEnabled && (
                            <th>
                                <Text weight='bold'>{tableContent.imported}</Text>
                            </th>
                        )}
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {currentPageOfOrders.map((order: Order) => {
                        const redirectUrl = `${
                            order.isImported && importOrderEnabled
                                ? ROUTES.ORDER_IMPORT
                                : ROUTES.ORDER
                        }/?orderId=${order.orderId}`

                        return (
                            <tr key={order.orderId}>
                                <td>
                                    <Text weight='bold'>{order.trimmedOrderId}</Text>
                                </td>
                                {hasSupplierRef ? (
                                    <td>{order.bookedAt}</td>
                                ) : (
                                    <td>{order.createdAt}</td>
                                )}
                                <td>{order.supplierName}</td>
                                <td>{order.departureDate}</td>
                                <td>{order.passengerContactDetailsLastname}</td>
                                <td>
                                    <Text font='mono'>
                                        {order.currencySymbol}
                                        {order.totalPrice}
                                    </Text>
                                </td>
                                {hasSupplierRef && importOrderEnabled && (
                                    <th>
                                        <Text weight='bold'>{order.isImported ? 'Yes' : '-'}</Text>
                                    </th>
                                )}
                                <td>
                                    {!hasSupplierRef ? (
                                        <Menu
                                            id={order.orderId}
                                            options={[
                                                {
                                                    label: tableContent.viewQuote,
                                                    onClick: () => navigate(redirectUrl),
                                                },
                                                ...(featureToggles.TURN_ON_EMAIL_QUOTES
                                                    ? [
                                                          {
                                                              label: tableContent.emailQuote,
                                                              onClick: (): void => {
                                                                  setIsSendQuoteModalOpen(true)
                                                                  setQuoteTemplateData(
                                                                      order.templateData
                                                                  )
                                                              },
                                                          },
                                                      ]
                                                    : []),
                                            ]}
                                            buttonText={tableContent.actions}
                                        />
                                    ) : (
                                        <Link to={redirectUrl}>{tableContent.orderLinkText}</Link>
                                    )}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        ) : null
    }

    const unfulfilledOrders: Order[] = []
    const bookedOrders: Order[] = []

    if (orders) {
        orders.forEach((order: Order) => {
            /** To separate orders between "booked" and "unbooked" - (confusing terminology as "on-hold" is a put in with booked, and "failed" is an attempted booking that is maybe going to be in with booked?)
             * Keep to just the presences of a supplier reference. */
            if (order.supplierReference) {
                bookedOrders.push(order)
                bookedOrders.sort((a: Order, b: Order) => {
                    return new Date(b.bookedAt).getTime() - new Date(a.bookedAt).getTime()
                })
            } else {
                unfulfilledOrders.push(order)
                unfulfilledOrders.sort((a: Order, b: Order) => {
                    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                })
            }
        })
    }

    const foundUnfulfilledOrders = getOrdersTable({
        orders: unfulfilledOrders,
        page: unfulfilledOrdersCurrentPage,
        itemsPerPage: unfulfilledOrdersPerPage,
        hasSupplierRef: false,
    })

    const foundBookedOrders = getOrdersTable({
        orders: bookedOrders,
        page: bookedOrdersCurrentPage,
        itemsPerPage: bookedOrdersPerPage,
        hasSupplierRef: true,
    })

    return (
        <div className='general-container'>
            <SendQuoteModal
                isOpen={isSendQuoteModalOpen}
                onClose={(): void => setIsSendQuoteModalOpen(false)}
                returnFocusId={'sendQuoteButton'}
                templateData={quoteTemplateData}
            />
            <div className={styles.heading}>
                <Spacing>
                    <Heading heading='1'>{content.title}</Heading>
                </Spacing>
            </div>
            {loading && <LargeSpinner text={content.fetchingOrder} />}
            {error && <ErrorList errorsList={error} source='all-orders-page' />}
            {!error && !loading && orders && (
                <div className={styles.content}>
                    <Card
                        header={
                            <>
                                <Heading heading='2' size='2' onDarkBackground={true}>
                                    {content.quotesTableTitle}
                                </Heading>
                                <Button
                                    type='button'
                                    onClick={refreshOrders}
                                    text={content.refreshOrders}
                                    flavour='tertiary'
                                    onDarkBackground={true}
                                />
                            </>
                        }
                    >
                        {foundUnfulfilledOrders ?? (
                            <Text className={styles['text']}>
                                {tableContent.noUnfulfilledOrdersFoundText}
                            </Text>
                        )}
                        <TablePagination
                            id={'quotes'}
                            page={unfulfilledOrdersCurrentPage}
                            itemsPerPage={unfulfilledOrdersPerPage}
                            itemsPerPageOptions={tablePaginationOptions}
                            totalPages={Math.ceil(
                                unfulfilledOrders.length / unfulfilledOrdersPerPage
                            )}
                            onPageChange={handleUnfulfilledPageClick}
                            onItemsPerPageChange={setUnfulfilledOrdersPerPage}
                            className={styles['orders-pagination']}
                        />
                    </Card>
                    <Card
                        header={
                            <>
                                <Heading heading='2' size='2' onDarkBackground={true}>
                                    {content.orderTableTitle}
                                </Heading>
                                <Button
                                    type='button'
                                    onClick={refreshOrders}
                                    text={content.refreshOrders}
                                    flavour='tertiary'
                                    onDarkBackground={true}
                                />
                            </>
                        }
                    >
                        {foundBookedOrders ?? (
                            <Text className={styles['text']}>
                                {tableContent.noBookedOrdersFoundText}
                            </Text>
                        )}
                        <TablePagination
                            id={'bookedOrders'}
                            page={bookedOrdersCurrentPage}
                            itemsPerPage={bookedOrdersPerPage}
                            totalPages={Math.ceil(bookedOrders.length / bookedOrdersPerPage)}
                            onPageChange={handleBookedPageClick}
                            onItemsPerPageChange={setBookedOrdersPerPage}
                            className={styles['orders-pagination']}
                        />
                    </Card>
                </div>
            )}
        </div>
    )
}

export default AllOrdersLayout
