import * as React from 'react'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector from '@mui/material/StepConnector'
import Icon from 'components/basics/Icon/Icon'
import styles from './Stepper.module.scss'
import classNames from 'classnames'
import Spacing from 'components/basics/Spacing/Spacing'

interface CustomizedStepperProps {
    steps: {
        label: string
        iconName: string
        onClick?: () => void
    }[]
    activeStep: number
    lastCompletedStep: number
    setActiveStep: (step: number) => void
}

const CustomizedStepper: React.FC<CustomizedStepperProps> = ({
    steps,
    activeStep,
    lastCompletedStep,
    setActiveStep,
}) => {
    return (
        <div>
            <Spacing />
            <Stack sx={{ width: '100%' }} spacing={4}>
                <Stepper
                    alternativeLabel
                    activeStep={lastCompletedStep}
                    connector={<StyledStepConnector />}
                >
                    {steps.map(({ label, iconName, onClick }, i) => (
                        <Step key={label} role='button'>
                            <StepLabel
                                classes={{
                                    label: styles['stepper-label'],
                                    active: styles['stepper-label-active'],
                                    completed: styles['stepper-label-completed'],
                                }}
                                StepIconComponent={(props): any => (
                                    <StepIcon
                                        {...props}
                                        active={i === activeStep}
                                        completed={i <= lastCompletedStep}
                                        iconName={iconName}
                                    />
                                )}
                                onClick={(): any => {
                                    if (i > lastCompletedStep + 1) return
                                    if (onClick) onClick()
                                    setActiveStep(i)
                                }}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Stack>
        </div>
    )
}

export default CustomizedStepper

function StepIcon(props: any): any {
    const { active, completed, className, iconName } = props

    const iconWrapperClassNames = classNames(className, styles['iconWrapper'], {
        [styles['iconWrapper-active']]: active,
        [styles['iconWrapper-completed']]: completed,
    })

    return (
        <div className={iconWrapperClassNames} data-testid={completed ? 'Check' : iconName}>
            {iconName && (
                <Icon
                    iconName={completed ? 'Check' : iconName}
                    iconColor={active ? 'white' : 'pink'}
                />
            )}
        </div>
    )
}

const StyledStepConnector = (props: any): any => (
    <StepConnector
        {...props}
        classes={{
            alternativeLabel: styles['connector-alternativeLabel'],
            line: styles['connector-line'],
            active: styles['connector-line-active'],
            completed: styles['connector-line-completed'],
        }}
    />
)
