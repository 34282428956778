import React from 'react'

import Button from 'components/basics/Button/Button'
import Heading from 'components/basics/Heading/Heading'
import Link from 'components/basics/Link/Link'
import Text from 'components/basics/Text/Text'
import { ROUTES } from 'components/sections/app/AppRoutes'

import styles from './AgentConnectPaidDescription.module.scss'
import allContent from 'content/content'

const content = allContent.admin.adminLanding.agentConnectCruise.paidProductDescription

const AgentConnectPaidDescription = (): JSX.Element => {
    const credentialsManagement = (
        <>
            <Text>{content.credentialsManagement.addSalesChannels}</Text>
            <div className={styles.indent}>
                <Link noUnderLine={true} to={ROUTES.SALES_CHANNEL_MANAGEMENT}>
                    <Button
                        type='button'
                        text={content.credentialsManagement.salesChannelManagementLink}
                    />
                </Link>
            </div>
        </>
    )

    const groupManagement = (
        <>
            <Text>{content.groupsManagement.manageGroups}</Text>
            <div className={styles.indent}>
                <Link noUnderLine={true} to={ROUTES.GROUP_MANAGEMENT}>
                    <Button type='button' text={content.groupsManagement.groupsManagementLink} />
                </Link>
            </div>
        </>
    )

    const userManagement = (
        <>
            <Text>{content.usersManagement.addUsers}</Text>
            <div className={styles.indent}>
                <Link noUnderLine={true} to={ROUTES.USER_MANAGEMENT}>
                    <Button type='button' text={content.usersManagement.userManagementLink} />
                </Link>
            </div>
            <Text>{content.usersManagement.loginInfo}</Text>
        </>
    )

    return (
        <div className={styles.container}>
            <div className={styles['heading-wrapper']}>
                <div className={styles['heading-text']}>
                    <Heading heading='3'>{content.productName}</Heading>
                    <Text weight='bold'>{content.subtitle}</Text>
                </div>
            </div>
            <Text>{content.text1}</Text>
            {credentialsManagement}
            {groupManagement}
            {userManagement}
            <span>
                <Text>{content.getStarted}</Text>{' '}
                <Link href={content.productLinkUrl}>{content.productLinkText}</Link>
            </span>
        </div>
    )
}

export default AgentConnectPaidDescription
