import React, { useState } from 'react'

import dropDownFormStyles from './DropDownForm.module.scss'
import Button from '../Button/Button'

type DropDownFormProps = Omit<React.AllHTMLAttributes<HTMLButtonElement>, 'onClick' | 'type'> & {
    text: string
    className?: string
    onDarkBackground?: boolean
    isErrored?: boolean
}

const DropDownForm: React.FC<DropDownFormProps> = ({
    text,
    className,
    children,
    disabled = false,
    onDarkBackground = false,
    isErrored = false, // caught here and unused so this component doesn't get warnings when placed within a labelled Input (which passes this prop to children - maybe it shouldn't?)
    ...rest
}) => {
    const [showForm, setShowForm] = useState(false)
    const buttonIcon = showForm ? 'ChevronUp' : 'ChevronDown'

    function toggleForm(): void {
        setShowForm(!showForm)
    }

    return (
        <>
            <Button
                type='button'
                flavour='text'
                iconName={buttonIcon}
                onDarkBackground={onDarkBackground}
                text={text}
                onClick={toggleForm}
                disabled={disabled}
                {...rest}
            />
            {showForm && !disabled && (
                <div className={dropDownFormStyles.container}>{children}</div>
            )}
        </>
    )
}

DropDownForm.displayName = 'DropDownForm'

export default DropDownForm
