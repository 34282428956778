import { gql } from '@apollo/client'

export const GET_HOTELS = gql`
    query GetHotels(
        $destination: String
        $checkInDate: Date!
        $checkOutDate: Date!
        $numberOfRooms: Int!
        $numberOfAdults: Int!
    ) {
        hotelSearch(
            criteria: {
                destinationName: $destination
                checkIn: $checkInDate
                checkOut: $checkOutDate
                rooms: { roomNumber: $numberOfRooms, numberOfAdults: $numberOfAdults }
            }
        ) {
            hotelOptions {
                id
                name
                address
                numberOfNights
                rating
                averagePricePerNight
                priceBreakdown {
                    priceDetail {
                        currency
                        net
                        gross
                    }
                }
            }
        }
    }
`

export const GET_HOTEL_ROOMS = gql`
    query GetHotelRooms($hotelId: String!, $checkInDate: Date!, $checkOutDate: Date!) {
        hotelSearch(
            criteria: {
                hotelId: [$hotelId]
                checkIn: $checkInDate
                checkOut: $checkOutDate
                rooms: { roomNumber: 1, numberOfAdults: 1 }
            }
        ) {
            hotelOptions {
                id
                name
                address
                numberOfNights
                rating
                averagePricePerNight
                rooms {
                    roomNumber
                    roomOptions {
                        id
                    }
                }
            }
        }
    }
`
