import React from 'react'
import { Menu as MuiMenu, MenuItem as MuiMenuItem, StyledEngineProvider } from '@mui/material'

import styles from './PaginationRangeSelect.module.scss'
import allContent from 'content/content'
import Text from 'components/basics/Text/Text'
import Icon from 'components/basics/Icon/Icon'

const content = allContent.components.paginationRangeSelect

interface PaginationRangeSelectProps {
    id: string
    options?: number[]
    value: number
    onChange: (pageSize: number) => void
}

const PaginationRangeSelect: React.FC<PaginationRangeSelectProps> = ({
    id,
    options = [5, 10, 15],
    value,
    onChange,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (): void => {
        setAnchorEl(null)
    }

    return (
        <StyledEngineProvider injectFirst>
            <div className={styles['menu__container']}>
                <Text size='S'>{content.entriesPerPageLabel}</Text>
                <button
                    className={styles['menu__button']}
                    id={id}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleOpen}
                >
                    <Text size='S' weight='bold'>
                        {value}
                    </Text>
                    <Icon iconName='ChevronDown' iconSize='S' />
                </button>
            </div>
            <MuiMenu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': id }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {options.map((pageSize) => (
                    <MuiMenuItem
                        key={pageSize}
                        onClick={(): void => {
                            onChange(pageSize)
                            handleClose()
                        }}
                    >
                        <Text weight='bold'>{pageSize}</Text>
                    </MuiMenuItem>
                ))}
            </MuiMenu>
        </StyledEngineProvider>
    )
}

export default PaginationRangeSelect
