import React from 'react'

import ListItem from 'components/basics/List/ListItem'
import HotelDetailsSection from './HotelResultsListItemComponents/HotelDetailsSection'
import { Hotel } from 'api-data-models/HotelResultsContentModel'
import styles from './HotelResultsListItem.module.css'

type HotelResultsListItemProps = React.AllHTMLAttributes<HTMLLIElement> & {
    listKey: string
    hotel: Hotel
}
const HotelResultsListItem: React.FC<HotelResultsListItemProps> = ({ listKey, hotel }) => {
    return (
        <ListItem className={styles.container} listKey={listKey}>
            <HotelDetailsSection hotel={hotel} />
        </ListItem>
    )
}
export default HotelResultsListItem
