import React, { FormEvent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import Button from 'components/basics/Button/Button'
import DropDownForm from 'components/basics/DropDownForm/DropDownForm'
import DatePicker from 'components/basics/DatePicker/DatePicker'
import { format } from 'date-fns'
import { DATE_FORMAT_Y_M_D_DASH } from 'utils/constants'
import Radio from 'components/basics/Input/Radio/Radio'
import RadioGroup from 'components/basics/Input/RadioGroup/RadioGroup'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Select from 'components/basics/Input/Select/Select'
import Heading from 'components/basics/Heading/Heading'
import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import Nudger from 'components/basics/Nudger/Nudger'

import styles from './SearchBar.module.css'
import allContent from 'content/content'

const content = allContent.flight.searchPage.searchBar

const SearchBar: React.FC = () => {
    const { control, getValues, handleSubmit, setValue } = useForm()
    const [tripType, setTripType] = useState('round-trip')
    const [maxDepartureDate, setMaxDepartureDate] = useState<Date | null>(getValues('returnDate')) // could use getValues('returnDate') as default if the form is ever being pre-populated from recent values
    const [minReturnDate, setMinReturnDate] = useState<Date | null>() // could use getValues('departureDate') as default if the form is ever being pre-populated from recent values
    const [numberOfChildren, setNumberOfChildren] = useState(0)
    const [childrenAges, setChildrenAges] = useState(getValues('childrenAges') || {})
    const selectChildrenInputs: JSX.Element[] = []
    const navigate = useNavigate()

    const handleRoundTripRadioClick = useCallback(() => {
        setMaxDepartureDate(getValues('returnDate')) // if there is a remembered return date it should be max departure of outbound date.
        setTripType('round-trip')
    }, [getValues])

    const handleOneWayRadioClick = useCallback(() => {
        setMaxDepartureDate(null) // if we are doing a single flight then the selected return leg should no longer limit max outbound date.
        setTripType('one-way')
    }, [setTripType])

    const handleMultiCityRadioClick = useCallback(() => {
        setTripType('multi-city')
    }, [setTripType])

    function handleDepartureDateClick(date: Date): void {
        setMinReturnDate(date)
        setValue('departureDate', date)
    }
    function handleReturnDateClick(date: Date): void {
        setMaxDepartureDate(date)
        setValue('returnDate', date)
    }
    function handleFromOnChange(value: string): void {
        setValue('fromIATACode', value)
    }
    function handleToOnChange(value: string): void {
        setValue('toIATACode', value)
    }
    function handleAdultNumberChange(value: number): void {
        setValue('numberOfAdults', value)
    }
    function handleChildrenNumberChange(value: number): void {
        setNumberOfChildren(value)
    }

    function handleInfantNumberChange(value: number): void {
        setValue('numberOfInfants', value)
    }

    function createSearchParams(fieldValues: any): string {
        const urlString: string[] = []

        if (fieldValues.childrenAges) {
            Object.entries(fieldValues.childrenAges).forEach(([key, value]) =>
                urlString.push(`child${key}Age=${encodeURIComponent(String(value))}`)
            )
        }

        Object.entries(fieldValues).forEach(([key, value]) => {
            if (typeof value !== 'undefined' && key !== 'childrenAges') {
                if ((key === 'departureDate' || key === 'returnDate') && value instanceof Date) {
                    urlString.push(
                        `${key}=${encodeURIComponent(
                            format(new Date(value.toDateString()), DATE_FORMAT_Y_M_D_DASH)
                        )}`
                    )
                } else {
                    urlString.push(`${key}=${encodeURIComponent(String(value))}`)
                }
            }
        })

        return urlString.join('&')
    }

    for (let i = 0; i < numberOfChildren; i++) {
        const key = `passenger${i + 1}`
        const passengerNumber = i + 1
        const currentAge = childrenAges?.[passengerNumber]
        selectChildrenInputs.push(
            <div key={key} className={styles['age-inputs']}>
                <LabelledInput
                    label={`${content.passengerAdvanced.nudgerChildrenSelectHeadingPrefix} 
                    ${i + 1} ${content.passengerAdvanced.nudgerChildrenSelectHeadingSuffix}`}
                    htmlFor={key}
                >
                    <Select
                        data-testid='passengerAgeOptions'
                        value={currentAge}
                        placeholder={content.passengerAdvanced.passengerAgePlaceholder}
                        options={content.passengerAdvanced.passengerAgeOptions}
                        onChange={(e: FormEvent<HTMLSelectElement>): void => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            childrenAges[passengerNumber] = e.target.value
                            setChildrenAges({ ...childrenAges }) // spread to cause rerender as creates NEW object, as existing object would be evaluated as same value (even with new keys)
                            setValue('childrenAges', childrenAges)
                        }}
                        required
                    />
                </LabelledInput>
            </div>
        )
    }

    return (
        <form
            className={styles['search-bar']}
            onSubmit={handleSubmit((fieldValues) => {
                const queryParamsString = createSearchParams(fieldValues)
                navigate(`/flight/results/${queryParamsString}`) // TODO: doesn't exist yet....
            })}
        >
            <RadioGroup groupName='trip-type'>
                <Radio
                    onDarkBackground={true}
                    text={content.radioButtons.roundTrip}
                    checked={tripType === 'round-trip'}
                    onChange={handleRoundTripRadioClick}
                />
                <Radio
                    onDarkBackground={true}
                    text={content.radioButtons.oneWay}
                    checked={tripType === 'one-way'}
                    onChange={handleOneWayRadioClick}
                />
                <Radio
                    onDarkBackground={true}
                    text={content.radioButtons.multiCiCty}
                    checked={tripType === 'multi-city'}
                    onChange={handleMultiCityRadioClick}
                />
            </RadioGroup>
            <div className={styles['trip-section']}>
                {(tripType === 'one-way' || tripType === 'round-trip') && (
                    <>
                        <Controller
                            control={control}
                            name='fromIATACode'
                            render={(): React.ReactElement => (
                                <LabelledInput
                                    className={styles['airport-input']}
                                    htmlFor='fromIATACode'
                                    label={content.from}
                                    labelColor='white'
                                >
                                    <TextInput
                                        onChangeCallback={handleFromOnChange}
                                        placeholder={content.fromPlaceholder}
                                        autoComplete='off'
                                    />
                                </LabelledInput>
                            )}
                        />
                        <Controller
                            control={control}
                            name='toIATACode'
                            render={(): React.ReactElement => (
                                <LabelledInput
                                    className={styles['airport-input']}
                                    htmlFor='toIATACode'
                                    label={content.to}
                                    labelColor='white'
                                >
                                    <TextInput
                                        onChangeCallback={handleToOnChange}
                                        placeholder={content.toPlaceholder}
                                        autoComplete='off'
                                    />
                                </LabelledInput>
                            )}
                        />
                        <Controller
                            name='departureDate'
                            control={control}
                            render={(): React.ReactElement => (
                                <DatePicker
                                    autoComplete='off'
                                    name='departureDate'
                                    dateFormat='dd/MM/yyyy'
                                    maxDate={maxDepartureDate}
                                    onChange={handleDepartureDateClick}
                                    placeholderText={content.departureDatePlaceholder}
                                    selected={getValues('departureDate')}
                                    customInput={
                                        <LabelledInput
                                            labelColor='white'
                                            label={content.departureDate}
                                            htmlFor='departure-date'
                                        >
                                            <TextInput
                                                iconName='Calendar'
                                                onKeyDown={(e): void => {
                                                    e.preventDefault() // this stops typing into input - manually typing the date doesn't set the value so shouldn't let people try.
                                                }}
                                            />
                                        </LabelledInput>
                                    }
                                />
                            )}
                        />
                    </>
                )}
                {tripType === 'round-trip' && (
                    <Controller
                        name='returnDate'
                        control={control}
                        render={(): React.ReactElement => (
                            <DatePicker
                                autoComplete='off'
                                name='returnDate'
                                dateFormat='dd/MM/yyyy'
                                minDate={minReturnDate}
                                onChange={handleReturnDateClick}
                                placeholderText={content.returnDatePlaceholder}
                                selected={getValues('returnDate')}
                                customInput={
                                    <LabelledInput
                                        labelColor='white'
                                        label={content.returnDate}
                                        htmlFor='return-date'
                                    >
                                        <TextInput
                                            iconName='Calendar'
                                            onKeyDown={(e): void => {
                                                e.preventDefault() // this stops typing into input - manually typing the date doesn't set the value so shouldn't let people try.
                                            }}
                                        />
                                    </LabelledInput>
                                }
                            />
                        )}
                    />
                )}
                {tripType === 'multi-city' && (
                    <Text color='white'>way of adding multiple legs?</Text>
                )}
                <LabelledInput htmlFor='passengers' label={content.passenger} labelColor='white'>
                    <DropDownForm
                        onDarkBackground={true}
                        text={content.passengerAdvanced.passengerButton}
                    >
                        <Heading heading='3' size='3'>
                            {content.addPassengerFormTitle}
                        </Heading>
                        <ColoredLine />
                        <Controller
                            name='numberOfAdults'
                            control={control}
                            render={({ field: { value } }): React.ReactElement => (
                                <Nudger
                                    value={value}
                                    onChange={handleAdultNumberChange}
                                    labelText={content.passengerAdvanced.nudgerAdultLabel}
                                    subLabelText={content.passengerAdvanced.nudgerAdultSubLabel}
                                />
                            )}
                        />
                        <ColoredLine />
                        <Nudger
                            value={numberOfChildren}
                            onChange={handleChildrenNumberChange}
                            labelText={content.passengerAdvanced.nudgerChildrenLabel}
                            subLabelText={content.passengerAdvanced.nudgerChildrenSubLabel}
                        />
                        {selectChildrenInputs}
                        <ColoredLine />
                        <Controller
                            name='numberOfInfants'
                            control={control}
                            render={({ field: { value } }): React.ReactElement => (
                                <Nudger
                                    value={value}
                                    onChange={handleInfantNumberChange}
                                    labelText={content.passengerAdvanced.nudgerInfantLabel}
                                    subLabelText={content.passengerAdvanced.nudgerInfantSubLabel}
                                />
                            )}
                        />
                    </DropDownForm>
                </LabelledInput>
                <Button
                    onDarkBackground={true}
                    iconName='Search'
                    type='submit'
                    text={content.searchButton}
                />
            </div>
        </form>
    )
}
export default SearchBar
