import React from 'react'

import Button from 'components/basics/Button/Button'
import DescriptionListDisplay from 'components/blocks/DescriptionListDisplay/DescriptionListDisplay'
import Heading from 'components/basics/Heading/Heading'
import Text from 'components/basics/Text/Text'
import { FlightProduct, ORDER_ITEM_STATUS } from 'api-data-models/order/OrderContentModel'
import { copyToClipboardHandler } from '../../../../../utils/file-system-helpers'
import styles from './FlightOrderItem.module.scss'
import allContent from '../../../../../content/content'

const content = allContent.order.orderPage.flightOrderItem

type FlightOrderItemProps = {
    flightProduct: FlightProduct
    numberOfPassengers: number
    supplierOrderItemReference: string | null
    orderItemStatus: string
}

const FlightOrderItem = ({
    flightProduct,
    numberOfPassengers,
    supplierOrderItemReference,
    orderItemStatus,
}: FlightOrderItemProps): JSX.Element => {
    const flightDetailsMaxKeyLength = Math.max(
        ...[
            content.flightNumber.length,
            content.airline.length,
            content.cabinClass.length,
            content.checkedBaggage.length,
        ]
    )

    return (
        <>
            <div className={styles['order-item__header']}>
                <div className={styles['order-item__header-title']}>
                    <Heading heading='2' onDarkBackground={true}>
                        {flightProduct.productType}
                    </Heading>
                    <Text
                        size='L'
                        weight='bold'
                        color='orange'
                    >{`${flightProduct.currency}${flightProduct.pricing.price}`}</Text>
                </div>
                <div className={styles['order-item__header-sub-text']}>
                    <Text size='S' color='white'>
                        {`${flightProduct.itineraryString} • ${flightProduct.flightType} • ${numberOfPassengers} ${content.passengers}`}
                    </Text>
                    {orderItemStatus === ORDER_ITEM_STATUS.BOOKED && supplierOrderItemReference && (
                        <div className={styles['order-item__header-sub-text--supplier-ref']}>
                            <Text size='S' color='white'>
                                {content.supplierOrderReference} {supplierOrderItemReference}
                            </Text>
                            <Button
                                onClick={(): Promise<void> =>
                                    copyToClipboardHandler(supplierOrderItemReference)
                                }
                                flavour='icon'
                                onDarkBackground={true}
                                text={content.copySupplierRefButton}
                                type='button'
                                iconName='Copy'
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles['order-item__details-container']}>
                {flightProduct.flightLegs.map((leg) => {
                    return leg.segments.map((segment) => {
                        const stops =
                            segment.stops.length > 0
                                ? `${segment.stops.length} ${content.segmentStops}`
                                : content.segmentDirect
                        return (
                            <div
                                key={segment.segmentId}
                                className={styles['order-item__details-segment']}
                            >
                                <Heading heading='3'>{segment.departureDatetime}</Heading>
                                <Text>{`${segment.departureAirportCode} - ${segment.arrivalAirportCode} • ${stops}`}</Text>
                                <DescriptionListDisplay
                                    key={segment.segmentId}
                                    overrideMaxKeyLength={flightDetailsMaxKeyLength}
                                    keyValueContent={[
                                        [content.flightNumber, segment.flightNumber],
                                        [content.airline, segment.marketingAirlineName ?? ''],
                                        [
                                            content.checkedBaggage,
                                            segment.checkedBaggage ? 'Yes' : 'No',
                                        ], // "
                                    ]}
                                />
                            </div>
                        )
                    })
                })}
            </div>
            <div className={styles['order-item__footer']}>
                <Button flavour='secondary' text={content.pricingInfo} type='button' />
                <Button
                    flavour='secondary'
                    disabled={true}
                    text={content.cancellationPolicy}
                    type='button'
                />
            </div>
        </>
    )
}

export default FlightOrderItem
