import React from 'react'

import Accordion from 'components/blocks/Accordion/Accordion/Accordion'
import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import Icon from 'components/basics/Icon/Icon'
import Text from 'components/basics/Text/Text'
import { RateCode } from 'api-data-models/SailingContentModel'
import { REFUND_POLICIES } from 'utils/constants'

import styles from '../RateCodes/RateCodes.module.css'
import allContent from 'content/content'
const content = allContent.cruise.sailingPage.rateCodeSection

type RateCodesSectionProps = {
    /** A single sailing result object */
    rateCodes: RateCode[]
}

export const getRefundPolicyBadgeText = (refundPolicy?: RefundPolicyType): string | null => {
    switch (refundPolicy) {
        case REFUND_POLICIES.NON_REFUNDABLE_DEPOSIT:
            return content.nonRefundableDeposit
        case REFUND_POLICIES.NON_REFUNDABLE_FARE:
            return content.nonRefundable
        default:
            return null
    }
}

const RateCodes: React.FC<RateCodesSectionProps> = ({ rateCodes }: RateCodesSectionProps) => {
    const maxLen = rateCodes?.length
        ? Math.max(...rateCodes.map((rateCode) => rateCode.code.length))
        : null

    return (
        <Accordion
            baseId='ratecode-section'
            title={content.heading}
            titleLevel='2'
            titleSize='3'
            darkHeading={true}
            isPadded={true}
        >
            <div className={styles.wrapper}>
                {rateCodes.map((rateCode, i) => {
                    const width = maxLen ? maxLen * 0.67 + 'rem' : 'inherit'
                    const refundPolicyBadge = getRefundPolicyBadgeText(rateCode?.refundPolicy)
                    const rateExtraInfo =
                        !!refundPolicyBadge || rateCode?.military || rateCode?.residency
                    return (
                        <div key={`${rateCode.code}`}>
                            {i !== 0 && <ColoredLine className={styles.divider} />}
                            <div className={styles.column}>
                                <div className={styles.row}>
                                    <div style={{ minWidth: width }}>
                                        <Text weight='bold'>{rateCode.code}</Text>
                                    </div>
                                    <div>
                                        <Text>{rateCode.description}</Text>
                                    </div>
                                </div>
                                {rateExtraInfo && (
                                    <div className={styles.row}>
                                        {refundPolicyBadge && (
                                            <div className={styles['row-small']}>
                                                <Icon iconName='NonRefundable' />
                                                <Text size='XS'>{refundPolicyBadge}</Text>
                                            </div>
                                        )}
                                        {rateCode?.military && (
                                            <div className={styles['row-small']}>
                                                <Icon iconName='MilitaryRate' />
                                                <Text size='XS'>{content.militaryRate}</Text>
                                            </div>
                                        )}
                                        {rateCode?.residency && (
                                            <div className={styles['row-small']}>
                                                <Icon iconName='ResidentialRate' />
                                                <Text size='XS'>{content.residentialRate}</Text>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </Accordion>
    )
}

export default RateCodes
