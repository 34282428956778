import classnames from 'classnames'
import React from 'react'

import PaginationRangeSelect from '../PaginationRangeSelect/PaginationRangeSelect'
import TablePaginationButtons from 'components/blocks/TablePaginationButtons/TablePaginationButtons'
import styles from './TablePagination.module.scss'

export interface TablePaginationProps {
    id?: string
    page: number
    itemsPerPage: number
    itemsPerPageOptions?: number[]
    onPageChange: (page: number) => void
    onItemsPerPageChange: (itemsPerPage: number) => void
    totalPages: number
    className?: string
}

const TablePagination: React.FC<TablePaginationProps> = ({
    id,
    page,
    itemsPerPage,
    itemsPerPageOptions,
    totalPages,
    onPageChange,
    onItemsPerPageChange,
    className,
}) => {
    return (
        <div className={classnames(styles['table-pagination'], className)}>
            <PaginationRangeSelect
                id={`${id}-paginate-per-page`}
                value={itemsPerPage}
                onChange={onItemsPerPageChange}
                options={itemsPerPageOptions}
            />
            <TablePaginationButtons
                handlePageChange={onPageChange}
                page={!totalPages ? 0 : page}
                totalNumOfPages={totalPages}
            />
        </div>
    )
}

export default TablePagination
