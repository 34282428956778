import React from 'react'

import Button from 'components/basics/Button/Button'
import Card from 'components/blocks/Card/Card'
import Heading from 'components/basics/Heading/Heading'
import Tooltip from 'components/basics/Tooltip/Tooltip'

import { SailingContent } from 'api-data-models/SailingContentModel'

import styles from './Itinerary.module.css'

import allContent from 'content/content'

const content = allContent.cruise.sailingPage.itineraryView

type ItineraryProps = {
    sailingSearchResult: SailingContent
}

const Itinerary = (props: ItineraryProps): JSX.Element => {
    const itineraryItems = props.sailingSearchResult.itinerary
    const tableBody = itineraryItems.map((item) => (
        <tr className={styles['body-row']} key={item.dayNumber}>
            <td>{`${content.dayNumberText}${item.dayNumber}`}</td>
            <td>
                <div className={styles['date-cells']}>
                    <div className={styles['date-cell']}>{item.itemDate}</div>
                    <div className={styles['arrive-depart-times']}>
                        {item.arrivalTime && `${content.arriveText}${item.arrivalTime}`}
                        {item.departureTime && item.arrivalTime && ', '}
                        {item.departureTime && `${content.departText}${item.departureTime}`}
                    </div>
                </div>
            </td>
            <td>{item.portName}</td>
        </tr>
    ))

    const handleCopyItinerary = (): void => {
        const textToCopy = itineraryItems
            .map(
                (item) =>
                    `${content.dayNumberText} ${item.dayNumber}\n` +
                    `${content.dateTimeColumnHeader} - ${item.itemDate} ${
                        item.arrivalTime ? `${content.arriveText} ${item.arrivalTime}` : ''
                    }${item.arrivalTime && item.departureTime ? ', ' : ''}${
                        item.departureTime ? `${content.departText} ${item.departureTime}` : ''
                    }\n` +
                    `${content.itineraryColumnHeader} - ${item.portName}`
            )
            .join('\n\n')

        navigator.clipboard.writeText(textToCopy)
    }

    const header = (
        <div className={styles['header']}>
            <Heading heading='2' size='2' onDarkBackground={true}>
                {content.headerText}
            </Heading>
            <Tooltip content={content.copiedAfterClickText} id='copy-itinerary' openOnClick={true}>
                <Button
                    id='copy-itinerary-button'
                    flavour='tertiary'
                    iconName='Copy'
                    text={content.copyButton}
                    onDarkBackground={true}
                    onClick={(): void => handleCopyItinerary()}
                    type='button'
                />
            </Tooltip>
        </div>
    )

    return (
        <Card header={header}>
            <div className={styles['table-container']}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th className={styles['day-number-head']}>
                                {content.daysColumnHeader}
                            </th>
                            <th className={styles['date-time-head']}>
                                {content.dateTimeColumnHeader}
                            </th>
                            <th className={styles['itinerary-head']}>
                                {content.itineraryColumnHeader}
                            </th>
                        </tr>
                    </thead>
                    <tbody>{tableBody}</tbody>
                </table>
            </div>
        </Card>
    )
}

export default Itinerary
