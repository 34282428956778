import { APIBreakDownItem } from './sailing-api-types'
import { insertDecimal2CharsFromEnd } from '../utils/string-helpers'
import { CURRENCY_CODES } from '../utils/constants'
import { getFormattedDate } from '../utils/date-helpers'

/** returns an object with each passenger's breakdown: key = passengerNumber, values = array of breakdownItems for that passenger */
export function getBreakDownItemsByPassenger(
    breakdownItems: APIBreakDownItem[]
): BreakdownPerPassenger {
    if (!breakdownItems || breakdownItems.length <= 0) return {}
    const breakdownsPerPassenger:
        | Record<string, Breakdown | Record<string, never>>
        | Record<string, never> = {} // any should be type Breakdown but can't make code below happy with typescript

    breakdownItems.forEach((bdItem) => {
        if (!breakdownsPerPassenger[bdItem.passengerNumber])
            breakdownsPerPassenger[bdItem.passengerNumber] = {} // if a passengerNumber as key doesn't exist, create object
        if (
            bdItem.fareType !== 'GROSS' &&
            bdItem.fareType !== 'COMMISSION' &&
            bdItem.fareType !== 'ONBOARD_CREDIT'
        ) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            breakdownsPerPassenger[bdItem.passengerNumber][bdItem.name] = {
                // add breakdown item object using fareType as key and value being: { price, name }
                price: insertDecimal2CharsFromEnd(bdItem.price),
                name: bdItem.name,
            }
        } else if (bdItem.fareType === 'GROSS') {
            // } else if (bdItem.fareType === 'GROSS' && bdItem.itemType !== 'AGENT') {  // this was needed for data where there are more than one gross for a passenger but of type AGENT and type GUEST
            breakdownsPerPassenger[bdItem.passengerNumber].GROSS = {
                // add breakdown item object using fareType as key and value being: { price, name }
                price: insertDecimal2CharsFromEnd(bdItem.price),
                name: 'GROSS',
            }
        }
    })
    breakdownsPerPassenger.totals = breakdownsPerPassenger['0']
    delete breakdownsPerPassenger['0']
    return breakdownsPerPassenger
}

export const GUARANTEED = 'GUAR'

export type BreakdownItem = {
    name: string
    price: number | string
}

type FareTypeName =
    | 'FEES_TAXES_PORT_EXPENSES'
    | 'FARE'
    | 'COMMISSION'
    | 'NON_COMMISSIONABLE_FARE'
    | 'ONBOARD_CREDIT'
    | 'MISC'
    | 'GROSS'
    | 'BONUS_COMMISSION'
    | 'NET_FARE'
    | 'NET_DUE'
    | 'TRANSPORTATION_ADDON'
    | 'INSURANCE'
    | 'PACKAGES'
    | 'SPECIAL_SERVICES'
    | 'PAYMENT_APPLIED'
    | 'AVERAGE_CRUISE_RATE_PER_PERSON_WITH_NCFS'
    | 'OBGUEST_SUB_TOTAL'
    | 'PROMOTIONAL_ONBOARD_CREDIT'

export type Breakdown = Record<FareTypeName, BreakdownItem> | Record<string, never>

export type BreakdownPerPassenger =
    | Record<string, Breakdown | Record<string, never>>
    | Record<string, never>

export type BeddingConfiguration = {
    code: string
    description: string
}

export type Deck = {
    level: string
    code: string
    name: string
    image: string
}

export type Decks = Record<string, Deck>

export type Location = {
    frontMiddleBack: string
    insideOceanview: string
    sideOfShip: string
}

export type Amenity = {
    name: string
    description: string
}

export type Cabin = {
    accessibility: string
    beddingConfiguration: BeddingConfiguration[]
    deck: Deck
    description: string
    location: Location
    number: string
    obstructedView: string
    amenities: Amenity[]
    roomSize: string
    maxGuestCount: number
    minGuestCount: number
}

export type Cabins = Record<string, Cabin>

export type DiningOption = {
    availableInd: boolean
    code: string
    description: string
}

export type CabinGrade = {
    available: boolean
    balcony: boolean
    cabinType: string
    colorCode: string
    code: string
    description: string
    name: string
}

export type RateCode = {
    code: string
    description: string
    name: string
    rateType: string
    refundPolicy?: RefundPolicyType
    military?: boolean
    residency?: boolean
}

export class CabinContent {
    /** Object of all cabins including the guaranteed cabin*/
    readonly cabins: Record<string, Cabin>
    /** Guaranteed cabin, if it exists*/
    readonly guaranteedCabin: boolean
    /** list of all cabins names excluding the guaranteed cabin */
    readonly cabinNumbers: string[]
    /** name of the cruise*/
    readonly productName: string
    /** code of the cruise line */
    readonly supplierCode: string
    /** name of the cruise line */
    readonly supplierName: string
    /** name of the ship */
    readonly shipName: string
    /** date of embarkation - format dd/MM/yyyy */
    readonly embarkDate: string
    /** date of disembarkation - format dd/MM/yyyy */
    readonly disembarkDate: string
    /** number of days */
    readonly duration: string
    /** decks that cabins have been returned for */
    readonly decks: Record<string, Deck>
    /** list of all deck names */
    readonly deckNames: string[]
    /** list of all dining options */
    readonly diningOptions: DiningOption[]
    /** cabin grade object containing details of the cabin grade */
    readonly cabinGrade: CabinGrade
    /** rate code object containing details of the rate code */
    readonly rateCode: string
    readonly rateCodes: RateCode[]
    /** the default selected cabin number chosen at first render */
    readonly defaultSelectedCabinNumber: string | null
    /** the sailing id */
    readonly cruiseId: string
    /** pricing items per passenger */
    readonly breakdownPerPassenger: BreakdownPerPassenger
    readonly currency: string
    readonly totalGrossPrice: string
    readonly totalGrossPriceWithoutDecimal: number
    readonly totalOnboardCreditPrice: string
    readonly totalNonCommissionablePrice: string
    readonly commission: string
    constructor(cruiseData: Record<string, any>) {
        const { cabins, cabinNumbers, decks, deckNames, guaranteedCabin } = this.getCabinsDecks(
            cruiseData.priceItems[0].cabinGrade?.cabins
        )
        this.productName = cruiseData.product.name
        this.currency = CURRENCY_CODES[cruiseData.priceItems[0].currency]
        this.totalGrossPrice = insertDecimal2CharsFromEnd(cruiseData.priceItems[0].totalGrossPrice)
        this.totalGrossPriceWithoutDecimal = cruiseData.priceItems[0].totalGrossPrice // this is for submitting on cabin selection
        this.totalOnboardCreditPrice = insertDecimal2CharsFromEnd(
            cruiseData.priceItems[0].totalObcPrice
        )
        this.totalNonCommissionablePrice = insertDecimal2CharsFromEnd(
            cruiseData.priceItems[0].totalNcfPrice
        )
        this.commission = insertDecimal2CharsFromEnd(cruiseData.priceItems[0].commission)
        this.decks = decks
        this.cabins = cabins
        this.guaranteedCabin = guaranteedCabin
        this.deckNames = deckNames
        this.cruiseId = cruiseData.id
        this.cabinNumbers = cabinNumbers
        /** dining options are sorted based on code */
        this.diningOptions = this.getDiningOptions(cruiseData?.diningOptions?.dining)
        this.duration = cruiseData.duration
        this.shipName = cruiseData.ship.name
        this.supplierCode = cruiseData.ship.line.code
        this.supplierName = cruiseData.ship.line.name
        this.rateCode = cruiseData.priceItems[0].rateCode
        this.rateCodes = cruiseData.rateCodes
        this.embarkDate = getFormattedDate(cruiseData.embarkDate)
        this.disembarkDate = getFormattedDate(cruiseData.disembarkDate)
        this.cabinGrade = this.getCabinGrade(cruiseData.priceItems[0].cabinGrade)
        this.breakdownPerPassenger = getBreakDownItemsByPassenger(
            cruiseData.priceItems[0].breakdownItems
        )
        this.defaultSelectedCabinNumber = this.getDefaultSelectedCabinNumber(
            this.guaranteedCabin,
            this.cabins,
            this.cabinNumbers
        )
    }
    getDiningOptions(diningOptions: DiningOption[]): DiningOption[] {
        if (!diningOptions) return []
        const filteredArray = diningOptions.filter((option) => option.availableInd === true)

        const arrayForSorting = [...filteredArray]
        arrayForSorting.sort(
            (a: DiningOption, b: DiningOption) => parseInt(a.code) - parseInt(b.code)
        )
        return arrayForSorting
    }

    getDefaultSelectedCabinNumber(
        guaranteedCabin: boolean,
        cabins: Record<string, Cabin>,
        cabinNumbers: string[]
    ): string | null {
        const firstListedCabinNumber = cabins[cabinNumbers[0]]?.number
        const hasGuaranteedCabin = guaranteedCabin ? GUARANTEED : undefined

        return firstListedCabinNumber ?? hasGuaranteedCabin ?? null
    }

    /**
     * getCabinsDecks creates deck and cabin objects where each item is under a key of its name or number,
     * and creates arrays of these keys for both deckNames and cabinNumbers
     * @param { array }  cabins - an array containing all the cabins returned from api
     * @return { object } - object containing { cabins, cabinNumbers, decks, deckNames }
     */
    getCabinsDecks(cabins: Record<string, any>[]): {
        guaranteedCabin: boolean
        cabins: Cabins
        cabinNumbers: string[]
        decks: Decks
        deckNames: string[]
    } {
        const theCabins: Record<string, Cabin> = {}
        const theDecks: Record<string, Deck> = {}

        cabins?.forEach((cabin: Record<string, any>): void => {
            theCabins[cabin.number] = {
                accessibility: cabin.accessibility,
                beddingConfiguration: cabin.beddingConfiguration,
                deck: cabin.deck,
                description: cabin.description,
                location: cabin.location,
                number: cabin.number,
                obstructedView: cabin.obstructedView,
                amenities: cabin.amenity,
                roomSize: cabin.roomSize,
                maxGuestCount: cabin.maxGuestCount,
                minGuestCount: cabin.minGuestCount,
            }
            if (cabin.number !== GUARANTEED) theDecks[cabin.deck.name] = cabin.deck
        })

        const cabinNumbersWithoutGuar = Object.keys(theCabins)
            .filter((name: string) => name !== GUARANTEED)
            .sort((a: string, b: string): number => {
                return a.localeCompare(b)
            })

        const deckNames = Object.keys(theDecks).sort()

        return {
            guaranteedCabin: !!theCabins[GUARANTEED] || false,
            cabins: theCabins,
            cabinNumbers: cabinNumbersWithoutGuar,
            decks: theDecks,
            deckNames: deckNames,
        }
    }

    /** get the cabin grade of a cabin */
    getCabinGrade(cabinGrade: CabinGrade): CabinGrade {
        return {
            balcony: cabinGrade.balcony,
            cabinType: cabinGrade.cabinType,
            colorCode: cabinGrade.colorCode,
            code: cabinGrade.code,
            description: cabinGrade.description,
            name: cabinGrade.name,
            available: cabinGrade.available,
        }
    }
}
