export type SalesChannel = {
    salesChannelId: string
    salesChannelTitle: string
}

export type SalesChannelFromApi = {
    salesChannelId: string
    title: string
}

export type Group = {
    groupTitle: string
    groupId: string
    salesChannels: SalesChannel[]
    phoneCallingCode?: string
    phoneNumber?: string
    phoneIso2Code?: string
}

type PhoneNumber = {
    callingCode: string
    iso2Code: string
    number: string
}

export type GroupFromApi = {
    title: string
    userGroupId: string
    salesChannels: SalesChannelFromApi[]
    phone?: PhoneNumber
}

export const GroupsContentFunction = (groupsData: GroupFromApi[]): Group[] => {
    return groupsData.map((group: GroupFromApi) => ({
        groupTitle: group.title,
        groupId: group.userGroupId,
        salesChannels: group.salesChannels.map(
            (salesChannel: SalesChannelFromApi): SalesChannel => ({
                salesChannelId: salesChannel.salesChannelId,
                salesChannelTitle: salesChannel.title,
            })
        ),
        phoneCallingCode: group.phone?.callingCode,
        phoneNumber: group.phone?.number,
        phoneIso2Code: group.phone?.iso2Code,
    }))
}
