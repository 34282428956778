import React, { useState, useEffect, useMemo } from 'react'

import Breadcrumb from 'components/basics/Breadcrumb/Breadcrumb'
import FilterSection from '../../../sections/cruise/FilterSection/FilterSection'
import ModifySearchBar from '../../../sections/cruise/ModifySearchBar/ModifySearchBar'
import PaginatedResultsList from '../../../sections/cruise/PaginatedResultsList/PaginatedResultsList'
import useFilterCheckbox from '../../../hooks/useFilterCheckbox'

import { Cruise, CruisesMetaData, DO_NOT_FILTER_OUT } from 'api-data-models/CruisesContentModel'
import { MetaDataItem } from 'api-data-models/cruises-api-types'
import { findMinMaxPrice } from './find-min-max-price'
import { ROUTES } from 'components/sections/app/AppRoutes'
import * as self from './ResultsLayout'
import allContent from 'content/content'
import styles from './ResultsLayout.module.css'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import { DestinationCategory } from '../../../sections/cruise/CategoryIndicator/CategoryIndicator'

const content = allContent.cruise.resultsPage
const breadcrumbContent = allContent.app.breadcrumbs

type FilterCruisesProps = {
    selectedSupplierNames: string[]
    selectedShipNames: string[]
    selectedCabinTypes: string[]
    cruises: Cruise[]
    cruiseNameFilterString: string
    departurePortFilterStringList: string[]
    arrivalPortFilterStringList: string[]
    selectedMinPrice: number
    selectedMaxPrice: number
}

/** filterCruises: a function that filters search results based on parameters set by filterOptions
 @param { object } selectedSupplierNames - an array of Cruise Lines that have been checked, each value is a cruise line name to be filtered IN
 @param { object } selectedShipNames - an array of Ship Names that have been checked, each value is a ship name to be filtered IN
 @param { object } selectedCabinTypes - an array of Cabin Types that have been checked, each value is a cabin type to be filtered IN
 @param { array } cruises - the api cruises having gone through our cruise model
 @param { string } cruiseNameFilterString - the cruise title string which to match filtered cruise titles with
 @param { number } selectedMinPrice - currently selected min price range to keep
 @param { string } departurePortFilterString - the departure port string which to match filtered departure ports with
 @param { number } selectedMaxPrice - currently selected max price range to keep
 */
export const filterCruises = ({
    selectedSupplierNames,
    selectedShipNames,
    selectedCabinTypes,
    selectedMinPrice,
    selectedMaxPrice,
    cruiseNameFilterString,
    departurePortFilterStringList,
    arrivalPortFilterStringList,
    cruises,
}: FilterCruisesProps): any[] => {
    const filteredResults: Cruise[] = [] // start with no cruises
    cruises.forEach((cruise: Cruise) => {
        let keepCruise // default is false
        const lowestCruisePrice = cruise.pricing.fromPrice // we created fromPrice in results model using smallest cabinTypingPrice.fare or the string 'Do Not Filter Out' if no prices.

        // 1) First only keep cruises whose price falls within minSelectedPrice and MaxSelectedPrice
        // NOTE: fromPrice is in pence/cents at this stage, so need divide by 100 to work with priceFilter as selected max/min are in whole dollars/pounds.
        if (lowestCruisePrice === DO_NOT_FILTER_OUT) {
            // if fromPrice is string DO_NOT_FILTER_OUT we wish to show, even without prices, so a message 'call to book' or something can be shown.
            keepCruise = true
        } else if (typeof lowestCruisePrice === 'number') {
            const cruisePrice = Math.round(lowestCruisePrice / 100)
            keepCruise = selectedMinPrice <= cruisePrice && cruisePrice <= selectedMaxPrice
        }

        // 2) If cruise is still in the game, check the cruise line matches the list (only if at least one has been checked)
        if (keepCruise && selectedSupplierNames.length > 0) {
            keepCruise = selectedSupplierNames.includes(cruise.supplierName)
        }

        // 3) If cruise is still in the game, check the ship name matches the list (only if at least one has been checked)
        if (keepCruise && selectedShipNames.length > 0) {
            keepCruise = selectedShipNames.includes(cruise.shipName)
        }

        // 4) If cruise is still in the game, check if the cabin types matching the list (only if at least one has been checked)
        if (keepCruise && selectedCabinTypes.length > 0) {
            const availableCabinTypes = cruise.pricing.cabinTypePricing
                .filter((cabinType: Record<string, any>) => cabinType.available)
                .map((cabinType: Record<string, any>) => cabinType.cabinType)
            keepCruise = !!selectedCabinTypes.filter((cabinType) => {
                return availableCabinTypes.includes(cabinType)
            }).length
        }

        if (keepCruise && cruiseNameFilterString.length > 0) {
            const cruiseTitle = cruise.productName.toLowerCase()
            keepCruise = cruiseTitle.includes(cruiseNameFilterString.toLowerCase())
        }
        if (keepCruise && departurePortFilterStringList.length > 0) {
            const firstPort = cruise.itinerary.portCodesWithNamesAndDaysAndTimes[0]
            if (firstPort?.portName) {
                const departurePortName = firstPort.portName.toLowerCase()
                keepCruise = departurePortFilterStringList.some((subStr) =>
                    departurePortName.includes(subStr.toLowerCase())
                )
            }
        }
        if (keepCruise && arrivalPortFilterStringList.length > 0) {
            const lastPortIndex = cruise.itinerary.portCodesWithNamesAndDaysAndTimes.length - 1
            const lastPort = cruise.itinerary.portCodesWithNamesAndDaysAndTimes[lastPortIndex]
            if (lastPort?.portName) {
                const arrivalPortName = lastPort.portName.toLowerCase()
                keepCruise = arrivalPortFilterStringList.some((subStr) =>
                    arrivalPortName.includes(subStr.toLowerCase())
                )
            }
        }
        if (keepCruise) filteredResults.push(cruise)
    })
    return filteredResults
}

type ResultsProps = {
    loading: boolean
    /** array of unique arrival ports names and hits */
    arrivalPorts: MetaDataItem[]
    /** array of unique departure ports names and hits*/
    departurePorts: MetaDataItem[]
    /** array of product names to be used in the filter section */
    cruiseProductNames: string[]
    /** array of result items from getCruises */
    cruises: Cruise[]
    queryParams: Record<string, any>
    /** call back function for modify search bar to pass new search input values as urlQueryParams*/
    handleModifySearch(paramsString: string): void
    /** error returned from the api call */
    apiError: CustomApiError[] | null
    /** callback to trigger a new search query */
    makeNewSearch(): void
    /** metadata used for auto suggest options for destinatoin */
    cruisesMetaData: CruisesMetaData
    /** For saved params (pre-populated form), need to know the category to submit string value to api - or use 'destination' as default */
    prePopulateDestinationCategory?: DestinationCategory
}

/** ResultsLayout: Layout for the Results page */
const ResultsLayout: React.FC<ResultsProps> = ({
    loading,
    departurePorts,
    arrivalPorts,
    cruiseProductNames,
    cruises,
    queryParams,
    handleModifySearch,
    apiError,
    makeNewSearch,
    cruisesMetaData,
}: ResultsProps) => {
    const [selectedSupplierNames, handleSelectedSupplierNames] = useFilterCheckbox([])
    const [selectedShipNames, handleSelectedShipNames] = useFilterCheckbox([])
    const [selectedCabinTypes, handleSelectedCabinTypes] = useFilterCheckbox([])
    const [cruiseNameFilterString, setCruiseNameFilterString] = useState('')
    const [departurePortFilterStringList, setDeparturePortFilterStringList] = useState<string[]>([])
    const [arrivalPortFilterStringList, setArrivalPortFilterStringList] = useState<string[]>([])
    const initialMinMaxPrice = useMemo(() => findMinMaxPrice(cruises), [cruises])
    const [minPrice, setMinPrice] = useState(initialMinMaxPrice[0])
    const [maxPrice, setMaxPrice] = useState(initialMinMaxPrice[1])
    const [selectedMinPrice, setSelectedMinPrice] = useState(initialMinMaxPrice[0])
    const [selectedMaxPrice, setSelectedMaxPrice] = useState(initialMinMaxPrice[1])

    /** Onload, set min and max price from all the cruises returned in the api result */
    useEffect(() => {
        setSelectedMinPrice(initialMinMaxPrice[0])
        setSelectedMaxPrice(initialMinMaxPrice[1])
        setMinPrice(initialMinMaxPrice[0])
        setMaxPrice(initialMinMaxPrice[1])
    }, [cruises, initialMinMaxPrice])

    const filteredCruises = self.filterCruises({
        selectedSupplierNames,
        selectedShipNames,
        selectedCabinTypes,
        cruises,
        cruiseNameFilterString,
        selectedMinPrice,
        selectedMaxPrice,
        departurePortFilterStringList,
        arrivalPortFilterStringList,
    })

    function clearFilters(): void {
        handleSelectedSupplierNames('')
        handleSelectedShipNames('')
        handleSelectedCabinTypes('')
        setSelectedMinPrice(initialMinMaxPrice[0])
        setSelectedMaxPrice(initialMinMaxPrice[1])
        setCruiseNameFilterString('')
        setDeparturePortFilterStringList([])
        setArrivalPortFilterStringList([])
    }

    useEffect(() => {
        /** Clear filters when loading new search results - needed when doing another search with modify search bar */
        if (loading) {
            clearFilters()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]) // do not add others - infinite loops!

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Breadcrumb
                    urlList={[
                        { text: breadcrumbContent.search, url: ROUTES.CRUISE_SEARCH },
                        { text: breadcrumbContent.results },
                    ]}
                />
            </div>
            <ModifySearchBar
                prePopulateDestinationCategory={queryParams?.destinationInputCategory}
                queryParams={queryParams}
                handleModifySearch={handleModifySearch}
                makeNewSearch={makeNewSearch}
                cruisesMetaData={cruisesMetaData}
            />
            {loading ? (
                <LargeSpinner text={content.fetchingResults} />
            ) : (
                <>
                    {apiError && <ErrorList errorsList={apiError} source='cruise-results-page' />}
                    {!apiError && cruises.length === 0 && <p>{content.emptyResults}</p>}
                    {!apiError && cruises.length > 0 && (
                        <div className={styles.main}>
                            <FilterSection
                                clearFilters={clearFilters}
                                arrivalPortsFilterState={[
                                    arrivalPortFilterStringList,
                                    setArrivalPortFilterStringList,
                                ]}
                                arrivalPorts={arrivalPorts}
                                departurePortsFilterState={[
                                    departurePortFilterStringList,
                                    setDeparturePortFilterStringList,
                                ]}
                                departurePorts={departurePorts}
                                cruiseProductNames={cruiseProductNames}
                                cruiseProductNameFilterState={[
                                    cruiseNameFilterString,
                                    setCruiseNameFilterString,
                                ]}
                                cruises={cruises}
                                supplierNameState={[
                                    selectedSupplierNames,
                                    handleSelectedSupplierNames,
                                ]}
                                shipNameState={[selectedShipNames, handleSelectedShipNames]}
                                cabinTypeState={[selectedCabinTypes, handleSelectedCabinTypes]}
                                setSelectedMinPrice={setSelectedMinPrice}
                                setSelectedMaxPrice={setSelectedMaxPrice}
                                minPrice={minPrice}
                                maxPrice={maxPrice}
                            />
                            <PaginatedResultsList
                                filteredItems={filteredCruises}
                                totalNumberOfCruises={cruises.length}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default ResultsLayout
