import React from 'react'
import { useForm, Controller } from 'react-hook-form'

import Button from 'components/basics/Button/Button'
import Checkbox from 'components/basics/Input/Checkbox/Checkbox'
import FieldError from 'components/basics/FieldError/FieldError'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import InlineSpinner from 'components/basics/Spinners/InlineSpinner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Modal from 'components/blocks/Modal/Modal'
import Select from 'components/basics/Input/Select/Select'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import Tooltip from 'components/basics/Tooltip/Tooltip'

import { REGEX, USER_ROLES } from 'utils/constants'
import { Group } from 'api-data-models/admin/GroupsContentModel'
import { User } from 'api-data-models/admin/UsersContentModel'

import styles from './UpdateUserModal.module.css'
import allContent from 'content/content'
import { datadogLogs } from '@datadog/browser-logs'
import { extractUserDataFields } from '../../../../../utils/user-data-helpers/extract-user-data-fields'

const content = allContent.admin.adminLanding.agentConnectCruise.updateUserModal

export type FormData = {
    emailAddress?: string
    firstName?: string
    lastName?: string
    isAdmin: boolean
    groupId?: string
    userId?: string
}

type FormProps = {
    fetchingGroups: boolean
    userDataForUpdate: User | null
    groupListData: Group[]
    handleUpdateUser: (params: {
        userDetailsData: UpdatedUserDetailsData | null
        userGroupData: UpdatedUserGroupData | null
    }) => void
    isSubmitting: boolean
    onClose: () => void
}

const Form: React.FC<FormProps> = ({
    fetchingGroups,
    userDataForUpdate,
    handleUpdateUser,
    onClose,
    isSubmitting,
    groupListData,
}): React.ReactElement => {
    const userContext = datadogLogs.getGlobalContext()
    const { emailAddress: loggedInUserEmail } = extractUserDataFields(
        userContext as GlobalContextUserData
    )

    const groupsOptions = groupListData.map((group) => {
        return {
            text: group.groupTitle,
            value: group.groupId,
        }
    })
    const currentGroupId = userDataForUpdate?.userGroups.length
        ? userDataForUpdate?.userGroups[0].groupId
        : ''

    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields },
    } = useForm({
        defaultValues: {
            firstName: userDataForUpdate?.firstName,
            lastName: userDataForUpdate?.lastName,
            emailAddress: userDataForUpdate?.emailAddress,
            isAdmin: !!userDataForUpdate?.userRoles?.includes(USER_ROLES.ADMIN),
            groupId: currentGroupId,
            userId: userDataForUpdate?.userId,
        },
    })
    const disableSubmit = Object.keys(dirtyFields).length === 0
    return (
        <form
            className={styles.form}
            onSubmit={handleSubmit((formData: FormData): void => {
                const hasUserDataChanged = Boolean(
                    dirtyFields.firstName ||
                        dirtyFields.lastName ||
                        dirtyFields.emailAddress ||
                        dirtyFields.isAdmin
                )
                const hasUserGroupDataChanged = Boolean(dirtyFields.groupId)

                let userData: UpdatedUserDetailsData | null = null
                let groupData: UpdatedUserGroupData | null = null
                if (hasUserDataChanged && formData.userId && formData.emailAddress) {
                    userData = {
                        userId: formData.userId,
                        emailAddress: formData.emailAddress,
                        firstName: formData.firstName,
                        userRoles: formData.isAdmin
                            ? [USER_ROLES.ADMIN, USER_ROLES.AGENT]
                            : [USER_ROLES.AGENT],
                        lastName: formData.lastName,
                    }
                }
                if (hasUserGroupDataChanged && formData.userId && formData.groupId) {
                    groupData = {
                        userId: formData.userId,
                        groupId: formData.groupId,
                    }
                }
                if (!hasUserDataChanged && !hasUserGroupDataChanged) {
                    return // Exit early if no data has changed
                }
                handleUpdateUser({ userDetailsData: userData, userGroupData: groupData })
            })}
        >
            <p>
                <Text>{content.text1}</Text>
            </p>
            <Controller
                control={control}
                name='firstName'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                    pattern: REGEX.NAME,
                }}
                render={({ field: { onBlur, onChange, value } }): React.ReactElement => (
                    <div>
                        <LabelledInput
                            required={true}
                            htmlFor='first-name'
                            label={content.firstName}
                            aria-describedby={content.firstName}
                            disabled={isSubmitting}
                            isErrored={!!errors.firstName}
                        >
                            <TextInput
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                required={true}
                            />
                        </LabelledInput>
                        <FieldError
                            inputId='first-name'
                            showError={!!errors.firstName}
                            errorMessage={content.errors.firstNameInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='lastName'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                    pattern: REGEX.NAME,
                }}
                render={({ field: { onBlur, onChange, value } }): React.ReactElement => (
                    <div>
                        <LabelledInput
                            required={true}
                            htmlFor='last-name'
                            label={content.lastName}
                            aria-describedby={content.lastName}
                            disabled={isSubmitting}
                            isErrored={!!errors.lastName}
                        >
                            <TextInput
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                required={true}
                            />
                        </LabelledInput>
                        <FieldError
                            inputId='last-name'
                            showError={!!errors.lastName}
                            errorMessage={content.errors.lastNameInput}
                        />
                    </div>
                )}
            />

            <LabelledInput
                htmlFor='email-address'
                label={content.email}
                aria-describedby={content.email}
                disabled
                isErrored={!!errors.emailAddress}
            >
                <TextInput value={userDataForUpdate?.emailAddress} disabled />
            </LabelledInput>
            <Controller
                control={control}
                name='isAdmin'
                render={({ field: { onBlur, onChange, value } }): React.ReactElement => {
                    return (
                        <div>
                            <Checkbox
                                // Do not let current user edit their Admin role to anything else (in-case they are the only admin and change to Agent role)
                                disabled={
                                    isSubmitting ||
                                    userDataForUpdate?.emailAddress === loggedInUserEmail
                                }
                                isErrored={!!errors.isAdmin}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                text={value ? content.isAdminLabelText : content.makeAdminLabelText}
                            />

                            <FieldError
                                inputId='is-admin'
                                showError={!!errors.isAdmin}
                                errorMessage={content.errors.isAdmin}
                            />
                        </div>
                    )
                }}
            />
            <Controller
                control={control}
                name='groupId'
                rules={{
                    required: true,
                }}
                render={({ field: { onBlur, onChange, value } }): React.ReactElement => {
                    return (
                        <div>
                            <LabelledInput
                                required={true}
                                htmlFor='group-id'
                                label={content.groupsLabelText}
                                aria-describedby={content.groupsLabelText}
                                disabled={isSubmitting}
                                isErrored={!!errors.groupId}
                            >
                                {!fetchingGroups ? (
                                    <Select
                                        placeholder={content.groupsPlaceHolderText}
                                        options={groupsOptions}
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        required={true}
                                    />
                                ) : (
                                    <div className={styles['spinner']}>
                                        <InlineSpinner
                                            // circleOnRight={true} // TODO: look into this, is it still needed or OK without
                                            text={content.fetchingGroupsData}
                                        />
                                    </div>
                                )}
                            </LabelledInput>
                            <FieldError
                                inputId='group-id'
                                showError={!!errors.groupId}
                                errorMessage={content.errors.groupsId}
                            />
                        </div>
                    )
                }}
            />
            <div className={styles['button-wrapper']}>
                <Button
                    type='button'
                    flavour='tertiary'
                    text={content.cancelButton}
                    onClick={onClose}
                    disabled={isSubmitting}
                />
                {disableSubmit ? (
                    <Tooltip id='update-details' content={'Update details'} position='bottom'>
                        <Button
                            text={isSubmitting ? content.submittingButton : content.confirmButton}
                            type='submit'
                            showSpinner={isSubmitting}
                            disabled={disableSubmit}
                        />
                    </Tooltip>
                ) : (
                    <Button
                        text={isSubmitting ? content.submittingButton : content.confirmButton}
                        type='submit'
                        showSpinner={isSubmitting}
                        disabled={disableSubmit}
                    />
                )}
            </div>
        </form>
    )
}

export type UpdatedUserDetailsData = {
    emailAddress: string
    firstName?: string
    lastName?: string
    userRoles: UserRole[]
    userId: string
}

export type UpdatedUserGroupData = {
    userId: string
    groupId: string
}

type UpdateUserModalProps = {
    isOpen: boolean
    isSubmitting: boolean
    userDataForUpdate: User | null
    handleUpdateUser: (params: {
        userDetailsData: UpdatedUserDetailsData | null
        userGroupData: UpdatedUserGroupData | null
    }) => void
    onClose: () => void
    fetchingGroups: boolean
    groupsListData: Group[]
    groupsApiErrorMessage: string | null
}

const UpdateUserModal: React.FC<UpdateUserModalProps> = ({
    isOpen,
    isSubmitting,
    onClose,
    handleUpdateUser,
    userDataForUpdate,
    fetchingGroups,
    groupsListData,
    groupsApiErrorMessage,
}): React.ReactElement => {
    return (
        <Modal
            headerText={content.title}
            returnFocusId=''
            isOpen={isOpen}
            setClosed={onClose}
            className={styles.wrapper}
        >
            {groupsApiErrorMessage && (
                <InfoBanner
                    bannerType='error'
                    text={groupsApiErrorMessage}
                    id='group-api-error-banner'
                />
            )}
            {isOpen && userDataForUpdate !== null && (
                <Form
                    fetchingGroups={fetchingGroups}
                    userDataForUpdate={userDataForUpdate}
                    groupListData={groupsListData}
                    handleUpdateUser={handleUpdateUser}
                    isSubmitting={isSubmitting}
                    onClose={onClose}
                />
            )}
        </Modal>
    )
}
export default UpdateUserModal
