import {
    AmplifyUserData,
    HALF_SESSION_EXPIRY_MS,
    HostedUserData,
} from './check-and-perform-user-session-refresh-if-needed'
import allContent from '../../content/content'

const content = allContent.app.errors

// Type guard to check if userData is of type AmplifyUserData
const isAmplifyUserDataWithRefreshToken = (userData: any): userData is AmplifyUserData => {
    return (userData as AmplifyUserData).user?.signInUserSession?.refreshToken !== undefined
}

// Type guard to check if userData is of type HostedUserData
const isHostedUserData = (userData: any): userData is HostedUserData => {
    return (userData as HostedUserData)?.refresh_token !== undefined
}

export async function shouldUserSessionBeRefreshed(
    userData: AmplifyUserData | HostedUserData | undefined
): Promise<boolean> {
    if (!userData) {
        // If session data is missing, then it has expired - send user to Timeout Page
        return Promise.reject(new Error(content.timeout))
    }

    // Do not refresh if user is Hosted UX user
    if (isHostedUserData(userData)) {
        return Promise.resolve(false)
    }

    const timeTillExpiryMs = userData.expiry - Date.now()
    // Only refresh Amplify user data that is over halfway through its session
    if (isAmplifyUserDataWithRefreshToken(userData)) {
        if (timeTillExpiryMs > HALF_SESSION_EXPIRY_MS) {
            return Promise.resolve(false)
        } else {
            return Promise.resolve(true)
        }
    }
    // If above are not met then had Amplify data but not with refresh token?
    return Promise.reject(new Error(content.noRefreshTokenFound))
}
