import React, { useRef, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'

import FieldsRequired from 'components/basics/FieldsRequired/FieldsRequired'
import Button from 'components/basics/Button/Button'
import FieldError from 'components/basics/FieldError/FieldError'
import Heading from 'components/basics/Heading/Heading'
import InfoBanner from '../../../blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Link from 'components/basics/Link/Link'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import TempPasswordForm from './SaasTempPasswordForm/TempPasswordForm'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { SIGNUP } from 'graphql-queries/app-queries'
import { COMPANY_TIER_NAMES, COMPANY_TIERS, COMPANY_TYPE, REGEX, USER_ROLES } from 'utils/constants'

import styles from './SaaSSignupForm.module.css'
import allContent from 'content/content'

const content = allContent.signUpPage

type SaaSSignUpFormDataType = {
    firstName: string
    lastName: string
    emailAddress: string
    contactNumberCallingCode: string
    contactNumber: string
    companyName: string
    companyLocation: string
    companyType: string
}

type SassSignUpVariablesType = {
    firstName: string
    lastName: string
    emailAddress: string
    contactNumber: string
    companyName: string
    companyLocation: string
    companyType: string
    companyTier: string
    userRoles: UserRole[]
}

type SaaSSignupFormProps = {
    isAuthorised: boolean | null
    signUpClient: ApolloClient<NormalizedCacheObject>
}
const SaaSSignUpForm: React.FC<SaaSSignupFormProps> = ({ isAuthorised, signUpClient }) => {
    const [successfulSignup, setSuccessfulSignup] = useState<boolean>(false)
    const [signUpError, setSignUpError] = useState<string | null>(null)
    const [isSubmittingSignUp, setIsSubmittingSignUp] = useState(false)
    const submittedEmailAddress = useRef('')

    // Get the tier passed from Traveltek website and map with constants
    const params = new URLSearchParams(window.location.search)
    const tierParam = params.get('tier')?.toUpperCase()

    // TODO: make this app level state or local storage or something...
    const companyTier = useRef(COMPANY_TIERS.CRUISE_STARTER)

    useEffect(() => {
        if (tierParam && COMPANY_TIER_NAMES.includes(tierParam as CompanyTiers)) {
            companyTier.current = tierParam as CompanyTiers
        } else {
            datadogLogs.logger.info(
                `SaaS Sign up page, loaded without tier param. Defaulted to ${COMPANY_TIERS.CRUISE_STARTER}`
            )
        }
    }, [tierParam])

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<SaaSSignUpFormDataType>({
        mode: 'onBlur',
        reValidateMode: 'onChange', // only comes into effect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            contactNumber: '',
            companyName: '',
        },
    })

    const SaaSSignUpForm = (
        <form
            className={styles.form}
            onSubmit={handleSubmit(
                async ({
                    firstName,
                    lastName,
                    emailAddress,
                    companyName,
                }: SaaSSignUpFormDataType) => {
                    setIsSubmittingSignUp(true)
                    setSignUpError(null)
                    const SaaSSignupVariables: SassSignUpVariablesType = {
                        firstName: firstName,
                        lastName: lastName,
                        emailAddress: emailAddress,
                        contactNumber: `+19679041869`, // TODO: Remove these once API stops requiring them
                        companyName: companyName,
                        companyLocation: 'US', // TODO: Remove these once API stops requiring them
                        companyType: COMPANY_TYPE.TRAVELAGENT, // TODO: Remove these once API stops requiring them
                        companyTier: companyTier.current,
                        userRoles: [USER_ROLES.ADMIN, USER_ROLES.AGENT],
                    }
                    submittedEmailAddress.current = emailAddress
                    await signUpClient
                        .mutate({ mutation: SIGNUP, variables: SaaSSignupVariables })
                        .then((response) => {
                            if (response) {
                                setSuccessfulSignup(true)
                                datadogLogs.logger.info(
                                    `SassSignup successfulSignup for: ${companyName} with email: ${emailAddress}`
                                )
                            }
                            setIsSubmittingSignUp(false)
                            reset() // clear form data if successfulSignup.
                        })
                        .catch((error) => {
                            let errorMessage = ''
                            switch (error.message) {
                                case 'This company name is already in use. Please select another one.':
                                    errorMessage = content.signUpForm.errors.companyError
                                    break
                                case 'An Account with this email already exists.':
                                    errorMessage = content.signUpForm.errors.emailError
                                    break
                                default:
                                    errorMessage = content.signUpForm.errors.signupError
                            }
                            setSignUpError(errorMessage)
                            datadogLogs.logger.error(
                                `Mutate SIGNUP SaaSSignUpForm - userEmail: ${emailAddress}, error: ${error.message}`
                            )
                            setIsSubmittingSignUp(false)
                        })
                }
            )}
        >
            <Controller
                control={control}
                name='firstName'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                    pattern: REGEX.NAME,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='first-name'
                            label={content.signUpForm.firstNameInput}
                            isErrored={!!errors.firstName}
                            disabled={isSubmittingSignUp}
                            aria-describedby='first-name-error-message'
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='first-name'
                            showError={!!errors.firstName}
                            errorMessage={content.signUpForm.errors.firstNameInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='lastName'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                    pattern: REGEX.NAME,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='last-name'
                            label={content.signUpForm.lastNameInput}
                            aria-describedby='last-name-error-message'
                            isErrored={!!errors.lastName}
                            disabled={isSubmittingSignUp}
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='last-name'
                            showError={!!errors.lastName}
                            errorMessage={content.signUpForm.errors.lastNameInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='companyName'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 30,
                    pattern: REGEX.COMPANY_NAME,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='company-name'
                            label={content.signUpForm.companyNameInput}
                            aria-describedby='company-name-error-message'
                            isErrored={!!errors.companyName}
                            disabled={isSubmittingSignUp}
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='company-name'
                            showError={!!errors.companyName}
                            errorMessage={content.signUpForm.errors.companyNameInput}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='emailAddress'
                rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 300,
                    pattern: REGEX.EMAIL,
                }}
                render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                    <div className={styles['input-wrapper']}>
                        <LabelledInput
                            required={true}
                            htmlFor='email'
                            label={content.signUpForm.emailInput}
                            aria-describedby='email-error-message'
                            isErrored={!!errors.emailAddress}
                            disabled={isSubmittingSignUp}
                        >
                            <TextInput value={value} onChange={onChange} onBlur={onBlur} />
                        </LabelledInput>
                        <FieldError
                            inputId='email'
                            showError={!!errors.emailAddress}
                            errorMessage={content.signUpForm.errors.emailInput}
                        />
                    </div>
                )}
            />
            <div className={styles['submit-login-wrapper']}>
                <div className={styles['submit-login-container']}>
                    <FieldsRequired />
                    <Button
                        type='submit'
                        flavour='primary'
                        text={
                            isSubmittingSignUp
                                ? content.signUpForm.submitButtonProcessing
                                : content.signUpForm.submitButton
                        }
                        showSpinner={isSubmittingSignUp}
                    />
                    <p className={styles['login-text']}>
                        {content.signUpForm.loginText}
                        <Link to={ROUTES.LOGIN} bold={true}>
                            {content.signUpForm.loginLinkText}
                        </Link>
                    </p>
                </div>
            </div>
            <div className={styles['signup-error-message']}>
                {signUpError && (
                    <InfoBanner bannerType='error' text={signUpError} id='submit-api-error' />
                )}
            </div>
        </form>
    )

    return (
        <div className={styles.container}>
            {isAuthorised && <p>{content.loggedOnMessage}</p>}
            <Heading heading='1'>
                {successfulSignup ? content.tempPasswordForm.successSignUpMessage1 : content.title}
            </Heading>
            <Text>
                {successfulSignup
                    ? content.tempPasswordForm.successSignUpMessage2
                    : content.signUpForm.subHeading}
            </Text>
            {!successfulSignup && SaaSSignUpForm}
            {successfulSignup && <TempPasswordForm userEmail={submittedEmailAddress.current} />}
        </div>
    )
}

export default SaaSSignUpForm
