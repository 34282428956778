import React, { useEffect, useState } from 'react'

import ResultsList from '../ResultsList/ResultsList'
import ReactPaginate from 'react-paginate'
import { Cruise } from 'api-data-models/CruisesContentModel'

import styles from './PaginatedResultsList.module.scss'
import allContent from 'content/content'

const content = allContent.cruise.resultsPage.pagination

const DEFAULT_ITEMS_PER_PAGE = 15

type PaginatedResultsListProps = {
    filteredItems: Cruise[]
    totalNumberOfCruises: number
}

const PaginatedResultsList: React.FC<PaginatedResultsListProps> = ({
    filteredItems,
    totalNumberOfCruises,
}) => {
    const [itemOffset, setItemOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE)
    const [filteredItemCount, setFilteredItemCount] = useState(filteredItems.length)

    // We need to track when filters are applied and then to update the number/count of items and update which set of pages to display with itemOffset
    useEffect(() => {
        setFilteredItemCount(filteredItems.length)
        setItemOffset(0)
    }, [filteredItems])

    const endOffset = itemOffset + itemsPerPage
    const currentItems = filteredItems.slice(itemOffset, endOffset)
    const pageCount = Math.ceil(filteredItemCount / itemsPerPage)

    const handlePageClick = (event: any): void => {
        const newOffset = (event.selected * itemsPerPage) % filteredItems.length
        setItemOffset(newOffset)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    const ariaLabel = (): string => `pages-${itemOffset}-to-${endOffset}`

    return (
        <div className={styles.container}>
            <ResultsList
                cruisesToDisplay={currentItems}
                totalNumberOfCruises={totalNumberOfCruises}
                filteredAmountOfCruises={filteredItems.length}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
            />
            <ReactPaginate
                className={styles['controller-container']}
                breakLabel='...'
                nextLabel=' >'
                previousAriaLabel={content.previousPageButtonText}
                previousLabel='< '
                pageRangeDisplayed={3}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                pageClassName={styles.buttons}
                activeClassName={styles['active-item']}
                previousClassName={styles.buttons}
                nextClassName={styles.buttons}
                previousLinkClassName={styles.buttons}
                nextLinkClassName={styles.buttons}
                ariaLabelBuilder={ariaLabel}
            />
        </div>
    )
}

export default PaginatedResultsList
