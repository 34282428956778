import { datadogLogs } from '@datadog/browser-logs'

/** error returned from the REST response - usually API returns an array of these errors **/
type RestApiResponseErrorDetail = {
    name: string
    message: string
    /** input field that caused an error **/
    input: string
    /** error location in API services **/
    loc: string[]
    /** error message **/
    msg: string
    /** error type **/
    type: string
}

export type RestApiResponseError = {
    /** error body **/
    body: {
        detail: RestApiResponseErrorDetail[] | string
        error: {
            message: string
            code: number
            details: string
        }
    }
    /** HTTP status code **/
    status?: number
}

export function getRestErrorMessage({
    errors,
    source,
    variables = {},
}: {
    errors: RestApiResponseError
    source: string
    variables?: Record<string, any>
}): CustomApiError[] {
    const userContext = datadogLogs.getGlobalContext()
    if (typeof errors?.body?.detail === 'string') {
        return [
            {
                name: source,
                id: 0,
                error_description: errors.body.detail,
                error_code: 0,
                error_details: [
                    {
                        source: source,
                        code: '0',
                        message: errors.body.detail,
                    },
                ],
            },
        ]
    } else if (typeof errors?.body?.error?.message === 'string') {
        return [
            {
                name: source,
                id: 0,
                error_description: errors.body.error.message,
                error_code: 0,
                error_details: [
                    {
                        source: source,
                        code: '0',
                        message: errors.body.error.message,
                    },
                ],
            },
        ]
    }

    return errors.body?.detail?.map((error, index) => {
        datadogLogs.logger.error(
            `Response (rest) source: ${source}, REST-request-variables: ${JSON.stringify(
                variables
            )}, error-message: ${JSON.stringify(error)}`,
            { userContext },
            error
        )
        return {
            name: source,
            id: index++,
            error_description: error.msg,
            error_code: index,
            error_details: [
                {
                    source: error.input,
                    code: (errors.status ?? index).toString(),
                    message: error.type,
                    path: error.loc.join(', '),
                },
            ],
        }
    })
}
