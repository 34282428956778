import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'components/sections/app/AppRoutes'

import Text from 'components/basics/Text/Text'
import Button from 'components/basics/Button/Button'

import styles from './FeedbackBanner.module.css'
import allContent from 'content/content'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'
import { VITALLY_EVENTS } from 'utils/constants'
import Icon from 'components/basics/Icon/Icon'
const content = allContent.feedbackBanner

const FeedbackBanner: React.FC = () => {
    const navigate = useNavigate()
    const handleButtonClick = (): void => {
        navigate(ROUTES.FEATURE_PORTAL)
        CustomerSuccess.track({
            eventName: VITALLY_EVENTS.FEATURE_PORTAL_BANNER_OPEN,
            properties: {
                page: window.location.pathname,
            },
        })
    }
    return (
        <div className={styles['container']}>
            <div className={styles['content-row']}>
                <div className={styles['content-row']}>
                    <Icon iconName='Bullhorn' iconColor='pink' iconSize='XL' />
                    <div>
                        <Text size='L' weight='bold'>
                            {content.title}
                        </Text>
                        <br />
                        <Text>{content.subtitle}</Text>
                    </div>
                </div>
                <Button
                    text={content.button}
                    onClick={handleButtonClick}
                    flavour='secondary'
                ></Button>
            </div>
        </div>
    )
}

export default FeedbackBanner
