import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Footer from 'components/blocks/Footer/Footer'
import FooterContent from 'components/sections/app/FooterContent/FooterContent'
import HeaderSection from 'components/sections/app/HeaderContent/HeaderContent'
import { INITIAL_PRODUCT_TIERS } from 'utils/constants'

import styles from './GenericLayout.module.css'

type GenericLayoutProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    children: React.ReactNode
    isAuthorised: null | boolean
    isDemoUrl: boolean
    logUserOut({ to }: { to: string }): void
    productTiers: Record<ProductNameType, ProductTierType>
    userEmail: string
    userRoles: UserRole[]
    tenantFinanceId?: string
}

const GenericLayout: React.FC<GenericLayoutProps> = ({
    apiClient,
    children,
    isAuthorised,
    isDemoUrl,
    logUserOut,
    productTiers = INITIAL_PRODUCT_TIERS,
    userEmail,
    userRoles,
    tenantFinanceId,
}: GenericLayoutProps): JSX.Element => {
    return (
        <div className={styles['full-page']}>
            <HeaderSection
                apiClient={apiClient}
                isAuthorised={isAuthorised}
                isDemoUrl={isDemoUrl}
                logUserOut={logUserOut}
                userEmail={userEmail}
                productTiers={productTiers}
                userRoles={userRoles}
                tenantFinanceId={tenantFinanceId}
            />
            <main className={styles.content}>{children}</main>
            <Footer>
                <FooterContent
                    isAuthorised={isAuthorised}
                    isDemoUrl={isDemoUrl}
                    productTiers={productTiers}
                    tenantFinanceId={tenantFinanceId}
                />
            </Footer>
        </div>
    )
}

export default GenericLayout
