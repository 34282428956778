import React from 'react'

import Breadcrumb from 'components/basics/Breadcrumb/Breadcrumb'
import HotelRoomsList from 'components/sections/hotel/RoomsList/RoomsList'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { Hotel } from 'api-data-models/HotelRoomsContentModel'
import allContent from 'content/content'

const content = allContent.hotel.roomDetailsPage
const breadcrumbContent = allContent.app.breadcrumbs

/** RoomDetailsLayout: Layout for the Room Details page */
type RoomDetailsProps = {
    hotel: Hotel
}
const RoomDetailsLayout: React.FC<RoomDetailsProps> = ({ hotel }: RoomDetailsProps) => {
    return (
        <div>
            <div>
                <Breadcrumb
                    urlList={[
                        { text: breadcrumbContent.search, url: ROUTES.HOTEL_SEARCH },
                        {
                            text: breadcrumbContent.results,
                            url: `${ROUTES.HOTEL_RESULTS}/checkInDate=2023-08-26&checkOutDate=2023-08-27&numberOfAdults=1&numberOfRooms=1`,
                        },
                        { text: breadcrumbContent.roomDetails },
                    ]}
                />
            </div>
            {hotel.rooms.length < 1 && <p>{content.emptyResults}</p>}
            {hotel.rooms.length > 0 && <HotelRoomsList roomsToDisplay={hotel.rooms} />}
        </div>
    )
}

export default RoomDetailsLayout
