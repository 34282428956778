// NOTE: TODO all these details in future should be provided by a service, not hard coded here
import { COGNITO_SETUPS, APP_FLAVOURS, ENVIRONMENTS } from '../utils/constants'

const domainAuthInfo: DomainAuthInfoData = {
    /** PAID SITE */
    'https://agentconnect.traveltek.net': {
        appFlavour: APP_FLAVOURS.PAID_TT,
        environment: ENVIRONMENTS.PROD,
        cognitoSetup: COGNITO_SETUPS.AMPLIFY,
        hostName: 'amplify-does-not-need-hostname',
        clientId: '6jt7edf740shq14dt1pm3ueu5p',
        userPoolId: 'eu-west-1_TzliCih9b',
    },
    'https://enya.traveltekstage.com': {
        appFlavour: APP_FLAVOURS.PAID_TT,
        environment: ENVIRONMENTS.STAGE,
        cognitoSetup: COGNITO_SETUPS.AMPLIFY,
        hostName: 'amplify-does-not-need-hostname',
        clientId: '4jif60jf8k208dqco8p50dabmk',
        userPoolId: 'eu-west-1_CDBj0vD51',
    },
    'https://enya.traveltekdev.com': {
        appFlavour: APP_FLAVOURS.PAID_TT,
        environment: ENVIRONMENTS.DEVELOPMENT,
        cognitoSetup: COGNITO_SETUPS.AMPLIFY,
        hostName: 'amplify-does-not-need-hostname',
        clientId: '2bdbtliidk6m2i84umkhmtai1g',
        userPoolId: 'eu-west-1_Z1xQjGrl5',
    },
    'http://localhost:3000': {
        appFlavour: APP_FLAVOURS.PAID_TT,
        environment: ENVIRONMENTS.LOCAL,
        cognitoSetup: COGNITO_SETUPS.AMPLIFY,
        hostName: 'amplify-does-not-need-hostname',
        clientId: '4jif60jf8k208dqco8p50dabmk', // STAGE
        userPoolId: 'eu-west-1_CDBj0vD51',
    },
    /** -----------------------------------------------------------
     * DEMO SITE */
    'https://agentconnect-demo.traveltek.net': {
        appFlavour: APP_FLAVOURS.DEMO_TT,
        environment: ENVIRONMENTS.PROD,
        cognitoSetup: COGNITO_SETUPS.HOSTED_UI,
        hostName: 'auth.agentconnect-demo.traveltek.net',
        clientId: '1nqlphhicf4udnmedo4ge5j7c3',
        redirectUri: 'https://agentconnect-demo.traveltek.net/login',
    },
    'https://enya-demo.traveltekdev.com': {
        appFlavour: APP_FLAVOURS.DEMO_TT,
        environment: ENVIRONMENTS.DEVELOPMENT,
        cognitoSetup: COGNITO_SETUPS.HOSTED_UI,
        hostName: 'agentconnectdev-demo.auth.eu-west-1.amazoncognito.com',
        clientId: '3g0lif4oer976680kg7abm6v2c',
        redirectUri: 'https://enya-demo.traveltekdev.com/login',
    },
    'https://enya-demo.traveltekstage.com': {
        appFlavour: APP_FLAVOURS.DEMO_TT,
        environment: ENVIRONMENTS.STAGE,
        cognitoSetup: COGNITO_SETUPS.HOSTED_UI,
        hostName: 'agentconnectstage-demo.auth.eu-west-1.amazoncognito.com',
        clientId: '23b0j6hmqk4vlgtqovikpq4akm',
        redirectUri: 'https://enya-demo.traveltekstage.com/login',
    },
    'http://localhost:4001': {
        appFlavour: APP_FLAVOURS.DEMO_TT,
        environment: ENVIRONMENTS.LOCAL,
        cognitoSetup: COGNITO_SETUPS.HOSTED_UI,
        hostName: 'agentconnectstage-demo.auth.eu-west-1.amazoncognito.com',
        clientId: '23b0j6hmqk4vlgtqovikpq4akm',
        redirectUri: 'http://localhost:4001/login',
    },
}
export const DEMO_DOMAINS = [
    'http://localhost:4001',
    'https://enya-demo.traveltekdev.com',
    'https://enya-demo.traveltekstage.com',
    'https://agentconnect-demo.traveltek.net',
]

export default domainAuthInfo
