import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from 'components/basics/Button/Button'
import Icon from 'components/basics/Icon/Icon'
import Heading from 'components/basics/Heading/Heading'
import Text from 'components/basics/Text/Text'
import { Hotel } from 'api-data-models/HotelResultsContentModel'
import { ROUTES } from 'components/sections/app/AppRoutes'
import styles from './HotelDetailsSection.module.css'
import allContent from 'content/content'

const content = allContent.hotel.resultsPage.resultsItem

type HotelDetailsSectionProps = {
    hotel: Hotel
}

const HotelDetailsSection: React.FC<HotelDetailsSectionProps> = ({ hotel }): JSX.Element => {
    const navigate = useNavigate()

    function handleSelectHotelClick(): void {
        navigate(`${ROUTES.HOTEL_ROOM_DETAILS}/${encodeURI(hotel.id)}`) // only need to encodeURI while id has wild chars in it from travelgate-x, should become a simple id when fixed
    }
    const grossPrice = hotel.priceBreakdown[0].priceDetail.gross
    const averagePricePerNight = hotel.averagePricePerNight
    const currencySymbol = hotel.currencySymbol

    return (
        <div className={styles.container}>
            <div>
                <Heading heading='3' size='3'>
                    {hotel.name}
                </Heading>
                <div className={styles['location-container']}>
                    <Icon iconName={'Location'} iconColor='tertiary-blue' iconSize='M' />
                    <Text
                        size='M'
                        weight='bold'
                        color='tertiary-blue'
                        className={styles['address-text']}
                    >
                        {hotel.address}
                    </Text>
                </div>
                <div className={styles['gray-line-separator']} />
                <div className={styles['item-prices-container']}>
                    <div>
                        <Text size='M'>{content.pricesFrom}</Text>
                        <Text size='L' weight='bold' className={styles['price-horizontal-indent']}>
                            {`${currencySymbol} ${averagePricePerNight}`}
                        </Text>
                        <Text size='M'>{content.perNight}</Text>
                    </div>
                    <div>
                        <Text size='M'>{content.from}</Text>
                        <Text size='M' weight='bold' className={styles['price-horizontal-indent']}>
                            {`${currencySymbol} ${grossPrice}`}
                        </Text>
                        <Text size='M'>{content.forNightPreAmount}</Text>
                        <Text size='M' weight='bold' className={styles['price-horizontal-indent']}>
                            {hotel.numberOfNights}
                        </Text>
                        <Text size='M'>{`${content.forNightsPostAmount}`}</Text>
                    </div>
                    <Button
                        className={styles.button}
                        type='button'
                        flavour='primary'
                        text={content.selectHotelButton}
                        onClick={handleSelectHotelClick}
                    />
                </div>
            </div>
        </div>
    )
}
export default HotelDetailsSection
