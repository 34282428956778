import React from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import CrashErrorLayout from 'components/layouts/app/CrashErrorLayout/CrashErrorLayout'
import FooterContent from 'components/sections/app/FooterContent/FooterContent'
import Footer from 'components/blocks/Footer/Footer'
import HeaderSection from 'components/sections/app/HeaderContent/HeaderContent'
import { extractUserDataFields } from 'utils/user-data-helpers/extract-user-data-fields'
import { DEMO_DOMAINS } from 'data/domainAuthInfo'
import { INITIAL_PRODUCT_TIERS } from 'utils/constants'

import styles from './CrashErrorPage.module.css'

export const CrashErrorPage = ({
    cleanUpAppOnLogout,
}: {
    cleanUpAppOnLogout(): void
}): JSX.Element => {
    const isDemoUrl = DEMO_DOMAINS.includes(window.location.origin)
    const userContext = datadogLogs.getGlobalContext()
    const { emailAddress } = extractUserDataFields(userContext as GlobalContextUserData)

    /** To prevent anything that might not work, if app crashes turn off all features */
    const isAuthorised = false
    const userRoles: UserRole[] = []
    const productTiers = INITIAL_PRODUCT_TIERS
    const handleLogoutWhenCrashed = ({
        cleanUpAppOnLogout,
    }: {
        cleanUpAppOnLogout(): void
    }): void => {
        cleanUpAppOnLogout()
        window.location.reload()
    }

    return (
        <div className={styles.page}>
            <HeaderSection
                isAuthorised={isAuthorised}
                isDemoUrl={isDemoUrl}
                logUserOut={(): void => handleLogoutWhenCrashed({ cleanUpAppOnLogout })}
                userEmail={emailAddress}
                userRoles={userRoles}
            />
            <CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />
            <Footer>
                <FooterContent
                    isAuthorised={isAuthorised}
                    isDemoUrl={isDemoUrl}
                    productTiers={productTiers}
                />
            </Footer>
        </div>
    )
}

export default CrashErrorPage
