import {
    INITIAL_FULL_PASSENGER_CONFIGURATION,
    SESSION_STORAGE_PASSENGER_CONFIG_KEY,
} from 'utils/constants'
import {
    getDataFromSessionStorage,
    removeDataFromSessionStorage,
    setDataToSessionStorage,
} from 'utils/use-session-storage'
import { Passenger } from 'components/sections/cruise/PassengerConfiguration/PassengerConfiguration'

export type PassengerConfigurationDataAsQueryVar = {
    age: number
    pastPassengerReference?: string
    military?: boolean
    residency?: string
    travellerNumber: number
}

export type PassengerConfigurationData = {
    passengerConfiguration: Passenger[]
    adults: number
    children: number
}

type Response = {
    numberOfPassengers: number
    passengerConfigurationData: PassengerConfigurationData
    passengerConfigurationDataAsQueryVar: PassengerConfigurationDataAsQueryVar[]
    setPassengers: (data: Record<string, any>) => void
    sortPassengers: (a: Passenger, b: Passenger) => number
    clearPassengers: () => void
}

export const sortPassengers = (a: Passenger, b: Passenger): number => {
    /** First passenger is always lead */
    if (a.lead && !b.lead) {
        return -1
    } else if (!a.lead && b.lead) {
        return 1
    }
    /** Then sort passengers adult first, then children. */
    if (a.ageType === 'adult' && b.ageType !== 'adult') {
        return -1
    } else if (a.ageType !== 'adult' && b.ageType === 'adult') {
        return 1
    }
    /** if passengers are of the same ageType then sort by lowest passenger number first */
    if (a.ageType === b.ageType) {
        return a.ageTypeNumber - b.ageTypeNumber
    }
    return 0
}

export const usePassengersInfo = (): Response => {
    let passengerConfigurationData = getDataFromSessionStorage({
        key: SESSION_STORAGE_PASSENGER_CONFIG_KEY,
    }) as PassengerConfigurationData
    if (!passengerConfigurationData) {
        passengerConfigurationData =
            INITIAL_FULL_PASSENGER_CONFIGURATION as PassengerConfigurationData
        setDataToSessionStorage({
            key: SESSION_STORAGE_PASSENGER_CONFIG_KEY,
            data: INITIAL_FULL_PASSENGER_CONFIGURATION,
        })
    }

    const passengerConfigurationDataAsQueryVar = passengerConfigurationData.passengerConfiguration
        ?.filter((passenger: Record<string, any>) => !!passenger.age)
        ?.map((passenger: Record<string, any>) => {
            const passengerData = {
                travellerNumber: passenger.travellerNumber,
                age: +passenger.age,
            } as PassengerConfigurationDataAsQueryVar
            if (passenger.pastPassenger)
                passengerData.pastPassengerReference = passenger.pastPassenger
            if (passenger.military) passengerData.military = passenger.military
            if (passenger.residency) passengerData.residency = passenger.residency.iata
            return passengerData
        })

    const setPassengers = (data: Record<string, any>): void => {
        const fields = data.passengerConfiguration
        const sortedData = {
            ...data,
            passengerConfiguration: [...fields]
                .sort(sortPassengers)
                .map((passenger, i) => ({ ...passenger, travellerNumber: i + 1 })),
        }
        setDataToSessionStorage({ data: sortedData, key: SESSION_STORAGE_PASSENGER_CONFIG_KEY })
    }

    const clearPassengers = (): void => {
        removeDataFromSessionStorage({ key: SESSION_STORAGE_PASSENGER_CONFIG_KEY })
    }

    const numberOfPassengers = passengerConfigurationData.passengerConfiguration.length

    return {
        passengerConfigurationData,
        numberOfPassengers,
        passengerConfigurationDataAsQueryVar,
        setPassengers,
        sortPassengers,
        clearPassengers,
    }
}
