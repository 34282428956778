import React from 'react'

import Text from 'components/basics/Text/Text'
import { FlightProduct } from 'api-data-models/order/OrderContentModel'

import styles from './OrderPricingSection.module.scss'

type FlightPricingSectionProps = {
    flight: FlightProduct
}

const FlightPricingSection = ({ flight }: FlightPricingSectionProps): JSX.Element => {
    const flightName = flight.name
    const currency = flight?.currency ?? 'USD' // TODO: WHAT!? i guess its because it can be undefined... not sure the endpoint should allow that
    const price = flight?.pricing.price ?? '0.00' // TODO: WHAT!? i guess its because it can be undefined... not sure the endpoint should allow that

    return (
        <div className={styles['pricing-summary-item__price-details']}>
            <Text weight='bold'>{flightName}</Text>
            <div className={styles['pricing-summary-item__price-item']}>
                <Text>{flightName}</Text>
                <Text font='mono'>
                    {currency}
                    {price}
                </Text>
            </div>
        </div>
    )
}

export default FlightPricingSection
