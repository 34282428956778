import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Navigation from 'components/sections/admin/NavigationPannel/NavigationPanel'

import styles from './CompanyConfigurationLayout.module.css'
import CompanyConfigurationSection from 'components/sections/admin/CompanyConfiguration/CompanyConfiguration'

type CompanyConfigurationProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productTiers: ProductTiersDataType
}

const CompanyConfigurationLayout = ({
    apiClient,
    productTiers,
}: CompanyConfigurationProps): JSX.Element => {
    return (
        <div className='general-container'>
            <div className={styles.container}>
                <Navigation productTiers={productTiers} />
                <CompanyConfigurationSection apiClient={apiClient} />
            </div>
        </div>
    )
}

export default CompanyConfigurationLayout
