import { ErrorResponse } from '@apollo/client/link/error'
import { datadogLogs } from '@datadog/browser-logs'

import { getGqlErrors, isNetworkError } from './get-type-of-error'
import { createGenericError } from './create-generic-error'
import { getNetworkErrorMessage } from './get-network-error-message'
import allContent from 'content/content'

const errorContent = allContent.error

// TODO: RENAME TO getGRAPHQLErrorMessage to avoid miss use.
export function getGraphqlErrorMessage(error: ErrorResponse, source: string): CustomApiError[] {
    const userContext = datadogLogs.getGlobalContext()
    if (isNetworkError(error) && 'statusCode' in (error?.networkError ?? {})) {
        const statusCode = error.networkError.statusCode
        const errorMessage = getNetworkErrorMessage(statusCode)

        datadogLogs.logger.error(
            `Response (graphql) source: ${source}, Network-error, statusCode: ${statusCode}, message: ${errorMessage}`,
            { userContext },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error // Error type is apollo which doesn't match datadog logger but it extends Error so is fine. (we think)
        )
        return [
            createGenericError({
                description: errorMessage,
                source,
                error,
            }),
        ]
    }

    const hasGqlErrors = getGqlErrors(error).length
    if (hasGqlErrors) {
        return getGqlErrors(error).map(({ extensions, message, path }, index) => {
            if (!extensions || !Object.keys(extensions).length) {
                extensions = {
                    error_description: message ?? errorContent.network.unknown,
                    error_code: index,
                }
            }
            const error = { ...extensions, id: index } as CustomApiError
            if (error.error_details && path && !!path.length)
                error.error_details[0].path = path.join(', ')

            datadogLogs.logger.error(
                `Response (graphql) source: ${source}, GraphQL-error`,
                { userContext },
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                error // Error type is apollo which doesn't match datadog logger but it extends Error so is fine. (we think)
            )
            return error
        })
    } else {
        return [createGenericError({ description: errorContent.network.unknown, source, error })]
    }
}
