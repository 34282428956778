import React from 'react'

import Navigation from 'components/sections/admin/NavigationPannel/NavigationPanel'
import ReportingSection from 'components/sections/admin/Reporting/Reporting'

import styles from './ReportingLayout.module.css'

const Reporting = ({ productTiers }: { productTiers: ProductTiersDataType }): JSX.Element => {
    return (
        <div className='general-container'>
            <div className={styles.container}>
                <Navigation productTiers={productTiers} />
                <ReportingSection />
            </div>
        </div>
    )
}

export default Reporting
