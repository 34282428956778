import React from 'react'

import DuffelSearchLayout from '../../layouts/flight/DuffelSearchLayout/DuffelSearchLayout'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

const DuffelSearchPage: React.FC<{ apiClient: ApolloClient<NormalizedCacheObject> }> = ({
    apiClient,
}): JSX.Element => {
    return <DuffelSearchLayout apiClient={apiClient} />
}

export default DuffelSearchPage
