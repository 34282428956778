import React, { SetStateAction, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import FieldError from 'components/basics/FieldError/FieldError'
import Heading from 'components/basics/Heading/Heading'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import PasswordInput from 'components/basics/Input/PasswordInput/PasswordInput'
import Spacing from 'components/basics/Spacing/Spacing'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { setDataToLocalStorage, removeDataFromLocalStorage } from 'utils/use-local-storage'
import { LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY, COGNITO_SESSION_EXPIRY_MINS } from 'utils/constants'
import { extractCognitoFields } from 'utils/cognito-helpers/extract-cogntio-fields'

import styles from './NewPasswordForm.module.css'
import allContent from 'content/content'
const content = allContent.cognito.newPasswordForm

type NewPasswordFormProps = {
    setIsAuthorised: React.Dispatch<SetStateAction<boolean | null>>
    setUserData: React.Dispatch<SetStateAction<Record<string, any> | undefined>>
    userData: Record<string, any> | undefined
}

type NewPasswordFormDataType = {
    newPassword: string
}

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({
    setIsAuthorised,
    setUserData,
    userData,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isApiPasswordError, setIsApiPasswordError] = useState<boolean>(false)
    const [showSessionExpiredError, setShowSessionExpiredError] = useState<boolean>(false)
    const [isCognitoUser, setIsCognitoUser] = useState<boolean>(false)
    const [cognitoUser, setCognitoUser] = useState<Record<string, any> | undefined>()
    const navigate = useNavigate()

    const userContext = datadogLogs.getGlobalContext()

    useEffect(() => {
        if (userData && !isCognitoUser) {
            setCognitoUser(assignCognitoUser(userData))
        }
    }, [userData, isCognitoUser])

    function goToHomePage(): void {
        navigate(ROUTES.ROOT)
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<NewPasswordFormDataType>({
        reValidateMode: 'onChange', // only comes into effect after submit has been pressed... doesn't work for revalidating otherwise so not that useful
        defaultValues: {
            newPassword: '',
        },
    })
    function handleResetSessionClick(): void {
        removeDataFromLocalStorage({ key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY })
        navigate(ROUTES.ROOT)
    }

    function assignCognitoUser(userData: Record<string, any>): any {
        let cognitoUserObject: any = userData
        // Make sure that userObject is an instance of CognitoUser
        if (!(cognitoUserObject instanceof CognitoUser) && !!userData.pool) {
            const pool = new CognitoUserPool({
                UserPoolId: userData?.pool.userPoolId,
                ClientId: userData?.pool.clientId,
                endpoint: userData?.client.endpoint,
                Storage: userData?.storage,
                AdvancedSecurityDataCollectionFlag:
                    userData?.pool?.advancedSecurityDataCollectionFlag,
            })

            cognitoUserObject = new CognitoUser({
                Username: userData?.username,
                Pool: pool,
                Storage: window?.localStorage,
            })
            cognitoUserObject.Session = userData?.Session
        }
        setIsCognitoUser(true)
        return cognitoUserObject
    }

    async function handleSubmitClick(formFields: NewPasswordFormDataType): Promise<void> {
        setIsSubmitting(true)
        await Auth.completeNewPassword(
            cognitoUser, // the Cognito User Object
            formFields.newPassword // the new password
        )
            .then((user) => {
                setIsSubmitting(false)
                /** At this time the user is logged in (when MFA is not set required) */
                if (user) {
                    Auth.currentAuthenticatedUser()
                        .then((user) => {
                            datadogLogs.logger.info(
                                `source: CompleteNewPassword, success: ${user.username}`,
                                { userContext: { userName: user.username } }
                            )
                            setDataToLocalStorage({
                                data: { user: user },
                                key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY,
                                expiryMins: COGNITO_SESSION_EXPIRY_MINS,
                            })
                            setIsAuthorised(true)
                            setUserData(user)
                            goToHomePage()
                        })
                        .catch((error) => {
                            const { userName, email } = extractCognitoFields(user)
                            datadogLogs.logger.error(
                                'source: currentAuthenticatedUser, error',
                                { userEmail: email, userName: userName },
                                error
                            )
                        })
                }
            })
            .catch((error) => {
                setIsSubmitting(false)
                setIsApiPasswordError(true)
                if (error.message === 'Invalid session for the user, session is expired.') {
                    setShowSessionExpiredError(true)
                }
                datadogLogs.logger.error(
                    'source: completeNewPassword error',
                    { userContext },
                    error
                )
            })
    }

    return (
        <div className={styles.container}>
            <Heading heading='1' size='2' colorOverride='primary-blue'>
                {content.title}
            </Heading>
            <Spacing />
            <form
                className={styles.form}
                onSubmit={handleSubmit(async (formFields: NewPasswordFormDataType) => {
                    await handleSubmitClick(formFields)
                })}
            >
                <Controller
                    control={control}
                    name='newPassword'
                    rules={{
                        required: true,
                        minLength: 10,
                        maxLength: 30,
                        pattern:
                            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[\^$*.[\]{}()?"!@#%&/,><':;|_~`=+-]).{6,30}$/,
                        // cognito password policy: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
                    }}
                    render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                        <div className={styles['input-wrapper']}>
                            <LabelledInput
                                required={true}
                                htmlFor='new-password'
                                label={content.passInput}
                                disabled={isSubmitting}
                                aria-describedby='new-password-error-message'
                            >
                                <PasswordInput
                                    autoComplete='off'
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                            </LabelledInput>
                            <FieldError
                                inputId='new-password'
                                showError={!!errors.newPassword}
                                errorMessage={content.errors.passInput}
                            />
                            <Spacing />
                        </div>
                    )}
                />
                {isApiPasswordError && (
                    <InfoBanner
                        id='new-password-api-error'
                        bannerType='error'
                        text={content.errors.apiError}
                        logType='error'
                        source='choose-password-page'
                    />
                )}
                {showSessionExpiredError && (
                    <InfoBanner
                        id='session-expired-error'
                        bannerType='error'
                        text={content.errors.sessionExpiredError}
                        buttonText={content.errors.sessionExpiredError}
                        onButtonClick={handleResetSessionClick}
                        logType='error'
                        source='choose-password-page'
                    />
                )}
                <div className={styles['button-wrapper']}>
                    <Button
                        type='button'
                        flavour='tertiary'
                        disabled={isSubmitting}
                        text={content.cancelButton}
                        onClick={handleResetSessionClick}
                    />
                    <Button
                        type='submit'
                        flavour='primary'
                        showSpinner={isSubmitting}
                        text={isSubmitting ? content.submitting : content.submitButton}
                    />
                </div>
            </form>
        </div>
    )
}

export default NewPasswordForm
