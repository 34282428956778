import React, { useState } from 'react'
import { Tab as MUITab, Tabs as MUITabs } from '@mui/material'

import styles from './Tabs.module.scss'

type TabsProps = {
    tabs: { label: string; component: JSX.Element }[]
    ariaLabel?: string
}

const Tabs: React.FC<TabsProps> = ({ tabs, ariaLabel }: TabsProps) => {
    const [tabIndex, setTabIndex] = useState<number>(0)

    const handleTabChange = (_event: any, newTabIndex: number): void => {
        setTabIndex(newTabIndex)
    }

    return (
        <>
            <MUITabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label={ariaLabel}
                className={styles.tabs}
                classes={{
                    indicator: styles.indicator,
                }}
            >
                {tabs.map(({ label }) => (
                    <MUITab
                        label={label}
                        key={label}
                        className={styles.tab}
                        classes={{
                            selected: styles.selected,
                        }}
                    />
                ))}
            </MUITabs>
            {tabs.map(({ component }, i) => tabIndex === i && component)}
        </>
    )
}

export default Tabs
