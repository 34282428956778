// import { ROUTES } from 'components/sections/app/AppRoutes'

/** REGEX for input (each key press) and submit (final value) validation */
export const REGEX = {
    NAME: /^[a-zA-Z](([',.\- a-zA-Z])*[a-zA-Z])?$/, // Allows lower upper case letters, along with characters [',.\- ] except at start and end.
    COMPANY_NAME: /^[a-zA-Z\d][\w.!#£$&@%'*+=?^`, -]*[a-zA-Z\d]$/,
    DIGITS_ONLY: /^[0-9]*$/, // checks for any number of 0-9's. Don't use \d as this allows other ASCII characters that are numbers but not ones our backend would recognise!
    DIGITS_0_to_2: /^[0-9]{0,2}$/, // checks for any number of 0-9's. Don't use \d as this allows other ASCII characters that are numbers but not ones our backend would recognise!
    DIGITS_0_to_3: /^[0-9]{0,3}$/, // checks for any number of 0-9's. Don't use \d as this allows other ASCII characters that are numbers but not ones our backend would recognise!
    DIGITS_0_to_4: /^[0-9]{0,4}$/, // checks for any number of 0-9's. Don't use \d as this allows other ASCII characters that are numbers but not ones our backend would recognise!
    DAY: /^(([1-9]?|0[1-9])|(1[0-9])|(2[0-9])|(3[01]))$/, // 01 or 1 (02 or 2) up to 31
    MONTH: /^(([1-9]|0[1-9])|(1[0-2]))$/, // 01 or 1 (02 or 2) to 12, not 13 and above
    YEAR_1900_to_2099: /^(19|20)\d\d$/,
    EMAIL: /^[\w.!#$%'*+/=?^`{|}~-]+@[a-zA-Z\d-]+(?:\.[a-zA-Z\d-]+)*$/,
    // Valid characters in email: https://ladedu.com/valid-characters-for-email-addresses-the-complete-list/
    PASSPORT: /^[a-zA-Z0-9]*$/, // Not sure what patterns are valid
    CREDIT_CARD_DIGITS: /^[0-9]{0,16}$/, // 16 digits
    PASSWORD:
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[\^$*.[\]{}()?"!@#%&/,><':;|_~`=+-]).{6,30}$/,
    // cognito password policy: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
}

/** PASSENGER CONFIG */
export const SESSION_STORAGE_PASSENGER_CONFIG_KEY = 'passengerConfigurationData'
export const MAX_PASSENGERS_PER_BOOKING = 4
export const MAX_CHILDREN_PER_BOOKING = MAX_PASSENGERS_PER_BOOKING - 1

export const DEFAULT_PASSENGER_CONFIGURATION_ARRAY = [
    { travellerNumber: 1, ageType: 'adult', ageTypeNumber: 1, age: 30, lead: true },
    { travellerNumber: 2, ageType: 'adult', ageTypeNumber: 2, age: 30 },
]
export const INITIAL_FULL_PASSENGER_CONFIGURATION = {
    passengerConfiguration: DEFAULT_PASSENGER_CONFIGURATION_ARRAY,
    adults: 2,
    children: 0,
}

/** APP SETUPS */
export const APP_FLAVOURS = {
    DEMO_TT: 'demo_traveltek',
    PAID_TT: 'paid_traveltek',
}

/** ALL PRE-SAAS users to be on CRUISE_ENTERPRISE */
export const COMPANY_TIERS: {
    CRUISE_STARTER: CompanyTiers
    CRUISE_PLUS: CompanyTiers
    CRUISE_ENTERPRISE: CompanyTiers
} = {
    CRUISE_STARTER: 'CRUISESTARTER',
    CRUISE_PLUS: 'CRUISEPLUS',
    CRUISE_ENTERPRISE: 'CRUISEENTERPRISE',
}

export const COMPANY_TIER_NAMES = [
    COMPANY_TIERS.CRUISE_STARTER,
    COMPANY_TIERS.CRUISE_PLUS,
    COMPANY_TIERS.CRUISE_ENTERPRISE,
]

export const USER_ROLES: { AGENT: UserRole; ADMIN: UserRole } = {
    AGENT: 'AGENT',
    ADMIN: 'ADMIN',
}

/** THESE ARE THE NONE SAAS TIERS */
export const PRODUCT_TIERS: {
    PAID: 'PAID'
    PROCESSING: 'PROCESSING'
    FREE: 'FREE'
    UNDEFINED: undefined
} = {
    PAID: 'PAID',
    PROCESSING: 'PROCESSING',
    FREE: 'FREE',
    UNDEFINED: undefined,
}

export const PRODUCT_NAMES: {
    CRUISECONNECT: 'CRUISECONNECT'
    AGENTCONNECTCRUISE: 'AGENTCONNECTCRUISE'
    AGENTCONNECTFLIGHT: 'AGENTCONNECTFLIGHT'
    AGENTCONNECTHOTEL: 'AGENTCONNECTHOTEL'
} = {
    CRUISECONNECT: 'CRUISECONNECT',
    AGENTCONNECTCRUISE: 'AGENTCONNECTCRUISE',
    AGENTCONNECTFLIGHT: 'AGENTCONNECTFLIGHT',
    AGENTCONNECTHOTEL: 'AGENTCONNECTHOTEL',
}

export const REFUND_POLICIES: {
    REFUNDABLE: 'REFUNDABLE'
    NON_REFUNDABLE_DEPOSIT: 'NON_REFUNDABLE_DEPOSIT'
    NON_REFUNDABLE_FARE: 'NON_REFUNDABLE_FARE'
    NOT_SUPPORTED: 'NOT_SUPPORTED'
} = {
    REFUNDABLE: 'REFUNDABLE',
    NON_REFUNDABLE_DEPOSIT: 'NON_REFUNDABLE_DEPOSIT',
    NON_REFUNDABLE_FARE: 'NON_REFUNDABLE_FARE',
    NOT_SUPPORTED: 'NOT_SUPPORTED',
}

export const INITIAL_PRODUCT_TIERS: ProductTiersDataType = {
    CRUISECONNECT: PRODUCT_TIERS.UNDEFINED,
    AGENTCONNECTCRUISE: PRODUCT_TIERS.UNDEFINED,
    AGENTCONNECTFLIGHT: PRODUCT_TIERS.UNDEFINED,
    AGENTCONNECTHOTEL: PRODUCT_TIERS.UNDEFINED,
}

export const DEMO_PRODUCT_TIERS: ProductTiersDataType = {
    CRUISECONNECT: PRODUCT_TIERS.UNDEFINED,
    AGENTCONNECTCRUISE: PRODUCT_TIERS.PAID,
    AGENTCONNECTFLIGHT: PRODUCT_TIERS.PAID,
    AGENTCONNECTHOTEL: PRODUCT_TIERS.PAID,
}

export const ENVIRONMENTS = {
    LOCAL: 'local',
    DEVELOPMENT: 'development',
    STAGE: 'stage',
    PROD: 'production',
}

export const LOCAL_STORAGE_USER_DATA_KEY = 'userDataProductsAndTenantInfo'

/** COGNITO */
export const COGNITO_SETUPS: { HOSTED_UI: 'HOSTED_UI'; AMPLIFY: 'AMPLIFY' } = {
    HOSTED_UI: 'HOSTED_UI',
    AMPLIFY: 'AMPLIFY',
}

export const LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY = 'cognitoAuthData'
export const COGNITO_SESSION_EXPIRY_MINS = 60
export const COGNITO_ACTIONS = { new_password: 'NEW_PASSWORD_REQUIRED' }

/** Order */
export const PAYMENT_OPTIONS: {
    FULL: PaymentOptionType
    DEPOSIT: PaymentOptionType
    ONHOLD: PaymentOptionType
} = {
    FULL: 'FULL',
    DEPOSIT: 'DEPOSIT',
    ONHOLD: 'ONHOLD',
}

/** MISC/GENERAL */
export type SupplierNames =
    | 'Carnival Cruise Line'
    | 'Celebrity Cruises'
    | 'Cunard'
    | 'Holland America'
    | 'MSC Cruise Line'
    | 'Norwegian Cruise Lines'
    | 'Princess'
    | 'Royal Caribbean'
    | 'Seabourn'
    | 'Virgin Voyages'
    | 'Viking Cruises'
    | 'Disney Cruise Line'
    | 'Azamara Cruises'
    | 'Explora Journeys'

export type SupplierCodes =
    | 'CCL'
    | 'CEL'
    | 'CUN'
    | 'HAL'
    | 'MSC'
    | 'NCL'
    | 'PCL'
    | 'RCL'
    | 'SBN'
    | 'VVC'
    | 'VKG'
    | 'DCL'
    | 'AZA'
    | 'EXP'

export const SUPPLIER_NAMES: Record<SupplierCodes, SupplierNames> = {
    CCL: 'Carnival Cruise Line',
    CEL: 'Celebrity Cruises',
    CUN: 'Cunard',
    HAL: 'Holland America',
    MSC: 'MSC Cruise Line',
    NCL: 'Norwegian Cruise Lines',
    PCL: 'Princess',
    RCL: 'Royal Caribbean',
    SBN: 'Seabourn',
    VVC: 'Virgin Voyages',
    VKG: 'Viking Cruises',
    DCL: 'Disney Cruise Line',
    AZA: 'Azamara Cruises',
    EXP: 'Explora Journeys',
}

export const CARD_NAMES = { VISA: 'VI', MASTERCARD: 'MC', DISCOVER: 'DS', AMERICAN_EXPRESS: 'AX' }

export const DATE_FORMAT_D_M_Y_SLASH = 'dd/MM/yyyy' // 31/12/2024
export const DATE_FORMAT_Y_M_D_DASH = 'yyyy-MM-dd' // used in query params parser
export const DATE_FORMAT_USA_SHORT = 'MM/dd/yyyy' // 12/31/2024
export const DATE_FORMAT_USA_LONG = 'MMM d, yyyy' // 'Jan 27, 2024'
export const DATE_FORMAT_Y_M_D_HYPHEN = 'yyyy-MM-dd' // 2024-01-07
export const DATE_FORMAT_USA_LONG_WITH_TIME = 'MMM dd yyyy HH:mm a'
export const DATE_FORMAT_USA_TIME = 'HH:mm'
export const CURRENCY_CODES: Record<string, string> = { USD: '$', GBP: '£', EUR: '€' } // todo: source these from ? in future

export const CompanyTypes = {
    CONSORTIATRAVELAGENT: 'Consortia - Travel Agent',
    CONSORTIAHOMEWORKER: 'Consortia - Homeworker',
    OTA: 'OTA',
    TRAVELAGENT: 'TRAVELAGENT',
    SUPPLIER: 'Supplier',
    INTEGRATOR: 'Integrator',
    OTHER: 'Other',
}

export const VITALLY_EVENTS = {
    APP_LOAD: 'Connect - App load',
    SEARCH_CRUISES: 'Connect - Search Cruises',
    LIVE_PRICING_SAILING: 'Connect - Live prices sailing',
    LIVE_PRICING_SAILING_BREAKDOWN: 'Connect - Live prices sailing - breaking-down',
    LIVE_PRICING_CABIN: 'Connect - Live pricing cabin',
    ADD_TO_BASKET: 'Connect - Add product to basket',
    ADD_TRAVELLER_DETAILS: 'Connect - Add traveller details',
    UPDATE_TRAVELLER_DETAILS: 'Connect - Update traveller details',
    UPDATE_CONTACT_DETAILS: 'Connect - Update contact details',
    GET_ALL_ORDERS: 'Connect - All orders',
    BOOK_SUCCESS: 'Connect - Book success',
    BOOK_FAIL: 'Connect - Book fail',
    RETRIEVE_BOOKING: 'Connect - Retrieve booking',
    IMPORT_BOOKING: 'Connect - Import booking',
    EMAIL_QUOTE_MODAL_OPEN: 'Connect - Email quote modal opened',
    IMPORT_CRUISE_MODAL_OPEN: 'Connect - Import cruise modal opened',
    FEATURE_PORTAL_OPEN: 'Connect - Feature portal opened',
    FEATURE_PORTAL_BANNER_OPEN: 'Connect - Feature portal opened from banner',
}

export const ENVIRONMENT_NAMES = {
    DEVELOPMENT: 'development',
    STAGE: 'stage',
    PRODUCTION: 'production',
    LOCAL: 'local',
}

export const nonProductionEnvironments = [
    ENVIRONMENT_NAMES.DEVELOPMENT,
    ENVIRONMENT_NAMES.STAGE,
    ENVIRONMENT_NAMES.LOCAL,
]

export const COMPANY_TYPE = {
    CONSORTIATRAVELAGENT: 'CONSORTIATRAVELAGENT',
    CONSORTIAHOMEWORKER: 'CONSORTIAHOMEWORKER',
    OTA: 'OTA',
    TRAVELAGENT: 'TRAVELAGENT',
    SUPPLIER: 'SUPPLIER',
    INTEGRATOR: 'INTEGRATOR',
    OTHER: 'OTHER',
}

export const HTTP_METHODS: {
    GET: HTTPMethods
    POST: HTTPMethods
    DELETE: HTTPMethods
    UPDATE: HTTPMethods
    PATCH: HTTPMethods
} = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    UPDATE: 'UPDATE',
    PATCH: 'PATCH',
}
