import React from 'react'

import Heading from 'components/basics/Heading/Heading'
import HotelResultsListItem from './HotelResultsListItem/HotelResultsListItem'
import List from 'components/basics/List/List'
import Text from 'components/basics/Text/Text'
import { Hotel } from 'api-data-models/HotelResultsContentModel'
import styles from './HotelResultsList.module.css'

type HotelResultsListProps = {
    hotelsToDisplay: Hotel[]
    totalNumberOfHotels: number
    filteredNumberOfItems: number
}
const HotelResultsList: React.FC<HotelResultsListProps> = ({
    hotelsToDisplay,
    totalNumberOfHotels,
    filteredNumberOfItems,
}) => {
    const hotelResultsListItems = hotelsToDisplay.map((hotel: Hotel) => {
        const uniqueID = hotel.name + hotel.id
        const listItemKey = `${uniqueID}-hotel-item`
        return <HotelResultsListItem key={uniqueID} listKey={listItemKey} hotel={hotel} />
    })
    return (
        <div>
            <div className={styles.heading}>
                <Heading heading='2' size='1'>
                    Hotels
                </Heading>
                <Text
                    size='S'
                    weight='normal'
                    color='dark-gray'
                    className={styles['bottom-padding']}
                >
                    {`(found: ${totalNumberOfHotels}) of ${filteredNumberOfItems}`}
                </Text>
            </div>
            <List className={styles.list}>{hotelResultsListItems}</List>
        </div>
    )
}
export default HotelResultsList
