import React from 'react'

import Heading from 'components/basics/Heading/Heading'
import List from 'components/basics/List/List'
import Text from 'components/basics/Text/Text'
import ResultsListItem from './ResultsListItem/ResultsListItem'

import { Cruise } from 'api-data-models/CruisesContentModel'
import styles from './ResultsList.module.css'
import allContent from '../../../../content/content'
import PaginationRangeSelect from 'components/blocks/PaginationRangeSelect/PaginationRangeSelect'

const content = allContent.cruise.resultsPage

type ResultsListProps = {
    /** array of result items from getCruises */
    cruisesToDisplay: Cruise[]
    /** total number of cruises before filter is applied */
    totalNumberOfCruises: number
    filteredAmountOfCruises: number
    itemsPerPage: number
    setItemsPerPage: (itemsPerPage: number) => void
}

const ResultsList: React.FC<ResultsListProps> = ({
    cruisesToDisplay,
    totalNumberOfCruises,
    filteredAmountOfCruises,
    itemsPerPage,
    setItemsPerPage,
}) => {
    const cruiseResultsListItems = cruisesToDisplay.map((cruise: Cruise) => {
        const uniqueId = cruise.cruiseId + '-' + cruise.supplierCode + '-' + cruise.itemIndex
        const listItemKey = `${uniqueId}-cruise-item`
        return <ResultsListItem key={uniqueId} listKey={listItemKey} cruise={cruise} />
    })
    if (cruisesToDisplay.length === 0)
        return (
            <p>
                <Text>{content.filteredOutAll}</Text>
            </p>
        )
    return (
        <div>
            <div className={styles['header-wrapper']}>
                <div className={styles.header}>
                    <div>
                        <Heading heading='1'>{content.title}</Heading>
                        <Text weight='bold' className={styles['bottom-padding']}>
                            {`${totalNumberOfCruises}`}
                        </Text>
                        <Text weight='normal' className={styles['bottom-padding']}>
                            {totalNumberOfCruises === 1
                                ? `${content.counterTextSingular}${filteredAmountOfCruises}`
                                : `${content.counterTextPlural}${filteredAmountOfCruises}`}
                        </Text>
                    </div>
                    <Text size='XS'>{content.priceBasedOnTwoAdultsInfo}</Text>
                </div>
                <div className={styles['sit-bottom']}>
                    <PaginationRangeSelect
                        id='resutls-pagination-size-select'
                        value={itemsPerPage}
                        options={[5, 10, 15, 20, 30, 50, 100]} // Are these options OK?
                        onChange={(value): void => setItemsPerPage(value)}
                    />
                </div>
            </div>
            <List className={styles.list}>{cruiseResultsListItems}</List>
        </div>
    )
}

export default ResultsList
