import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import Button from 'components/basics/Button/Button'
import Navigation from 'components/sections/admin/NavigationPannel/NavigationPanel'
import AdminLanding from 'components/sections/admin/AdminLanding/AdminLanding'
import { ROUTES } from 'components/sections/app/AppRoutes'
import styles from './AdminLayout.module.css'
import allContent from 'content/content'

const content = allContent.admin.adminLanding
type AdminLayoutProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productTiers: ProductTiersDataType
    tenantFinanceId?: string
}

const AdminLayout = ({
    apiClient,
    productTiers,
    tenantFinanceId,
}: AdminLayoutProps): JSX.Element => {
    const navigate = useNavigate()
    return (
        <div className='general-container'>
            <div className={styles.container}>
                {!tenantFinanceId ? (
                    <div className={styles['card-content']}>
                        <p>{content.mustSubscribeMessage}</p>
                        <Button
                            type='button'
                            onClick={(): void => navigate(ROUTES.ROOT)}
                            text={content.subscribeButton}
                        />
                    </div>
                ) : (
                    <>
                        <Navigation productTiers={productTiers} />
                        <AdminLanding productTiers={productTiers} apiClient={apiClient} />
                    </>
                )}
            </div>
        </div>
    )
}

export default AdminLayout
