import React, { ReactNode } from 'react'
import classnames from 'classnames'

import Heading, { HeadingSizes } from 'components/basics/Heading/Heading'
import styles from './Card.module.scss'

type CardProps = {
    header?: string | ReactNode
    headerSize?: HeadingSizes
    headerLevel?: '1' | '2' | '3' | '4' | '5' | '6'
    footer?: ReactNode
    children: ReactNode
}

const Card = ({
    header,
    headerSize = '2',
    headerLevel = '2',
    footer,
    children,
}: CardProps): JSX.Element => {
    const classNames = classnames(styles['card'], { [styles[`card__without_underline`]]: !!footer })

    return (
        <div className={classNames}>
            {header && (
                <div className={styles['card__heading']}>
                    {typeof header === 'string' ? (
                        <Heading heading={headerLevel} size={headerSize} onDarkBackground={true}>
                            {header}
                        </Heading>
                    ) : (
                        header
                    )}
                </div>
            )}
            <div className={styles['card__content']}>{children}</div>
            {footer && <div className={styles['card__footer']}>{footer}</div>}
        </div>
    )
}
export default Card
