import React, { SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from 'components/sections/app/AppRoutes'
import { getDataFromLocalStorage } from 'utils/use-local-storage'
import { COGNITO_ACTIONS, LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY } from 'utils/constants'
import NewPasswordForm from 'components/sections/cognito/NewPasswordForm/NewPasswordForm'

type NewPasswordLayoutProps = {
    setIsAuthorised: React.Dispatch<SetStateAction<boolean | null>>
}
const NewPasswordLayout: React.FC<NewPasswordLayoutProps> = ({ setIsAuthorised }) => {
    const navigate = useNavigate()
    const [userData, setUserData] = useState<Record<string, any>>()
    const [renderNothingAsNavigating, setRenderNothingAsNavigating] = useState(true)
    const renderNothingAsNavigatingInstant = renderNothingAsNavigating

    useEffect(() => {
        const data = getDataFromLocalStorage({ key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY })
        if (!data) {
            /** If no user data, then user should log in. */
            navigate(ROUTES.LOGIN)
        } else if (data?.user?.challengeName === COGNITO_ACTIONS.new_password) {
            /** If user data and challenge, the user should be on this form. */
            setUserData(data.user)
            setRenderNothingAsNavigating(false)
        } else {
            /** If user data and not a challenge, the user has already set a password - go to app. */
            navigate(ROUTES.ROOT)
        }
    }, [navigate])

    if (renderNothingAsNavigatingInstant) {
        return null
    } else {
        return (
            <div className='general-container'>
                <NewPasswordForm
                    setIsAuthorised={setIsAuthorised}
                    setUserData={setUserData}
                    userData={userData}
                />
            </div>
        )
    }
}
export default NewPasswordLayout
