import React, { useEffect, useReducer, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'

import Breadcrumb from 'components/basics/Breadcrumb/Breadcrumb'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import OrderConfirmationLayout from 'components/layouts/order/OrderConfirmationLayout/OrderConfirmationLayout'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { ORDER_ACTIONS, orderReducer } from 'components/reducers/orderReducer'
import { useDomainAndIsDemoUrl } from 'App'

import allContent from 'content/content'

const breadcrumbContent = allContent.app.breadcrumbs

/** OrderConfirmationPage: Shows the order with success booked banner, or redirects to appropriate location */
const OrderConfirmationPage: React.FC = (): JSX.Element | null => {
    const { isDemoUrl } = useDomainAndIsDemoUrl()
    const navigate = useNavigate()
    const state = useLocation().state
    const userContext = datadogLogs.getGlobalContext()

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const orderIdParam = queryParams.get('orderId') ?? ''
    const existingOrder = state?.existingOrder
    const orderItemBookResponse = state?.orderItemBookResponse

    /** Needed to send user to correct page when url is copy and pasted and state is lost, or the param is missing because user manually came to this url */
    let navigateTo: string | null = null
    /** navigate() should be called in useEffect so first render is completed before the component is attempted to be unmounted - https://www.dhiwise.com/post/why-you-should-call-navigate-in-areact-useeffect */
    useEffect(() => {
        if (navigateTo) navigate(navigateTo)
    }, [navigate, navigateTo])

    /** No orderId param means user has come here accidentally, best we send to all orders list */
    if (!orderIdParam) {
        datadogLogs.logger.error(
            `source: BookingConfirmation, issue: no orderId in urlParam, orderItemBookResponse: ${JSON.stringify(
                orderItemBookResponse
            )}, existingOrder: ${JSON.stringify(existingOrder)}`,
            { userContext }
        )
        navigateTo = ROUTES.ALL_ORDERS
    }

    /** Missing state or ItemBook response, but has url param - user has copy/pasted url. Send to booked url to see booking. */
    if (!navigateTo && (existingOrder === undefined || orderItemBookResponse === undefined)) {
        datadogLogs.logger.error(
            `source: BookingConfirmation, issue: no orderState or booking response, orderIdParam: ${orderIdParam}, orderItemBookResponse: ${JSON.stringify(
                orderItemBookResponse
            )}, existingOrder: ${JSON.stringify(existingOrder)}`,
            { userContext }
        )
        navigateTo = `${ROUTES.ORDER}/?orderId=${orderIdParam}` // TODO: update to /order-booked/orderId=orderId once page exists
    }

    /** Response is missing supplierOrderItemReference, but has product data and url... then something went wrong with orderItemBook but came back without error */
    if (!navigateTo && !orderItemBookResponse?.items[0].supplier_order_reference) {
        datadogLogs.logger.error(
            `source: BookingConfirmation, issue: no supplierOrderReference, orderIdParam: ${orderIdParam}, orderItemBookResponse: ${JSON.stringify(
                orderItemBookResponse
            )}, existingOrder: ${JSON.stringify(existingOrder)}`,
            { userContext }
        )
        navigateTo = `${ROUTES.ORDER}/?orderId=${orderIdParam}` // TODO: update to /order-quote/orderId=orderId once page exists
    }

    const [orderState, dispatchOrderState] = useReducer(orderReducer, state?.existingOrder)
    const [isProcessingBookingResponse, setIsProcessingBookingResponse] = useState(true)
    const breadcrumbItems = [
        { text: breadcrumbContent.home, url: ROUTES.ROOT },
        { text: breadcrumbContent.allOrders, url: isDemoUrl ? undefined : ROUTES.ALL_ORDERS },
        { text: breadcrumbContent.confirmation },
    ]

    useEffect(() => {
        /** If not navigating away then lets put the api response through the orderReducer with current state to get the data to render the booked order */
        if (!navigateTo && orderItemBookResponse) {
            dispatchOrderState({
                type: ORDER_ACTIONS.ORDER_ITEM_BOOK,
                payload: orderItemBookResponse,
            })
        }
    }, [navigateTo, orderItemBookResponse])

    /** SupplierRef becomes populated once the reducer has finished successfully */
    const hasSupplierReference = orderState?.orderItem.supplierOrderItemReference

    useEffect(() => {
        /** Once reducer has finished - turn off spinner! */
        if (isProcessingBookingResponse && hasSupplierReference) {
            setIsProcessingBookingResponse(false)
        }
    }, [isProcessingBookingResponse, hasSupplierReference])

    /** Will likely flash very quickly, so no text. Needed to not render confirmation page when no data or about to leave it due to scenarios above. */
    if (isProcessingBookingResponse || navigateTo) {
        return <LargeSpinner />
    }

    return (
        <div className='general-container'>
            <Breadcrumb urlList={breadcrumbItems} />
            <OrderConfirmationLayout orderData={orderState} />
        </div>
    )
}

export default OrderConfirmationPage
