import React, { useState, useContext } from 'react'

import { FeatureToggleContext } from 'App'
import Heading from 'components/basics/Heading/Heading'
import Icon from 'components/basics/Icon/Icon'
import Image from 'components/basics/Image/Image'
import Text from 'components/basics/Text/Text'
import { Cruise } from 'api-data-models/CruisesContentModel'

import styles from './DetailsSection.module.css'
import allContent from 'content/content'
import ColoredLine from 'components/basics/ColoredLine/ColoredLine'

const content = allContent.cruise.resultsPage.resultsListItem.detailsSection

type DetailsSectionProps = {
    cruise: Cruise
}

const DetailsSection: React.FC<DetailsSectionProps> = ({ cruise }): JSX.Element => {
    const context = useContext(FeatureToggleContext)

    const disableButtonMessage =
        (cruise?.supplierCode === 'MSC' && !context.TURN_ON_RESULTS_PRICE_BUTTON_MSC) ||
        (cruise?.supplierCode === 'RCL' && !context.TURN_ON_RESULTS_PRICE_BUTTON_RCL) ||
        (cruise?.supplierCode === 'VKG' && !context.TURN_ON_RESULTS_PRICE_BUTTON_VKG) ||
        (cruise?.supplierCode === 'VVC' && !context.TURN_ON_RESULTS_PRICE_BUTTON_VVC) ||
        (cruise?.supplierCode === 'CEL' && !context.TURN_ON_RESULTS_PRICE_BUTTON_CEL) ||
        (cruise?.supplierCode === 'DCL' && !context.TURN_ON_RESULTS_PRICE_BUTTON_DCL) ||
        (cruise?.supplierCode === 'AZA' && !context.TURN_ON_RESULTS_PRICE_BUTTON_AZA) ||
        (cruise?.supplierCode === 'EXP' && !context.TURN_ON_RESULTS_PRICE_BUTTON_EXP)

    const { itinerary } = cruise
    const { portListContentLimited, portListContentFull } = itinerary
    const [showMore, setShowMore] = useState(Boolean(portListContentLimited.length))
    const itineraryField = (
        <>
            {showMore && (
                <Text>
                    <Text className={styles['itinerary-label']} weight='bold'>
                        {content.itineraryListLabel}
                    </Text>
                    {portListContentLimited.map((portName, i) => {
                        if (i === 0) {
                            return <Text key={portName + i}>{portName}</Text>
                        } else {
                            return (
                                <Text key={portName + i}>
                                    <Icon
                                        iconName='ChevronRight'
                                        className={styles['itinerary-divider-icon']}
                                        iconColor='pink'
                                        iconSize='XS'
                                    />
                                    {portName}
                                </Text>
                            )
                        }
                    })}
                    ...&nbsp;
                    <button
                        type='button'
                        onClick={(): void => setShowMore(!showMore)}
                        className={styles['more-button']}
                    >
                        <Text color='tertiary-blue' size='S'>
                            {content.showMore}
                        </Text>
                    </button>
                </Text>
            )}
            {!showMore && (
                <span>
                    <Text className={styles['itinerary-label']} weight='bold'>
                        {content.itineraryListLabel}
                    </Text>
                    {portListContentFull.map((portName, i) => {
                        if (i === 0) {
                            return <Text key={portName + i}>{portName}</Text>
                        } else {
                            return (
                                <Text key={portName + i}>
                                    <Icon
                                        iconName='ChevronRight'
                                        className={styles['itinerary-divider-icon']}
                                        iconColor='pink'
                                        iconSize='XS'
                                    />
                                    {portName}
                                </Text>
                            )
                        }
                    })}
                </span>
            )}
        </>
    )

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Heading heading='2' size='3'>
                    {cruise.productName}
                </Heading>
                <div className={styles.logo}>
                    <Image
                        alt={`Cruise line logo for ${cruise.supplierName}`}
                        src={cruise.supplierLogo}
                    />
                </div>
            </div>
            <ColoredLine />
            <div className={styles['icon-list']}>
                <div className={styles['icon-list__item']}>
                    <Icon iconSize='S' iconName='Calendar' />
                    <Text>{cruise.embarkDate}</Text>
                </div>
                <div className={styles['icon-list__item']}>
                    <Icon iconSize='S' iconName='Moon' />
                    <Text>{`${cruise.duration} ${content.nights}`}</Text>
                </div>
                <div className={styles['icon-list__item']}>
                    <Icon iconSize='S' iconName='Ship' />
                    <Text>{cruise.shipName}</Text>
                </div>
                <div className={styles['icon-list__item']}>
                    <Icon iconSize='S' iconName='Ship' />
                    <Text>{cruise.supplierName}</Text>
                </div>
            </div>
            <div className={styles['image-itinerary']}>
                <div className={styles.image}>
                    <Image
                        alt={cruise.shipName}
                        src={cruise.shipImage}
                        altImageSrc='/images/image-unavailable.png'
                    />
                </div>
                {itineraryField}
            </div>
            {disableButtonMessage && (
                <div className={styles['not-integrate-message']}>
                    <Text weight='bold'>
                        {content.supplierNotFullyIntegratedMessage[cruise?.supplierCode]}
                    </Text>
                </div>
            )}
        </div>
    )
}

export default DetailsSection
