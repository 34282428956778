import React from 'react'

import List from 'components/basics/List/List'
import RoomListItem from './RoomsListItem/RoomListItem'
import { Room } from 'api-data-models/HotelRoomsContentModel'

type RoomsListProps = {
    roomsToDisplay: Room[]
}
const RoomsList: React.FC<RoomsListProps> = ({ roomsToDisplay }) => {
    const roomsListItems = roomsToDisplay.map((room: Room) => {
        const uniqueID = room.roomNumber + room.roomOptions[0].id
        const listItemKey = `${uniqueID}-room-item`
        return <RoomListItem key={uniqueID} listKey={listItemKey} room={room} />
    })
    return <List>{roomsListItems}</List>
}
export default RoomsList
