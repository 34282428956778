import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'

import SearchLayout from 'components/layouts/cruise/SearchLayout/SearchLayout'
import FeedbackBanner from 'components/sections/cruise/FeedbackBanner/FeedbackBanner'
import {
    DESTINATION_CATEGORIES,
    DestinationCategory,
} from 'components/sections/cruise/CategoryIndicator/CategoryIndicator'
import { getDataFromLocalStorage, setDataToLocalStorage } from 'utils/use-local-storage'
import { createQueryParamString } from 'utils/create-query-param-string'
import { DATE_FORMAT_Y_M_D_DASH } from 'utils/constants'
import { ROUTES } from 'components/sections/app/AppRoutes'
import * as self from './SearchPage'
import styles from './SearchPage.module.css'
import { CruisesMetaData } from 'api-data-models/CruisesContentModel'

export const CRUISE_SEARCH_DATA_KEY = 'previousCruiseSearchParams'

export const EXPIRY_TIME = 15 // 15 minutes

type SearchBarQueryData = {
    destinationInputCategory: DestinationCategory
    destination?: string
    embarkEarliestDate: string
    embarkLatestDate?: string
    durationMin?: string
    durationMax?: string
    supplier?: string
    ship?: string
    embarkPort?: string
    cruiseId?: string
}

/** getQueryInput: takes the form fields constructed by react-hook-form
 * and converts them into an object with fields that match the getCruises query
 * variables, and encodes values where its required
 * @param allFormFields is an object representing all form data received by react-hook-form */
export const getCruiseSearchQueryInput = (
    allFormFields: Record<string, any> | undefined
): SearchBarQueryData => {
    const startAfter48hours = new Date(new Date().getTime() + 24000 * 60 * 60 * 2)
    const embarkEarliestDate = allFormFields?.embarkEarliestDate || startAfter48hours
    const embarkLatestDate = allFormFields?.embarkLatestDate
    const [durationMin, durationMax] = allFormFields?.duration?.split('-') || [false, false]
    const destination = allFormFields?.destination
    const destinationInputCategory =
        allFormFields?.destinationInputCategory || DESTINATION_CATEGORIES.DESTINATION // This value is default passed into search bar as this, and search bar only changes value is autosuggest input has a category to change it with - but better safe than sorry.
    const supplier = allFormFields?.supplier
    const ship = allFormFields?.ship
    const embarkPort = allFormFields?.embarkPort
    const cruiseId = allFormFields?.cruiseId

    /** Only take fields where the value exists on allFormFields */
    return {
        destinationInputCategory,
        /** REGION OR DESTINATION values go to api under the destination key because there is no Region fields, and free type is 'destination'. */
        ...(Boolean(destination) &&
            (destinationInputCategory === DESTINATION_CATEGORIES.DESTINATION ||
                destinationInputCategory === DESTINATION_CATEGORIES.REGION) && {
                destination: destination,
            }),
        /** PORT values go to api under the visitingPort key */
        ...(Boolean(destination) &&
            destinationInputCategory === DESTINATION_CATEGORIES.PORT && {
                visitingPort: destination,
            }),
        /** COUNTRY values go to api under the country key */
        ...(Boolean(destination) &&
            destinationInputCategory === DESTINATION_CATEGORIES.COUNTRY && {
                country: destination,
            }),
        /** SUPPLIER values go to api under the supplierName key */
        ...(Boolean(destination) &&
            destinationInputCategory === DESTINATION_CATEGORIES.SUPPLIER && {
                supplierName: destination,
            }),
        /** SHIP values go to api under the shipName key */
        ...(Boolean(destination) &&
            destinationInputCategory === DESTINATION_CATEGORIES.SHIP && {
                shipName: destination,
            }),
        ...{ embarkEarliestDate: format(new Date(embarkEarliestDate), DATE_FORMAT_Y_M_D_DASH) },
        ...(Boolean(embarkLatestDate) && {
            embarkLatestDate: format(new Date(embarkLatestDate), DATE_FORMAT_Y_M_D_DASH),
        }),
        ...(Boolean(durationMin) && { durationMin }),
        ...(Boolean(durationMax) && { durationMax }),
        ...(Boolean(supplier) && { supplier }),
        ...(Boolean(ship) && { ship }),
        ...(Boolean(embarkPort) && { embarkPort }),
        ...(Boolean(cruiseId) && { cruiseId }),
    }
}

export type OnSubmitSearchBarData = {
    destinationInputCategory: DestinationCategory
    destination?: string
    embarkEarliestDate: string
    embarkLatestDate?: string
    duration?: Record<string, string>
}

/** onSubmit: passed as a callback to react-hook-forms handleSubmit to handle
 the searchBarData generated by handleSubmit and make a search with it
 @param { object } searchDataToPersist search field names + user input as key/value pairs
 @param { object } allData are all the input values on the page
 @param { function } handleNavigate is function that calls navigate with url passed in
 */
export const onSubmit = (
    searchDataToPersist: OnSubmitSearchBarData,
    allData: Record<string, any>,
    handleNavigate: (url: string) => void
): void => {
    const queryInput = self.getCruiseSearchQueryInput(allData)
    const queryParamsString = createQueryParamString(queryInput)
    setDataToLocalStorage({
        data: searchDataToPersist,
        key: CRUISE_SEARCH_DATA_KEY,
        expiryMins: EXPIRY_TIME,
    })
    handleNavigate(`${ROUTES.CRUISE_RESULTS}/?${queryParamsString}`)
}

/** SearchPage: renders the search layout component wrapped in a FormProvider */

const SearchPage: React.FC<{ cruisesMetaData: CruisesMetaData }> = ({ cruisesMetaData }) => {
    const navigate = useNavigate()
    function handleNavigate(url: string): void {
        navigate(url)
    }

    const previousSearchValues = getDataFromLocalStorage({
        key: CRUISE_SEARCH_DATA_KEY,
    })
    const duration = getDataFromLocalStorage({ key: CRUISE_SEARCH_DATA_KEY })?.duration
    const embarkLatestDate = getDataFromLocalStorage({
        key: CRUISE_SEARCH_DATA_KEY,
    })?.embarkLatestDate
    const embarkEarliestDate = getDataFromLocalStorage({
        key: CRUISE_SEARCH_DATA_KEY,
    })?.embarkEarliestDate
    const destinationCategory =
        getDataFromLocalStorage({
            key: CRUISE_SEARCH_DATA_KEY,
        })?.destinationInputCategory ?? DESTINATION_CATEGORIES.DESTINATION

    const destinationDefault =
        previousSearchValues?.destination ||
        previousSearchValues?.country ||
        previousSearchValues?.supplierName ||
        previousSearchValues?.shipName ||
        previousSearchValues?.port
    const formFns = useForm({
        /** Set default form values for the search bar inputs if values present in local storage */
        defaultValues: {
            destination: destinationDefault ?? '',
            ...(embarkEarliestDate && {
                embarkEarliestDate: new Date(embarkEarliestDate),
            }),
            ...(embarkLatestDate && {
                embarkLatestDate: new Date(embarkLatestDate),
            }),
            ...(duration && { duration }),
        },
    })

    return (
        <div className={styles.wrapper}>
            <div className={styles.background}>
                <FormProvider {...formFns}>
                    <SearchLayout
                        prePopulateDestinationCategory={destinationCategory}
                        cruisesMetaData={cruisesMetaData}
                        onSubmit={(searchDataToPersist, allFormFields): void =>
                            self.onSubmit(searchDataToPersist, allFormFields, handleNavigate)
                        }
                    />
                </FormProvider>
            </div>
            <FeedbackBanner />
        </div>
    )
}

export default SearchPage
