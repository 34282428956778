import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'

import Breadcrumb from 'components/basics/Breadcrumb/Breadcrumb'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import OrderImportLayout from 'components/layouts/order/OrderImportLayout/OrderImportLayout'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { GET_ORDER } from 'graphql-queries/order/order-queries'
import { CruiseProduct, OrderContent } from 'api-data-models/order/OrderContentModel'
import { useApolloQuery } from 'components/hooks/useApolloQuery'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'
import { FeatureToggleContext } from 'App'
import { extractUserDataFields } from 'utils/user-data-helpers/extract-user-data-fields'
import { isTestEmailDomain } from 'utils/testing-handlers'
import { parseQueryParams } from 'utils/parse-query-params'
import { VITALLY_EVENTS } from 'utils/constants'

import allContent from 'content/content'

const content = allContent.order.orderPage
const breadcrumbContent = allContent.app.breadcrumbs

const OrderImportPage: React.FC<{ apiClient: ApolloClient<NormalizedCacheObject> }> = ({
    apiClient,
}): JSX.Element => {
    const { params } = useParams()
    const navigate = useNavigate()
    const featureToggles = useContext(FeatureToggleContext)
    const { emailAddress } = extractUserDataFields(
        datadogLogs.getGlobalContext() as GlobalContextUserData
    )

    // exceptions for testing cases on production when feature toggle is turned off, the content will be visible only to traveltek users
    const importOrderEnabled =
        featureToggles.TURN_ON_ORDERS_IMPORT || isTestEmailDomain(emailAddress)

    const parsedParams = params ? parseQueryParams(params) : null
    const orderIdParam = parsedParams?.orderId || null
    const hasCorrectParams = Boolean(orderIdParam)

    if (!importOrderEnabled) {
        navigate(`${ROUTES.ORDER}/orderId=${orderIdParam}`)
    }

    const [orderResult, setOrderResult] = useState<OrderContent | undefined>()
    const {
        result: getOrderResult,
        loading: getOrderLoading,
        error: getOrderError,
    } = useApolloQuery({
        client: apiClient,
        variables: { orderId: orderIdParam },
        query: GET_ORDER,
        skip: !hasCorrectParams,
        source: 'Order - GET_ORDER',
    })

    useEffect(() => {
        if (getOrderResult?.data && !getOrderLoading) {
            const order = new OrderContent(getOrderResult.data.order)
            /** If order isn't imported then we assume it's just a quote/booked order */
            if (order.isImported) {
                const product = order.orderItem.product as CruiseProduct
                CustomerSuccess.track({
                    properties: { supplierCode: product.supplierCode, orderId: orderIdParam },
                    eventName: VITALLY_EVENTS.IMPORT_BOOKING,
                })
                setOrderResult(order)
            } else {
                navigate(`${ROUTES.ORDER}/orderId=${orderIdParam}`)
            }
        }
    }, [getOrderLoading, getOrderResult, orderIdParam, navigate])

    const breadcrumbItems = [
        { text: breadcrumbContent.home, url: ROUTES.ROOT },
        { text: breadcrumbContent.import },
    ]

    return (
        <div className='general-container'>
            <Breadcrumb urlList={breadcrumbItems} />
            {getOrderLoading && <LargeSpinner text={content.fetchingOrder} />}
            {getOrderError && (
                <InfoBanner
                    id='api-error-banner'
                    bannerType='error'
                    text={`${content.errorOrderApi} ${orderIdParam}`}
                    isCloseable={false}
                />
            )}
            {!hasCorrectParams && (
                <InfoBanner
                    id='api-error-banner'
                    bannerType='error'
                    text={`${content.incorrectParams} ${JSON.stringify(parsedParams)}`}
                    isCloseable={false}
                    logType='info'
                    source='order-page'
                />
            )}
            {!getOrderError && orderResult && !getOrderLoading && (
                <OrderImportLayout orderData={orderResult} />
            )}
        </div>
    )
}

export default OrderImportPage
