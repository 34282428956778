import React, { useMemo, useRef, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { Controller, useForm } from 'react-hook-form'

import Button from 'components/basics/Button/Button'
import FieldError from 'components/basics/FieldError/FieldError'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Modal from 'components/blocks/Modal/Modal'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import PhoneInput from 'components/blocks/PhoneInput/PhoneInput'
import * as self from './AddGroupButton'
import { AGENT_CONNECT_GROUP_ADD_V2 } from 'graphql-queries/admin/admin-queries'

import styles from '../AddUserButton/AddUserButton.module.css'
import { REGEX } from 'utils/constants'
import allContent from 'content/content'
import { countryData } from '../../../../../../data/country-codes'

const content = allContent.admin.adminLanding.agentConnectCruise.addGroupButton

type AddGroupFormData = {
    title: string
    description?: string
    phoneCallingCode?: string
    phoneNumber?: string
}

type OnSubmitProps = {
    formData: AddGroupFormData
    apiClient: ApolloClient<NormalizedCacheObject>
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
    setApiResponseStatus: React.Dispatch<React.SetStateAction<boolean>>
    setApiError: React.Dispatch<React.SetStateAction<any>>
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const onSubmit = ({
    formData,
    apiClient,
    setSubmitting,
    setApiResponseStatus,
    setApiError,
    setIsModalOpen,
}: OnSubmitProps): void => {
    setSubmitting(true)
    apiClient
        .mutate({
            mutation: AGENT_CONNECT_GROUP_ADD_V2,
            variables: {
                title: formData.title,
                description: formData.description,
                phoneNumber:
                    formData.phoneCallingCode && formData.phoneNumber
                        ? `+${formData.phoneCallingCode}${formData.phoneNumber}`
                        : undefined,
            },
        })
        .then((response) => {
            if (response?.data) {
                setApiResponseStatus(true)
                datadogLogs.logger.info(
                    `Mutate AGENT_CONNECT_GROUP_ADD Successfully created group: ${formData.title}`
                )
            }
            setIsModalOpen(false)
            setSubmitting(false)
        })
        .catch((error) => {
            datadogLogs.logger.error(
                `Mutate AGENT_CONNECT_GROUP_ADD AddGroupButton - error: ${JSON.stringify({
                    error,
                })}`
            )
            const graphErrors = error?.graphQLErrors ?? undefined
            const errorMessage =
                graphErrors?.length > 0 ? graphErrors[0]?.message : content.errors.defaultApiError
            setApiError(errorMessage)
            setIsModalOpen(false)
            setSubmitting(false)
        })
}

type AddGroupButtonProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    setApiError: React.Dispatch<React.SetStateAction<any>>
    setApiResponseStatus: React.Dispatch<React.SetStateAction<boolean>>
}

const AddGroupButton: React.FC<AddGroupButtonProps> = ({
    apiClient,
    setApiResponseStatus,
    setApiError,
}): React.ReactElement => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [submitting, setSubmitting] = useState<boolean>(false)

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
    } = useForm({
        defaultValues: { title: '', description: '', phoneCallingCode: '', phoneNumber: '' },
    })

    const addGroupButtonRef = useRef<HTMLButtonElement | null>(null)
    const addGroupButtonId = 'open-add-group-modal-button-id'
    const handleOnAddGroupClick = (): void => {
        setApiError(null)
        setApiResponseStatus(false)
        setIsModalOpen(true)
        reset()
    }

    const handleCloseModal = (): void => {
        setIsModalOpen(false)
        reset()
        if (addGroupButtonRef.current) {
            // This is for when the user clicks the cancel button which the modal has no knowledge of (can't use ID passed in to set focus)
            addGroupButtonRef.current.focus()
        }
    }
    const countryCallingCodeOptions = useMemo(() => {
        return countryData.map((country) => ({
            value: country.phoneCode,
            text: country.flag + ' ' + country.name + ' +' + country.phoneCode,
        }))
    }, [])
    function handleOnChangeContactCntryCllngCd(e: React.ChangeEvent<HTMLSelectElement>): void {
        setValue('phoneCallingCode', e.target.value)
    }
    return (
        <div>
            <Button
                id={addGroupButtonId}
                text={content.addGroupButtonText}
                type='button'
                onClick={(): void => handleOnAddGroupClick()}
                ref={addGroupButtonRef}
            />
            <Modal
                className={styles.wrapper}
                headerText={content.modal.modalTitle}
                isOpen={isModalOpen}
                returnFocusId={addGroupButtonId}
                setClosed={handleCloseModal}
            >
                <form
                    className={styles.form}
                    onSubmit={handleSubmit((formData: AddGroupFormData): void =>
                        self.onSubmit({
                            formData,
                            apiClient,
                            setSubmitting,
                            setApiResponseStatus,
                            setApiError,
                            setIsModalOpen,
                        })
                    )}
                >
                    <Controller
                        control={control}
                        name='title'
                        rules={{
                            required: true,
                            minLength: 1,
                            maxLength: 30,
                            pattern: REGEX.COMPANY_NAME,
                        }}
                        render={({ field: { onBlur, onChange, value } }): React.ReactElement => (
                            <div>
                                <LabelledInput
                                    required={true}
                                    htmlFor='title'
                                    label={content.modal.titleField}
                                    aria-describedby={content.modal.titleField}
                                    disabled={submitting}
                                    isErrored={!!errors.title}
                                >
                                    <TextInput
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        required={true}
                                    />
                                </LabelledInput>
                                <FieldError
                                    inputId='title'
                                    showError={!!errors.title}
                                    errorMessage={content.errors.groupNameInput}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        control={control}
                        name='description'
                        render={({ field: { onBlur, onChange, value } }): React.ReactElement => (
                            <div>
                                <LabelledInput
                                    required={false}
                                    htmlFor='description'
                                    label={content.modal.descriptionField}
                                    aria-describedby={content.modal.descriptionField}
                                    disabled={submitting}
                                    isErrored={!!errors.description}
                                >
                                    <TextInput
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        required={true}
                                    />
                                </LabelledInput>
                            </div>
                        )}
                    />
                    <PhoneInput
                        countryCodeFieldName='phoneCallingCode'
                        inputFieldName='phoneNumber'
                        generalError={!!errors.phoneNumber || !!errors.phoneCallingCode}
                        groupLabel={content.modal.phoneNumberField}
                        countryCodeLabel={content.modal.countryCallingCodeLabel}
                        countryCodeOptions={countryCallingCodeOptions}
                        control={control}
                        inputLabel={content.modal.phoneNumberField}
                        onChange={setValue}
                        onCountryCodeChange={handleOnChangeContactCntryCllngCd}
                    />
                    <div className={styles['button-wrapper']}>
                        <Button
                            type='button'
                            flavour='tertiary'
                            text={content.modal.cancelButton}
                            onClick={handleCloseModal}
                            disabled={submitting}
                        />
                        <Button
                            text={
                                submitting
                                    ? content.modal.submittingButton
                                    : content.modal.confirmButton
                            }
                            type='submit'
                            showSpinner={submitting}
                        />
                    </div>
                </form>
            </Modal>
        </div>
    )
}
export default AddGroupButton
