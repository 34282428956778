import { datadogLogs } from '@datadog/browser-logs'

type FeatureToggleValue = 'on' | 'off'

/** This Default feature toggle object needs to be kept inline with current production toggle values */
export const DEFAULT_REACT_APP_FEATURE_TOGGLES: {
    TURN_ON_COMPANY_CONFIG: FeatureToggleValue
    TURN_ON_CRUISE_ADVANCED_SEARCH: FeatureToggleValue
    TURN_ON_RESULTS_PRICE_BUTTON: FeatureToggleValue
    TURN_ON_RESULTS_PRICE_BUTTON_CEL: FeatureToggleValue
    TURN_ON_RESULTS_PRICE_BUTTON_MSC: FeatureToggleValue
    TURN_ON_RESULTS_PRICE_BUTTON_RCL: FeatureToggleValue
    TURN_ON_RESULTS_PRICE_BUTTON_VKG: FeatureToggleValue
    TURN_ON_RESULTS_PRICE_BUTTON_VVC: FeatureToggleValue
    TURN_ON_RESULTS_PRICE_BUTTON_DCL: FeatureToggleValue
    TURN_ON_RESULTS_PRICE_BUTTON_AZA: FeatureToggleValue
    TURN_ON_RESULTS_PRICE_BUTTON_EXP: FeatureToggleValue
    TURN_ON_SAILING_DETAILS_PRICES_BUTTON: FeatureToggleValue
    TURN_ON_PAYMENT_SCHEDULE: FeatureToggleValue
    TURN_ON_ORDERS_PAGE: FeatureToggleValue
    TURN_ON_OPTIONAL_EXTRA: FeatureToggleValue
    TURN_ON_EMAIL_QUOTES: FeatureToggleValue
    TURN_ON_ORDERS_IMPORT: FeatureToggleValue
    TURN_ON_ORDERS_ADDITIONAL_INFO: FeatureToggleValue
    TURN_ON_ADMIN_PAGES: FeatureToggleValue
    TURN_ON_SEARCH_AUTO_SUGGEST: FeatureToggleValue
    TURN_ON_SIGN_UP_LINK: FeatureToggleValue
    TURN_ON_ALT_SAIL_DATES: FeatureToggleValue
} = {
    TURN_ON_COMPANY_CONFIG: 'off',
    TURN_ON_CRUISE_ADVANCED_SEARCH: 'off',
    TURN_ON_RESULTS_PRICE_BUTTON: 'on',
    TURN_ON_RESULTS_PRICE_BUTTON_CEL: 'on',
    TURN_ON_RESULTS_PRICE_BUTTON_MSC: 'on',
    TURN_ON_RESULTS_PRICE_BUTTON_RCL: 'on',
    TURN_ON_RESULTS_PRICE_BUTTON_VKG: 'off',
    TURN_ON_RESULTS_PRICE_BUTTON_VVC: 'off',
    TURN_ON_RESULTS_PRICE_BUTTON_DCL: 'off',
    TURN_ON_RESULTS_PRICE_BUTTON_AZA: 'off',
    TURN_ON_RESULTS_PRICE_BUTTON_EXP: 'off',
    TURN_ON_SAILING_DETAILS_PRICES_BUTTON: 'on',
    TURN_ON_PAYMENT_SCHEDULE: 'off', // DO NOT SHOW
    TURN_ON_ORDERS_PAGE: 'on',
    TURN_ON_OPTIONAL_EXTRA: 'off',
    TURN_ON_EMAIL_QUOTES: 'on',
    TURN_ON_ORDERS_IMPORT: 'on',
    TURN_ON_ORDERS_ADDITIONAL_INFO: 'off',
    TURN_ON_ADMIN_PAGES: 'off',
    TURN_ON_SEARCH_AUTO_SUGGEST: 'on',
    TURN_ON_SIGN_UP_LINK: 'off', //Will be on once user pools are merged
    TURN_ON_ALT_SAIL_DATES: 'off',
}

const allTrueForTest = true
/** This feature toggle object has all toggles on for testing purposes */
export const testingAllOn = {
    TURN_ON_COMPANY_CONFIG: allTrueForTest,
    TURN_ON_RESULTS_PRICE_BUTTON: allTrueForTest,
    TURN_ON_RESULTS_PRICE_BUTTON_MSC: allTrueForTest,
    TURN_ON_SAILING_DETAILS_PRICES_BUTTON: allTrueForTest,
    TURN_ON_CRUISE_ADVANCED_SEARCH: allTrueForTest,
    TURN_ON_RESULTS_PRICE_BUTTON_CEL: allTrueForTest,
    TURN_ON_RESULTS_PRICE_BUTTON_RCL: allTrueForTest,
    TURN_ON_PAYMENT_SCHEDULE: allTrueForTest,
    TURN_ON_ORDERS_PAGE: allTrueForTest,
    TURN_ON_OPTIONAL_EXTRA: allTrueForTest,
    TURN_ON_EMAIL_QUOTES: allTrueForTest,
    TURN_ON_ORDERS_IMPORT: allTrueForTest,
    TURN_ON_ORDERS_ADDITIONAL_INFO: allTrueForTest,
    TURN_ON_ADMIN_PAGES: allTrueForTest,
    TURN_ON_SEARCH_AUTO_SUGGEST: allTrueForTest,
    TURN_ON_SIGN_UP_LINK: allTrueForTest,
    TURN_ON_ALT_SAIL_DATES: allTrueForTest,
}

type DefaultFeatureToggleKeys = keyof typeof DEFAULT_REACT_APP_FEATURE_TOGGLES

export function getReactAppToggles(
    featureTogglesProcessEnvString: string
): Record<string, boolean> {
    let newFeatureToggleObject: Record<string, FeatureToggleValue> = {}
    const userContext = datadogLogs.getGlobalContext()
    try {
        if (featureTogglesProcessEnvString) {
            newFeatureToggleObject = JSON.parse(featureTogglesProcessEnvString)
        } else {
            newFeatureToggleObject = { ...DEFAULT_REACT_APP_FEATURE_TOGGLES }
        }
    } catch (error: any) {
        datadogLogs.logger.error('Error parsing feature toggles:', { userContext }, error)
    }
    //filter out anything that has value which isn't 'on' or 'off' and keys that are misspelled/different to defaults
    const filteredOutWrongKeysAndValues = Object.fromEntries(
        Object.entries(newFeatureToggleObject).filter(
            ([key, value]) =>
                (value === 'on' || value === 'off') &&
                DEFAULT_REACT_APP_FEATURE_TOGGLES.hasOwnProperty(key) //this checks if newFeatureToggleObject key is also a key in DEFAULT_REACT_APP_FEATURE_TOGGLES
        )
    )
    //identify missing keys by comparing the keys in default and result
    const missingKeys = Object.keys(DEFAULT_REACT_APP_FEATURE_TOGGLES).filter(
        (key) => !filteredOutWrongKeysAndValues.hasOwnProperty(key)
    )
    // Create the final object with boolean values
    return {
        ...Object.fromEntries(
            Object.entries(filteredOutWrongKeysAndValues).map(([key, value]) => [
                key,
                value === 'on', // Convert 'on' to true and 'off' to false
            ])
        ),
        ...Object.fromEntries(
            missingKeys.map((key) => [
                key,
                DEFAULT_REACT_APP_FEATURE_TOGGLES[key as DefaultFeatureToggleKeys] === 'on',
            ])
        ),
    }
}
