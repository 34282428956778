import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Auth } from '@aws-amplify/auth'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import FieldError from 'components/basics/FieldError/FieldError'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import Spacing from 'components/basics/Spacing/Spacing'
import Text from 'components/basics/Text/Text'
import { COGNITO_SESSION_EXPIRY_MINS, LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY } from 'utils/constants'
import { setDataToLocalStorage } from 'utils/use-local-storage'
import { getCognitoErrorMessage } from 'utils/cognito-helpers/get-cognito-error-message'

import styles from './TempPasswordForm.module.css'
import allContent from 'content/content'
import PasswordInput from 'components/basics/Input/PasswordInput/PasswordInput'
import FieldsRequired from 'components/basics/FieldsRequired/FieldsRequired'
import { ROUTES } from '../../AppRoutes'
import { useNavigate } from 'react-router-dom'

const content = allContent.signUpPage.tempPasswordForm

type TempPasswordFormDataType = {
    userEmail: string
    tempPassword: string
}

type TempPasswordFormProps = {
    userEmail: string
}
const TempPasswordForm: React.FC<TempPasswordFormProps> = ({ userEmail }) => {
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [goToPasswordChallenge, setGoToPasswordChallenge] = useState(false)
    const [tempPasswordApiError, setTempPasswordApiError] = useState('')

    useEffect(() => {
        //Render occurred. Navigate to new password if form submission is done as user will be on challenge param = NEW_PASSWORD:',
        if (goToPasswordChallenge) {
            navigate(ROUTES.NEW_PASSWORD)
        }
    }, [goToPasswordChallenge, navigate])

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<TempPasswordFormDataType>({
        reValidateMode: 'onChange',
        defaultValues: {
            tempPassword: '',
        },
    })

    async function handleSubmitClick(formFields: TempPasswordFormDataType): Promise<void> {
        setIsSubmitting(true)
        setTempPasswordApiError('')
        await Auth.signIn(userEmail, formFields.tempPassword.trim())
            .then((user) => {
                /** Handle successful TempPassword submit */
                setDataToLocalStorage({
                    data: { user: user },
                    key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY,
                    expiryMins: COGNITO_SESSION_EXPIRY_MINS,
                })
                datadogLogs.logger.info(`source: SaaSTempPassword, userEmail: ${userEmail}`, {
                    userContext: { userEmail: userEmail },
                })
                /** Trigger navigate to 'choose password' from */
                setGoToPasswordChallenge(true)
            })
            .catch((error) => {
                setIsSubmitting(false)
                setTempPasswordApiError(getCognitoErrorMessage(error?.name || 'unknown'))
                datadogLogs.logger.error(
                    `source: SaaSTempPassword, error using email: ${userEmail}`,
                    { userEmail: userEmail },
                    error
                )
            })
    }

    return (
        <div className={styles.container}>
            <form
                className={styles['temp-password-form']}
                onSubmit={handleSubmit(async (formFields: TempPasswordFormDataType) => {
                    await handleSubmitClick(formFields)
                })}
            >
                <fieldset disabled={isSubmitting} className={styles['field-set']}>
                    <legend className='visually-hidden'>{`${content.userEmailLabel} + ${content.tempPasswordInput}`}</legend>
                    <div className={styles['input-wrapper']}>
                        <Text> {content.userEmailLabel} </Text>
                        <Text> {userEmail} </Text>
                    </div>

                    <Controller
                        control={control}
                        name='tempPassword'
                        rules={{
                            required: true,
                            minLength: 10,
                            maxLength: 10,
                        }}
                        render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
                            <div className={styles['input-wrapper']}>
                                <LabelledInput
                                    required={true}
                                    htmlFor='tempPassword'
                                    label={content.tempPasswordInput}
                                    aria-describedby='temp-password-error-message'
                                    disabled={isSubmitting}
                                >
                                    <PasswordInput
                                        className={styles['password-input']}
                                        autoComplete='off'
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                    />
                                </LabelledInput>
                                <FieldError
                                    inputId='tempPassword'
                                    showError={!!errors.tempPassword}
                                    errorMessage={content.errors.tempPasswordInput}
                                />
                                {!errors.tempPassword && <Spacing size='double' />}
                            </div>
                        )}
                    />
                </fieldset>
                <FieldsRequired />
                <Button
                    className={styles['submit-button']}
                    type='submit'
                    flavour='primary'
                    text={isSubmitting ? 'Submitting' : content.submitButton}
                    showSpinner={isSubmitting}
                />
                {!!tempPasswordApiError && (
                    <InfoBanner
                        id='temp-password-api-error'
                        bannerType='error'
                        text={tempPasswordApiError}
                        isFocusable={true}
                    />
                )}
            </form>
        </div>
    )
}

export default TempPasswordForm
