import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Link from 'components/basics/Link/Link'
import Button from 'components/basics/Button/Button'
import DuffelGetFlight from 'components/sections/flight/DuffelGetFlight/DuffelGetFlight'
import { parseQueryParams } from 'utils/parse-query-params'
import { ROUTES } from 'components/sections/app/AppRoutes'

import * as self from './DuffelGetFlightPage'
import allContent from 'content/content'

const content = allContent.flight.duffelGetFlightPage

/** getOfferIdFromURL: take a query string and parse key/values from it into an object
 * Duffel Links Expected URL Structure
 * ?id=off_0000ATdUCeLBNvHGCKOBcn
 * @param {string} queryParams - query string params with leading ? removed */
export const getOfferIdFromURL = (queryParams: string): string => {
    const parsedQueryParams = parseQueryParams(queryParams)
    const { offerId } = parsedQueryParams
    return offerId
}

export type FlightOptionsDetails = {
    id: string
    providerId: string
    providerItemId: string
    grossCost: number
}

/** DuffelGetFlightPage: renders a processing page that calls the Duffel Pricing API on render and redirects to order page once order has been created. */
const DuffelGetFlightPage: React.FC<{ apiClient: ApolloClient<NormalizedCacheObject> }> = ({
    apiClient,
}): JSX.Element => {
    const [fetchingOffer, setFetchingOffer] = useState(false)
    const [errorFetchingOffer, setErrorFetchingOffer] = useState<boolean | null>(null)
    const [offerDetails, setOfferDetails] = useState<FlightOptionsDetails | undefined>(undefined)

    const [creatingOrder, setCreatingOrder] = useState(false)
    const [errorCreatingOrder, setErrorCreatingOrder] = useState<boolean | null>(null)

    const { params } = useParams()
    const queryParams = self.getOfferIdFromURL(params ?? '')

    const offerId = self.getOfferIdFromURL(queryParams) || null

    // eslint-disable-next-line no-console
    // console.log('Landing on Duffel Get Flight Page, has offerId:', offerId)
    const hasError = errorFetchingOffer || errorCreatingOrder
    return (
        <div className='general-container'>
            {!offerDetails && !errorFetchingOffer && fetchingOffer && (
                <p>{content.fetchingOffer}</p>
            )}
            {!offerDetails && errorFetchingOffer && !fetchingOffer && (
                <>
                    <p>
                        {content.errorFetchingOffer}: {offerId}
                    </p>
                    <Link to={ROUTES.FLIGHT_DUFFEL_SEARCH}>
                        <Button text='Back to Flight Search' type='button' />
                    </Link>
                </>
            )}

            {!errorCreatingOrder && creatingOrder && <p>{content.creatingOrder}</p>}
            {errorCreatingOrder && !creatingOrder && (
                <p>
                    {content.errorCreatingOrder}: {offerId}
                </p>
            )}

            {!hasError && offerId && (
                <DuffelGetFlight
                    agentConnectApiClient={apiClient}
                    offerId={offerId}
                    setOfferDetails={setOfferDetails}
                    setFetchingOffer={setFetchingOffer}
                    setErrorFetchingOffer={setErrorFetchingOffer}
                    setCreatingOrder={setCreatingOrder}
                    setErrorCreatingOrder={setErrorCreatingOrder}
                />
            )}
        </div>
    )
}

export default DuffelGetFlightPage
