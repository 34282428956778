import React from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import allContent from 'content/content'

const content = allContent.app.four04Page

const Four04Layout: React.FC = () => {
    const userContext = datadogLogs.getGlobalContext()
    const domain = window.location.origin
    datadogLogs.logger.error(`source: 404-page, message: User domain invalid, ${domain}`, {
        userContext,
    })
    if (domain === content.wwwProdPaidUrl) {
        window.location.assign(content.paidUrl)
    }
    return (
        <div className='general-container'>
            <InfoBanner
                id='404-error'
                text={content.message}
                bannerType='error'
                onButtonClick={(): void => window.location.assign(content.paidUrl)}
                buttonText={content.paidUrlText}
            />
        </div>
    )
}
export default Four04Layout
