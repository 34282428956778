import React, { SetStateAction } from 'react'
import { useLocation } from 'react-router-dom'

import ResetPasswordForm from 'components/sections/cognito/ResetPasswordForm/ResetPasswordForm'

type ResetPasswordLayoutProps = {
    setIsAuthorised: React.Dispatch<SetStateAction<boolean | null>>
}

const ResetPasswordLayout: React.FC<ResetPasswordLayoutProps> = ({ setIsAuthorised }) => {
    const { state } = useLocation()
    const email = state?.email
    return (
        <div className='general-container'>
            <ResetPasswordForm setIsAuthorised={setIsAuthorised} email={email} />
        </div>
    )
}

export default ResetPasswordLayout
