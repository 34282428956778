import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from 'components/sections/app/AppRoutes'
import { useApolloQuery } from '../../../hooks/useApolloQuery'
import { GET_DUFFEL_LINKS_SESSION_URL } from '../../../../graphql-queries/flight/flight-queries'

import styles from './DuffelLinksIframe.module.css'
import allContent from '../../../../content/content'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

const content = allContent.flight.duffelIframe

const DuffelLinksIframe: React.FC<{ apiClient: ApolloClient<NormalizedCacheObject> }> = ({
    apiClient,
}): JSX.Element => {
    const [iframeUrl, setIframeUrl] = useState('')
    const navigate = useNavigate()

    const { result, loading, error } = useApolloQuery({
        client: apiClient,
        query: GET_DUFFEL_LINKS_SESSION_URL,
        source: 'DuffelLinksIFrame - GET_DUFFEL_LINKS_SESSION_URL',
    })

    useEffect(() => {
        if (result?.data?.flightSessionUrl) {
            // eslint-disable-next-line no-console
            // console.log('Get DuffleLinks SessionUrl (jarvis) Response:', result.data)
            setIframeUrl(result.data.flightSessionUrl.url)
        }
    }, [result])

    // add event listener to catch message from links-iframe - if Type = height update then resize window, if offer-chosen redirect to offer processing page
    useEffect(() => {
        const duffelLinksCollected = (event: any): void => {
            const iFrame = document.getElementById('links-iframe') as HTMLElement
            switch (event.data.type) {
                case 'duffel-links-height-update':
                    iFrame.style.height = event.data.height
                    break
                case 'duffel-links-offer-chosen': {
                    const queryParamsString = `offerId=${event.data.offer_id}`
                    navigate(`${ROUTES.FLIGHT_DUFFEL_GET_OFFER}/${queryParamsString}`)
                    break
                }
            }
        }

        window.addEventListener('message', (event) => duffelLinksCollected(event))
        return () => window.removeEventListener('message', duffelLinksCollected)
    }, [navigate])

    return (
        <>
            {loading && <p>{content.fetchingUrl}</p>}
            {error && <p>{content.errorFetchingUrl}</p>}
            {!loading && !error && (
                <iframe
                    className={styles['duffel-iframe']}
                    id='links-iframe'
                    title='links-flights-iframe'
                    src={iframeUrl}
                />
            )}
        </>
    )
}
export default DuffelLinksIframe
