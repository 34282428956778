import React, { useContext } from 'react'

import Heading from 'components/basics/Heading/Heading'
import Link from 'components/basics/Link/Link'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { PRODUCT_TIERS, VITALLY_EVENTS } from 'utils/constants'
import { FeatureToggleContext } from 'App'

import styles from 'components/layouts/app/GenericLayout/GenericLayout.module.css'
import allContent from 'content/content'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'

const footerContent = allContent.footer
type FooterContentProps = {
    isAuthorised: null | boolean
    isDemoUrl: boolean
    productTiers: Record<ProductNameType, ProductTierType>
    tenantFinanceId?: string
}

const FooterContent: React.FC<FooterContentProps> = ({
    isAuthorised,
    isDemoUrl,
    productTiers,
    tenantFinanceId,
}) => {
    const featureToggles = useContext(FeatureToggleContext)

    const onFeaturePortalClick = (): void => {
        CustomerSuccess.track({
            eventName: VITALLY_EVENTS.FEATURE_PORTAL_OPEN,
            properties: {
                page: window.location.pathname,
            },
        })
    }

    return (
        <div className={styles['link-container']}>
            {isAuthorised && (
                <nav className={styles['link-container--column']}>
                    <Heading heading='2' onDarkBackground={true} size='3'>
                        {footerContent.heading1}
                    </Heading>
                    {tenantFinanceId && productTiers.AGENTCONNECTCRUISE === PRODUCT_TIERS.PAID && (
                        <Link to={ROUTES.CRUISE_SEARCH} onDarkBackground={true}>
                            {footerContent.cruiseText}
                        </Link>
                    )}
                    {tenantFinanceId && !isDemoUrl && featureToggles.TURN_ON_ORDERS_PAGE && (
                        <Link to={ROUTES.ALL_ORDERS} onDarkBackground={true}>
                            {footerContent.ordersText}
                        </Link>
                    )}
                    <Link
                        to={ROUTES.FEATURE_PORTAL}
                        onDarkBackground={true}
                        onClick={onFeaturePortalClick}
                    >
                        {footerContent.feedback}
                    </Link>
                </nav>
            )}
            <div className={styles['link-container--column']}>
                <Heading heading='2' onDarkBackground={true} size='3'>
                    {footerContent.heading2}
                </Heading>
                {/* Links going to a traveltek url only need rel='noopener', all other external links will need rel='noopener 'noreferrer' */}
                <Link
                    href={footerContent.serviceDeskUrl}
                    rel='noopener'
                    onDarkBackground={true}
                    target='_blank'
                >
                    {footerContent.serviceDeskText}
                </Link>
                <Link
                    href={footerContent.privacyPolicyUrl}
                    rel='noopener'
                    onDarkBackground={true}
                    target='_blank'
                >
                    {footerContent.privacyPolicyText}
                </Link>
                <Link
                    href={footerContent.termsOfServiceUrl}
                    rel='noopener'
                    onDarkBackground={true}
                    target='_blank'
                >
                    {footerContent.termsOfServiceText}
                </Link>
                <Link
                    href={footerContent.faqUrl}
                    rel='noopener'
                    onDarkBackground={true}
                    target='_blank'
                >
                    {footerContent.faqText}
                </Link>
            </div>
        </div>
    )
}

export default FooterContent
