import React, { useState, Dispatch, SetStateAction } from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { RenderInputComponentProps } from 'react-autosuggest'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import AutosuggestWithQuery from 'components/blocks/AutosuggestWithQuery/AutosuggestWithQuery'
import Button from 'components/basics/Button/Button'
import Checkbox from 'components/basics/Input/Checkbox/Checkbox'
import FieldError from 'components/basics/FieldError/FieldError'
import Heading from 'components/basics/Heading/Heading'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import Modal from 'components/blocks/Modal/Modal'
import Nudger from 'components/basics/Nudger/Nudger'
import PassengerItem, { PassengerItemTypes } from './PassengerItem/PassengerItem'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import { PassengerConfigurationData, usePassengersInfo } from 'components/hooks/usePassengersInfo'
import {
    MAX_PASSENGERS_PER_BOOKING,
    MAX_CHILDREN_PER_BOOKING,
    DEFAULT_PASSENGER_CONFIGURATION_ARRAY,
} from 'utils/constants'
import { capitalizeEachWord } from 'utils/string-helpers'
import { createPassengersText } from 'utils/pluralisePassengers'
import { GET_IATA_BY_SUBSTRING } from 'graphql-queries/cruise/cruise-queries'
import * as self from './PassengerConfiguration'

import styles from './PassengerConfiguration.module.scss'
import allContent from 'content/content'

const content = allContent.cruise.sharedSections.passengerConfigurationSection

const NUMBER_OF_SUGGESTIONS_TO_RENDER = 8

type IataLocation = {
    name: string
    placeType: string
    iata: string
}

export type Passenger = {
    ageType: PassengerItemTypes
    ageTypeNumber: number
    lead?: boolean
    pastPassenger?: string
    age?: number
    residency?: IataLocation
    military?: boolean
}

export interface IPassenger {
    name: string
    passengers: Passenger[]
}

export function handleOnClickResidentialSuggestion({
    suggestionPicked,
    update,
    fields,
    setIsResidentialMissingValueError,
    setResidencyValue,
}: {
    suggestionPicked: IataLocation
    update: any
    fields: Passenger[]
    setIsResidentialMissingValueError: Dispatch<SetStateAction<boolean>>
    setResidencyValue: Dispatch<SetStateAction<Record<any, string> | null>>
}): void {
    setIsResidentialMissingValueError(false)
    setResidencyValue(suggestionPicked)
    self.handleUpdatePassenger({
        passengerNumber: 0,
        property: 'residency',
        value: suggestionPicked,
        update,
        fields,
    })
}

/** Calculate number of passengers from default passengers configuration or defaults to two adults */
export function calculateInitialNumberOfPassengers(
    passengerConfiguration: Passenger[]
): Record<string, number> {
    const initialValue = { adults: 0, children: 0 }
    return (
        passengerConfiguration?.reduce(
            (sumOfPassengers, currentPassenger) =>
                currentPassenger.ageType === 'adult'
                    ? { ...sumOfPassengers, adults: sumOfPassengers.adults + 1 }
                    : { ...sumOfPassengers, children: sumOfPassengers.children + 1 },
            initialValue
        ) || { adults: 2, children: 0 }
    )
}

/** Calculate initial max number of passengers based on data in session storage or use defaults */
export function calculateMaxNumberOfPassengers(
    passengerConfiguration: PassengerConfigurationData
): Record<string, number> {
    const adults = passengerConfiguration?.adults
    const children = passengerConfiguration?.children

    return {
        adults: !isNaN(adults)
            ? calculateMaxNudgerValueForAdults({
                  adults,
                  children,
              })
            : MAX_PASSENGERS_PER_BOOKING,
        children: !isNaN(children)
            ? calculateMaxNudgerValueForChildren({ adults, children })
            : MAX_CHILDREN_PER_BOOKING,
    }
}

export function calculateMaxNudgerValueForAdults({
    adults,
    children,
}: {
    adults: number
    children: number
}): number {
    let maxAdults = 1
    if (adults + children <= MAX_PASSENGERS_PER_BOOKING) {
        maxAdults = MAX_PASSENGERS_PER_BOOKING - children
    }
    return maxAdults < 1 ? 1 : maxAdults // make sure there is always at least 1 adult
}

export function calculateMaxNudgerValueForChildren({
    adults,
    children,
}: {
    adults: number
    children: number
}): number {
    let maxChildren = 0
    if (adults + children <= MAX_PASSENGERS_PER_BOOKING && children <= MAX_CHILDREN_PER_BOOKING) {
        maxChildren = MAX_PASSENGERS_PER_BOOKING - adults
    }
    return maxChildren > MAX_CHILDREN_PER_BOOKING ? MAX_CHILDREN_PER_BOOKING : maxChildren
}

// TODO: TEST THIS
export function handleUpdatePassenger({
    passengerNumber,
    property,
    value,
    update,
    fields,
}: {
    passengerNumber: number
    property: 'age' | 'pastPassenger' | 'military' | 'residency'
    value: string | number | boolean | IataLocation
    update: HackyUpdateType
    fields: Passenger[]
}): void {
    update(passengerNumber, { ...fields[passengerNumber], [property]: value })
}

export function createRatesText(leadPassenger: Passenger): string {
    const rates = []
    if (leadPassenger.military) rates.push(content.ratesMilitary)
    if (leadPassenger.residency) rates.push(content.ratesResidential)
    if (!leadPassenger.residency && !leadPassenger.military) rates.push(content.ratesNone)

    return rates.join(', ')
}

export type HackyUpdateType = (number: number, data: Record<string, any>) => void

type PassengerConfigurationProps = {
    setShouldUpdatePassengerData: Dispatch<SetStateAction<boolean>>
    l3ApiClient: ApolloClient<NormalizedCacheObject>
}

const PassengerConfiguration: React.FC<PassengerConfigurationProps> = ({
    setShouldUpdatePassengerData,
    l3ApiClient,
}) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IPassenger>({ mode: 'all' })
    const { fields, append, update, remove, replace } = useFieldArray<IPassenger>({
        control,
        name: 'passengers',
    })

    const { setPassengers, passengerConfigurationData } = usePassengersInfo()
    const initialPassengerConfigurationToLoadInModal: Passenger[] = passengerConfigurationData
        ? passengerConfigurationData.passengerConfiguration
        : (DEFAULT_PASSENGER_CONFIGURATION_ARRAY as Passenger[])

    const hasPassengerConfigurationRanRef = React.useRef<boolean>(false)
    /** On component load, append passengers to useFiledArray with configuration pulled from session storage  */
    if (!hasPassengerConfigurationRanRef.current) {
        replace(initialPassengerConfigurationToLoadInModal)
        hasPassengerConfigurationRanRef.current = true
    }

    const [numberOfPassengers, setNumberOfPassengers] = useState<Record<string, number>>(
        calculateInitialNumberOfPassengers(initialPassengerConfigurationToLoadInModal)
    )

    /** maxNumberOfPassengers is needed to calculate max value in Nudgers and it's refreshed after each change to numberOfPassengers */
    const [maxNumberOfPassengers, setMaxNumberOfPassengers] = useState<Record<string, number>>(
        calculateMaxNumberOfPassengers(passengerConfigurationData)
    )
    const [isPassengerModalOpen, setIsPassengerModalOpen] = useState<boolean>(false)
    const [clickedButtonId, setClickedButtonId] = useState('') // set ID of button that opens modal so focus can be returned on close

    const [isResidentialMissingValueError, setIsResidentialMissingValueError] = useState(false)
    const [isResidentialChecked, setIsResidentialChecked] = useState(
        !!initialPassengerConfigurationToLoadInModal[0].residency
    )
    const initialResidentialValue = initialPassengerConfigurationToLoadInModal[0].residency ?? null
    const [residencyValue, setResidencyValue] = useState<Record<any, string> | null>(
        initialPassengerConfigurationToLoadInModal[0].residency ?? null
    )
    const [residencyInputValue, setResidencyInputValue] = useState<string>(
        initialResidentialValue?.name ?? ''
    )
    const [ageError, setAgeError] = useState(false)
    const [residencySuggestions, setResidencySuggestions] = useState<string[]>([])

    function removePropertyFromLeadPassenger(
        fields: Passenger[],
        property: 'military' | 'residency'
    ): void {
        const fieldsNoProperty = [...fields]
        delete fieldsNoProperty[0][property]
        replace(fieldsNoProperty)
    }

    function handleOnClickCheckMilitary(isChecked: boolean): void {
        if (isChecked) {
            handleUpdatePassenger({
                passengerNumber: 0,
                property: 'military',
                value: isChecked,
                update: update as HackyUpdateType,
                fields,
            })
        } else {
            removePropertyFromLeadPassenger(fields, 'military')
        }
    }

    function handleOnClickCheckResidential(isChecked: boolean): void {
        if (!isChecked) {
            removePropertyFromLeadPassenger(fields, 'residency')
        }
        setIsResidentialChecked(isChecked)
    }

    // TODO: TEST THIS (move out of component)
    function findLowestFreePassengerNumber(
        passengersList: Passenger[],
        passengerType: PassengerItemTypes
    ): number {
        const takenPassengerNumbers = passengersList
            .filter((passenger) => passenger.ageType === passengerType)
            .map((passenger) => +passenger.ageTypeNumber)
        for (let i = 1; i <= passengersList.length; i++) {
            if (takenPassengerNumbers.indexOf(i) === -1) {
                return i
            }
        }
        return takenPassengerNumbers.length + 1
    }

    // TODO: TEST THIS (move out of component)
    function findIndexOfPassengerWithHighestNumber(passengerType: PassengerItemTypes): number {
        const firstPassengerOfType = fields.findIndex(
            (passenger) => passenger.ageType === passengerType
        )
        return fields.reduce((highestIndex, currentObj, currentIndex, array) => {
            const highestValue: number = array[highestIndex].ageTypeNumber
            const currentValue = currentObj.ageTypeNumber
            return currentObj.ageType === passengerType && currentValue > highestValue
                ? currentIndex
                : highestIndex
        }, firstPassengerOfType)
    }

    // TODO: TEST THIS (move out of component)
    function findIndexOfPassengerByTypeAndNumber(
        passengerType: PassengerItemTypes,
        passengerNumber: number
    ): number {
        return fields.findIndex(
            (passenger) =>
                passenger.ageType === passengerType && passenger.ageTypeNumber === passengerNumber
        )
    }

    // TODO: TEST THIS (move out of component)
    function handlePassengersNumberChange(
        value: number,
        passengerType: PassengerItemTypes,
        passengerNumber?: number
    ): void {
        const passType = passengerType === 'adult' ? 'adults' : 'children'
        setNumberOfPassengers({ ...numberOfPassengers, [passType]: value })
        /** To add new passenger */
        if (value > numberOfPassengers[passType]) {
            let passengerToAppend: Passenger = {
                ageType: passengerType,
                ageTypeNumber: findLowestFreePassengerNumber(fields, passengerType),
            }
            /** Set default age to adult passenger */
            if (passengerType === 'adult') passengerToAppend = { ...passengerToAppend, age: 30 }
            append(passengerToAppend)
        }
        /** To remove a passenger, it filters out a passenger with the highest passenger number of given type
         * or removes specific passenger if the passenger number is given */
        if (value < numberOfPassengers[passType]) {
            const indexOfPassengerToRemove = passengerNumber
                ? findIndexOfPassengerByTypeAndNumber(passengerType, passengerNumber)
                : findIndexOfPassengerWithHighestNumber(passengerType)
            remove(indexOfPassengerToRemove)
        }
        let adults, children
        if (passengerType === 'adult') {
            adults = value
            children = numberOfPassengers.children
        }
        if (passengerType === 'child') {
            adults = numberOfPassengers.adults
            children = value
        }
        if (
            typeof adults !== 'undefined' &&
            adults &&
            typeof children !== 'undefined' &&
            children >= 0
        )
            setMaxNumberOfPassengers({
                ...numberOfPassengers,
                adults: calculateMaxNudgerValueForAdults({ adults, children }),
                children: calculateMaxNudgerValueForChildren({ adults, children }),
            })
    }

    function handleOnChangeAdultsNudger(number: number): void {
        handlePassengersNumberChange(number, 'adult')
    }

    function handleOnChangeChildrenNudger(number: number): void {
        handlePassengersNumberChange(number, 'child')
    }

    function handleRemovePassenger(
        passengerNumber: number,
        passengerAgeType: PassengerItemTypes
    ): void {
        const passType = passengerAgeType === 'adult' ? 'adults' : 'children'
        handlePassengersNumberChange(
            numberOfPassengers[passType] - 1,
            passengerAgeType,
            passengerNumber
        )
    }

    // TODO: Is this the same result as the same named function in usePassengerInfo.ts? maybe export one of them and remove the other?
    function sortPassengers(a: Passenger, b: Passenger): number {
        /** Makes sure the first passenger is always an adult and lead.
         * Then sorts passengers adult first, then children.
         * If passengers are of the same ageType then sort by lowest passenger number first */
        if (
            (a.ageType === 'adult' && a?.lead) ||
            (a.ageType === 'adult' && b.ageType === 'adult' && a.ageTypeNumber > b.ageTypeNumber) ||
            (a.ageType === 'child' && b.ageType === 'adult') ||
            (a.ageType === 'child' && b.ageType === 'child' && a.ageTypeNumber > b.ageTypeNumber)
        ) {
            return 1
        }
        return -1
    }

    function onClickModifyPassengerConfigButton({ buttonId }: { buttonId: string }): void {
        setClickedButtonId(buttonId)
        setIsPassengerModalOpen(true)
    }
    const passengers = (
        <div className={styles['passengers-container']}>
            {fields.length > 0 &&
                [...fields].sort(sortPassengers).map((passenger, index) => {
                    if (errors.passengers?.[index]?.age && !ageError) setAgeError(true)
                    return (
                        <div className={styles.passenger} key={passenger.id}>
                            <PassengerItem
                                passenger={passenger}
                                index={index}
                                control={control}
                                closePassenger={handleRemovePassenger}
                                updatePassenger={handleUpdatePassenger}
                                update={update as HackyUpdateType}
                                fields={fields}
                            />
                        </div>
                    )
                })}
            {Array.from(
                /** Create an array to display placeholders for visual slots without passengers in */
                Array(MAX_PASSENGERS_PER_BOOKING - fields.length),
                (_, index) => {
                    return { ageType: 'placeholder', ageTypeNumber: index } as Passenger
                }
            ).map((placeholder) => (
                <div className={styles.passenger} key={`placeholder${placeholder.ageTypeNumber}`}>
                    <PassengerItem
                        passenger={placeholder}
                        update={update as HackyUpdateType}
                        fields={fields}
                    />
                </div>
            ))}
        </div>
    )

    return (
        <div className={styles.container}>
            <div className={styles['modify-passengers']}>
                <div className={styles['modify-passengers-item']}>
                    <Text weight='bold' color='white'>
                        {content.passengersHeading}
                    </Text>
                    {passengerConfigurationData && (
                        <Text color='white'>{`${createPassengersText(
                            passengerConfigurationData?.adults,
                            passengerConfigurationData?.children
                        )}`}</Text>
                    )}
                </div>
                <div className={styles['modify-passengers-item']}>
                    <Text weight='bold' color='white'>
                        {content.ratesHeading}
                    </Text>
                    {passengerConfigurationData && (
                        <Text color='white'>
                            {createRatesText(passengerConfigurationData.passengerConfiguration[0])}
                        </Text>
                    )}
                </div>
                <Button
                    id='modify-passengers-button'
                    onDarkBackground={true}
                    flavour='secondary'
                    iconName='User'
                    type='button'
                    text={content.buttonModify}
                    onClick={(): void =>
                        onClickModifyPassengerConfigButton({ buttonId: 'modify-passengers-button' })
                    }
                />
            </div>
            <Modal
                headerText={content.title}
                isOpen={isPassengerModalOpen}
                returnFocusId='modifyPassengers'
                setClosed={(): void => setIsPassengerModalOpen(false)}
            >
                <form
                    className={styles.form}
                    onSubmit={handleSubmit((formFields) => {
                        const data = {
                            passengerConfiguration: formFields.passengers,
                            adults: numberOfPassengers.adults,
                            children: numberOfPassengers.children,
                        }
                        if (isResidentialChecked && !formFields.passengers[0].residency) {
                            /** Show error if submitted with checked residential but no input value */
                            setIsResidentialMissingValueError(true)
                        } else {
                            /** Clear out residency */
                            if (!isResidentialChecked && residencyValue !== null) {
                                setResidencyValue(null)
                                setResidencyInputValue('')
                            }
                            setPassengers(data)
                            setShouldUpdatePassengerData(true)
                            setIsPassengerModalOpen(false)
                            setAgeError(false)
                        }
                    })}
                >
                    <div className={styles['controls-container']}>
                        <div className={styles.controls}>
                            <div>
                                <Heading heading='2'>{content.adultHeading}</Heading>
                                <Text>{content.adultAges}</Text>
                            </div>
                            <Nudger
                                minCounterValue={1}
                                maxCounterValue={maxNumberOfPassengers.adults}
                                value={numberOfPassengers.adults}
                                onChange={handleOnChangeAdultsNudger}
                                labelText={''} // TODO: Fix so we don't have to add '', should be the right style, or need to pass aria label value, or pass JSX in rather than string.
                            />
                        </div>
                        <div className={styles.controls}>
                            <div>
                                <Heading heading='2'>{content.childrenHeading}</Heading>
                                <Text>{content.childrenAges}</Text>
                            </div>
                            <Nudger
                                minCounterValue={0}
                                maxCounterValue={maxNumberOfPassengers.children}
                                value={numberOfPassengers.children}
                                onChange={handleOnChangeChildrenNudger}
                                labelText={''}
                            />
                        </div>
                    </div>
                    {passengers}
                    {ageError && (
                        <InfoBanner
                            bannerType='error'
                            text={content.errors.childAge}
                            id='age-error'
                            isCloseable={false}
                        />
                    )}
                    <div className={styles['additional-information-section__container']}>
                        <div className={styles['additional-information-section__header']}>
                            <Text weight='bold' color='white'>
                                {content.additionalInformationSection.title}
                            </Text>
                        </div>
                        <div className={styles['additional-information-section__content']}>
                            <Text weight='bold' color='primary-blue'>
                                {content.additionalInformationSection.residentialRatesHeader}
                            </Text>
                            <Checkbox
                                id='residential-rates-checkbox'
                                text={
                                    content.additionalInformationSection
                                        .residentialRatesCheckboxText
                                }
                                defaultChecked={isResidentialChecked}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                    handleOnClickCheckResidential(event.target.checked)
                                }
                            />
                            <FieldError
                                errorMessage={content.errors.residentialValueMissing}
                                showError={isResidentialMissingValueError}
                                inputId='residential-rates-checkbox'
                            />
                            {isResidentialChecked && (
                                <Controller
                                    control={control}
                                    name='passengers.0.residency'
                                    render={(): React.ReactElement => (
                                        <AutosuggestWithQuery
                                            openSuggestionsOnFocus={true}
                                            inputValue={residencyInputValue}
                                            setInputValue={(value: string): void =>
                                                setResidencyInputValue(value)
                                            }
                                            // initialInputValue={residencyValue?.name ?? ''}
                                            getSuggestionValue={(value: IataLocation): string =>
                                                value.name
                                            }
                                            onSuggestionSelected={(value: IataLocation): void => {
                                                handleOnClickResidentialSuggestion({
                                                    suggestionPicked: {
                                                        iata: value.iata,
                                                        name: value.name,
                                                        placeType: value.placeType,
                                                    },
                                                    fields,
                                                    update,
                                                    setIsResidentialMissingValueError,
                                                    setResidencyValue,
                                                })
                                            }}
                                            name='destination'
                                            renderSuggestion={(
                                                value: Record<string, any>
                                            ): React.ReactElement => (
                                                <span>
                                                    {value.name} (
                                                    {capitalizeEachWord(value.placeType)})
                                                </span>
                                            )}
                                            setSuggestionsData={setResidencySuggestions}
                                            suggestionsData={residencySuggestions}
                                            query={GET_IATA_BY_SUBSTRING}
                                            staticVariables={{
                                                numberOfLocations: NUMBER_OF_SUGGESTIONS_TO_RENDER,
                                            }}
                                            client={l3ApiClient}
                                            handleResults={(result): void => {
                                                if (result?.data.getIataByPlacenameSubString) {
                                                    setResidencySuggestions(
                                                        result.data.getIataByPlacenameSubString
                                                    )
                                                }
                                            }}
                                            renderInputComponent={({
                                                ...rest
                                            }: RenderInputComponentProps): React.ReactElement => (
                                                <LabelledInput
                                                    label={
                                                        content.additionalInformationSection
                                                            .residencyInputLabel
                                                    }
                                                    labelHidden={true}
                                                    placeholder={
                                                        content.additionalInformationSection
                                                            .residencyInputPlaceholder
                                                    }
                                                    htmlFor='passengers.0.residency'
                                                    {...rest}
                                                >
                                                    <TextInput />
                                                </LabelledInput>
                                            )}
                                        />
                                    )}
                                />
                            )}
                            <Text weight='bold' color='primary-blue'>
                                {content.additionalInformationSection.militaryRatesHeader}
                            </Text>
                            <Checkbox
                                text={
                                    content.additionalInformationSection
                                        .militaryRateCodesCheckBoxText
                                }
                                defaultChecked={
                                    typeof passengerConfigurationData?.passengerConfiguration !==
                                    'undefined'
                                        ? passengerConfigurationData?.passengerConfiguration[0]
                                              .military
                                        : false
                                }
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                    handleOnClickCheckMilitary(event.target.checked)
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            type='button'
                            onClick={(): void => {
                                const returnFocusTo = document.getElementById(clickedButtonId)
                                setIsPassengerModalOpen(false)
                                /** Uncheck the checkbox (and clear error) if no value is in the input */
                                setIsResidentialMissingValueError(false)
                                /** Set checkbox and input to state on opening modal*/
                                setIsResidentialChecked(!!initialResidentialValue)
                                setResidencyValue(initialResidentialValue)
                                setResidencyInputValue(initialResidentialValue?.name ?? '')
                                returnFocusTo?.focus()
                            }}
                            text={content.buttonCancel}
                            flavour='tertiary'
                        />
                        <Button type='submit' text={content.buttonContinue} />
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default PassengerConfiguration
