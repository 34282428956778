import React, { useState, useEffect, useRef, createContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import { Helmet } from 'react-helmet'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

import ErrorBoundary from 'components/blocks/ErrorBoundary/ErrorBoundary'
import GenericLayout from 'components/layouts/app/GenericLayout/GenericLayout'
import {
    getDataFromLocalStorage,
    removeDataFromLocalStorage,
    setDataToLocalStorage,
} from 'utils/use-local-storage'
import { getReactAppToggles } from 'utils/get-feature-toggles'
import { hostedUxCognitoAuth } from 'utils/cognito-helpers/hosted-ux-cognito-auth'
import { extractCognitoFields } from './utils/cognito-helpers/extract-cogntio-fields'
import AppRoutes, { ROUTES } from 'components/sections/app/AppRoutes'
import domainAuthInfo, { DEMO_DOMAINS } from 'data/domainAuthInfo'
import { GET_CONNECT_USER_DATA } from './graphql-queries/app-queries'

import {
    COGNITO_ACTIONS,
    COGNITO_SETUPS,
    LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY,
    LOCAL_STORAGE_USER_DATA_KEY,
    INITIAL_PRODUCT_TIERS,
    DEMO_PRODUCT_TIERS,
    VITALLY_EVENTS,
    COGNITO_SESSION_EXPIRY_MINS,
} from './utils/constants'

import createApolloClient from './utils/apollo-client/create-apollo-client'
import CustomerSuccess from './services/customerSuccess/customerSuccess.service'

import content from './content/content'
import { UserAnalytics } from 'services/userAnalytics/userAnalytics.service'
import {
    APICruisesMetaData,
    CruisesMetaData,
    getCruisesMetaData,
} from './api-data-models/CruisesContentModel'
import { useRest, UseRestOptions } from './components/hooks/useRest'

const toggles = getReactAppToggles(
    process.env.REACT_APP_FEATURE_TOGGLES ? process.env.REACT_APP_FEATURE_TOGGLES : `"{}"`
)
export const FeatureToggleContext = createContext(toggles)

export function useDomainAndIsDemoUrl(): { domain: string; isDemoUrl: boolean } {
    /** Used to decide is sub url is valid (www.not-valid.agentconnect.traveltek.net) and for extracting cognito setup params */
    const domainRef = useRef<string>(window.location.origin)
    const domain = domainRef.current

    /** Set Demo site features on/off */
    const isDemoUrlRef = useRef<boolean>(DEMO_DOMAINS.includes(domain)) // if true, see spinner instead of LoginForm (for when HOSTED UX cognito pools are fetching the JWT with the CODE url param).
    const isDemoUrl = isDemoUrlRef.current
    return { domain, isDemoUrl }
}

const managerUrl = process.env.REACT_APP_CONNECT_MANAGER_SERVICE_URL + '/manager'
const managerApiClient = createApolloClient(managerUrl)

function App({ apiClient }: { apiClient: ApolloClient<NormalizedCacheObject> }): JSX.Element {
    const navigate = useNavigate()
    const location = useLocation().pathname
    const navigateTo = useRef<string | null>(null)
    /** navigate() should be called in useEffect so first render is completed before the component is attempted to be unmounted - https://www.dhiwise.com/post/why-you-should-call-navigate-in-areact-useeffect */

    useEffect(() => {
        if (navigateTo.current) {
            navigate(navigateTo.current)
            navigateTo.current = null
        }
    }, [navigate])

    const { domain, isDemoUrl } = useDomainAndIsDemoUrl()
    /** The cognito response (HOSTED and AMPLIFY) for signed-in user, (contains the access tokens needed for api auth) */
    const [userCognitoData, setUserCognitoData] = useState<Record<string, any> | undefined>(
        (): Record<string, any> | undefined => {
            return getDataFromLocalStorage({ key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY })
        }
    )
    /** ASSUME  on loading that app if valid useCognitoData is found they should be authorised */
    const [isAuthorised, setIsAuthorised] = useState<null | boolean>(userCognitoData ? true : null)

    /** State for knowing if the session has timed out - to show banner to user why they are suddenly on login page */
    const [showExpiredBanner, setShowExpiredBanner] = useState<boolean>(false)

    /** Tell app if url uses HOSTED or AMPLIFY login - Used to show spinner on login page while sending to CognitoAuth login, and keep spinner while verifying session (when user returns from hosted cognito page with code param). */
    const [isHostedUxDomain] = useState<boolean | null>((): boolean | null => {
        if (domain in domainAuthInfo) {
            const cognitoSetup = domainAuthInfo[domain].cognitoSetup
            return cognitoSetup === COGNITO_SETUPS.HOSTED_UI // DRIVES HOSTED UX vs AMPLIFY login flow
        } else {
            /** DOMAIN is not valid for serving the app, show 404 page */
            datadogLogs.logger.log(`Domain not valid ${domain}, showing 404 page`)
            navigateTo.current = ROUTES.FOUR04
            return null
        }
    })

    /** This tenantId is populated from cognito auth data, needed to call getConnectUserData */
    const [tenantId, setTenantId] = useState<string>(extractCognitoFields(userCognitoData).tenantId)
    const [userEmail, setUserEmail] = useState<string>(
        userCognitoData ? extractCognitoFields(userCognitoData).email : ''
    )
    const [userId, setUserId] = useState<string>(
        userCognitoData ? extractCognitoFields(userCognitoData).userId : ''
    )
    const [userName, setUserName] = useState<string>(
        userCognitoData ? extractCognitoFields(userCognitoData).userName : ''
    )
    const [userRoles, setUserRoles] = useState<UserRole[]>([])

    /** Use Data for driving access to features (and preferences?) */
    const [productTiers, setProductTiers] =
        useState<Record<ProductNameType, ProductTierType>>(INITIAL_PRODUCT_TIERS)

    /** This tenant data is from getConnectUserData and includes a lot more than tenantId */
    const [tenantInfo, setTenantInfo] = useState<TenantData | undefined>()
    const [isFetchingConnectUserData, setIsFetchingConnectUserData] = useState<boolean>(true)
    const [errorFetchingUserData, setFetchingUserData] = useState<string | null>(null)

    const userContext = datadogLogs.getGlobalContext()

    function cleanUpAppOnLogout(): void {
        Auth.signOut().then() // sign out of cognito
        removeDataFromLocalStorage({ key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY }) // clear local storage of user cognito data
        setUserCognitoData(undefined) // set app state userCognitoData to 'un-fetched' state of undefined
        setIsAuthorised(null) // set app state isAuthorised to 'un-fetched' state of null (not false as if they were denied)
        setTenantId('') // set app state tenantId to 'un-fetched' state of undefined state (as if not fetched)
        setTenantInfo(undefined) // set app state tenantInfo to 'un-fetched' state of undefined state (as if not fetched)
        setIsFetchingConnectUserData(true) // set app state isFetchingConnectUserData to 'un-fetched' state of true
        setProductTiers(INITIAL_PRODUCT_TIERS) // reset app state product tiers to initial/default unset state
        removeDataFromLocalStorage({ key: LOCAL_STORAGE_USER_DATA_KEY }) // clear the userData - product tiers, tenantInfo etc.
        setUserEmail('') // reset app state userEmail to empty string
        setUserId('') // reset app state userId to empty string
        setUserName('') // reset app state userName to empty string
        datadogRum.setUser({ id: '', name: '', email: '', plan: '', tenantId: '' }) // clear datadog user
    }

    function logUserOut({ to }: { to: string }): void {
        cleanUpAppOnLogout()
        navigate(to)
    }

    /** When app first loads (say refresh window) or user has just been logged out */
    useEffect(() => {
        /** On load of app we check if userData exists in local storage */
        const localUserCognitoData = getDataFromLocalStorage({
            key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY,
        })

        /** Look-up userPool data from domainAuthInfo. AMPLIFY or COGNITO UX login, userPoolId clientId etc. */
        if (domain in domainAuthInfo) {
            const cognitoHostName: string | undefined = domainAuthInfo[domain].hostName
            const redirectUri = domainAuthInfo[domain].redirectUri
            const clientId = domainAuthInfo[domain].clientId
            const cognitoSetup = domainAuthInfo[domain].cognitoSetup

            /** FOUND COGNITO DATA (non-expired) USER DATA STORED LOCALLY */
            if (localUserCognitoData) {
                setUserId(extractCognitoFields(localUserCognitoData).userId)
                setUserEmail(extractCognitoFields(localUserCognitoData).email)
                setUserName(extractCognitoFields(localUserCognitoData).userName)

                if (localUserCognitoData.challengeName === COGNITO_ACTIONS.new_password) {
                    /** If challengeName = new_password they do not set to authorised, as they need to change their password.
                     * NOTE: Will happen if user is on NEW_PASSWORD form and refreshes app or presses back button.
                     * removeDataFromLocalStorage({ key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY }) // do not delete - just redirect back to password.
                     */
                    navigateTo.current = ROUTES.NEW_PASSWORD
                } else {
                    /** Cognito Data is OK to let loose on app, save localState userData to app state and setIsAuthorised=true */
                    setUserCognitoData(localUserCognitoData)
                    setIsAuthorised(true)
                    setTenantId(extractCognitoFields(localUserCognitoData).tenantId) // not present in demo pools, but is in paid pools.

                    /** If user REFRESHED the app, and is AMPLIFY we need to configure Auth again, so they can log out and in without getting stuck. */
                    if (cognitoSetup === COGNITO_SETUPS.AMPLIFY) {
                        const userPoolId = domainAuthInfo[domain].userPoolId

                        /** Configure aws-amplify ready for login pages etc. */
                        Auth.configure({
                            region: 'eu-west-1',
                            userPoolId: userPoolId,
                            userPoolWebClientId: clientId,
                            mandatorySignIn: true,
                        })
                    }
                    /** Demo urls need product tiers set manually on refreshing the app when logged on */
                    if (isDemoUrl) {
                        setProductTiers(DEMO_PRODUCT_TIERS)
                        setDataToLocalStorage({
                            expiryMins: COGNITO_SESSION_EXPIRY_MINS,
                            key: LOCAL_STORAGE_USER_DATA_KEY,
                            data: { productTiers: DEMO_PRODUCT_TIERS, tenantInfo: null },
                        })
                    }
                }
            } else if (isAuthorised === true) {
                /** Timeout of cognitoUser Data requires state clean up */
                datadogLogs.logger.log(
                    'Expired session. JWT expired but user was previously logged in.'
                )
                setShowExpiredBanner(true) // this is to warn user why they are suddenly on the login page.
                logUserOut({ to: ROUTES.TIMEOUT })
            } else if (cognitoSetup === COGNITO_SETUPS.HOSTED_UI) {
                /** NO COGNITO USER DATA stored locally - User must log in! */
                /** Hosted_ui - send user to our aws cognito auth to log in */

                hostedUxCognitoAuth({
                    appDomain: domain,
                    LOCAL_STORAGE_AUTH_DATA_KEY: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY,
                    setIsAuthorised,
                    cognitoHostName,
                    redirectUri,
                    clientId,
                    setUserCognitoData,
                }).then(() => {
                    /** Demo urls need need product tiers set manually */
                    if (isDemoUrl) {
                        setProductTiers(DEMO_PRODUCT_TIERS)
                        setDataToLocalStorage({
                            expiryMins: COGNITO_SESSION_EXPIRY_MINS,
                            key: LOCAL_STORAGE_USER_DATA_KEY,
                            data: {
                                productTiers: DEMO_PRODUCT_TIERS,
                                tenantInfo: { systemFinanceId: 'demo-tenant' },
                            },
                        })
                    }
                })
            } else if (cognitoSetup === COGNITO_SETUPS.AMPLIFY) {
                /** Use our app login pages to log - using aws-amplify sdk to communicate with cognito user pool */
                const userPoolId = domainAuthInfo[domain].userPoolId
                /** Configure aws-amplify ready for login pages etc. */
                Auth.configure({
                    region: 'eu-west-1',
                    userPoolId: userPoolId,
                    userPoolWebClientId: clientId,
                    mandatorySignIn: true,
                })

                /** Without local state user must log in (has either timed out or just not logged on)
                 *  Unless already on Pages that do not need LOGIN:
                 */
                if (
                    ![
                        ROUTES.LOGIN, // tried having this array as a constant, but app couldn't compile.
                        ROUTES.FORGOT_PASSWORD,
                        ROUTES.RESET_PASSWORD,
                        ROUTES.TIMEOUT,
                        ROUTES.VERSION,
                        ROUTES.FOUR04,
                        ROUTES.SIGNUP,
                    ].includes(location)
                ) {
                    navigateTo.current = ROUTES.LOGIN
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]) // Do NOT put isAuthorised as dependency, useEffect will loop infinitely!!

    /** To set app features we need the user data - tenantInfo and Tire for each product, will run this if tenantId changes value (from undefined to a value) */
    useEffect(() => {
        const localUserData = getDataFromLocalStorage({
            key: LOCAL_STORAGE_USER_DATA_KEY,
        })
        const localProductTiersData = localUserData?.productTiers ?? INITIAL_PRODUCT_TIERS
        const localTenantInfo = localUserData?.tenantInfo
        const localUserRoles = localUserData?.userRoles ?? []
        if (localUserData?.tenantInfo?.tenantId) {
            /** If local storage has productTiers, then on refresh of app use this and skip fetching */
            setProductTiers(localProductTiersData)
            setTenantInfo(localTenantInfo)
            setUserRoles(localUserRoles)
            setIsFetchingConnectUserData(false)
        } else if (tenantId) {
            setIsFetchingConnectUserData(true)
            /** Found TenantID but not UserData(productTiers) so trigger getConnectUserData.
             * user is freshly logged on then, or a demo user.*/
            managerApiClient
                .query({ query: GET_CONNECT_USER_DATA })
                .then((response) => {
                    const products: ProductTierData[] = response.data?.getConnectUserData?.products
                    const tenantInfo: TenantData = response.data?.getConnectUserData?.tenant
                    const userRoles: UserRole[] = response.data?.getConnectUserData?.roles
                    setTenantInfo(tenantInfo)
                    if (userRoles) setUserRoles(userRoles)
                    const productTiersTemp = INITIAL_PRODUCT_TIERS
                    if (products) {
                        for (const product of products) {
                            productTiersTemp[product.name] = product.tier
                        }
                    }
                    setProductTiers({ ...productTiersTemp })
                    setDataToLocalStorage({
                        expiryMins: COGNITO_SESSION_EXPIRY_MINS,
                        key: LOCAL_STORAGE_USER_DATA_KEY,
                        data: {
                            productTiers: productTiersTemp,
                            tenantInfo: tenantInfo,
                            userRoles: userRoles,
                        },
                    })
                    datadogLogs.logger.info(
                        `source: getConnectUserData, tenantId: ${tenantId}, productTiers: ${JSON.stringify(
                            products
                        )}, userRoles: ${userRoles}`,
                        { userContext }
                    )
                })
                .catch((error) => {
                    datadogLogs.logger.error(
                        `source: getConnectUserData, tenantId: ${tenantId}`,
                        { userContext },
                        error
                    )
                    setFetchingUserData(JSON.stringify(error)) // TODO: sort with better error handling - what is the error object etc?!.. meh it'll not be in use soon.
                })
                .finally(() => {
                    setIsFetchingConnectUserData(false)
                })
        } else {
            setIsFetchingConnectUserData(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managerApiClient, tenantId]) // userContext cannot be put in the dependency array because on every render the object is newly created, even if the values are the same due... I think

    useEffect(() => {
        if (datadogRum.getUser().id !== userId && userEmail) {
            if (isDemoUrl) {
                datadogRum.setUser({
                    id: userId,
                    name: userName,
                    email: userEmail,
                    plan: 'demo',
                    tenantId: 'demo-user',
                    companyName: 'Demo-pool',
                })
            } else if (tenantId && tenantInfo?.companyName) {
                datadogRum.setUser({
                    id: userId,
                    name: userName,
                    email: userEmail,
                    plan: 'paid',
                    tenantId: tenantId,
                    companyName: tenantInfo?.companyName,
                })
                datadogLogs.setGlobalContext({
                    userRoles: userRoles,
                    userName: userName,
                    userId: userId,
                    emailAddress: userEmail,
                    tenantId: tenantId,
                    companyName: tenantInfo.companyName,
                    companyLocation: tenantInfo.companyLocation,
                    financeSystemId: tenantInfo.financeSystemId,
                    companyType: tenantInfo.companyType,
                    productTiers: productTiers,
                })
                if (tenantInfo?.financeSystemId) {
                    CustomerSuccess.logAccount({ tenantInfo })
                    const userData = {
                        userName,
                        userEmail,
                        userId,
                        productTiers,
                        companyName: tenantInfo?.companyName,
                    }
                    CustomerSuccess.logUser(userData)
                    CustomerSuccess.survey()
                    CustomerSuccess.track({
                        eventName: VITALLY_EVENTS.APP_LOAD,
                        userId, // This tack even needs to pass userId because the service gets undefined from global user context on app load.
                    })
                }
            }
        }
        if (userId) UserAnalytics.sendUserId(userId)
        if (tenantId) UserAnalytics.sendCustomProperty('tenant_id', tenantId)
        if (tenantInfo?.financeSystemId)
            UserAnalytics.sendCustomProperty('finance_system_id', tenantInfo?.financeSystemId)
    }, [
        isDemoUrl,
        tenantId,
        userRoles,
        userId,
        userName,
        userEmail,
        tenantInfo,
        tenantInfo?.companyName,
        tenantInfo?.financeSystemId,
        productTiers,
    ])

    const [cruisesMetaData, setCruisesMetaData] = useState<CruisesMetaData>({
        allDeparturePorts: [],
        allArrivalPorts: [],
        allCountries: [],
        allPorts: [],
        allShips: [],
        allSuppliers: [],
        allUnPorts: [],
        numberOfResults: 0,
    })

    const fetchOptions: UseRestOptions = {
        url: process.env.REACT_APP_CRUISE_SEARCH_SERVICE_URL + '/metadata',
        method: 'GET',
        source: 'SearchPage - GET_META-DATA',
        cache: true,
        cacheMaxAge: 1000 * 60 * 60 * 3, // 3 hours
        skip: !isAuthorised,
    }
    const { result } = useRest(fetchOptions)

    useEffect(() => {
        if (result && result?.numberOfResults > 0) {
            setCruisesMetaData(getCruisesMetaData(result as APICruisesMetaData))
        }
    }, [result])

    return (
        <ErrorBoundary cleanUpAppOnRestart={cleanUpAppOnLogout}>
            <Helmet>
                <title>{content.app.tabTitle}</title>
            </Helmet>
            <GenericLayout
                apiClient={apiClient}
                isAuthorised={isAuthorised}
                isDemoUrl={isDemoUrl}
                logUserOut={logUserOut}
                productTiers={productTiers}
                userEmail={userEmail}
                userRoles={userRoles}
                tenantFinanceId={tenantInfo?.financeSystemId}
            >
                <AppRoutes
                    cruisesMetaData={cruisesMetaData}
                    userRoles={userRoles}
                    cleanUpAppOnLogout={cleanUpAppOnLogout}
                    apiClient={apiClient}
                    managerApiClient={managerApiClient}
                    isAuthorised={isAuthorised}
                    productTiers={productTiers}
                    errorFetchingUserData={!!errorFetchingUserData}
                    isFetchingConnectUserData={isFetchingConnectUserData}
                    setUserCognitoData={setUserCognitoData}
                    hasUserCognitoData={!!userCognitoData}
                    isHostedUxDomain={isHostedUxDomain}
                    setIsAuthorised={setIsAuthorised}
                    setTenantId={setTenantId}
                    showExpiredBanner={showExpiredBanner}
                    setShowExpiredBanner={setShowExpiredBanner}
                    tenantFinanceId={tenantInfo?.financeSystemId}
                    companyName={tenantInfo?.companyName}
                    isDemoUrl={isDemoUrl}
                />
            </GenericLayout>
        </ErrorBoundary>
    )
}

export default App
