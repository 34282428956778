import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import HotelResultsList from '../HotelResultsList/HotelResultsList'
import { Hotel } from 'api-data-models/HotelResultsContentModel'
import styles from './HotelPaginatedResultsList.module.scss'

type HotelPaginatedResultsListProps = {
    filteredItems: Hotel[]
    itemsPerPage: number
    totalNumberOfHotels: number
}
const HotelPaginatedResultsList: React.FC<HotelPaginatedResultsListProps> = ({
    itemsPerPage,
    filteredItems,
    totalNumberOfHotels,
}) => {
    const [itemOffset, setItemOffset] = useState(0)
    const endOffset = itemOffset + itemsPerPage
    const currentItems = filteredItems.slice(itemOffset, endOffset)
    const pageCount = Math.ceil(filteredItems.length / itemsPerPage)
    const handlePageClick = (event: any): void => {
        const newOffSet = (event.selected * itemsPerPage) % filteredItems.length
        setItemOffset(newOffSet)
    }
    return (
        <div>
            <HotelResultsList
                hotelsToDisplay={currentItems}
                totalNumberOfHotels={totalNumberOfHotels}
                filteredNumberOfItems={totalNumberOfHotels}
            />
            <ReactPaginate
                className={styles['controller-container']}
                breakLabel='...'
                nextLabel=' >'
                previousLabel='< '
                pageRangeDisplayed={3}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                pageClassName={styles.buttons}
                activeClassName={styles['active-item']}
                previousClassName={styles.buttons}
                nextClassName={styles.buttons}
                previousLinkClassName={styles.buttons}
                nextLinkClassName={styles.buttons}
            />
        </div>
    )
}
export default HotelPaginatedResultsList
