import { gql } from '@apollo/client'

export const GET_CONNECT_USER_DATA = gql`
    query GetConnectUserData {
        getConnectUserData {
            tenant {
                financeSystemId
                companyLocation
                companyName
                companyType
                id
            }
            roles
            products {
                name
                tier
            }
            userGroup {
                description
                title
                salesChannels {
                    title
                    salesChannelId
                }
                userGroupId
            }
        }
    }
`

export const SEND_EMAIL = gql`
    mutation sendEmail(
        $recipient: String!
        $template_name: String!
        $template_data: TemplateDataInput!
    ) {
        sendEmail(
            recipient: $recipient
            template_name: $template_name
            template_data: $template_data
        ) {
            recipient
        }
    }
`

export const SIGNUP = gql`
    mutation SignUp(
        $companyName: String!
        $companyLocation: String!
        $companyType: CompanyTypeEnum!
        $firstName: String!
        $lastName: String!
        $emailAddress: String!
        $contactNumber: String!
        $companyTier: CompanyTierEnum
    ) {
        signup(
            tenant: {
                companyName: $companyName
                companyType: $companyType
                companyLocation: $companyLocation
                companyTier: $companyTier
            }
            user: {
                firstName: $firstName
                lastName: $lastName
                emailAddress: $emailAddress
                contactNumber: $contactNumber
                role: OWNER
            }
        ) {
            status
        }
    }
`
