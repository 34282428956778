import { INITIAL_PRODUCT_TIERS } from '../constants'

/** The user data we store in our user context is */
export const extractUserDataFields = (
    userData: GlobalContextUserData | undefined
): {
    companyLocation: string
    companyName: string
    companyType: string
    emailAddress: string
    financeSystemId: string
    productTiers: Record<string, string>
    tenantId: string
    userId: string
    userName: string
    userRoles: UserRole[]
} => {
    const companyLocation = userData?.companyLocation ?? ''
    const companyName = userData?.companyName ?? ''
    const companyType = userData?.companyType ?? ''
    const emailAddress = userData?.emailAddress ?? ''
    const financeSystemId = userData?.financeSystemId ?? ''
    const productTiers = userData?.productTiers ?? INITIAL_PRODUCT_TIERS
    const tenantId = userData?.tenantId ?? ''
    const userId = userData?.userId ?? ''
    const userName = userData?.userName ?? ''
    const userRoles = userData?.userRoles ?? []

    return {
        companyLocation,
        companyName,
        companyType,
        emailAddress,
        financeSystemId,
        productTiers,
        tenantId,
        userId,
        userName,
        userRoles,
    }
}
