import { datadogLogs } from '@datadog/browser-logs'
import { v4 } from 'uuid'

import { getAccessToken, getIdToken } from 'utils/auth-header'

import allContent from 'content/content'

const content = allContent.error.userData

export type CallApiOptions = {
    /** url */
    url: string
    /** object containing variables to be passed in body of methods other than GET */
    variables?: Record<string, any>
    /** method type */
    method: HTTPMethods
    /** used to trigger browser cache of response */
    cache?: boolean
    /** sets max age in milliseconds allowed for cache of response */
    cacheMaxAge?: number
}

/**
 * callApi: is a service to call Rest API
 * Takes params of method, url and variables in order to call fetch API
 * @param { CallApiOptions }  options
 * @returns { Promise<Response> | Error } Returns a promise that resolves to a Response object or throws an Error
 */
async function callApi({
    url,
    method,
    variables,
    cache = false,
    cacheMaxAge = 1000 * 60 * 10, // 10 minutes
}: CallApiOptions): Promise<Response> {
    const userContext = datadogLogs.getGlobalContext()
    const accessToken = getAccessToken()
    const idToken = getIdToken()

    let tokenErrorMessage

    if (!accessToken) tokenErrorMessage = content.noAccessToken
    if (!idToken) tokenErrorMessage = content.noIdToken

    if (tokenErrorMessage) {
        throw new Error(tokenErrorMessage)
    }

    // don't pass body to the post request if there is none
    const shouldSendTheRequestBody =
        method !== 'GET' && variables && Object.keys(variables).length > 0

    const correlationId = v4() // Generate correlation ID for the request
    const options = {
        method: method,
        headers: {
            'x-correlation-id': correlationId,
            authorization: accessToken, // EKS
            authorizationToken: accessToken, // connect-manager-service
            tokens: JSON.stringify({
                authorization_token: accessToken,
                id_token: idToken,
            }),
            'Content-Type': 'application/json',
            ...(cache &&
                method === 'GET' && { 'Cache-Control': `private, max-age=${cacheMaxAge}` }),
        }, // Browsers only cache GET requests
        ...(shouldSendTheRequestBody && { body: JSON.stringify(variables) }), // body can not exist
    }

    /** Log Fetch request with correlation ID */
    datadogLogs.logger.info(
        `Call-Api-Service Request, method: ${method}, url: ${url} variables: ${JSON.stringify(
            variables
        )}, x-correlation-id: ${correlationId}`,
        {
            'x-correlation-id': correlationId,
            url: url,
            method: method,
            variables: variables,
            userContext: userContext,
        }
    )

    return fetch(url, options).then(async (response) => {
        const headers = response.headers
        if (response.ok) {
            /** Log Fetch response with correlation ID */
            datadogLogs.logger.info(
                `Call-Api-Service Response, status: ${response.status}, url: ${
                    response.url
                } variables: ${JSON.stringify(variables)}, x-correlation-id: ${headers.get(
                    'x-correlation-id'
                )}`,
                {
                    'x-correlation-id': headers.get('x-correlation-id'),
                    url: url,
                    status: response.status,
                    variables: variables,
                    userContext: userContext,
                }
            )
            return response
        }

        // TODO: Improve what we get back from HTTP REST api errors, are the more fields we can get, which are optional?
        const body: {
            name: string
            message: string
            detail: [
                {
                    loc: string[]
                    msg: string
                    type: string
                },
            ]
        } = await response.json()
        datadogLogs.logger.error(
            `Call-Api-Service Error, status: ${response.status}, url: ${
                response.url
            } variables: ${JSON.stringify(variables)}, x-correlation-id: ${headers.get(
                'x-correlation-id'
            )}`,
            {
                'x-correlation-id': headers.get('x-correlation-id'),
                url: url,
                status: response.status,
                variables: variables,
                userContext: userContext,
            },
            body
        )

        // Create custom error that our app expects the shape of + has extra details such as error cause
        throw new Error('Something went wrong.', {
            cause: {
                body: body,
                status: response.status,
            },
        })
    })
}

export default callApi
