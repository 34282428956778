import { AmplifyUserData } from './check-and-perform-user-session-refresh-if-needed'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { getDataFromLocalStorage, setDataToLocalStorage } from '../use-local-storage'
import {
    COGNITO_SESSION_EXPIRY_MINS,
    LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY,
    LOCAL_STORAGE_USER_DATA_KEY,
} from '../constants'

export function updateCognitoAuthData({
    userData,
    cognitoSession,
}: {
    userData: AmplifyUserData
    cognitoSession: CognitoUserSession
}): void {
    const user = userData.user
    const updatedUserData = { ...user, signInUserSession: cognitoSession }
    setDataToLocalStorage({
        data: { user: updatedUserData },
        key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY,
        expiryMins: COGNITO_SESSION_EXPIRY_MINS,
    })

    /** Also need to reset expiry time on useConnectData (tenantInfo and ProductTiers) */
    const currentLocalData = getDataFromLocalStorage({
        key: LOCAL_STORAGE_USER_DATA_KEY,
    })
    if (currentLocalData) {
        setDataToLocalStorage({
            expiryMins: COGNITO_SESSION_EXPIRY_MINS,
            key: LOCAL_STORAGE_USER_DATA_KEY,
            data: { ...currentLocalData },
        })
    }
}
