import React from 'react'
import Text from '../Text/Text'
import styles from './LargeSpinner.module.css'

type SpinnerProps = {
    text?: string
}

const LargeSpinner: React.FC<SpinnerProps> = ({ text }) => {
    return (
        <div className={styles.spinner}>
            <svg className={styles.circle}>
                <title>Spinner</title>
                <linearGradient id='gradient' x1='0' y1='0' x2='1' y2='1'>
                    <stop offset='0%' stopColor='#FF338A' />
                    <stop offset='100%' stopColor='#FF940A' />
                </linearGradient>
                <circle className={styles.path} cx='25' cy='25' r='19' fill='none' />
            </svg>
            {text && <Text weight='bold'>{text}</Text>}
        </div>
    )
}

export default LargeSpinner
