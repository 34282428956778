import React, { useState } from 'react'
import { add, getYear } from 'date-fns'

import Card from 'components/blocks/Card/Card'
import Heading from 'components/basics/Heading/Heading'
import Button from 'components/basics/Button/Button'
import DatePicker from 'components/basics/DatePicker/DatePicker'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import TextInput from 'components/basics/Input/TextInput/TextInput'

import { getAccessToken } from 'utils/auth-header'
import { getFormattedDate } from 'utils/date-helpers'

import * as self from './Reporting'
import styles from './Reporting.module.css'
import allContent from 'content/content'
import { datadogLogs } from '@datadog/browser-logs'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'

const content = allContent.admin.reportingPage

const REPORT_URL = process.env.REACT_APP_CONNECT_MANAGER_SERVICE_URL + '/report'

export const downloadFileFromBlob = (blob: Blob, fileName: string): void => {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
    link.remove()
}

export const onSubmit = (
    { dateFrom, dateTo }: { dateFrom: string; dateTo: string },
    setLoading: (state: boolean) => void,
    setError: (error: any) => void
): void => {
    const requestUrl = `${REPORT_URL}/booking/csv?start_date=${dateFrom}&end_date=${dateTo}`
    setLoading(true)
    fetch(requestUrl, {
        method: 'GET',
        headers: { authorizationtoken: getAccessToken() },
    })
        .then((res) => {
            if (res.status === 200) return res.blob()
            const error = new Error("Can't create blob file to download")
            setError(error)
            datadogLogs.logger.error(
                `source: FetchCsvReport, url: ${requestUrl})}, response: ${res}`,
                {},
                error
            )
            return null
        })
        .then((blob) => {
            if (blob) {
                const fileName = `Booking_report_${dateFrom}_${dateTo}.csv`
                downloadFileFromBlob(blob, fileName)
            }
        })
        .catch((error: any) => {
            setError(error)
            datadogLogs.logger.error(`source: FetchCsvReport, url: ${requestUrl}`, {}, error)
        })
        .finally(() => setLoading(false))
}

const Reporting: React.FC = () => {
    const today = new Date()
    const dateMonthBefore = add(today, { days: -30 })

    const [dateFrom, setDateFrom] = useState<Date>(dateMonthBefore)
    const [dateTo, setDateTo] = useState<Date>(today)
    const [maxDateTo, setMaxDateTo] = useState<Date>(today)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const handleDateFromClick = (selectedDate: Date): void => {
        setDateFrom(selectedDate)
        const dateMonthAfter = add(selectedDate, { days: 30 })
        setMaxDateTo(dateMonthAfter)
        if (dateTo && dateMonthAfter < dateTo) {
            setDateTo(dateMonthAfter)
        } else if (dateTo && selectedDate > dateTo) {
            setDateTo(dateMonthAfter) // TODO: should this be 'dataMonthBefore' or the one above??
        }
    }

    const handleDateToClick = (date: Date): void => {
        setDateTo(date)
    }

    return (
        <div className={styles.container}>
            <Heading heading='1'>{content.title}</Heading>
            <Card header={content.cardHeader}>
                <div className={styles['card-content']}>
                    {error && (
                        <InfoBanner
                            id='api-error-banner'
                            bannerType='error'
                            text={`${content.errorFetchingReport}`}
                            isCloseable={false}
                        />
                    )}
                    <div className={styles['card-row']}>
                        <div className={styles['card-inputs']}>
                            <DatePicker
                                autoComplete='off'
                                name='dateFrom'
                                dateFormat='dd/MM/yyyy'
                                minDate={null}
                                yearsInDropdown={3}
                                yearsStartFrom={getYear(new Date()) - 2}
                                onChange={handleDateFromClick}
                                selected={dateFrom}
                                disabled={loading}
                                customInput={
                                    <LabelledInput label={content.dateFrom} htmlFor='dateFrom'>
                                        <TextInput
                                            iconName='Calendar'
                                            onKeyDown={(e): void => {
                                                e.preventDefault() // this stops typing into input - manually typing the date doesn't set the value so shouldn't let people try.
                                            }}
                                        />
                                    </LabelledInput>
                                }
                            />
                            <DatePicker
                                autoComplete='off'
                                name='dateTo'
                                dateFormat='dd/MM/yyyy'
                                yearsInDropdown={3}
                                yearsStartFrom={getYear(new Date()) - 2}
                                minDate={dateFrom}
                                maxDate={maxDateTo}
                                onChange={handleDateToClick}
                                selected={dateTo}
                                disabled={loading}
                                customInput={
                                    <LabelledInput label={content.dateTo} htmlFor='dateTo'>
                                        <TextInput
                                            iconName='Calendar'
                                            onKeyDown={(e): void => {
                                                e.preventDefault() // this stops typing into input - manually typing the date doesn't set the value so shouldn't let people try.
                                            }}
                                        />
                                    </LabelledInput>
                                }
                            />
                        </div>
                        <div>
                            <Button
                                showSpinner={loading}
                                flavour={'primary'}
                                text={content.export}
                                type='button'
                                onClick={(): void => {
                                    if (dateFrom && dateTo) {
                                        self.onSubmit(
                                            {
                                                dateFrom: getFormattedDate(
                                                    dateFrom.toISOString(),
                                                    'Y_M_D_HYPHEN'
                                                ),
                                                dateTo: getFormattedDate(
                                                    dateTo.toISOString(),
                                                    'Y_M_D_HYPHEN'
                                                ),
                                            },
                                            setLoading,
                                            setError
                                        )
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default Reporting
