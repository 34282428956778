import React from 'react'

import Heading from 'components/basics/Heading/Heading'
import DuffelLinksIframe from 'components/sections/flight/DuffelLinksIframe/DuffelLinksIframe'
import styles from './DuffelSearchLayout.module.css'
import allContent from 'content/content'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

const content = allContent.flight.searchPage

/** DuffelSearchLayout: Layout for the Search page */
const DuffelSearchLayout: React.FC<{ apiClient: ApolloClient<NormalizedCacheObject> }> = ({
    apiClient,
}): JSX.Element => {
    return (
        <div className={styles.background}>
            <div className='general-container'>
                <div className={styles.header}>
                    <Heading heading='1HeroTitle' onDarkBackground={true}>
                        <span>{content.headerTitlePrefix}</span>
                        <span className={styles.bold}>{content.headerTitleBold}</span>
                    </Heading>
                </div>
                <div className={styles['iframe-wrapper']}>
                    <DuffelLinksIframe apiClient={apiClient} />
                </div>
            </div>
        </div>
    )
}

export default DuffelSearchLayout
