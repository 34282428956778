import React from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import Accordion from 'components/blocks/Accordion/Accordion/Accordion'
import Icon from 'components/basics/Icon/Icon'
import Spacing from 'components/basics/Spacing/Spacing'
import Text from 'components/basics/Text/Text'

import { BreakdownPerPassenger } from 'api-data-models/CabinContentModel'
import { capitalizeEachWord, combineCurrencyAndPrice } from 'utils/string-helpers'
import { getRefundPolicyBadgeText } from '../RateCodes/RateCodes'

import styles from './PriceBreakdownView.module.css'
import allContent from 'content/content'
const content = allContent.cruise.sailingPage.cabinGradesView.breakdown

export interface PriceBreakdownViewProps {
    currency: string
    breakdownPerPassenger: BreakdownPerPassenger
    totalGrossPrice: string
    commission: string
    cabinGradeCode: string
    rateCode: string
    cabinGradeDescription: string
    rateCodeDescription: string
    priceProps?: {
        military?: boolean
        residency?: boolean
        wifi?: boolean
        refundPolicy?: RefundPolicyType
        onBoardCredits?: number | string
    }
}

const PriceBreakdownView: React.FC<PriceBreakdownViewProps> = ({
    breakdownPerPassenger,
    totalGrossPrice,
    commission,
    currency,
    cabinGradeCode,
    rateCode,
    cabinGradeDescription,
    rateCodeDescription,
    priceProps,
}) => {
    const renderRefundPolicy = getRefundPolicyBadgeText(priceProps?.refundPolicy)
    const renderOnBoardCredits =
        priceProps?.onBoardCredits && +priceProps?.onBoardCredits
            ? `$${priceProps?.onBoardCredits}`
            : null
    const showPriceIncluded =
        priceProps?.military || priceProps?.residency || renderRefundPolicy || renderOnBoardCredits

    const passengers = Object.keys(breakdownPerPassenger || [])
    const emptyPassengerCells =
        passengers.length !== 0
            ? Array(passengers.length - 1)
                  .fill(null)
                  .map((_, index) => <td key={`${passengers[index]}-empty`} />)
            : null

    const userContext = datadogLogs.getGlobalContext()

    try {
        return (
            <>
                <div className={styles['breakdown-header-wrapper']}>
                    <div className={styles['breakdown-header-column']}>
                        <Text weight='bold'>{content.rateCode}</Text>
                        <Text weight='bold'>
                            {rateCode} <Text>{rateCodeDescription}</Text>
                        </Text>
                    </div>
                    <div className={styles['breakdown-header-column']}>
                        <Text weight='bold'>{content.grade}</Text>
                        <Text weight='bold'>
                            {cabinGradeCode} <Text>{cabinGradeDescription}</Text>
                        </Text>
                    </div>
                    {showPriceIncluded ? (
                        <div className={styles['breakdown-header-wrapper']}>
                            <Text weight='bold'>{content.includedInPrice}</Text>
                            <div className={styles['breakdown-header-included']}>
                                {renderOnBoardCredits ? (
                                    <div className={styles['breakdown-header-included-item']}>
                                        <Icon iconName='OnBoardCredit' />
                                        <Text size='XS' weight='bold'>
                                            {renderOnBoardCredits}
                                        </Text>
                                        <Text size='XS'>{content.onBoardCredit}</Text>
                                    </div>
                                ) : null}
                                {renderRefundPolicy && (
                                    <div className={styles['breakdown-header-included-item']}>
                                        <Icon iconName='NonRefundable' />
                                        <Text size='XS'>{renderRefundPolicy}</Text>
                                    </div>
                                )}
                                {priceProps?.military && (
                                    <div className={styles['breakdown-header-included-item']}>
                                        <Icon iconName='MilitaryRate' />
                                        <Text size='XS'>{content.militaryRate}</Text>
                                    </div>
                                )}
                                {priceProps?.residency && (
                                    <div className={styles['breakdown-header-included-item']}>
                                        <Icon iconName='ResidentialRate' />
                                        <Text size='XS'>{content.residentialRate}</Text>
                                    </div>
                                )}
                                {priceProps?.wifi && (
                                    <div className={styles['breakdown-header-included-item']}>
                                        <Icon iconName='WiFi' />
                                        <Text size='XS'>{content.wifi}</Text>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : null}
                </div>
                <table className={styles['breakdown-table']}>
                    <thead>
                        <tr>
                            <th scope='col' />
                            {passengers.map((passengerNumber) => {
                                const label =
                                    passengerNumber === 'totals'
                                        ? content.totalCol
                                        : `${content.passenger} ${passengerNumber}`
                                return (
                                    <th key={passengerNumber} scope='col'>
                                        <Text weight='bold'>{label}</Text>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody key='body'>
                        {Object.keys(breakdownPerPassenger[passengers[0]]).map((fareTypeName) => {
                            // Passenger number 0 contains the totals (of all passengers) for each fare type that make up the other passenger breakdowns
                            // SKIP 'GROSS' as we place it manually at the bottom of the list
                            if (fareTypeName !== 'GROSS') {
                                return (
                                    <tr key={fareTypeName}>
                                        <th>
                                            {capitalizeEachWord(
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                breakdownPerPassenger?.[passengers[0]][fareTypeName]
                                                    .name
                                            )}
                                        </th>
                                        {passengers.map((passengerNumber) => {
                                            const price =
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                breakdownPerPassenger[passengerNumber][fareTypeName]
                                                    ?.price
                                            return (
                                                <td key={passengerNumber + fareTypeName}>
                                                    {price
                                                        ? combineCurrencyAndPrice(price, currency)
                                                        : '-'}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            } else {
                                return null
                            }
                        })}
                        <tr key='GROSS'>
                            <th>Gross</th>
                            {passengers.map((passengerNumber) => {
                                return (
                                    <td key={passengerNumber + 'GROSS'}>
                                        {currency}
                                        {breakdownPerPassenger[passengerNumber].GROSS?.price}
                                    </td>
                                )
                            })}
                        </tr>
                        <tr key='total'>
                            <th>{content.totalRow}</th>
                            {emptyPassengerCells}
                            <td>
                                {currency}
                                {totalGrossPrice}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Accordion
                    className={styles['price-accordion']}
                    titleSize='3'
                    titleLevel='2'
                    baseId={content.commission}
                    title={content.agentViewAccordionTitle}
                >
                    <Text className={styles['accordion-text']}>
                        <div>{content.commission}</div>
                        <div>
                            {currency}
                            {commission}
                        </div>
                    </Text>
                </Accordion>
                <Spacing size='triple' />
            </>
        )
    } catch (error: any) {
        datadogLogs.logger.error('PriceBreakdownView.tsx js Error', { userContext }, error)
        return <p>{content.noData}</p>
    }
}

export default PriceBreakdownView
