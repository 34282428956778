import React, { useState } from 'react'

import Accordion from 'components/blocks/Accordion/Accordion/Accordion'
import Button from 'components/basics/Button/Button'
import Heading from 'components/basics/Heading/Heading'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import Slider from 'components/basics/Slider/Slider'
import allContent from '../../../../content/content'
import styles from './FilterSection.module.scss'
import { CURRENCY_CODES } from 'utils/constants'

const content = allContent.hotel.resultsPage.filter

type FilterSectionProps = {
    hotelNameSearchState: [string, (value: string) => void]
    /** currency symbol from getHotels api */
    currencySymbol: null | keyof typeof CURRENCY_CODES
    /** UseState setter function for price range filter max value */
    setSelectedMaxPrice(value: number): void
    /** UseState setter function for price range filter min value */
    setSelectedMinPrice(value: number): void
    /** maximum price value from cruise results */
    maxPrice: number
    /** minimum price value from cruise results */
    minPrice: number
}

const FilterSection: React.FC<FilterSectionProps> = ({
    hotelNameSearchState,
    currencySymbol,
    setSelectedMinPrice,
    setSelectedMaxPrice,
    maxPrice,
    minPrice,
}) => {
    const [hotelNameSearchString, setHotelNameSearchString] = hotelNameSearchState
    const [priceFilterValues, setPriceFilterValues] = useState<number | number[]>([
        minPrice,
        maxPrice,
    ])

    function handleHotelNameFilter(e: React.FormEvent<HTMLInputElement>): void {
        const { value } = e.currentTarget
        setHotelNameSearchString(value)
    }

    function handlePriceChange(_event: any, value: number | number[]): void {
        if (Array.isArray(value)) {
            setPriceFilterValues(value)
            setSelectedMinPrice(value[0])
            setSelectedMaxPrice(value[1])
        }
    }
    function clearFilters(): void {
        setHotelNameSearchString('')
        setSelectedMinPrice(minPrice)
        setSelectedMaxPrice(maxPrice)
        setPriceFilterValues([minPrice, maxPrice])
    }

    return (
        <div className={styles['filter__container']}>
            <div className={styles['filter__heading']}>
                <Heading heading='4' size='2' onDarkBackground={true}>
                    {content.sectionTitle}
                </Heading>
                <Button
                    type='button'
                    flavour='tertiary'
                    onDarkBackground={true}
                    text={content.clearButton}
                    onClick={clearFilters}
                />
            </div>
            <div className={styles['filter__items']}>
                <Accordion
                    title={content.headings.hotelName}
                    // titleSize='S'
                    titleSize='3'
                    baseId='hotel-name-filter'
                    startOpen
                    underscore
                >
                    <LabelledInput
                        label={content.inputLabels.hotelNameInputLabel}
                        htmlFor='hotel-name-filter'
                        labelHidden
                    >
                        <TextInput value={hotelNameSearchString} onChange={handleHotelNameFilter} />
                    </LabelledInput>
                </Accordion>
                {maxPrice !== 0 && currencySymbol && (
                    <Accordion
                        title={content.headings.priceRange}
                        // titleSize='S'
                        titleSize='3'
                        baseId='price-range-filter'
                        startOpen
                        underscore
                    >
                        <Slider
                            minValue={minPrice}
                            maxValue={maxPrice}
                            onChange={handlePriceChange}
                            value={priceFilterValues}
                            unit={currencySymbol}
                            ariaLabel='Price range filter'
                        />
                    </Accordion>
                )}
            </div>
        </div>
    )
}
export default FilterSection
