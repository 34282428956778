import { getFormattedDate } from 'utils/date-helpers'
import {
    capitalizeEachWord,
    createStringFromList,
    insertDecimal2CharsFromEnd,
} from 'utils/string-helpers'
import { OrderItemStatus, OrderStatus } from './OrderContentModel'
import { CURRENCY_CODES } from 'utils/constants'
import { QuoteTemplateDynamicFields } from 'components/layouts/order/SendQuoteModal/SendQuoteModal'

export type EKSApiProductType = 'CRUISE' | 'FLIGHT'
export const EKS_API_PRODUCT_TYPES: {
    CRUISE: EKSApiProductType
    FLIGHT: EKSApiProductType
} = {
    CRUISE: 'CRUISE',
    FLIGHT: 'FLIGHT',
}

export type Order = {
    readonly orderId: string
    readonly isImported: boolean
    readonly trimmedOrderId: string
    readonly createdAt: string
    readonly bookedAt: string
    readonly supplierName: string
    readonly supplierReference: string | null
    readonly departureDate: string
    readonly passengerContactDetailsLastname: string
    readonly totalPrice: string
    readonly orderStatus: OrderStatus
    readonly orderItemStatus: OrderItemStatus
    readonly currencySymbol: string
    readonly templateData: any
}

type FareType = 'FARE' | 'GROSS'
export const FARE_TYPE: { FARE: FareType; GROSS: FareType } = {
    FARE: 'FARE',
    GROSS: 'GROSS',
}

type EKSBreakdownItem = {
    fare_type: FareType
    name: string
    price: number | string
    passenger_number: number
}

// NOTE - breakdown_items and total_gross_price fields are not optional, but we only need breakdown_items while the EKS service is awaiting a fix to provide total_fare_price and total_gross_price.
// I don't want to have to add breakdown_items to all fixtures that are typed as part of this temp fix.
type EKSApiPriceItem = {
    currency: string
    commission: number
    breakdown_items?: EKSBreakdownItem[]
    total_gross_price?: number
}

type EKSApiOrderProduct = {
    cabin_grade_name: string
    cabin_grade_code: string
    cabin_grade_description: string
    cabin_number: string
    cruise_line_code: string
    deck_name: string
    deck_level: string | null
    disembark_date: string
    duration: number
    embark_date: string
    name: string
    product_name: string
    rate_code: string
    rate_name: string
    rate_description: string
    ship_name: string
    id: string
    line_name: string
    price: number
    itinerary: {
        port_name: string
    }[]
    price_items: EKSApiPriceItem[]
}

export type ApiOrder = {
    id: string
    status: OrderStatus
    booked_at: string | null
    created_at: string
    items: {
        imported?: boolean
        supplier_order_reference: string | null
        type: EKSApiProductType
        product: EKSApiOrderProduct
        number_of_travellers: number
        status: OrderItemStatus
    }[]
    contact_details: {
        last_name: string
    }
}

export function getOrder(apiOrder: ApiOrder): Order {
    const orderId = apiOrder.id
    const isImported = Boolean(apiOrder.items[0].imported) // TODO: Note this assumes only 1 product.
    const trimmedOrderId = apiOrder.id.slice(0, 8)
    const createdAt = apiOrder.created_at
        ? getFormattedDate(new Date(apiOrder.created_at).toISOString(), 'short')
        : '-'
    const bookedAt = apiOrder.booked_at
        ? getFormattedDate(new Date(apiOrder.booked_at).toISOString(), 'short')
        : '-'
    const supplierName = apiOrder.items[0].product.line_name
    const supplierReference = apiOrder.items[0].supplier_order_reference ?? null
    const departureDate = getFormattedDate(
        new Date(apiOrder.items[0].product.embark_date).toISOString(),
        'short'
    )
    const passengerContactDetailsLastname = apiOrder.contact_details
        ? apiOrder.contact_details.last_name
        : '-'
    const totalPrice = insertDecimal2CharsFromEnd(apiOrder.items[0].product.price)
    const orderStatus = apiOrder.status
    const currencySymbol = CURRENCY_CODES[apiOrder.items[0].product.price_items[0].currency]
    const orderItemStatus = apiOrder.items[0].status
    const templateData =
        apiOrder.items[0].type === EKS_API_PRODUCT_TYPES.CRUISE
            ? getProductDataForCruiseEmailTemplate(
                  orderId,
                  apiOrder.items[0].number_of_travellers,
                  apiOrder.items[0].product
              )
            : null
    return {
        orderId,
        isImported,
        trimmedOrderId,
        createdAt,
        bookedAt,
        supplierName,
        supplierReference,
        departureDate,
        passengerContactDetailsLastname,
        totalPrice,
        currencySymbol,
        orderStatus,
        orderItemStatus,
        templateData,
    }
}

export function getTotalFarePrice(breakdownItems: EKSBreakdownItem[] | undefined): string {
    if (breakdownItems) {
        for (const item of breakdownItems) {
            if (item.passenger_number === 0 && item.fare_type === FARE_TYPE.FARE) {
                return insertDecimal2CharsFromEnd(item.price.toString())
            }
        }
    }
    return '0'
}

export function getTotalGrossPrice(breakdownItems: EKSBreakdownItem[] | undefined): string {
    if (breakdownItems) {
        for (const item of breakdownItems) {
            if (item.passenger_number === 0 && item.fare_type === FARE_TYPE.GROSS) {
                return insertDecimal2CharsFromEnd(item.price.toString())
            }
        }
    }

    return '0'
}

export function getProductDataForCruiseEmailTemplate(
    orderId: string,
    numberOfTravellers: number,
    product: EKSApiOrderProduct
): QuoteTemplateDynamicFields {
    return {
        cruise_name: product.name || product.product_name,
        traveltek_ref: orderId,
        voyage_code: product.id,
        embark_date: product.embark_date,
        disembark_date: product.disembark_date,
        duration: product.duration.toString(),
        passengers_count: String(numberOfTravellers),
        itinerary: product.itinerary
            ?.map(({ port_name }: { port_name: string }) =>
                port_name ? port_name.split(',')[0] : ''
            )
            .filter((name: string) => name)
            .map(capitalizeEachWord)
            ?.join(', '),
        ship_name: product.ship_name,
        cruise_line: product.line_name,
        deck_number: capitalizeEachWord(
            createStringFromList([product.deck_level, product.deck_name])
        ),
        rate_code: createStringFromList([
            capitalizeEachWord(product.rate_name),
            capitalizeEachWord(product.rate_description),
            product.rate_code,
        ]),
        cabin_type: capitalizeEachWord(
            createStringFromList([
                product.cabin_number,
                product.cabin_grade_description,
                product.cabin_grade_code,
            ])
        ),
        cabin_number: product.cabin_number,
        price_currency_sym:
            CURRENCY_CODES[product.price_items[0].currency] ?? product.price_items[0].currency,
        /** While EKS service is lacking these two fields, a temp fix is to use breakdown_items and calculate ourselves */
        // price_fare: insertDecimal2CharsFromEnd(product.price_items[0].total_gross_price), // todo: should probably not be gross?
        // price_total: insertDecimal2CharsFromEnd(product.price_items[0].total_gross_price),
        price_fare: getTotalFarePrice(product.price_items[0].breakdown_items),
        price_total: getTotalGrossPrice(product.price_items[0].breakdown_items),
        price_currency: product.price_items[0].currency,
    }
}

export function AllOrdersContentModel(apiData: ApiOrder[]): Order[] {
    return apiData.map((apiOrder) => getOrder(apiOrder)) || []
}
