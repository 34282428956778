import React, { Component } from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import CrashErrorPage from 'components/pages/app/CrashErrorPage'
import { getDataFromLocalStorage } from 'utils/use-local-storage'
import { LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY } from 'utils/constants'

interface ErrorBoundaryProps {
    cleanUpAppOnRestart(): void
    children: React.ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(): any {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: Record<any, any>): void {
        const userContext = datadogLogs.getGlobalContext()
        const userCognitoData = getDataFromLocalStorage({
            key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY,
        })

        const email = userCognitoData?.user?.attributes?.email
        const tenantId = userCognitoData?.user?.signInUserSession.idToken.payload['custom:tenantId']
        datadogLogs.logger.error(
            `source: Error-Boundary, userEmail: ${email}, tenantId: ${tenantId}`,
            { userContext: userContext, errorInfo: errorInfo },
            error
        )
    }
    render(): any {
        if (this.state.hasError) {
            return <CrashErrorPage cleanUpAppOnLogout={this.props.cleanUpAppOnRestart} />
        }
        return this.props.children
    }
}
export default ErrorBoundary
