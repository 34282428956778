import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import SalesChannelManagement from 'components/sections/admin/SalesChannelManagement/SalesChannelManagement'
import Navigation from 'components/sections/admin/NavigationPannel/NavigationPanel'

import styles from './SalesChannelManagementLayout.module.css'

type SalesChannelManagementLayoutProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productTiers: ProductTiersDataType
}

const SalesChannelManagementLayout: React.FC<SalesChannelManagementLayoutProps> = ({
    apiClient,
    productTiers,
}) => {
    return (
        <div className='general-container'>
            <div className={styles.container}>
                <Navigation productTiers={productTiers} />
                <SalesChannelManagement apiClient={apiClient} productTiers={productTiers} />
            </div>
        </div>
    )
}

export default SalesChannelManagementLayout
