import React from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import { setDataToLocalStorage } from 'utils/use-local-storage'
import { parseQueryParams } from 'utils/parse-query-params'

type HostedUxCognitoAuthProps = {
    appDomain: string
    LOCAL_STORAGE_AUTH_DATA_KEY: string
    setIsAuthorised: React.Dispatch<React.SetStateAction<boolean | null>>
    setUserCognitoData: React.Dispatch<React.SetStateAction<Record<string, any> | undefined>>
    cognitoHostName: string
    redirectUri?: string // is only undefined on self hosted user pools that don't use this function.
    clientId: string
}

/**
 * hostedUxCognitoAuth redirects user to the HOSTED UX of the cognito pool linked to the url/domain the app is running on to login.
 * After user is logged on, they are redirected to the app with a CODE url param that is used to verify with cognito and get the JWT which is stored in local storage.
 * @param appDomain - string of appDomain from current url
 * @param hostName - string of hostName for app/url cogntio pool eg. https://agentconnect.traveltek.net
 * @param clientId - string of clientId for app/url cognito pool
 * @param redirectUri - string of redirectUrl for app/url cognito pool
 * @param LOCAL_STORAGE_AUTH_DATA_KEY - key to fetch/set auth data from local storage
 * @param setIsAuthorised - function to pass in true or false pending result of cognito
 * @param setUserCognitoData - function to save userCognitoData to app state (to cause rerender and allow app to show correct pages)
 */
export async function hostedUxCognitoAuth({
    appDomain,
    LOCAL_STORAGE_AUTH_DATA_KEY,
    setIsAuthorised,
    cognitoHostName,
    redirectUri,
    clientId,
    setUserCognitoData,
}: HostedUxCognitoAuthProps): Promise<void> {
    const userContext = datadogLogs.getGlobalContext()
    const queryParams = window.location.search?.substring(1) ?? ''
    let cognitoAuthCode: string | undefined

    // Get the query param "code" used for calling cognito (present when having just logged on to cognito)
    if (Object.keys(queryParams).length !== 0) {
        cognitoAuthCode = parseQueryParams(queryParams)?.code
    }

    /** Create Cognito Hosted Login url for appDomain's user pool */
    const cognitoHostedLoginUI = `https://${cognitoHostName}/login?client_id=${clientId}&response_type=code&scope=openid+aws.cognito.signin.user.admin&redirect_uri=${redirectUri}`

    /** Create Cognito Auth url for fetching JWT tokens when user comes back with CODE url param after logging on */
    const cognitoAuthoriseUrl = `https://${cognitoHostName}/oauth2/token?grant_type=authorization_code&client_id=${clientId}&code=${cognitoAuthCode}&redirect_uri=${redirectUri}`

    if (!cognitoAuthCode) {
        /** 1) Send user to HOSTED UX login page if not CODE param in url */
        /** They will be redirected back to the app with code param, and thus will skip this bit */
        datadogLogs.logger.info(
            `User landed on Enya, but is not logged on - redirecting to cognito hosted UX`,
            {
                appDomain: appDomain,
                redirectUrl: cognitoHostedLoginUI,
                userContext: userContext,
            }
        )
        setIsAuthorised(null) // null is used to show spinner while we verify the login was successful and fetch JWT's
        window.location.assign(cognitoHostedLoginUI)
    } else {
        /** 2) Call cognito with code param to get access and id JWT tokens - user has been redirected from hosted UX after successfully logging on. */
        const myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: '',
            redirect: 'follow',
        }

        /** GET JWT access and id Tokens using code param provide from logging on */
        await fetch(cognitoAuthoriseUrl, requestOptions)
            .then((response) => {
                if (response.status !== 200) {
                    datadogLogs.logger.error(
                        `Error calling hosted-cognito authorise was unsuccessful, appDomain: ${appDomain}: ${JSON.stringify(
                            response
                        )}`,
                        { userContext }
                    )
                    // If the response is not success then redirect back to login, as you need to get a new code, they are one time use only.
                    window.location.assign(cognitoHostedLoginUI)
                } else {
                    // If success then return the response (cognitoResponse) to next step.
                    return response.json()
                }
            })
            .then((cognitoResponse) => {
                // Only when the JWT has been passed in:
                if (cognitoResponse) {
                    setDataToLocalStorage({
                        expiryMins: cognitoResponse['expires_in'] / 60, // value in seconds so convert to mins
                        data: cognitoResponse,
                        key: LOCAL_STORAGE_AUTH_DATA_KEY,
                    })
                    setIsAuthorised(true) // To allow render of App
                    setUserCognitoData(cognitoResponse)
                } else {
                    datadogLogs.logger.error(
                        `Error calling hosted-cognito authorise, no response, appDomain: ${appDomain}, url: ${cognitoAuthoriseUrl}, requestOptions: ${JSON.stringify(
                            requestOptions
                        )}`,
                        { userContext }
                    )
                    window.location.assign(cognitoHostedLoginUI)
                    setIsAuthorised(false)
                }
            })
            .catch((error) => {
                datadogLogs.logger.error(
                    `Error thrown in code that calls hosted-cognito authorise, appDomain: ${appDomain}, error: ${error}`,
                    userContext
                )
                window.location.assign(cognitoHostedLoginUI)
                setIsAuthorised(false) // To prevent render of App
            })
    }
}
