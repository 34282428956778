import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'

import Button from 'components/basics/Button/Button'
import Header from 'components/blocks/Header/Header'
import Image from 'components/basics/Image/Image'
import Link from 'components/basics/Link/Link'
import Menu from 'components/blocks/Menu/Menu'
import Text from 'components/basics/Text/Text'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { INITIAL_PRODUCT_TIERS, PRODUCT_TIERS, USER_ROLES } from 'utils/constants'
import { FeatureToggleContext } from 'App'

import allContent from 'content/content'
import styles from './HeaderContent.module.css'
import ImportModal from './ImportModal/ImportModal'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { isTestEmailDomain } from 'utils/testing-handlers'
import { extractUserDataFields } from 'utils/user-data-helpers/extract-user-data-fields'

const headerContent = allContent.header
const userMenuContent = allContent.header.userMenu

type HeaderContentProps = {
    apiClient?: ApolloClient<NormalizedCacheObject>
    isAuthorised: null | boolean
    isDemoUrl?: boolean
    logUserOut({ to }: { to: string }): void
    productTiers?: Record<ProductNameType, ProductTierType>
    userEmail: string
    userRoles: UserRole[]
    tenantFinanceId?: string
}
const HeaderSection: React.FC<HeaderContentProps> = ({
    apiClient,
    isAuthorised,
    isDemoUrl,
    logUserOut,
    productTiers = INITIAL_PRODUCT_TIERS,
    userEmail,
    userRoles,
    tenantFinanceId,
}) => {
    const featureToggles = useContext(FeatureToggleContext)
    const navigate = useNavigate()
    const logoSrc = isDemoUrl ? headerContent.demoLogo : headerContent.logo
    const [isImportOrderModalOpen, setImportOrderModalOpen] = useState(false)
    const userContext = datadogLogs.getGlobalContext()
    const { companyName } = extractUserDataFields(userContext as GlobalContextUserData)
    const importOrderEnabled = featureToggles.TURN_ON_ORDERS_IMPORT || isTestEmailDomain(userEmail)
    const enyaSignUpEnabled = featureToggles.TURN_ON_SIGN_UP_LINK
    const location = useLocation().pathname

    function onClickLogOut(): void {
        datadogLogs.logger.info(`Logging out user: ${userEmail}`, { userContext: userContext })
        logUserOut({ to: ROUTES.LOGIN })
    }

    return (
        <Header>
            <a href={ROUTES.ROOT} className={styles.logo}>
                <Image className={styles.logo} src={logoSrc} alt='TravelTek logo' />
            </a>
            {location !== ROUTES.LOGIN && !isAuthorised && !isDemoUrl && (
                <Button
                    id='log-in-button'
                    className={styles['nav-button']}
                    text={headerContent.loginButton}
                    flavour='tertiary'
                    onDarkBackground={true}
                    type='button'
                    onClick={(): void => navigate(ROUTES.LOGIN)}
                />
            )}
            {isAuthorised && (
                <div className={styles['nav-container']}>
                    {isDemoUrl && (
                        <Link
                            href={
                                enyaSignUpEnabled
                                    ? headerContent.postAppsMergedSignUpLinkUrl
                                    : headerContent.connectManagerLinkUrl
                            }
                            noUnderLine
                        >
                            <Button
                                type='button'
                                flavour='secondary'
                                text={
                                    enyaSignUpEnabled
                                        ? headerContent.postAppsMergedSignUpLinkText
                                        : headerContent.connectManagerLinkText
                                }
                                onClick={(): void => {
                                    datadogLogs.logger.info(
                                        `User clicked open ConnectManager link, email: ${userEmail}`,
                                        { userContext: userContext }
                                    )
                                }}
                            />
                        </Link>
                    )}
                    {isAuthorised &&
                        tenantFinanceId &&
                        productTiers.AGENTCONNECTCRUISE === PRODUCT_TIERS.PAID && (
                            <Button
                                id='cruise-search-button'
                                className={styles['nav-button']}
                                text={headerContent.cruiseSearchButton}
                                flavour='tertiary'
                                onDarkBackground={true}
                                type='button'
                                onClick={(): void => navigate(ROUTES.CRUISE_SEARCH)}
                            />
                        )}
                    {!isDemoUrl &&
                        isAuthorised &&
                        tenantFinanceId &&
                        importOrderEnabled &&
                        apiClient && (
                            <>
                                <ImportModal
                                    isOpen={isImportOrderModalOpen}
                                    onClose={(): void => setImportOrderModalOpen(false)}
                                    returnFocusId='order-import-button'
                                    apiClient={apiClient}
                                />
                                <Button
                                    id='order-import-button'
                                    className={styles['nav-button']}
                                    text={headerContent.importButton}
                                    flavour='tertiary'
                                    onDarkBackground={true}
                                    type='button'
                                    onClick={(): void => setImportOrderModalOpen(true)}
                                />
                            </>
                        )}
                    {!isDemoUrl &&
                        tenantFinanceId &&
                        isAuthorised &&
                        featureToggles.TURN_ON_ORDERS_PAGE && (
                            <Button
                                className={styles['nav-button']}
                                text={headerContent.ordersButton}
                                flavour='tertiary'
                                onDarkBackground={true}
                                type='button'
                                onClick={(): void => navigate(ROUTES.ALL_ORDERS)}
                            />
                        )}
                    {isAuthorised &&
                        (isTestEmailDomain(userEmail) || featureToggles.TURN_ON_ADMIN_PAGES) &&
                        userRoles?.includes(USER_ROLES.ADMIN) && (
                            <Button
                                id='admin-settings-button'
                                className={styles['nav-button']}
                                text={headerContent.adminSettingsButton}
                                flavour='tertiary'
                                onDarkBackground={true}
                                type='button'
                                onClick={(): void => navigate(ROUTES.ADMIN_DASHBOARD)}
                            />
                        )}
                    <Menu
                        id={userMenuContent.label}
                        buttonText={userMenuContent.label}
                        onDarkBackground={true}
                        doubleTopMargin={true}
                        iconName='UserCircle'
                        options={[
                            ...(companyName || userEmail
                                ? [
                                      {
                                          jsxElement: (
                                              <div>
                                                  {companyName && (
                                                      <Text color='white'>{companyName}</Text>
                                                  )}
                                                  {companyName && userEmail && <br />}
                                                  {userEmail && (
                                                      <Text color='white' weight='bold'>
                                                          {userEmail}
                                                      </Text>
                                                  )}
                                              </div>
                                          ),
                                          label: 'company-name',
                                      },
                                  ]
                                : []),
                            {
                                jsxElement: (
                                    <Button
                                        flavour='secondary'
                                        text={userMenuContent.logoutButton}
                                        type='button'
                                        onClick={onClickLogOut}
                                    />
                                ),
                                label: userMenuContent.logoutButton,
                            },
                        ]}
                    />
                </div>
            )}
        </Header>
    )
}

export default HeaderSection
