import React, { useContext } from 'react'

import Heading from 'components/basics/Heading/Heading'
import Link from 'components/basics/Link/Link'
import List from 'components/basics/List/List'
import ListItem from 'components/basics/List/ListItem'
import Text from 'components/basics/Text/Text'
import { FeatureToggleContext } from 'App'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { PRODUCT_TIERS } from 'utils/constants'

import styles from './NavigationPanel.module.css'
import allContent from 'content/content'
import { datadogLogs } from '@datadog/browser-logs'

const content = allContent.admin.navigationPanel

type NavigationProps = {
    productTiers: ProductTiersDataType
}

const NavigationPanel: React.FC<NavigationProps> = ({ productTiers }: NavigationProps) => {
    const featureToggle = useContext(FeatureToggleContext)

    const turnOnUserGroupManagement = productTiers?.AGENTCONNECTCRUISE === PRODUCT_TIERS.PAID
    const turnOnCompanyConfiguration = featureToggle.TURN_ON_COMPANY_CONFIG

    return (
        <nav className={styles.container}>
            <div className={styles.heading}> </div>
            <div>
                <List className={styles.content}>
                    <ListItem listKey='home-link' className={styles['list-item']}>
                        <Link to={ROUTES.ADMIN_DASHBOARD}>
                            <Text weight='bold' size='M' color='tertiary-blue'>
                                {content.dashboard}
                            </Text>
                        </Link>
                    </ListItem>
                    <ListItem listKey='products-heading' className={styles['list-item']}>
                        <Heading heading='2'>{content.productHeading}</Heading>
                    </ListItem>
                    <ListItem listKey='agentConnect'>
                        {turnOnUserGroupManagement && (
                            <div className={styles['list-item']}>
                                <Heading heading='3'>{content.agentConnect}</Heading>
                            </div>
                        )}
                        <List className={styles.list}>
                            {turnOnUserGroupManagement && (
                                <ListItem listKey='userManagement'>
                                    <Link className={styles.link} to={ROUTES.USER_MANAGEMENT}>
                                        <Text weight='bold' color='tertiary-blue'>
                                            {content.userManagement}
                                        </Text>
                                    </Link>
                                </ListItem>
                            )}
                            {turnOnUserGroupManagement && (
                                <ListItem listKey='groupManagement'>
                                    <Link className={styles.link} to={ROUTES.GROUP_MANAGEMENT}>
                                        <Text weight='bold' color='tertiary-blue'>
                                            {content.groupManagement}
                                        </Text>
                                    </Link>
                                </ListItem>
                            )}
                        </List>
                    </ListItem>
                    <ListItem listKey='salesChannel'>
                        <div className={styles['list-item']}>
                            <Heading heading='3'>{content.salesChannel}</Heading>
                        </div>
                        <List>
                            <ListItem listKey='salesChannelManagement'>
                                <Link className={styles.link} to={ROUTES.SALES_CHANNEL_MANAGEMENT}>
                                    <Text weight='bold' color='tertiary-blue'>
                                        {content.salesChannelManagement}
                                    </Text>
                                </Link>
                            </ListItem>
                        </List>
                    </ListItem>
                    {turnOnCompanyConfiguration && (
                        <ListItem listKey='companyConfiguration'>
                            <div className={styles['list-item']}>
                                <Heading heading='3'>{content.companyConfiguration}</Heading>
                            </div>
                            <List>
                                <ListItem listKey='companyConfiguration'>
                                    <Link className={styles.link} to={ROUTES.COMPANY_CONFIGURATION}>
                                        <Text weight='bold' color='tertiary-blue'>
                                            {content.companyConfiguration}
                                        </Text>
                                    </Link>
                                </ListItem>
                            </List>
                        </ListItem>
                    )}
                    <ListItem listKey='reporting'>
                        <div className={styles['list-item']}>
                            <Heading heading='3'>{content.reporting}</Heading>
                        </div>
                        <List>
                            <ListItem listKey='reporting'>
                                <Link className={styles.link} to={ROUTES.MI_REPORTING}>
                                    <Text weight='bold' color='tertiary-blue'>
                                        {content.reportingBooking}
                                    </Text>
                                </Link>
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem listKey='cruiseConnect'>
                        <div className={styles['list-item']}>
                            <Heading heading='3'>{content.cruiseConnect}</Heading>
                        </div>
                        <List>
                            <ListItem listKey='apiDocumentation'>
                                <Link
                                    className={styles.link}
                                    href={content.apiDocumentationURL}
                                    target='_blank'
                                    onClick={(): void =>
                                        datadogLogs.logger.info('API docs link clicked (nav panel)')
                                    }
                                >
                                    <Text weight='bold' color='tertiary-blue'>
                                        {content.apiDocumentation}
                                    </Text>
                                </Link>
                            </ListItem>
                        </List>
                    </ListItem>
                </List>
            </div>
        </nav>
    )
}

export default NavigationPanel
