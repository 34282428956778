import React, { useEffect, useMemo, useState } from 'react'

import Breadcrumb from 'components/basics/Breadcrumb/Breadcrumb'
import FilterSection from 'components/sections/hotel/FilterSection/FilterSection'
import HotelPaginatedResultsList from 'components/sections/hotel/HotelPaginatedResultsList/HotelPaginatedResultsList'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { Hotel } from 'api-data-models/HotelResultsContentModel'
import styles from './ResultsLayout.module.css'
import { findMinMaxPrice } from './find-min-max-price'
import { CURRENCY_CODES } from 'utils/constants'

import allContent from 'content/content'
const content = allContent.hotel.resultsPage
const breadcrumbContent = allContent.app.breadcrumbs

const ITEMS_PER_PAGE = 10

export function filterHotels(hotels: Hotel[], hotelNameSearchString = ''): Hotel[] {
    return hotels.filter((hotel) => {
        const hotelName = hotel.name?.toLowerCase()
        return hotelName.includes(hotelNameSearchString.toLowerCase())
    })
}

type FilterHotelsPriceProps = {
    hotels: Hotel[]
    selectedMinPrice: number
    selectedMaxPrice: number
}
export const filterHotelsPrice = ({
    selectedMinPrice,
    selectedMaxPrice,
    hotels,
}: FilterHotelsPriceProps): any[] => {
    const filteredResults: Record<string, any>[] = []

    hotels.forEach(function (hotel: Hotel) {
        const price = hotel.priceBreakdown[0]
        const hotelPrice = price.priceDetail.gross
        const keepHotel = selectedMinPrice <= hotelPrice && hotelPrice <= selectedMaxPrice
        if (keepHotel) filteredResults.push(hotel)
    })

    return filteredResults
}

type ResultsProps = {
    currencySymbol: null | keyof typeof CURRENCY_CODES
    hotels: Hotel[]
}
const ResultsLayout: React.FC<ResultsProps> = ({ hotels, currencySymbol }: ResultsProps) => {
    const [hotelNameSearchString, setHotelNameSearchString] = useState('')
    const initialMinMaxPrice = useMemo(() => findMinMaxPrice(hotels), [hotels])
    const [minPrice, setMinPrice] = useState(initialMinMaxPrice[0])
    const [maxPrice, setMaxPrice] = useState(initialMinMaxPrice[1])
    const [selectedMinPrice, setSelectedMinPrice] = useState(initialMinMaxPrice[0])
    const [selectedMaxPrice, setSelectedMaxPrice] = useState(initialMinMaxPrice[1])

    useEffect(() => {
        setSelectedMinPrice(initialMinMaxPrice[0])
        setSelectedMaxPrice(initialMinMaxPrice[1])
        setMinPrice(initialMinMaxPrice[0])
        setMaxPrice(initialMinMaxPrice[1])
    }, [hotels, initialMinMaxPrice])

    let filteredHotels = filterHotels(hotels, hotelNameSearchString)

    filteredHotels = filterHotelsPrice({
        selectedMinPrice,
        selectedMaxPrice,
        hotels: filteredHotels,
    })

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Breadcrumb
                    urlList={[
                        { text: breadcrumbContent.search, url: ROUTES.HOTEL_SEARCH },
                        { text: breadcrumbContent.results },
                    ]}
                />
            </div>
            <div className={styles.main}>
                <FilterSection
                    currencySymbol={currencySymbol}
                    setSelectedMinPrice={setSelectedMinPrice}
                    setSelectedMaxPrice={setSelectedMaxPrice}
                    minPrice={minPrice}
                    maxPrice={maxPrice}
                    hotelNameSearchState={[hotelNameSearchString, setHotelNameSearchString]}
                />
                {hotels?.length === 0 && <p>{content.emptyResults}</p>}
                {hotels?.length > 0 && (
                    <HotelPaginatedResultsList
                        filteredItems={filteredHotels}
                        itemsPerPage={ITEMS_PER_PAGE}
                        totalNumberOfHotels={hotels.length}
                    />
                )}
            </div>
        </div>
    )
}
export default ResultsLayout
