import React from 'react'

import Accordion from 'components/blocks/Accordion/Accordion/Accordion'
import DescriptionListDisplay from 'components/blocks/DescriptionListDisplay/DescriptionListDisplay'
import { SailingContent } from 'api-data-models/SailingContentModel'

import styles from './SailingInfo.module.css'
import allContent from '../../../../content/content'
const content = allContent.cruise.sailingPage.informationSection

type CruiseDetailSectionProps = {
    /** A single sailing result object */
    sailingSearchResult: SailingContent
}

/** SailingInfoSection: Returns a component that displays information on a specific sailing */
const SailingInfo: React.FC<CruiseDetailSectionProps> = ({
    sailingSearchResult,
}: CruiseDetailSectionProps) => {
    const { cruiseId, departingDate, returningDate, duration, roundTrip } = content.informationKeys
    const informationDataListLeft = (
        <DescriptionListDisplay
            className={styles.columns}
            isAlternatingColor
            keyValueContent={[
                [cruiseId, sailingSearchResult.cruiseId],
                [departingDate, sailingSearchResult.embarkDate],
                [returningDate, sailingSearchResult.disembarkDate],
                [duration, sailingSearchResult.duration],
            ]}
        />
    )

    const { supplierName, shipName } = content.informationKeys
    const informationDataListRight = (
        <DescriptionListDisplay
            className={styles.columns}
            isAlternatingColor
            keyValueContent={[
                [supplierName, sailingSearchResult.supplierName],
                [shipName, sailingSearchResult.shipName],
                [roundTrip, sailingSearchResult.roundTrip],
            ]}
        />
    )

    return (
        <Accordion
            baseId={'info-section'}
            title={content.heading}
            titleSize='3'
            titleLevel='2'
            darkHeading={true}
            isPadded={true}
            startOpen={true}
        >
            <div className={styles.container}>
                {informationDataListLeft}
                {informationDataListRight}
            </div>
        </Accordion>
    )
}

export default SailingInfo
