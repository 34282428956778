import React from 'react'

import { Room } from 'api-data-models/HotelRoomsContentModel'
import Heading from 'components/basics/Heading/Heading'

import styles from './RoomDetailsSection.module.css'
import allContent from 'content/content'

const content = allContent.hotel.roomDetailsPage.roomDetails
type RoomDetailsSectionProps = {
    room: Room
}

const RoomDetailsSection: React.FC<RoomDetailsSectionProps> = ({ room }): JSX.Element => {
    return (
        <div className={styles.container}>
            <div>
                <Heading heading='3' size='3'>
                    {`${content.roomNumber} ${room.roomNumber}`}
                </Heading>
            </div>
        </div>
    )
}
export default RoomDetailsSection
