import React, { useEffect } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import { checkAndPerformUserSessionRefreshIfNeeded } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'
import { CREATE_FLIGHT_ORDER } from 'graphql-queries/order/order-queries'
import { GET_DUFFEL_OFFER_DETAILS } from 'graphql-queries/flight/flight-queries'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { FlightOptionsDetails } from 'components/pages/flight/DuffelGetFlightPage'
import allContent from 'content/content'

const content = allContent.flight.duffelGetFlightPage

type CreateFlightVariables = {
    flightId: string // This is the flightOffer id, but API calls it flightId
    grossCost: number
    providerId: string
    providerItemId: string
}

type RequestOrderWithFlightProps = {
    createFlightVariables: CreateFlightVariables
    setCreatingOrder: React.Dispatch<React.SetStateAction<boolean>>
    setErrorCreatingOrder: React.Dispatch<React.SetStateAction<boolean | null>>
    agentConnectApiClient: ApolloClient<NormalizedCacheObject>
    goToOrder: (queryParamsString: string) => void
    navigate(): void
}

const requestOrderWithFlight = ({
    createFlightVariables,
    setCreatingOrder,
    setErrorCreatingOrder,
    agentConnectApiClient,
    goToOrder,
    navigate,
}: RequestOrderWithFlightProps): void => {
    const handleUserSession = checkAndPerformUserSessionRefreshIfNeeded(navigate)
    setCreatingOrder(true)
    const userContext = datadogLogs.getGlobalContext()
    // eslint-disable-next-line no-console
    // console.log('Calling basket using flight variables:', createFlightVariables)
    handleUserSession.then(() => {
        agentConnectApiClient
            .mutate({
                mutation: CREATE_FLIGHT_ORDER,
                variables: { ...createFlightVariables },
            })
            .then((response) => {
                // eslint-disable-next-line no-console
                // console.log('FLIGHT BASKET/ORDER successfully created:', response.data)
                setCreatingOrder(false)
                if (response) {
                    // eslint-disable-next-line no-console
                    // console.log('redirecting to order page now')
                    const queryParamsString = `orderId=${response.data.orderCreate.id}`
                    goToOrder(queryParamsString)
                }
            })
            .catch((error) => {
                setCreatingOrder(false)
                setErrorCreatingOrder(error)
                datadogLogs.logger.error('OrderCreate flight', { userContext }, error)
            })
    })
}

type DuffelGetFlightLayoutProps = {
    offerId: string
    agentConnectApiClient: ApolloClient<NormalizedCacheObject>
    setFetchingOffer: React.Dispatch<React.SetStateAction<boolean>>
    setOfferDetails: React.Dispatch<React.SetStateAction<FlightOptionsDetails | undefined>>
    setCreatingOrder: React.Dispatch<React.SetStateAction<boolean>>
    setErrorFetchingOffer: React.Dispatch<React.SetStateAction<boolean | null>>
    setErrorCreatingOrder: React.Dispatch<React.SetStateAction<boolean | null>>
}

/** SearchLayout: Layout for the Search page */
const DuffelGetFlight = ({
    offerId,
    agentConnectApiClient,
    setFetchingOffer,
    setOfferDetails,
    setCreatingOrder,
    setErrorFetchingOffer,
    setErrorCreatingOrder,
}: DuffelGetFlightLayoutProps): React.ReactElement => {
    const navigate = useNavigate()

    useEffect(() => {
        function goToOrder(queryParamsString: string): void {
            navigate(`${ROUTES.ORDER}/${queryParamsString}`)
        }
        if (offerId) {
            // eslint-disable-next-line no-console
            // console.log('Calling duffel get offer details with offerId:', offerId)
            setFetchingOffer(true)
            agentConnectApiClient
                .query({
                    query: GET_DUFFEL_OFFER_DETAILS,
                    variables: { offerId, providerId: 'duffel', providerItemId: offerId },
                })
                .then((response) => {
                    // eslint-disable-next-line no-console
                    // console.log('Offer response is:', response)
                    setFetchingOffer(false)
                    const { flightOption } = response.data
                    if (flightOption) {
                        setOfferDetails(flightOption)
                        // eslint-disable-next-line no-console
                        // console.log('Get FlightOption from duffle of:', flightOption)
                        requestOrderWithFlight({
                            agentConnectApiClient,
                            createFlightVariables: {
                                flightId: flightOption.id,
                                providerId: flightOption.providerId,
                                providerItemId: flightOption.providerItemId,
                                grossCost: parseInt(flightOption.grossCost),
                            },
                            setCreatingOrder,
                            setErrorCreatingOrder,
                            goToOrder,
                            navigate,
                        } as RequestOrderWithFlightProps)
                    }
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    // console.log('Get Flight Offer ERROR:', error)
                    setErrorFetchingOffer(error)
                    setFetchingOffer(false)
                })
        } else {
            // If the url doesn't contain an offerId param then redirect to the flight search page
            navigate(ROUTES.FLIGHT_DUFFEL_SEARCH)
            // eslint-disable-next-line no-console
            // console.log(
            //     'url does not have (offerId/window.location.search):',
            //     offerId,
            //     window.location.search
            // )
        }
    }, [
        agentConnectApiClient,
        navigate,
        offerId,
        setCreatingOrder,
        setErrorCreatingOrder,
        setErrorFetchingOffer,
        setFetchingOffer,
        setOfferDetails,
    ])
    return <LargeSpinner text={content.spinnerText} />
}

export default DuffelGetFlight
