import React, { useEffect, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import UserManagement from 'components/sections/admin/UserManagement/UserManagement'
import Navigation from 'components/sections/admin/NavigationPannel/NavigationPanel'
import { handleFetchGroupList } from 'components/sections/admin/GroupManagement/GroupManagement'
import { Group } from 'api-data-models/admin/GroupsContentModel'

import styles from './UserManagementLayout.module.css'

type UserManagementLayoutProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    productTiers: ProductTiersDataType
}

const UserManagementLayout: React.FC<UserManagementLayoutProps> = ({ apiClient, productTiers }) => {
    const [groupsListData, setGroupsListData] = useState<Group[] | []>([])
    const [fetchingGroups, setFetchingGroups] = useState<boolean>(false)
    const [groupsApiErrorMessage, setGroupsApiErrorMessage] = useState<string | null>(null)

    useEffect(() => {
        handleFetchGroupList({
            apiClient,
            setGroupsListData,
            setGroupsApiErrorMessage,
            setFetchingGroups,
        })
    }, [apiClient])
    return (
        <div className='general-container'>
            <div className={styles.container}>
                <Navigation productTiers={productTiers} />
                <UserManagement
                    apiClient={apiClient}
                    groupsListData={groupsListData}
                    fetchingGroups={fetchingGroups}
                    groupsApiErrorMessage={groupsApiErrorMessage}
                />
            </div>
        </div>
    )
}

export default UserManagementLayout
