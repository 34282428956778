type RoomOption = {
    readonly id: string
}

export type Room = {
    readonly roomNumber: string
    readonly roomOptions: RoomOption[]
}

export class Hotel {
    readonly rooms: Room[]
    readonly hotelId: string
    readonly hotelName: string
    readonly hotelAddress: string
    readonly hotelRating: number
    readonly numberOfNights: number
    readonly averagePricePerNight: string
    constructor(hotels: Record<string, any>[]) {
        this.rooms = hotels[0].rooms || []
        this.hotelId = hotels[0].id
        this.hotelName = hotels[0].name
        this.hotelAddress = hotels[0].address
        this.hotelRating = hotels[0].rating
        this.numberOfNights = hotels[0].numberOfNights
        this.averagePricePerNight = hotels[0].averagePricePerNight
    }
}
