import { getDataFromLocalStorage } from './use-local-storage'
import { setContext } from '@apollo/client/link/context'
import { LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY } from './constants'

/** Get the access_token for api headers from local storage - COGNITO and AMPLIFY data structures */

export const getAccessToken = (): string => {
    const authData = getDataFromLocalStorage({ key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY })
    if (authData) {
        /** FOR HOSTED UX cognito user pools */
        if (authData['access_token']) {
            return `Bearer ${authData['access_token']}`
        }

        /** FOR AMPLIFY cognito user pools */
        if (authData.user?.signInUserSession?.accessToken?.jwtToken) {
            return `Bearer ${authData.user.signInUserSession.accessToken.jwtToken}`
        }
    }

    return ''
}

/** Get the id_token for api headers from local storage - COGNITO and AMPLIFY data structures */
export function getIdToken(): string {
    const authData = getDataFromLocalStorage({ key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY })
    if (authData) {
        /** FOR HOSTED UX cognito user pools */
        if (authData['id_token']) {
            return authData['id_token']
        }

        /** FOR AMPLIFY cognito user pools */
        if (authData.user?.signInUserSession?.idToken?.jwtToken) {
            return authData.user.signInUserSession.idToken?.jwtToken
        }
    }
    return ''
}

const authHeader = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            // TODO: Why do ours services have 3 ways to get the same authData in the headers? lol
            authorization: getAccessToken(), // EKS
            authorizationToken: getAccessToken(), // connect-manager-service
            tokens: JSON.stringify({
                authorization_token: getAccessToken(), // jarvis
                id_token: getIdToken(), // EKS
            }),
        },
    }
})

export default authHeader
