import React, { useEffect, useState } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useLocation, useNavigate } from 'react-router-dom'

import Breadcrumb from 'components/basics/Breadcrumb/Breadcrumb'
import Heading from 'components/basics/Heading/Heading'
import CabinInfoSection from 'components/sections/cruise/CabinInfoSection/CabinInfoSection'
import CabinPickerFormSection from 'components/sections/cruise/CabinPickerFormSection/CabinPickerFormSection'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import Spacing from 'components/basics/Spacing/Spacing'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'

import { CabinContent } from 'api-data-models/CabinContentModel'
import { PRODUCT_TYPES } from 'api-data-models/order/OrderContentModel'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { GET_CABIN_BY_RATE_CODE_AND_CABIN_GRADE_CODE } from 'graphql-queries/cruise/cruise-queries'
import { REFUND_POLICIES, VITALLY_EVENTS, HTTP_METHODS } from 'utils/constants'
import {
    CRUISE_SEARCH_DATA_KEY,
    getCruiseSearchQueryInput,
} from 'components/pages/cruise/SearchPage'
import { checkAndPerformUserSessionRefreshIfNeeded } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'
import { checkCabinParams } from './CabinLayout.utils'
import { buildApiVariables } from '../buildApiVariables.utils'
import { createQueryParamString } from 'utils/create-query-param-string'
import { getDataFromLocalStorage } from 'utils/use-local-storage'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'
import { usePassengersInfo } from 'components/hooks/usePassengersInfo'
import { useApolloQuery } from 'components/hooks/useApolloQuery'

import * as self from './CabinLayout'

import styles from './CabinLayout.module.css'
import allContent from 'content/content'
import callApi from 'services/callApi/callApi.service'
import { orderServiceUrls } from 'utils/order-service-urls'
const content = allContent.cruise.cabinPage
const breadcrumbContent = allContent.app.breadcrumbs

export const getResultsAndSailingUrls = (cruiseId?: string, supplierCode?: string): any => {
    const previousSearchParams = getDataFromLocalStorage({ key: CRUISE_SEARCH_DATA_KEY })

    let resultsPageUrl = ROUTES.CRUISE_RESULTS
    if (previousSearchParams) {
        const queryInput = getCruiseSearchQueryInput(previousSearchParams)
        const cruiseSearchQueryParamsString = createQueryParamString(queryInput)
        resultsPageUrl = `${ROUTES.CRUISE_RESULTS}/?${cruiseSearchQueryParamsString}`
    }

    let sailingPageUrl = ROUTES.CRUISE_SAILING
    if (cruiseId && supplierCode) {
        sailingPageUrl = `${ROUTES.CRUISE_SAILING}/?cruiseId=${cruiseId}&supplierCode=${supplierCode}`
    }

    return { resultsPageUrl, sailingPageUrl }
}

export type CreateOrderVariables = {
    numberOfPassengers: number
    cabinBedConfigurationCode: string
    cabinGradeCode: string
    cabinNumber: string
    rateCode: string
    cruiseId: string
    supplierCode: string
    cabinDiningOption: string
    specialRequest?: Record<string, any>
    price: number
    passengers?: { age: number; travellerNumber: number }[]
}

export const onCabinSubmit = ({
    orderCreateServiceUrl,
    createOrderVariables,
    setSubmitting,
    handleNavigate,
    clearPassengers,
    setCreateOrderApiError, // Add this argument
}: {
    orderCreateServiceUrl: string
    createOrderVariables: CreateOrderVariables
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
    handleNavigate: (url: string) => void
    clearPassengers: () => void
    setCreateOrderApiError: React.Dispatch<React.SetStateAction<string | null>> // Add this argument
}): void => {
    const userContext = datadogLogs.getGlobalContext()
    const handleUserSession = checkAndPerformUserSessionRefreshIfNeeded(handleNavigate)
    // Map passengers to snack case key names
    const passengers: { age: number; traveller_number: number }[] =
        createOrderVariables.passengers?.map(
            (passenger: {
                age: number
                travellerNumber: number
            }): { age: number; traveller_number: number } => ({
                traveller_number: passenger.travellerNumber,
                age: passenger.age,
            })
        ) ?? []
    handleUserSession.then(() => {
        const requestBody = {
            items: [
                {
                    product_type: 'Cruise',
                    attributes: [
                        {
                            Cruise: {
                                id: createOrderVariables.cruiseId,
                                rate_code: createOrderVariables.rateCode,
                                cabin_number: createOrderVariables.cabinNumber,
                                price: createOrderVariables.price, // number
                                cruise_line: createOrderVariables.supplierCode,
                                cabin_grade_code: createOrderVariables.cabinGradeCode,
                                number_of_passengers: createOrderVariables.numberOfPassengers, // number
                                cabin_bed_configuration_code:
                                    createOrderVariables.cabinBedConfigurationCode,
                                cabin_dining_option: createOrderVariables.cabinDiningOption,
                                // specialRequest: createOrderVariables.specialRequest, // not used on form so don't submit
                            },
                        },
                    ],
                },
            ],
            passengers: { passengers: passengers },
        }
        callApi({
            url: orderCreateServiceUrl,
            method: HTTP_METHODS.POST,
            variables: requestBody,
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                setCreateOrderApiError(null)
                let dataString = 'undefined'
                if (data) {
                    dataString =
                        // DataDog char limit
                        JSON.stringify(data).length > 225280
                            ? 'Data too large to show'
                            : JSON.stringify(data)
                }
                datadogLogs.logger.info(
                    `source: OrderCreateCruise, Fetch-variables: ${JSON.stringify(
                        createOrderVariables
                    )}, Fetch-response-data: ${dataString}`,
                    { userContext }
                )
                if (data) {
                    const queryParamsString = `orderId=${data.id}`
                    handleNavigate(`${ROUTES.ORDER}/?${queryParamsString}&new-order=yes`)
                }
            })
            .catch((error) => {
                datadogLogs.logger.error(
                    `source: OrderCreateCruise, REST-request-variables: ${JSON.stringify(
                        createOrderVariables
                    )}, error-message: ${JSON.stringify(error)}`,
                    { userContext },
                    error
                )
                setCreateOrderApiError(content.errorSubmit)
            })
            .finally(() => {
                setSubmitting(false)
                clearPassengers() // TODO: SHOULD WE DO THIS??? yes because it contains past passenger numbers, that should not accidentally be used on the next booking.
                /** Log the action of adding to basket here so both failed and successful get logged. */
                CustomerSuccess.track({
                    eventName: VITALLY_EVENTS.ADD_TO_BASKET,
                    properties: {
                        product: PRODUCT_TYPES.CRUISE,
                        cruiseId: createOrderVariables.cruiseId,
                        supplierCode: createOrderVariables.supplierCode,
                        rateCode: createOrderVariables.rateCode,
                        cabinGradeCode: createOrderVariables.cabinGradeCode,
                        numberOfPassengers: createOrderVariables.numberOfPassengers,
                        cabinBedConfigurationCode: createOrderVariables.cabinBedConfigurationCode,
                        cabinNumber: createOrderVariables.cabinNumber,
                        cabinDiningOption: createOrderVariables.cabinDiningOption,
                        price: createOrderVariables.price,
                    },
                })
            })
    })
}

/** CabinLayout: Layout for the Cabin select page */
const CabinLayout: React.FC<{ apiClient: ApolloClient<NormalizedCacheObject> }> = ({
    apiClient,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const [submitting, setSubmitting] = useState<boolean>(false)
    const [cabinData, setCabinData] = useState<CabinContent | undefined>(undefined)
    const [createOrderApiError, setCreateOrderApiError] = useState<string | null>(null)
    const [selectedDeck, setSelectedDeck] = useState<string>('') // to track the value of selected deck
    const orderCreateServiceUrl = orderServiceUrls.createOrder()
    function handleNavigate(url: string): void {
        navigate(url)
    }

    const { numberOfPassengers, passengerConfigurationDataAsQueryVar, clearPassengers } =
        usePassengersInfo()
    const queryVariables = buildApiVariables({ passengerConfigurationDataAsQueryVar, queryParams })
    const hasCorrectParams = checkCabinParams(queryParams)

    const cruiseId = queryVariables?.cruiseId
    const supplierCode = queryVariables?.supplierCode
    const cabinGradeCode = queryVariables?.cabinGradeCode
    const rateCode = queryVariables?.rateCode

    const { resultsPageUrl, sailingPageUrl } = self.getResultsAndSailingUrls(
        cruiseId, // switched to
        queryVariables?.supplierCode
    )

    const {
        result,
        loading,
        error: errorFetching,
    } = useApolloQuery({
        client: apiClient,
        variables: queryVariables,
        query: GET_CABIN_BY_RATE_CODE_AND_CABIN_GRADE_CODE,
        source: 'CabinLayout - GET_CABIN_BY_RATE_CODE_AND_CABIN_GRADE_CODE',
        skip: !hasCorrectParams, // boolean to skip calling api when we know it'll fail due to lack of params
    })

    useEffect(() => {
        if (result?.data?.cruise) {
            const cabinData = new CabinContent(result.data.cruise)
            setCabinData(cabinData)

            // Display deck of default selected cabin number as a default deck if defaultSelectedCabinNumber is present otherwise display first deck in list
            const defaultDeck = cabinData?.defaultSelectedCabinNumber
                ? cabinData.cabins[cabinData?.defaultSelectedCabinNumber].deck.name
                : cabinData.decks[cabinData.deckNames?.[0]]?.name
            setSelectedDeck(defaultDeck)

            const vitallyProperties = {
                cruiseId: cruiseId,
                supplierCode: supplierCode,
                rateCode: rateCode,
                cabinGradeCode: cabinGradeCode,
            }

            CustomerSuccess.track({
                eventName: VITALLY_EVENTS.LIVE_PRICING_CABIN,
                properties: vitallyProperties,
            })
        }
    }, [cabinGradeCode, cruiseId, rateCode, supplierCode, result])

    const breadcrumbItems = [
        { text: breadcrumbContent.search, url: ROUTES.CRUISE_SEARCH },
        { text: breadcrumbContent.results, url: resultsPageUrl },
        { text: breadcrumbContent.sailing, url: sailingPageUrl },
        { text: breadcrumbContent.cabin },
    ]

    const processingResultData = !!result && !cabinData && errorFetching

    const anyCabins = !!cabinData?.defaultSelectedCabinNumber // if default is null then there are no cabins

    const refundPolicy = cabinData?.rateCodes[0]?.refundPolicy
    const isNotRefundable = refundPolicy === REFUND_POLICIES.NON_REFUNDABLE_FARE

    const renderError = (): React.ReactNode | null => {
        if (!hasCorrectParams) {
            return (
                <InfoBanner
                    id='error-missing-params'
                    text={content.incorrectParams}
                    bannerType='error'
                    logType='info'
                    source='cabin-page'
                />
            )
        } else if (errorFetching) {
            return <ErrorList errorsList={errorFetching} source='cabin-page' />
        }
        if (cabinData && !cabinData?.defaultSelectedCabinNumber) {
            return (
                <InfoBanner
                    id='error-no-cabins'
                    text={content.noCabins}
                    bannerType='error'
                    logType='error'
                    source='cabin-page_no-cabins' // this needs specific tag so we can track it for investigating
                />
            )
        }
        return null
    }

    return (
        <div className='general-container'>
            <div className={styles.container}>
                <div className={styles['cabin-content-wrapper']}>
                    <Breadcrumb urlList={breadcrumbItems} />
                    {(!!errorFetching ||
                        !hasCorrectParams ||
                        !cabinData?.defaultSelectedCabinNumber) &&
                        renderError()}
                    {(loading || processingResultData) && (
                        <LargeSpinner text={content.fetchingResults} />
                    )}
                    {cabinData && anyCabins && !processingResultData && (
                        <>
                            <Spacing size='quadruple'>
                                <Heading heading='1'>{cabinData.productName}</Heading>
                            </Spacing>
                            <div className={styles.sections}>
                                {isNotRefundable && (
                                    <InfoBanner
                                        id='non-refundable-warning'
                                        isCloseable={false}
                                        bannerType='info'
                                        text={content.nonRefundable}
                                    />
                                )}
                                {createOrderApiError && (
                                    <InfoBanner
                                        id='item-create-api-error'
                                        isFocusable={true}
                                        text={createOrderApiError} // do not need to log because api errors are logged already
                                        bannerType='error'
                                    />
                                )}
                                <CabinInfoSection cabinData={cabinData} />
                                <CabinPickerFormSection
                                    numberOfPassengers={numberOfPassengers}
                                    cabinData={cabinData}
                                    setSelectedDeck={setSelectedDeck}
                                    defaultSelectedCabinNumber={
                                        cabinData.defaultSelectedCabinNumber
                                    }
                                    submitting={submitting}
                                    setSubmitting={setSubmitting}
                                    selectedDeck={selectedDeck}
                                    onSubmit={(formData, setSubmitting): void => {
                                        self.onCabinSubmit({
                                            orderCreateServiceUrl,
                                            createOrderVariables: {
                                                // Add passenger data and cruise id to form fields for api call body
                                                ...formData,
                                                cruiseId,
                                                passengers: passengerConfigurationDataAsQueryVar,
                                            },
                                            setSubmitting,
                                            handleNavigate,
                                            clearPassengers,
                                            setCreateOrderApiError,
                                        })
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CabinLayout
