import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import Button from 'components/basics/Button/Button'
import Heading from 'components/basics/Heading/Heading'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import Link from 'components/basics/Link/Link'
import Select from 'components/basics/Input/Select/Select'
import Spacing from 'components/basics/Spacing/Spacing'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { COMPANY_TIERS, LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY, PRODUCT_TIERS } from 'utils/constants'
import { getDataFromLocalStorage } from 'utils/use-local-storage'
import { extractCognitoFields } from 'utils/cognito-helpers/extract-cogntio-fields'
import { checkAndPerformUserSessionRefreshIfNeeded } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'

import styles from './LandingPage.module.css'
import allContent from 'content/content'
import Text from '../../basics/Text/Text'
import { isTestEmailDomain } from '../../../utils/testing-handlers'

const content = allContent.landingPage
const subscribeContent = content.subscribeForm

export function getChargeBeUrl({
    tier,
    firstName,
    lastName,
    email,
    companyName,
}: {
    tier: CompanyTiers
    firstName: string
    lastName: string
    email: string
    companyName: string
}): string {
    const chargeBeeTierNames: Record<CompanyTiers, string> = {
        CRUISESTARTER: 'CruiseStarter',
        CRUISEPLUS: 'CruisePlus',
        CRUISEENTERPRISE: 'CruiseEnterprise',
    }
    return (
        process.env.REACT_APP_CHARGEBEE_CHECKOUT_URL +
        `?subscription_items[item_price_id][0]=${encodeURIComponent(
            chargeBeeTierNames[tier]
        )}-USD-Monthly&utm_source=cb-app-copy&customer[email]=${encodeURIComponent(
            email
        )}&customer[first_name]=${encodeURIComponent(
            firstName
        )}&customer[last_name]=${encodeURIComponent(
            lastName
        )}&customer[company]=${encodeURIComponent(companyName)}`
    )
}

/** LandingPage is needed to handle where user should go after being Authorised.
 * Show spinner while fetching userData, and handle based on products paid for and user role.
 * Is also the holding area for if user has no paid products, or there's an error in fetching user data.
 */
const LandingPage: React.FC<{
    isFetchingConnectUserData: boolean
    errorFetchingUserData: boolean
    productTiers: ProductTiersDataType
    isAdminUser: boolean
    tenantFinanceId?: string
    companyName?: string
    isDemoUrl: boolean
}> = ({
    errorFetchingUserData,
    isFetchingConnectUserData,
    productTiers,
    isAdminUser,
    tenantFinanceId,
    companyName = '',
    isDemoUrl,
}) => {
    const navigate = useNavigate()
    const [selectedTier, setSelectedTier] = React.useState<CompanyTiers>(
        COMPANY_TIERS.CRUISE_STARTER
    )

    /** Show spinner while waiting for getUserData so app can determine what apps/pages/roles the user has access to */
    if (isFetchingConnectUserData) {
        return (
            <div className='general-container'>
                <LargeSpinner text={content.fetchingUserData} />
            </div>
        )
    }

    if (!tenantFinanceId && !isDemoUrl) {
        const cognitoDataLocalPool = getDataFromLocalStorage({
            key: LOCAL_STORAGE_COGNITO_AUTH_DATA_KEY,
        })
        const { firstName, lastName, email } = extractCognitoFields(cognitoDataLocalPool)
        /** User can sit on this page beyond cognito session timeout without the app knowing, so should check and refresh if needed to avoid the submit failing */
        checkAndPerformUserSessionRefreshIfNeeded(navigate).then()
        const saasChargeBeeEnabled =
            process.env.REACT_APP_CHARGEBEE_CHECKOUT_URL && isTestEmailDomain(email)

        return (
            <div className='general-container'>
                <div className={styles['choose-tier-container']}>
                    <Heading heading='1' className={styles.heading}>
                        {subscribeContent.title}
                    </Heading>
                    <div className={styles['choose-tier-content']}>
                        <LabelledInput
                            htmlFor='tier-select'
                            label={subscribeContent.confirmSelectLabel}
                        >
                            <Select
                                options={[
                                    {
                                        text: subscribeContent.cruiseStarterOptionLabel,
                                        value: COMPANY_TIERS.CRUISE_STARTER,
                                    },
                                    {
                                        text: subscribeContent.cruisePlusOptionLabel,
                                        value: COMPANY_TIERS.CRUISE_PLUS,
                                    },
                                ]}
                                onChange={(e): void =>
                                    setSelectedTier(e.target.value as CompanyTiers)
                                }
                            />
                        </LabelledInput>
                        <dl>
                            <dt>
                                <Text weight='bold'>Company:</Text>
                            </dt>
                            <dd>
                                <Text>{companyName}</Text>
                            </dd>
                            <dt>
                                <Text weight='bold'>Name:</Text>
                            </dt>
                            <dd>
                                <Text>{firstName + ' ' + lastName}</Text>
                            </dd>
                            <dt>
                                <Text weight='bold'>Email:</Text>
                            </dt>
                            <dd>
                                <Text>{email}</Text>
                            </dd>
                        </dl>
                        {saasChargeBeeEnabled ? (
                            <Link
                                href={getChargeBeUrl({
                                    tier: selectedTier,
                                    firstName,
                                    lastName,
                                    email,
                                    companyName,
                                })}
                                className={styles['full-width']}
                                noUnderLine={true}
                            >
                                <Button
                                    className={styles['full-width']}
                                    type='button'
                                    text={subscribeContent.subscribeButton}
                                />
                            </Link>
                        ) : (
                            <Spacing size='double'>
                                <p>
                                    {/* TO BE DELETED ONCE WE GO LIVE WITH CHARGEBEE, don't expect any old way signs away but better safe */}
                                    <Text>Please contact our team to make payment, using</Text>
                                    <Text weight='bold'> round blue button </Text>
                                    <Text>button in the bottom right.</Text>
                                </p>
                            </Spacing>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    if (productTiers.AGENTCONNECTCRUISE === PRODUCT_TIERS.PAID) {
        /** FOR NOW CRUSE SEARCH IS LANDING PAGE FOR EVERYONE */
        return <Navigate to={ROUTES.CRUISE_SEARCH} />
    } else if (errorFetchingUserData) {
        return (
            <div className='general-container'>
                <InfoBanner
                    source={'landing-page-get-user-data'}
                    logType='error'
                    text={content.fetchingProductTiersError}
                    bannerType='error'
                    id='get-user-data-banner'
                />
            </div>
        )
    } else {
        return (
            <div className='general-container'>
                <p>{content.noPaidProducts}</p>
            </div>
        )
    }
}

export default LandingPage
