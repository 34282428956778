import React from 'react'

import Heading from 'components/basics/Heading/Heading'
import SearchBar from 'components/sections/flight/SearchBar/SearchBar'
import styles from './SearchLayout.module.css'
import allContent from 'content/content'

const content = allContent.flight.searchPage

/** SearchLayout: Layout for the Search page */
const SearchLayout: React.FC = () => {
    return (
        <div className={styles.background}>
            <div className='general-container'>
                <div className={styles.header}>
                    <Heading heading='1' onDarkBackground={true}>
                        <span>{content.headerTitlePrefix}</span>
                        <span className={styles.bold}>{content.headerTitleBold}</span>
                    </Heading>
                </div>
                <div className={styles.form}>
                    <SearchBar />
                </div>
            </div>
        </div>
    )
}

export default SearchLayout
