import React, { useEffect, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import RoomDetailsLayout from 'components/layouts/hotel/RoomDetailsLayout/RoomDetailsLayout'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import { useApolloQuery } from 'components/hooks/useApolloQuery'
import { GET_HOTEL_ROOMS } from 'graphql-queries/hotel/hotel-queries'
import { Hotel } from 'api-data-models/HotelRoomsContentModel'
import allContent from 'content/content'

const content = allContent.hotel.resultsPage

const RoomDetailsPage: React.FC<{
    apiClient: ApolloClient<NormalizedCacheObject>
}> = ({ apiClient }): JSX.Element => {
    const [hotel, setHotel] = useState<Hotel | undefined>(undefined)

    const hotelQueryParams = {
        hotelId: '9215515',
        checkInDate: '2023-07-21',
        checkOutDate: '2023-07-22',
    } // TODO: remove once api returning hotelSearch includes working hotelId's.

    const { result, loading, error } = useApolloQuery({
        client: apiClient,
        variables: hotelQueryParams,
        query: GET_HOTEL_ROOMS,
        source: 'RoomDetailsPage - GET_HOTEL_ROOMS',
    })

    useEffect(() => {
        if (result?.data?.hotelSearch?.hotelOptions) {
            const hotelRoomsResult: Hotel = new Hotel(result.data.hotelSearch.hotelOptions)
            setHotel(hotelRoomsResult)
        }
    }, [loading, result])

    const processingResultData = !!result && !hotel && !error

    return (
        <div className='general-container'>
            {(loading || processingResultData) && <LargeSpinner text={content.fetchingResults} />}
            {error && <p>{content.error}</p>}
            {hotel && hotel.rooms.length > 0 && <RoomDetailsLayout hotel={hotel} />}
        </div>
    )
}

export default RoomDetailsPage
