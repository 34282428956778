import React from 'react'

import ListItem from 'components/basics/List/ListItem'
import RoomSection from './RoomListItemComponents/RoomDetailsSection'
import { Room } from 'api-data-models/HotelRoomsContentModel'
import styles from './RoomListItem.module.css'

type RoomListItemProps = React.AllHTMLAttributes<HTMLLIElement> & {
    listKey: string
    room: Room
}
const RoomListItem: React.FC<RoomListItemProps> = ({ listKey, room }) => {
    return (
        <ListItem className={styles.container} listKey={listKey}>
            <RoomSection room={room} />
        </ListItem>
    )
}
export default RoomListItem
