import React, { useEffect, useState, useContext } from 'react'

import Button from 'components/basics/Button/Button'
import Card from 'components/blocks/Card/Card'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import { FeatureToggleContext } from 'App'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import Link from 'components/basics/Link/Link'
import Text from 'components/basics/Text/Text'
import {
    AlternativeSailingDate,
    AlternativeSailingDatesFromApi,
    AlternativeSailingDatesFunction,
} from '../../../../api-data-models/AlternativeSailingDatesModel'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { useRest, UseRestOptions } from 'components/hooks/useRest'

import styles from './AlternativeSailingDates.module.scss'
import allContent from 'content/content'
import { shouldDisableSelectButton } from '../ResultsList/ResultsListItem/ResultsListItemComponents/PricingSection'
import { SupplierCodes } from 'utils/constants'
import Tooltip from 'components/basics/Tooltip/Tooltip'

const content = allContent.cruise.sharedSections.alternativeSailingDates

const AlternativeSailingDates: React.FC<{
    cruiseId: string
    supplierCode: string
}> = ({ cruiseId, supplierCode }): JSX.Element | null => {
    const context = useContext(FeatureToggleContext)
    const [alternativeSailDatesData, setAlternativeSailDatesData] =
        useState<AlternativeSailingDate[]>()
    const fetchOptions: UseRestOptions = {
        url:
            process.env.REACT_APP_CRUISE_SEARCH_SERVICE_URL +
            `/alternative_sailings?cruise_id=${cruiseId}`,
        source: 'ResultsPage - ALTERNATIVE_SAILINGS',
        method: 'GET',
    }

    const { result, loading, error } = useRest(fetchOptions)

    useEffect(() => {
        if (result) {
            setAlternativeSailDatesData(
                AlternativeSailingDatesFunction(result as AlternativeSailingDatesFromApi[])
            )
        }
    }, [result])

    const disableButton = shouldDisableSelectButton(supplierCode as SupplierCodes, context)

    const alternativeSailingRows = alternativeSailDatesData
        ? alternativeSailDatesData.map((alternativeSailingDate: AlternativeSailingDate) => {
              const selectButton = disableButton ? (
                  <Tooltip id='selectButtonTooltip' content={content.buttons.tooltip}>
                      <Button
                          text={content.buttons.select}
                          type='button'
                          flavour='secondary'
                          disabled={disableButton}
                      />
                  </Tooltip>
              ) : (
                  <Link
                      noUnderLine={true}
                      to={`${ROUTES.CRUISE_SAILING}/?cruiseId=${alternativeSailingDate.cruiseId}&supplierCode=${supplierCode}`}
                  >
                      <Button
                          text={content.buttons.select}
                          type='button'
                          flavour='secondary'
                          disabled={disableButton}
                      />
                  </Link>
              )
              return (
                  <tr className={styles['table-body-row']} key={alternativeSailingDate.cruiseId}>
                      <td>
                          <Text>{alternativeSailingDate.embarkDate}</Text>
                      </td>
                      <td>
                          <Text weight='bold'>
                              {alternativeSailingDate.pricing.inside ?? content.notAvailable}
                          </Text>
                      </td>
                      <td>
                          <Text weight='bold'>
                              {alternativeSailingDate.pricing.outside ?? content.notAvailable}
                          </Text>
                      </td>
                      <td>
                          <Text weight='bold'>
                              {alternativeSailingDate.pricing.balcony ?? content.notAvailable}
                          </Text>
                      </td>
                      <td>
                          <Text weight='bold'>
                              {alternativeSailingDate.pricing.suite ?? content.notAvailable}
                          </Text>
                      </td>
                      <td className={styles['table-button']}>{selectButton}</td>
                  </tr>
              )
          })
        : null

    return (
        <>
            {loading && <LargeSpinner text={content.spinnerText} />}
            {error && <ErrorList errorsList={error} source='Alternative Sailing Dates' />}
            {!!alternativeSailDatesData && alternativeSailDatesData.length === 0 && (
                <p>Sorry, we could not find any alternative sailings this time.</p>
            )}
            {!!alternativeSailDatesData && alternativeSailDatesData.length > 0 && (
                <div className={styles.container}>
                    <Card>
                        <table className={styles['table']}>
                            <thead>
                                <tr>
                                    <td>
                                        <Text weight='bold' color='white'>
                                            {content.date}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text weight='bold' color='white'>
                                            {content.grades.inside}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text weight='bold' color='white'>
                                            {content.grades.outside}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text weight='bold' color='white'>
                                            {content.grades.balcony}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text weight='bold' color='white'>
                                            {content.grades.suite}
                                        </Text>
                                    </td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>{alternativeSailingRows}</tbody>
                        </table>
                    </Card>
                </div>
            )}
        </>
    )
}

export default AlternativeSailingDates
