import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import SailingLayout from 'components/layouts/cruise/SailingLayout/SailingLayout'

/** SailingPage: returns either a component that render the details of a sailing, or false */
const SailingPage: React.FC<{ apiClient: ApolloClient<NormalizedCacheObject> }> = ({
    apiClient,
}): JSX.Element => {
    return <SailingLayout apiClient={apiClient} />
}

export default SailingPage
